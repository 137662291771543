//底部标签栏

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';
import Icon from "../../../utils/icon";
import style from '../../../utils/config.scss'
import PublicUtils from '../../../utils/publicUtils'
import model from "../../../services/model";
import Image from "../../image";
import global from "../../../global";

class Theme1 extends React.Component {
    // 构造
    constructor(props) {
        super(props);
    }

    static propTypes = {
        tabIndex: PropTypes.number
    };
    static defaultProps = {
        tabIndex: 0
    };

    /**
     * 导航
     * @private
     */
    _navigate = (path) => {
        if (PublicUtils._checkLogin()) {
            PublicUtils.navigate(path);
        } else {
            PublicUtils.navigate('Login', {nextPage: path});
        }
    };

    render() {

        let bgcolor, bgcolor2;
        switch (global.ID) {
            case 'x9':
                bgcolor = "#996500";
                bgcolor2 = "#b57e0f";
                break;
            case 'w7':
                bgcolor = "#091223";
                bgcolor2 = "#1d3345";
                break;
            default:
                bgcolor = "#037f5f";
                bgcolor2 = "#037f5f";
                break;
        }

        const styles = {
            container: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                height: model.bottomTabHeight,
                backgroundColor: bgcolor,
                boxShadow: "0 -5px 10px rgba(0,0,0,0.08)",
                maxWidth: "640px",
                margin: "auto"
            },
            tabItem: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontSize: 12
            },
            tabItemName: {
                marginTop: 5
            },
            gameTabItem: {
                marginTop: -4
            },
            actived: {
                color: style['primaryLight' + model.SKINNAME],
                marginTop: 5
            },
            gameTabBox: {
                width: 55,
                height: 55,
                borderRadius: 55,
                display: "flex",
                backgroundColor: bgcolor2,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-27px",
                boxShadow: "0 -5px 10px rgba(0,0,0,0.08)"
            },
            gameTab: {
                width: 50,
                height: 50,
                borderRadius: 50,
                // backgroundColor: style['primaryColor' + model.SKINNAME],
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                // boxShadow: "0 0px 10px rgba(0,0,0,0.3)"
            },
            gameTabImg: {
                fill: "red",
                width: "70%",
                marginLeft: 1
            }
        };
        return (
            <div style={styles.container} className={'footPadding'}>
                <div style={styles.tabItem} onClick={() => {
                    PublicUtils.navigate('/');
                }}>
                    <Icon icon={'new-home'} size={18}
                          color={this.props.tabIndex === 0 ? style['primaryLight' + model.SKINNAME] : "#fff"}/>
                    <span style={this.props.tabIndex === 0 ? styles.actived : styles.tabItemName}>首页</span>
                </div>
                <div style={styles.tabItem} onClick={() => {
                    this._navigate('/wallet');
                }}>
                    <Icon icon={'qianbao'} size={18}
                          color={this.props.tabIndex === 1 ? style['primaryLight' + model.SKINNAME] : "#fff"}/>
                    <span style={this.props.tabIndex === 1 ? styles.actived : styles.tabItemName}>钱包</span>
                </div>
                <div style={styles.tabItem} onClick={() => {
                    PublicUtils.navigate('RechargeChannel');
                }}>
                    <div style={styles.gameTabBox}>
                        <div style={styles.gameTab}>
                            {/*<Icon icon={'youxi1'} size={20}*/}
                            {/*color={"#fff"}/>*/}
                            <Image style={styles.gameTabImg} uri={require("../../../assets/images/chongzhi.png")}/>
                        </div>
                    </div>
                    <span
                        style={{...this.props.tabIndex === 2 ? styles.actived : styles.tabItemName, ...styles.gameTabItem}}>充值</span>
                </div>
                <div style={styles.tabItem} onClick={() => {


                    if(global.ID == 'b3' || global.ID == 'b5'){

                        let xlObj = JSON.parse(localStorage.getItem('line'))


                        PublicUtils.linking(xlObj.url+global.ID+'/service',"_blank");
                        return
                    }

                    PublicUtils.linking(model.serviceUrl,"_blank");
                }}>
                    <Icon icon={'liaotian'} size={20}
                          color={this.props.tabIndex === 3 ? style['primaryLight' + model.SKINNAME] : "#fff"}/>
                    <span style={this.props.tabIndex === 3 ? styles.actived : styles.tabItemName}>客服</span>
                </div>
                <div style={styles.tabItem} onClick={() => {
                    this._navigate('/me');
                }}>
                    <Icon icon={'new-person'} size={20}
                          color={this.props.tabIndex === 4 ? style['primaryLight' + model.SKINNAME] : "#fff"}/>
                    <span style={this.props.tabIndex === 4 ? styles.actived : styles.tabItemName}>我的</span>
                </div>
            </div>
        )
    }
}


export default withRouter(Theme1);
