/**
 * 玩法说明
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../../component/container";
import PublicUtils from "../../../utils/publicUtils";
import Navigate from '../../../component/navigate'
import Http from "../../../services/http";
import $ from 'jquery'

export default class LotteryIntroductionDetail extends Component {
    // 构造
    constructor(props) {
        super(props);
        // alert(this.props.navigation.getParam("code"));
        this.name = PublicUtils.getState(this.props.location.pathname).name;
        this.title = PublicUtils.getState(this.props.location.pathname).title;
        this.state = {
            extHTML: ""
        }
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._renderInfo()
    };
    _renderInfo = () => {
        let url = "";
        switch (this.name) {
            case "一般体育说明":
                url = "Rule_ybtysm.html";
                break;
            case "足球":
                url = "Rule_zq.html";
                break;
            case "篮球":
                url = "Rule_Lq.html";
                break;
            case "网球":
                url = "Rule_wq.html";
                break;
            case "排球":
                url = "Rule_Pq.html";
                break;
            case "棒球":
                url = "Rule_bq.html";
                break;
            case "乒乓球":
                url = "Rule_bbq.html";
                break;
            case "羽毛球":
                url = "Rule_xmq.html";
                break;
            case "美式足球":
                url = "Rule_mszq.html";
                break;
            case "电子竞技":
                url = "Rule_dzjj.html";
                break;
            case "冠军":
                url = "Rule_gj.html";
                break;
            case "连串过关/复式过关":
                url = "Rule_cg.html";
                break;
            case "沙滩足球":
                url = "Rule_stzq.html";
                break;
            case "沙滩排球":
                url = "Rule_stqq.html";
                break;
            case "室内足球":
                url = "Rule_snzq.html";
                break;
            default:
                PublicUtils.showToast("游戏不存在...");
                break;
        }
        $.get("https://newpic.hdyb33.com/new/rule/" + url).then(
            response => {
                this.setState({
                    extHTML: response
                });
            }, err => {
                //handle your error here
            }
        );
    };

    render() {
        const styles = {
            itemContainer: {},
            itemText: {},
        };
        return (
            <Container title={this.title} style={{backgroundColor: "#fff"}}>
                <div dangerouslySetInnerHTML={{__html: this.state.extHTML}}/>
                {/*<div id={'aa'}></div>*/}
            </Container>
        )
    }
}
