/**
 * PC蛋蛋
 * @format
 * @flow
 */
import React, {Component} from 'react';
import Container from "../../component/container";
import MyPicker from "../../component/myPicker";
import BetTypeNav from "./components/betTypeNav";
import BetNumberlist from "./components/betNumberlist";
import RecentOpenList from "./components/recentOpenList";
import Footer from "./components/footer";
import Betbox from "./components/betbox";
import TimeBox from "./components/timeBox";
import style from "../../utils/config.scss";
import Icon from "../../utils/icon";
import model from "../../services/model";
import Http from "../../services/http";
import history from "../../services/history";
import PublicUtils from "../../utils/publicUtils";
import global from "../../global";


export default class LHC extends React.Component {
    // 构造
    constructor(props) {
        super(props);
        this.is_code = PublicUtils.getState(this.props.location.pathname).id;
        this.defaultBetAmount = "";
        this.canBet = true;

        console.log(this.is_code)


      if(this.is_code == 10015 && global.ID == 'bk'){
        // oddType ?什么玩意
        this.betTemplate = [
          {
            index: 0,
            oddType: 7,
            name: "特码",
            subPlayList: [{label: "A面", value: 1}, {label: "B面", value: 2}],
            subPlayInfo: {label: "A面", value: 1},
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          }
        ];
      }else  if(this.is_code == 10015 && global.ID == 'ar'|| this.is_code == 10015 && global.ID == 'br' || this.is_code == 10015 && global.ID == 'bw'){


        this.betTemplate = [
          {
            index: 0,
            oddType: 7,
            name: "特码",
            subPlayList: [{label: "A面", value: 1}, {label: "B面", value: 2}],
            subPlayInfo: {label: "A面", value: 1},
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 1,
            oddType: 7,
            name: "特码生肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 2,
            oddType: 7,
            name: "特码尾数",
            nums: [["0尾", 0], ["1尾", 0], ["2尾", 0], ["3尾", 0], ["4尾", 0], ["5尾", 0], ["6尾", 0], ["7尾", 0], ["8尾", 0], ["9尾", 0]]
          },
          {
            index: 3,
            oddType: 1,
            name: "正一特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 4,
            oddType: 2,
            name: "正二特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 5,
            oddType: 3,
            name: "正三特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 6,
            oddType: 4,
            name: "正四特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 7,
            oddType: 5,
            name: "正五特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 8,
            oddType: 6,
            name: "正六特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40",], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 9,
            oddType: 8,
            name: "正码",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0]]
          },
          {
            index: 17,
            oddType: 19,
            name: "总和",
            selected: false,
            nums: [["大", 0], ["小", 0], ["单", 0], ["双", 0]]
          },

          {
            index: 20,
            oddType: 20,
            name: "正肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 11,
            oddType: 11,
            name: "尾数",
            nums: [["0尾", 0], ["1尾", 0], ["2尾", 0], ["3尾", 0], ["4尾", 0], ["5尾", 0], ["6尾", 0], ["7尾", 0], ["8尾", 0], ["9尾", 0]]
          },

          {
            index: 12,
            oddType: 13,
            name: "连码",
            subPlayList: [
              {
                label: "四全中",
                value: 1,
                min: 4,
                max: 10,
                oddIndex: 1,
                tips: "请选择 4 - 10 个号码",
              },
              {label: "三全中", value: 2, min: 3, max: 10, oddIndex: 2, tips: "请选择 3 - 10 个号码"},
              {label: "三中二", value: 3, min: 3, max: 10, oddIndex: [3, 4], tips: "请选择 3 - 10 个号码"},
              {label: "二全中", value: 5, min: 2, max: 10, oddIndex: 5, tips: "请选择 2 - 10 个号码"},
              {label: "二中特", value: 6, min: 2, max: 10, oddIndex: [6, 7], tips: "请选择 2 - 10 个号码"},
              {label: "特串", value: 8, min: 2, max: 10, oddIndex: 8, tips: "请选择 2 - 10 个号码"}
            ],
            secondSubPlayList: [
              {
                label: "普通",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  }
                ]
              },
              {
                label: "生肖对碰",
                range: [5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  }
                ]
              },
              {
                label: "尾数对碰",
                range: [5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  }
                ]
              },
              {
                label: "肖串尾数",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "主肖",
                    nums:  [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  },
                  {
                    name: "拖尾数",
                    nums: [["1尾", 0, ["01", 11, 21, 31, 41]], ["2尾", 0, ["02", 12, 22, 32, 42]], ["3尾", 0, ["03", 13, 23, 33, 43]], ["4尾", 0, ["04", 14, 24, 34, 44]],
                      ["5尾", 0, ["05", 15, 25, 35, 45]], ["6尾", 0, ["06", 16, 26, 36, 46]], ["7尾", 0, ["07", 17, 27, 37, 47]], ["8尾", 0, ["08", 18, 28, 38, 48]], ["9尾", 0, ["09", 19, 29, 39, 49]], ["0尾", 0, [10, 20, 30, 40]]]
                  }
                ]
              },
              {
                label: "拖胆",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "胆码",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  },
                  {
                    name: "拖码",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  }
                ]
              },
            ],
            subPlayInfo: {label: "四全中", value: 1, min: 4, max: 10, oddIndex: 1, tips: "请选择 4 - 10 个号码"},
            secondSubPlayInfo: {label: "普通", range: [1, 2, 3, 5, 6, 8]},
            nums: [
              {
                name: "",
                nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
              }
            ]
          },
          {
            index: 13,
            oddType: 12,
            name: "合肖",
            subPlayInfo: {min: 2, max: 11, tips: "请选择 2 - 11 个生肖"},
            nums:  [["鼠", 0,1, ['05', 17,29,41]], ["牛", 0, 2,["04",16,28,40]], ["虎", 0, 3,["03",15,27,39]], ["兔", 0, 4,[14,26,38]], ["龙", 0,5, ["01",13,25,37,49]], ["蛇", 0, 6,["12",24,36,48]], ["马", 0,7, ["11",23,35,47]], ["羊", 0, 8,["10",22,34,46]], ["猴", 0,9, ["09",21,33,45]], ["鸡", 0, 10,["08",20,32,44]], ["狗", 0, 11,["07",19,31,43]], ["猪", 0, 12,["06",18,30,42]]]
          },

          {
            index: 15,
            oddType: 15,
            name: "尾数连",
            subPlayList: [
              {
                label: "二尾全中",
                value: 1,
                min: 2,
                max: 6,
                oddIndex: 1,
                tips: "请选择 2 - 6 个尾数",

              },
              {label: "三尾全中", value: 2, min: 3, max: 6, oddIndex: 11, tips: "请选择 3 - 6 个尾数"},
              {label: "四尾全中", value: 3, min: 4, max: 6, oddIndex: 21, tips: "请选择 4 - 6 个尾数"},
              {label: "五尾全中", value: 4, min: 5, max: 6, oddIndex: 31, tips: "请选择 5 - 6 个尾数"}
            ],
            subPlayInfo: {label: "二尾全中", value: 1, min: 2, max: 6, oddIndex: 1, tips: "请选择 2 - 6 个尾数"},
            nums: [["0尾", 0, 1, [10, 20, 30, 40]], ["1尾", 0, 2, ["01", 11, 21, 31, 41]], ["2尾", 0, 3, ["02", 12, 22, 32, 42]], ["3尾", 0, 4, ["03", 13, 23, 33, 43]], ["4尾", 0, 5, ["04", 14, 24, 34, 44]],
              ["5尾", 0, 6,["05", 15, 25, 35, 45]], ["6尾", 0, 7, ["06", 16, 26, 36, 46]], ["7尾", 0, 8, ["07", 17, 27, 37, 47]], ["8尾", 0, 9, ["08", 18, 28, 38, 48]], ["9尾", 0, 10, ["09", 19, 29, 39, 49]]]
          },
          {
            index: 16,
            oddType: 16,
            name: "全不中",
            //id,名称，是否选中，最小投注号码数量，最大投注号码数量
            subPlayList: [
              {label: "五不中", value: 1, min: 5, max: 10, tips: "请选择 5 - 10 个号码"},
              {label: "六不中", value: 2, min: 6, max: 10, tips: "请选择 6 - 10 个号码"},
              {label: "七不中", value: 3, min: 7, max: 10, tips: "请选择 7 - 10 个号码"},
              {label: "八不中", value: 4, min: 8, max: 11, tips: "请选择 8 - 11 个号码"},
              {label: "九不中", value: 5, min: 9, max: 12, tips: "请选择 9 - 12 个号码"},
              {label: "十不中", value: 6, min: 10, max: 13, tips: "请选择 10 - 13 个号码"},
              {label: "十一不中", value: 7, min: 11, max: 13, tips: "请选择 11 - 13 个号码"},
              {label: "十二不中", value: 8, min: 12, max: 14, tips: "请选择 12 - 14 个号码"}
            ],
            // subPlayList: [[1, "五不中", true, 5, 10], [2, "六不中", false, 6, 10], [3, "七不中", false, 7, 10], [4, "八不中", false, 8, 11], [5, "九不中", false, 9, 12], [6, "十不中", false, 10, 13], [7, "十一不中", false, 11, 13], [8, "十二不中", false, 12, 14]],
            subPlayInfo: {label: "五不中", value: 1, min: 5, max: 10, tips: "请选择 5 - 10 个号码"},
            typeid: 1,
            nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
          }
        ];

      }else if(this.is_code == 10015 && global.ID == 'yy'){

        this.betTemplate = [
          {
            index: 0,
            oddType: 7,
            name: "特码",
            subPlayList: [{label: "A面", value: 1}, {label: "B面", value: 2}],
            subPlayInfo: {label: "A面", value: 1},
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 1,
            oddType: 7,
            name: "特码生肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 2,
            oddType: 7,
            name: "特码尾数",
            nums: [["0尾", 0], ["1尾", 0], ["2尾", 0], ["3尾", 0], ["4尾", 0], ["5尾", 0], ["6尾", 0], ["7尾", 0], ["8尾", 0], ["9尾", 0]]
          },
          {
            index: 3,
            oddType: 1,
            name: "正一特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 4,
            oddType: 2,
            name: "正二特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 5,
            oddType: 3,
            name: "正三特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 6,
            oddType: 4,
            name: "正四特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 7,
            oddType: 5,
            name: "正五特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 8,
            oddType: 6,
            name: "正六特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40",], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 9,
            oddType: 8,
            name: "正码",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0]]
          },
          {
            index: 17,
            oddType: 19,
            name: "总和",
            selected: false,
            nums: [["大", 0], ["小", 0], ["单", 0], ["双", 0]]
          }
          ,
          {
            index: 10,
            oddType: 10,
            name: "一肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 20,
            oddType: 20,
            name: "正肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 11,
            oddType: 11,
            name: "尾数",
            nums: [["0尾", 0], ["1尾", 0], ["2尾", 0], ["3尾", 0], ["4尾", 0], ["5尾", 0], ["6尾", 0], ["7尾", 0], ["8尾", 0], ["9尾", 0]]
          },

          {
            index: 12,
            oddType: 13,
            name: "连码",
            subPlayList: [
              {
                label: "四全中",
                value: 1,
                min: 4,
                max: 10,
                oddIndex: 1,
                tips: "请选择 4 - 10 个号码",
              },
              {label: "三全中", value: 2, min: 3, max: 10, oddIndex: 2, tips: "请选择 3 - 10 个号码"},
              {label: "三中二", value: 3, min: 3, max: 10, oddIndex: [3, 4], tips: "请选择 3 - 10 个号码"},
              {label: "二全中", value: 5, min: 2, max: 10, oddIndex: 5, tips: "请选择 2 - 10 个号码"},
              {label: "二中特", value: 6, min: 2, max: 10, oddIndex: [6, 7], tips: "请选择 2 - 10 个号码"},
              {label: "特串", value: 8, min: 2, max: 10, oddIndex: 8, tips: "请选择 2 - 10 个号码"}
            ],
            secondSubPlayList: [
              {
                label: "普通",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  }
                ]
              },
              {
                label: "生肖对碰",
                range: [5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  }
                ]
              },
              {
                label: "尾数对碰",
                range: [5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  }
                ]
              },
              {
                label: "肖串尾数",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "主肖",
                    nums:  [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  },
                  {
                    name: "拖尾数",
                    nums: [["1尾", 0, ["01", 11, 21, 31, 41]], ["2尾", 0, ["02", 12, 22, 32, 42]], ["3尾", 0, ["03", 13, 23, 33, 43]], ["4尾", 0, ["04", 14, 24, 34, 44]],
                      ["5尾", 0, ["05", 15, 25, 35, 45]], ["6尾", 0, ["06", 16, 26, 36, 46]], ["7尾", 0, ["07", 17, 27, 37, 47]], ["8尾", 0, ["08", 18, 28, 38, 48]], ["9尾", 0, ["09", 19, 29, 39, 49]], ["0尾", 0, [10, 20, 30, 40]]]
                  }
                ]
              },
              {
                label: "拖胆",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "胆码",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  },
                  {
                    name: "拖码",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  }
                ]
              },
            ],
            subPlayInfo: {label: "四全中", value: 1, min: 4, max: 10, oddIndex: 1, tips: "请选择 4 - 10 个号码"},
            secondSubPlayInfo: {label: "普通", range: [1, 2, 3, 5, 6, 8]},
            nums: [
              {
                name: "",
                nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
              }
            ]
          },
          {
            index: 13,
            oddType: 12,
            name: "合肖",
            subPlayInfo: {min: 2, max: 11, tips: "请选择 2 - 11 个生肖"},
            nums:  [["鼠", 0,1, ['05', 17,29,41]], ["牛", 0, 2,["04",16,28,40]], ["虎", 0, 3,["03",15,27,39]], ["兔", 0, 4,[14,26,38]], ["龙", 0,5, ["01",13,25,37,49]], ["蛇", 0, 6,["12",24,36,48]], ["马", 0,7, ["11",23,35,47]], ["羊", 0, 8,["10",22,34,46]], ["猴", 0,9, ["09",21,33,45]], ["鸡", 0, 10,["08",20,32,44]], ["狗", 0, 11,["07",19,31,43]], ["猪", 0, 12,["06",18,30,42]]]
          },

          {
            index: 15,
            oddType: 15,
            name: "尾数连",
            subPlayList: [
              {
                label: "二尾全中",
                value: 1,
                min: 2,
                max: 6,
                oddIndex: 1,
                tips: "请选择 2 - 6 个尾数",

              },
              {label: "三尾全中", value: 2, min: 3, max: 6, oddIndex: 11, tips: "请选择 3 - 6 个尾数"},
              {label: "四尾全中", value: 3, min: 4, max: 6, oddIndex: 21, tips: "请选择 4 - 6 个尾数"},
              {label: "五尾全中", value: 4, min: 5, max: 6, oddIndex: 31, tips: "请选择 5 - 6 个尾数"}
            ],
            subPlayInfo: {label: "二尾全中", value: 1, min: 2, max: 6, oddIndex: 1, tips: "请选择 2 - 6 个尾数"},
            nums: [["0尾", 0, 1, [10, 20, 30, 40]], ["1尾", 0, 2, ["01", 11, 21, 31, 41]], ["2尾", 0, 3, ["02", 12, 22, 32, 42]], ["3尾", 0, 4, ["03", 13, 23, 33, 43]], ["4尾", 0, 5, ["04", 14, 24, 34, 44]],
              ["5尾", 0, 6,["05", 15, 25, 35, 45]], ["6尾", 0, 7, ["06", 16, 26, 36, 46]], ["7尾", 0, 8, ["07", 17, 27, 37, 47]], ["8尾", 0, 9, ["08", 18, 28, 38, 48]], ["9尾", 0, 10, ["09", 19, 29, 39, 49]]]
          },
          {
            index: 16,
            oddType: 16,
            name: "全不中",
            //id,名称，是否选中，最小投注号码数量，最大投注号码数量
            subPlayList: [
              {label: "五不中", value: 1, min: 5, max: 10, tips: "请选择 5 - 10 个号码"},
              {label: "六不中", value: 2, min: 6, max: 10, tips: "请选择 6 - 10 个号码"},
              {label: "七不中", value: 3, min: 7, max: 10, tips: "请选择 7 - 10 个号码"},
              {label: "八不中", value: 4, min: 8, max: 11, tips: "请选择 8 - 11 个号码"},
              {label: "九不中", value: 5, min: 9, max: 12, tips: "请选择 9 - 12 个号码"},
              {label: "十不中", value: 6, min: 10, max: 13, tips: "请选择 10 - 13 个号码"},
              {label: "十一不中", value: 7, min: 11, max: 13, tips: "请选择 11 - 13 个号码"},
              {label: "十二不中", value: 8, min: 12, max: 14, tips: "请选择 12 - 14 个号码"}
            ],
            // subPlayList: [[1, "五不中", true, 5, 10], [2, "六不中", false, 6, 10], [3, "七不中", false, 7, 10], [4, "八不中", false, 8, 11], [5, "九不中", false, 9, 12], [6, "十不中", false, 10, 13], [7, "十一不中", false, 11, 13], [8, "十二不中", false, 12, 14]],
            subPlayInfo: {label: "五不中", value: 1, min: 5, max: 10, tips: "请选择 5 - 10 个号码"},
            typeid: 1,
            nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
          }
        ];



      } else{

        this.betTemplate = [
          {
            index: 0,
            oddType: 7,
            name: "特码",
            subPlayList: [{label: "A面", value: 1}, {label: "B面", value: 2}],
            subPlayInfo: {label: "A面", value: 1},
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 1,
            oddType: 7,
            name: "特码生肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 2,
            oddType: 7,
            name: "特码尾数",
            nums: [["0尾", 0], ["1尾", 0], ["2尾", 0], ["3尾", 0], ["4尾", 0], ["5尾", 0], ["6尾", 0], ["7尾", 0], ["8尾", 0], ["9尾", 0]]
          },
          {
            index: 3,
            oddType: 1,
            name: "正一特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 4,
            oddType: 2,
            name: "正二特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 5,
            oddType: 3,
            name: "正三特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 6,
            oddType: 4,
            name: "正四特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 7,
            oddType: 5,
            name: "正五特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 8,
            oddType: 6,
            name: "正六特",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40",], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]]
          },
          {
            index: 9,
            oddType: 8,
            name: "正码",
            nums: [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0]]
          },
          {
            index: 17,
            oddType: 19,
            name: "总和",
            selected: false,
            nums: [["大", 0], ["小", 0], ["单", 0], ["双", 0]]
          }
          ,
          {
            index: 10,
            oddType: 10,
            name: "一肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 20,
            oddType: 20,
            name: "正肖",
            nums: [["鼠", 0], ["牛", 0], ["虎", 0], ["兔", 0], ["龙", 0], ["蛇", 0], ["马", 0], ["羊", 0], ["猴", 0], ["鸡", 0], ["狗", 0], ["猪", 0]]
          },
          {
            index: 11,
            oddType: 11,
            name: "尾数",
            nums: [["0尾", 0], ["1尾", 0], ["2尾", 0], ["3尾", 0], ["4尾", 0], ["5尾", 0], ["6尾", 0], ["7尾", 0], ["8尾", 0], ["9尾", 0]]
          },

          {
            index: 12,
            oddType: 13,
            name: "连码",
            subPlayList: [
              {
                label: "四全中",
                value: 1,
                min: 4,
                max: 10,
                oddIndex: 1,
                tips: "请选择 4 - 10 个号码",
              },
              {label: "三全中", value: 2, min: 3, max: 10, oddIndex: 2, tips: "请选择 3 - 10 个号码"},
              {label: "三中二", value: 3, min: 3, max: 10, oddIndex: [3, 4], tips: "请选择 3 - 10 个号码"},
              {label: "二全中", value: 5, min: 2, max: 10, oddIndex: 5, tips: "请选择 2 - 10 个号码"},
              {label: "二中特", value: 6, min: 2, max: 10, oddIndex: [6, 7], tips: "请选择 2 - 10 个号码"},
              {label: "特串", value: 8, min: 2, max: 10, oddIndex: 8, tips: "请选择 2 - 10 个号码"}
            ],
            secondSubPlayList: [
              {
                label: "普通",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  }
                ]
              },
              {
                label: "生肖对碰",
                range: [5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  }
                ]
              },
              {
                label: "尾数对碰",
                range: [5, 6, 8],
                nums: [
                  {
                    name: "",
                    nums: [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  }
                ]
              },
              {
                label: "肖串尾数",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "主肖",
                    nums:  [["鼠", 0, ['06', 18,30,42]], ["牛", 0,["05",17,29,41]], ["虎", 0,["04",16,28,40]], ["兔", 0,["03",15,27,39]], ["龙", 0, ["02",14,26,38]], ["蛇", 0, ["01",13,25,37,49]], ["马", 0, ["12",24,36,48]], ["羊", 0, ["11",23,35,47]], ["猴", 0, ["10",22,34,46]], ["鸡", 0,["09",21,33,45]], ["狗", 0,["08",20,32,44]], ["猪", 0,["07",19,31,43]]]
                  },
                  {
                    name: "拖尾数",
                    nums: [["1尾", 0, ["01", 11, 21, 31, 41]], ["2尾", 0, ["02", 12, 22, 32, 42]], ["3尾", 0, ["03", 13, 23, 33, 43]], ["4尾", 0, ["04", 14, 24, 34, 44]],
                      ["5尾", 0, ["05", 15, 25, 35, 45]], ["6尾", 0, ["06", 16, 26, 36, 46]], ["7尾", 0, ["07", 17, 27, 37, 47]], ["8尾", 0, ["08", 18, 28, 38, 48]], ["9尾", 0, ["09", 19, 29, 39, 49]], ["0尾", 0, [10, 20, 30, 40]]]
                  }
                ]
              },
              {
                label: "拖胆",
                range: [1, 2, 3, 5, 6, 8],
                nums: [
                  {
                    name: "胆码",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  },
                  {
                    name: "拖码",
                    nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
                  }
                ]
              },
            ],
            subPlayInfo: {label: "四全中", value: 1, min: 4, max: 10, oddIndex: 1, tips: "请选择 4 - 10 个号码"},
            secondSubPlayInfo: {label: "普通", range: [1, 2, 3, 5, 6, 8]},
            nums: [
              {
                name: "",
                nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
              }
            ]
          },
          {
            index: 13,
            oddType: 12,
            name: "合肖",
            subPlayInfo: {min: 2, max: 11, tips: "请选择 2 - 11 个生肖"},
            nums:  [["鼠", 0,1, ['06', 18,30,42]], ["牛", 0, 2,["05",17,29,41]], ["虎", 0, 3,["04",16,28,40]], ["兔", 0, 4,["03",15,27,39]], ["龙", 0,5, ["02",14,26,38]], ["蛇", 0, 6,["01",13,25,37,49]], ["马", 0,7, ["12",24,36,48]], ["羊", 0, 8,["11",23,35,47]], ["猴", 0,9, ["10",22,34,46]], ["鸡", 0, 10,["09",21,33,45]], ["狗", 0, 11,["08",20,32,44]], ["猪", 0, 12,["07",19,31,43]]]
          },
          {
            index: 14,
            oddType: 14,
            name: "生肖连",
            subPlayList: [
              {label: "二肖全中", value: 1, min: 2, max: 6, oddIndex: 1, tips: "请选择 2 - 6 个生肖"},
              {label: "三肖全中", value: 2, min: 3, max: 6, oddIndex: 13, tips: "请选择 3 - 6 个生肖"},
              {label: "四肖全中", value: 3, min: 4, max: 6, oddIndex: 25, tips: "请选择 4 - 6 个生肖"},
              {label: "五肖全中", value: 4, min: 5, max: 6, oddIndex: 37, tips: "请选择 5 - 6 个生肖"}
            ],
            subPlayInfo: {label: "二肖全中", value: 1, min: 2, max: 6, oddIndex: 1, tips: "请选择 2 - 6 个生肖"},
            nums: [["鼠", 0,1, ['06', 18,30,42]], ["牛", 0, 2,["05",17,29,41]], ["虎", 0, 3,["04",16,28,40]], ["兔", 0, 4,["03",15,27,39]], ["龙", 0,5, ["02",14,26,38]], ["蛇", 0, 6,["01",13,25,37,49]], ["马", 0,7, ["12",24,36,48]], ["羊", 0, 8,["11",23,35,47]], ["猴", 0,9, ["10",22,34,46]], ["鸡", 0, 10,["09",21,33,45]], ["狗", 0, 11,["08",20,32,44]], ["猪", 0, 12,["07",19,31,43]]]
          },
          {
            index: 15,
            oddType: 15,
            name: "尾数连",
            subPlayList: [
              {
                label: "二尾全中",
                value: 1,
                min: 2,
                max: 6,
                oddIndex: 1,
                tips: "请选择 2 - 6 个尾数",

              },
              {label: "三尾全中", value: 2, min: 3, max: 6, oddIndex: 11, tips: "请选择 3 - 6 个尾数"},
              {label: "四尾全中", value: 3, min: 4, max: 6, oddIndex: 21, tips: "请选择 4 - 6 个尾数"},
              {label: "五尾全中", value: 4, min: 5, max: 6, oddIndex: 31, tips: "请选择 5 - 6 个尾数"}
            ],
            subPlayInfo: {label: "二尾全中", value: 1, min: 2, max: 6, oddIndex: 1, tips: "请选择 2 - 6 个尾数"},
            nums: [["0尾", 0, 1, [10, 20, 30, 40]], ["1尾", 0, 2, ["01", 11, 21, 31, 41]], ["2尾", 0, 3, ["02", 12, 22, 32, 42]], ["3尾", 0, 4, ["03", 13, 23, 33, 43]], ["4尾", 0, 5, ["04", 14, 24, 34, 44]],
              ["5尾", 0, 6,["05", 15, 25, 35, 45]], ["6尾", 0, 7, ["06", 16, 26, 36, 46]], ["7尾", 0, 8, ["07", 17, 27, 37, 47]], ["8尾", 0, 9, ["08", 18, 28, 38, 48]], ["9尾", 0, 10, ["09", 19, 29, 39, 49]]]
          },
          {
            index: 16,
            oddType: 16,
            name: "全不中",
            //id,名称，是否选中，最小投注号码数量，最大投注号码数量
            subPlayList: [
              {label: "五不中", value: 1, min: 5, max: 10, tips: "请选择 5 - 10 个号码"},
              {label: "六不中", value: 2, min: 6, max: 10, tips: "请选择 6 - 10 个号码"},
              {label: "七不中", value: 3, min: 7, max: 10, tips: "请选择 7 - 10 个号码"},
              {label: "八不中", value: 4, min: 8, max: 11, tips: "请选择 8 - 11 个号码"},
              {label: "九不中", value: 5, min: 9, max: 12, tips: "请选择 9 - 12 个号码"},
              {label: "十不中", value: 6, min: 10, max: 13, tips: "请选择 10 - 13 个号码"},
              {label: "十一不中", value: 7, min: 11, max: 13, tips: "请选择 11 - 13 个号码"},
              {label: "十二不中", value: 8, min: 12, max: 14, tips: "请选择 12 - 14 个号码"}
            ],
            // subPlayList: [[1, "五不中", true, 5, 10], [2, "六不中", false, 6, 10], [3, "七不中", false, 7, 10], [4, "八不中", false, 8, 11], [5, "九不中", false, 9, 12], [6, "十不中", false, 10, 13], [7, "十一不中", false, 11, 13], [8, "十二不中", false, 12, 14]],
            subPlayInfo: {label: "五不中", value: 1, min: 5, max: 10, tips: "请选择 5 - 10 个号码"},
            typeid: 1,
            nums: [["01", 0, 1], ["02", 0, 2], ["03", 0, 3], ["04", 0, 4], ["05", 0, 5], ["06", 0, 6], ["07", 0, 7], ["08", 0, 8], ["09", 0, 9], ["10", 0, 10], ["11", 0, 11], ["12", 0, 12], ["13", 0, 13], ["14", 0, 14], ["15", 0, 15], ["16", 0, 16], ["17", 0, 17], ["18", 0, 18], ["19", 0, 19], ["20", 0, 20], ["21", 0, 21], ["22", 0, 22], ["23", 0, 23], ["24", 0, 24], ["25", 0, 25], ["26", 0, 26], ["27", 0, 27], ["28", 0, 28], ["29", 0, 29], ["30", 0, 30], ["31", 0, 31], ["32", 0, 32], ["33", 0, 33], ["34", 0, 34], ["35", 0, 35], ["36", 0, 36], ["37", 0, 37], ["38", 0, 38], ["39", 0, 39], ["40", 0, 40], ["41", 0, 41], ["42", 0, 42], ["43", 0, 43], ["44", 0, 44], ["45", 0, 45], ["46", 0, 46], ["47", 0, 47], ["48", 0, 48], ["49", 0, 49]]
          }
        ];



      }






        this.lotteryInfo = {
            state: 0,//投注状态 0：正在投注  1：停止投注
            lottery_no: null,//当前期号
            stop_second: 0,
            open_second: 0,
        };
        this.total = {
            count: 0,
            amount: 0
        };
        this.currentBetTemplate = this.betTemplate[0];
        this.currentBetNumber = {nums: []};
        this.state = {
            showBetBox: false,
            showSubPlayTypeFliter: false,
            showSecondSubPlayTypeFliter: false,
            render: false//重新渲染页面
        };
        this.hasSetAmount = false;
    }

    /**
     * 页面进入时
     * @returns {Promise<void>}
     */
    componentWillMount = () => {
        this._init_();
    };

    /**
     * 页面完全进入时
     * @returns {Promise<void>}
     */
    componentDidMount() {
        // this.props.navigation.setParams({openMenu: this._openMenu});
    }

    /**
     * 初始化
     * @private
     */
    _init_ = () => {
        this._fetchLotteryOdds();
        this._fetchLotteryTime();
    };

    /**
     * 获取当前期数和封盘时间
     */
    _fetchLotteryTime() {
        let parm = {is_code: this.is_code};
        Http.request("lotteryTime", parm, (res) => {
            if (res.data && res.data.status) {
                if (res.data.close_a === 0 && res.data.close_b === 0) {
                    this.betTemplate[0].subPlayList = [{
                        label: "A面",
                        value: 1,
                    }, {label: "B面", value: 2}];
                } else if (res.data.close_a === 0 && res.data.close_b !== 0) {
                    this.betTemplate[0].subPlayList = [{
                        label: "A面",
                        value: 1,
                    }];
                } else {
                    this.betTemplate[0].subPlayList = [{label: "B面", value: 2}];
                }
                this.betTemplate[0].subPlayInfo = res.data.close_a === 0 ? {
                    label: "A面",
                    value: 1
                } : {label: "B面", value: 2};
                this.lotteryInfo = {
                    lottery_no: res.data.is_no,//当前期号
                    state: res.data.stop_time > 0 ? 0 : 1,//投注状态 0：正在投注  1：停止投注
                    stop_second: res.data.stop_time,
                    open_second: res.data.open_time,
                    // state: 0,
                    // stop_second: 500,
                    // open_second: 500,
                };
                // this._fetchHistoryResult();
                // this.lotteryBaseInfo.stop_second = 10000;
                // this.lotteryBaseInfo.open_second = 10003;
                // this.getHistoryResult();
                /*当前期正在下注*/
                if (this.lotteryInfo.stop_second > 0) {

                }
                this.setState({render: !this.state.render});
            } else {
                PublicUtils.alert({
                    title: "温馨提示",
                    msg: res.data.message,
                    buttons: [
                        {
                            text: "好的", onPress: () => {
                                PublicUtils.navigate("GameNavigation");
                            }
                        },
                    ]
                });
            }
        });
    }

    /**
     * 切换玩法
     * */
    _changeBetTemplate = (i, item) => {
        console.log(item);
        let canBet = true;
        if (item.name === "连码" || item.name === "合肖" || item.name === "生肖连" || item.name === "尾数连" || item.name === "全不中") {
            canBet = false;
        }
        this.currentBetTemplate = item;
        this.currentBetNumber = {nums: []};
        this.canBet = canBet;
        this._clearingBetInfo();
        this.total = {
            count: 0,
            amount: 0
        };
        this.setState({render: !this.state.render});
        // this._hideBetBox();
    };
    /**
     * 点击投注号码
     * */
    _betNumberClick = (item, parentItem) => {
        // this.setState({
        //     currentBetNumber: {name: item[0], odds: item[1]},
        //     betAmount: item[2] || "",
        //     showBetBox: true
        // }, () => {
        //     this._betAmount(this.defaultBetAmount);
        // });
        const name = this.currentBetTemplate.name;
        let betamount = item[2];
        if (name === "连码" || name === "合肖" || name === "全不中") {
            this._LM_HX_SXL_WSL_QBZ_NumberClick(item, parentItem);
            betamount = 0;
        } else if (name === "生肖连" || name === "尾数连") {
            this._LM_HX_SXL_WSL_QBZ_NumberClick(item);
        } else {
            this.currentBetNumber = {name: item[0], odds: item[1], amount: item[2] || this.defaultBetAmount};
        }
        this._betAmount(betamount || this.defaultBetAmount, 'betClick');
    };
    /**
     * 清除单个投注号码
     * @param item
     * @private
     */
    _clearBetNumber = (item) => {
        this.currentBetNumber = {name: item[0], odds: item[1], amount: 0};
        this._betAmount(0);
        this.setState({showBetBox: false});
    };
    /**
     * 投注金额
     * */
    _betAmount = (value, type) => {
        if (type !== "betClick") {
            this.defaultBetAmount = value;
        }
        this.currentBetNumber.amount = value;
        this.betAmount = value;
        this._betCount(type);
    };

    /**
     * 计算投注数量
     * @param ws
     * @param number
     * @returns {number}
     */
    _calculationBetAmount = (ws, number) => {
        let amount = 1;
        let factorial = 1;
        for (let i = 0; i < number; i++) {
            amount *= (ws - i);
            factorial *= i + 1;
        }
        return amount / factorial;
    };
    /**
     * 投注数量/总的投注金额
     * @private
     */
    _betCount = (type) => {
        let currentBetTemplate = this.currentBetTemplate;
        let currentBetNumber = this.currentBetNumber;
        let totalCount = 0;//总投注数量
        let totalAmount = 0;//总投注数量
        let showBetBox = true;
        if (currentBetTemplate.name === "连码" || currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连" || currentBetTemplate.name === "全不中") {
            // showBetBox = this.state.showBetBox;
            if (currentBetTemplate.secondSubPlayInfo && currentBetTemplate.secondSubPlayInfo.label === "肖串尾数"
                && currentBetNumber.nums.zx && currentBetNumber.nums.zx.length && currentBetNumber.nums.ws && currentBetNumber.nums.ws.length) {
                let count = 0;
                for (let i = 0; i < currentBetNumber.nums.zx[0][2].length; i++) {
                    for (let j = 0; j < currentBetNumber.nums.ws[0][2].length; j++) {
                        if (currentBetNumber.nums.zx[0][2][i] === currentBetNumber.nums.ws[0][2][j]) {
                            count++;
                        }
                    }
                }
                if (currentBetTemplate.subPlayInfo.label === "四全中" || currentBetTemplate.subPlayInfo.label === "三全中" || currentBetTemplate.subPlayInfo.label === "三中二") {
                    let maxBetCount = currentBetTemplate.subPlayInfo.label === "四全中" ? 4 : 3;
                    totalCount = currentBetNumber.nums.zx[0][2].length * PublicUtils.combination(currentBetNumber.nums.ws[0][2].length - count, maxBetCount - 1);
                    if (totalCount) {
                        totalAmount = totalCount * this.betAmount;
                    }
                }
                else {
                    totalCount = currentBetNumber.nums.zx[0][2].length * (currentBetNumber.nums.ws[0][2].length - count);
                    if (totalCount) {
                        totalAmount = totalCount * this.betAmount;
                    }
                }

            } else if (currentBetTemplate.secondSubPlayInfo && currentBetTemplate.secondSubPlayInfo.label === "拖胆"
                && currentBetNumber.nums.dm && currentBetNumber.nums.dm.length && currentBetNumber.nums.tm && currentBetNumber.nums.tm.length) {

                if (currentBetTemplate.subPlayInfo.label === "四全中" || currentBetTemplate.subPlayInfo.label === "三全中" || currentBetTemplate.subPlayInfo.label === "三中二") {
                    let maxBetCount = currentBetTemplate.subPlayInfo.label === "四全中" ? 4 : 3;
                    if ((currentBetNumber.nums.dm.length + currentBetNumber.nums.tm.length) >= currentBetTemplate.subPlayInfo.min) {
                        totalCount = PublicUtils.combination(currentBetNumber.nums.tm.length, maxBetCount - currentBetNumber.nums.dm.length);
                        if (totalCount) {
                            totalAmount = totalCount * this.betAmount;
                        }
                    }

                } else {
                    totalCount = currentBetNumber.nums.dm.length * currentBetNumber.nums.tm.length;
                    if (totalCount) {
                        totalAmount = totalCount * this.betAmount;
                    }
                }
            } else if (currentBetTemplate.secondSubPlayInfo && (currentBetTemplate.secondSubPlayInfo.label === "生肖对碰" || currentBetTemplate.secondSubPlayInfo.label === "尾数对碰")) {
                if (currentBetNumber.nums.length === 2) {
                    totalCount = currentBetNumber.nums[0][2].length * currentBetNumber.nums[1][2].length;
                    if (totalCount) {
                        totalAmount = totalCount * this.betAmount;
                    }
                }
            } else {
                totalCount = this._calculationBetAmount((currentBetNumber.nums && currentBetNumber.nums.length) || 0, currentBetTemplate.subPlayInfo.min);
                if (totalCount) {
                    totalAmount = totalCount * this.betAmount;
                }
            }
        }
        else if (currentBetTemplate.name === "合肖") {
            // showBetBox = this.state.showBetBox;
            totalCount = (currentBetNumber.nums.length < currentBetTemplate.subPlayInfo.min) || (currentBetNumber.nums.length > currentBetTemplate.subPlayInfo.max) ? 0 : 1;
            if (totalCount) {
                totalAmount = this.betAmount;
            }
        }
        else {

            for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                if (currentBetTemplate.nums[i][0] === currentBetNumber.name) {
                    currentBetTemplate.nums[i][3] = type === "betClick" ? !currentBetTemplate.nums[i][3] : currentBetTemplate.nums[i][3];
                    // showBetBox = true;
                }
                // if (currentBetTemplate.nums[i][3]){
                //     if(!currentBetTemplate.nums[i][2]){
                //         currentBetTemplate.nums[i][2] = this.betAmount;
                //     }else{
                //         currentBetTemplate.nums[i][2] = "";
                //     }
                // }
                if (currentBetTemplate.nums[i][3]) {
                    totalCount++;
                    if (!currentBetTemplate.nums[i][2] || !this.hasSetAmount) {
                        currentBetTemplate.nums[i][2] = this.betAmount;
                    }
                    totalAmount += Number(currentBetTemplate.nums[i][2]);
                } else {
                    currentBetTemplate.nums[i][2] = "";
                }
            }
            currentBetTemplate.count = totalCount;
            // for (let i = 0; i < this.betTemplate.length; i++) {
            //     if (this.betTemplate[i].name === currentBetTemplate.name) {
            //         this.betTemplate[i].nums = currentBetTemplate.nums;
            //         this.betTemplate[i].count = count;
            //     }
            //     for (let l = 0; l < this.betTemplate[i].nums.length; l++) {
            //         if (this.betTemplate[i].nums[l][2]) {
            //             totalCount++;
            //             totalAmount += Number(this.betTemplate[i].nums[l][2]);
            //         }
            //     }
            // }
        }
        // const showBetBox = totalCount > this.total.count;
        this.total = {count: totalCount, amount: totalAmount};
        this.currentBetTemplate = currentBetTemplate;
        this.setState({showBetBox: showBetBox, render: !this.state.render});
    };

    /**
     * 获取彩种赔率
     */
    _fetchLotteryOdds() {
        let parm = {is_code: this.is_code};
        Http.request("getLotteryOdds", parm, (res) => {
            if (res.data && res.data.status) {
                this.Odds = res.data.odds;
                this._bindLotteryOdds();
            }
        });
    }

    /**
     * 绑定彩种赔率
     */
    _bindLotteryOdds() {
        let odds = this.Odds;
        let currentBetTemplate;
        for (let i = 0; i < this.betTemplate.length; i++) {
            let value = this.betTemplate[i];
            if (value.name === "特码") {
                //A面
                if (value.subPlayInfo.label === "A面") {
                    for (let i = 0; i < value.nums.length; i++) {
                        value.nums[i][1] = odds[value.oddType][i + 1];
                    }
                }
                //B面
                if (value.subPlayInfo.label === "B面") {
                    for (let i = 0; i < value.nums.length; i++) {
                        value.nums[i][1] = odds[value.oddType][i + 101];
                    }
                }
            }
            if (value.name === "正一特" || value.name === "正二特" || value.name === "正三特" || value.name === "正四特" ||
                value.name === "正五特" || value.name === "正六特" || value.name === "正码" || value.name === "一肖" || value.name === "正肖" || value.name === "尾数" || value.name === "生肖连" || value.name === "尾数连" || value.name === "总和") {
                for (let i = 0; i < value.nums.length; i++) {
                    value.nums[i][1] = odds[value.oddType][i + 1];
                }
            }
            if (value.name === "连码" || value.name === "合肖" || value.name === "生肖连" || value.name === "尾数连" || value.name === "全不中") {
                value.odds = odds[value.oddType];
                // this.lotteryBetArea.game.odds = odds[value.oddType];
            }
            if (value.name === "特码生肖") {
                for (let i = 0; i < value.nums.length; i++) {
                    value.nums[i][1] = odds[value.oddType][i + 63];
                }
            }
            if (value.name === "特码尾数") {
                for (let i = 0; i < value.nums.length; i++) {
                    value.nums[i][1] = odds[value.oddType][i + 75];
                }
            }
            if (value.name === this.currentBetTemplate.name) {
                currentBetTemplate = value;
            }
        }
        this.currentBetTemplate = currentBetTemplate;
        this.setState({render: this.state.render});
    }

    /**
     * 清除投注
     * @private
     */
    _clearingBetInfo = () => {
        for (let i = 0; i < this.betTemplate.length; i++) {
            this.betTemplate[i].count = 0;
            for (let l = 0; l < this.betTemplate[i].nums.length; l++) {
                this.betTemplate[i].nums[l][2] = 0;
                this.betTemplate[i].nums[l][3] = false;
            }
        }
        this.setState({render: this.state.render});
    };
    /**
     * 开奖结果总和
     * @param numbers
     * @returns {number}
     */
    _total = (numbers) => {
        let numList = numbers.split(',');
        let total = 0;
        for (let i = 0; i < numList.length; i++) {
            total += Number(numList[i]);
        }
        return total;
    };
    /**
     * 切换子玩法
     * @private
     */
    _changeSubPlayType = (item) => {
        this.currentBetNumber = {nums: []};
        let currentBetTemplate = this.currentBetTemplate;
        currentBetTemplate.subPlayInfo = item;
        if (this.currentBetTemplate.name === "特码") {
            let nums;
            if (item.label === "A面") {
                nums = [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0], ["大", 0], ["小", 0], ["单", 0], ["双", 0], ["合大", 0], ["合小", 0], ["合单", 0], ["合双", 0], ["尾大", 0], ["尾小", 0], ["红波", 0], ["蓝波", 0], ["绿波", 0]];
            }
            if (item.label === "B面") {
                nums = [["01", 0], ["02", 0], ["03", 0], ["04", 0], ["05", 0], ["06", 0], ["07", 0], ["08", 0], ["09", 0], ["10", 0], ["11", 0], ["12", 0], ["13", 0], ["14", 0], ["15", 0], ["16", 0], ["17", 0], ["18", 0], ["19", 0], ["20", 0], ["21", 0], ["22", 0], ["23", 0], ["24", 0], ["25", 0], ["26", 0], ["27", 0], ["28", 0], ["29", 0], ["30", 0], ["31", 0], ["32", 0], ["33", 0], ["34", 0], ["35", 0], ["36", 0], ["37", 0], ["38", 0], ["39", 0], ["40", 0], ["41", 0], ["42", 0], ["43", 0], ["44", 0], ["45", 0], ["46", 0], ["47", 0], ["48", 0], ["49", 0]];
            }
            currentBetTemplate.nums = nums;
        }
        if (this.currentBetTemplate.name === "连码") {
            this._changeSecondSubPlayType(currentBetTemplate.secondSubPlayList[0])
        }
        this.currentBetTemplate = currentBetTemplate;
        this.setState({
            showSubPlayTypeFliter: false
        }, () => {
            this._clearingBetInfo();
            this._bindLotteryOdds();
            this._getCurrentOdds();
        });
    };
    /**
     * 切换子玩法（连码）
     * @private
     */
    _changeSecondSubPlayType = (item) => {
        this.currentBetNumber = {nums: []};
        let currentBetTemplate = this.currentBetTemplate;
        currentBetTemplate.secondSubPlayInfo = item;
        currentBetTemplate.nums = item.nums;
        this.currentBetTemplate = currentBetTemplate;
        this.setState({
            showSecondSubPlayTypeFliter: false
        }, () => {
            this._clearingBetInfo();
            this._bindLotteryOdds();
            this._getCurrentOdds();
        });
    };
    /**
     * 获取当前玩法赔率
     * @private
     */
    _getCurrentOdds = () => {
        let odds = "";
        let canBet = true;
        let currentBetNumber = this.currentBetNumber;
        let currentBetTemplate = this.currentBetTemplate;
        if (currentBetTemplate.name === "合肖") {
            odds = currentBetTemplate.odds[this.currentBetNumber.nums.length - 1];
        } else if (currentBetTemplate.name === "全不中") {
            odds = currentBetTemplate.odds[currentBetTemplate.subPlayInfo.value];
        } else if (currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连") {
            let min = Infinity;
            for (let i = 0; i < currentBetTemplate.nums.length; i++) {
                if(currentBetTemplate.odds[i] < min ){
                    min = currentBetTemplate.odds[i];
                }
                currentBetTemplate.nums[i][1] = currentBetTemplate.odds[i + currentBetTemplate.subPlayInfo.oddIndex];
            }
            odds = min;
        } else {
            if (currentBetTemplate.subPlayInfo.oddIndex) {
                if (currentBetTemplate.subPlayInfo.oddIndex.length > 1) {
                    for (let i = 0; i < currentBetTemplate.subPlayInfo.oddIndex.length; i++) {
                        odds += currentBetTemplate.odds[currentBetTemplate.subPlayInfo.oddIndex[i]];
                        if (i < currentBetTemplate.subPlayInfo.oddIndex.length - 1) {
                            odds += " | ";
                        }
                    }
                } else {
                    odds += currentBetTemplate.odds[currentBetTemplate.subPlayInfo.oddIndex];
                }
            }
        }

        if ((currentBetTemplate.subPlayInfo.min && currentBetNumber.nums.length < currentBetTemplate.subPlayInfo.min) || (currentBetTemplate.subPlayInfo.max && currentBetNumber.nums.length > currentBetTemplate.subPlayInfo.max)) {
            canBet = false;
        }
        currentBetNumber.odds = odds;
        this.currentBetTemplate = currentBetTemplate;
        this.currentBetNumber = currentBetNumber;
        this.canBet = canBet;
        // alert(JSON.stringify(currentBetNumber));
        this.setState({render: !this.state.render});
    };
    /**
     * 连码/合肖/生肖连/尾数连/全不中/号码点击
     * @private
     */
    _LM_HX_SXL_WSL_QBZ_NumberClick = (item, parentItem) => {
        let currentBetTemplate = this.currentBetTemplate;

        let old_bet_nums = {};
        if (currentBetTemplate.name === "连码" && currentBetTemplate.secondSubPlayInfo.label === "肖串尾数") {
            old_bet_nums = {
                zx: this.currentBetNumber.nums.zx || [],//主肖
                ws: this.currentBetNumber.nums.ws || []//拖尾数
            };
            if (parentItem.name === "主肖") {
                old_bet_nums.zx = [];
                old_bet_nums.zx.push(item);
            }
            if (parentItem.name === "拖尾数") {
                old_bet_nums.ws = [];
                old_bet_nums.ws.push(item);
            }
        }
        else if (currentBetTemplate.name === "连码" && currentBetTemplate.secondSubPlayInfo.label === "拖胆") {
            old_bet_nums = {
                dm: this.currentBetNumber.nums.dm || [],//胆码
                tm: this.currentBetNumber.nums.tm || []//拖码
            };
            let dm_count = (currentBetTemplate.subPlayInfo.label !== "三全中" && currentBetTemplate.subPlayInfo.label !== "三中二") ? 3 : 2;
            if (parentItem.name === "胆码") {
                const index = old_bet_nums.dm.indexOf(item);
                if (index !== -1) {
                    old_bet_nums.dm.splice(index, 1);
                } else {
                    for (let i = 0; i < old_bet_nums.tm.length; i++) {
                        if (old_bet_nums.tm[i][0] === item[0]) {
                            PublicUtils.showToast("拖码不能与胆码重复");
                            return;
                        }
                    }
                    if (old_bet_nums.dm.length > dm_count - 1) {
                        PublicUtils.showToast("最多选择" + dm_count + "个胆码");
                        return;
                    }
                    old_bet_nums.dm.push(item);
                }
            }
            if (parentItem.name === "拖码") {
                const index = old_bet_nums.tm.indexOf(item);
                if (index !== -1) {
                    old_bet_nums.tm.splice(index, 1);
                } else {
                    for (let i = 0; i < old_bet_nums.dm.length; i++) {
                        if (old_bet_nums.dm[i][0] === item[0]) {
                            PublicUtils.showToast("拖码不能与胆码重复");
                            return;
                        }
                    }
                    old_bet_nums.tm.push(item);
                }
            }
        }
        else if (currentBetTemplate.name === "连码" && (currentBetTemplate.secondSubPlayInfo.label === "生肖对碰" || currentBetTemplate.secondSubPlayInfo.label === "尾数对碰")) {
            old_bet_nums = this.currentBetNumber.nums || [];
            const index = old_bet_nums.indexOf(item);
            if (index !== -1) {
                old_bet_nums.splice(index, 1);
            } else {
                if (old_bet_nums.length > 1) {
                    PublicUtils.showToast(currentBetTemplate.secondSubPlayInfo.label === "生肖对碰" ? "最多选择2个生肖" : "最多选择2个尾数");
                    return;
                }
                old_bet_nums.push(item);
            }
        }
        else {
            old_bet_nums = this.currentBetNumber.nums || [];
            const index = old_bet_nums.indexOf(item);
            if (index !== -1) {
                old_bet_nums.splice(index, 1);
            } else {
                old_bet_nums.push(item);
            }
        }
        this.currentBetNumber = {nums: old_bet_nums, amount: item[2] || this.defaultBetAmount};
        this._betCount();
        this._getCurrentOdds();
    };


    /**
     * 计算左边玩法导航高度
     * @private
     */
    _getNavHeight = () => {
        let height = model.clientHeight - model.headerHeight;
        if (this.state.showBetBox) {
            height -= 278;
        } else {
            height -= 216;
        }
        return height;
    };
    /**
     *  计算右边投注区容器高度
     **/
    _getBetHeight = () => {
        let height = model.clientHeight - model.headerHeight;
        if (this.state.showBetBox) {
            if (this.currentBetTemplate.subPlayList) {
                if (this.currentBetTemplate.secondSubPlayList) {
                    height -= 348;
                } else {
                    height -= 338;
                }
            } else {
                height -= 288;
            }
        } else {
            if (this.currentBetTemplate.subPlayList) {
                if (this.currentBetTemplate.secondSubPlayList) {
                    height -= 286;
                } else {
                    height -= 256;
                }
            } else {
                height -= 216;
            }
        }
        return height;
    };

    /**
     * 彩种
     **/
    _checkTitle = () => {
        // if (this.is_code === 1001) {
        //     return "六合彩";
        // }
        // if (this.is_code === 10011) {
        //     return "三分六合彩";
        // }
        this.lotteryInfo.is_name = PublicUtils.getState(this.props.location.pathname).is_name;
        return PublicUtils.getState(this.props.location.pathname).is_name;
    };

    render() {
        const styles = {
            topBox: {},
            openListBox: {
                backgroundColor: "#fff",
                zIndex: 10
            },
            lotteryBox: {
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden"
            },
            disabledBtn: {
                opacity: 0.6
            },
            typeOptionBtn: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 40,
                backgroundColor: "#fff",
                borderBottom: "1px solid #f5f7f9",
                // borderLeftWidth: 1,
                // borderLeftColor: "#f5f7f9"
            },
            typeOptionInnerBtn: {
                flex: 1,
            },
            typeOptionItem: {
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            },
            typeOptionItemText: {
                marginRight: 5,
                color: "rgba(0,0,0,1)",
                fontSize: 14,
            },
        };
        const betInfoStyles = {
            betInfoBox: {
                display: "flex",
                flexDirection: "column",
                borderTop: "1px solid #f5f7f9"
            },
            betTypeBox: {
                display: "flex",
                flex: 1,
                backgroundColor: "#f5f7f9",
                // borderRight: "1px solid #f5f7f9"
            },
            scrollBox: {
                width: "100%",
                overflow: "auto",
                height: "100%"
            },
            betBallBox: {
                width: 270,
                backgroundColor: "#fff"
            },
            btnBox: {
                paddingLeft: 10,
                paddingRight: 10
            },
            betTypeInnerBox: {
                // borderRightWidth: 1,
                // borderRightColor: "#f5f7f9"
            },
            betTypeItemBox: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                height: 40,
                lineHeight: "40px",
                fontSize: 15,
            },
            betTypeItem: {
                color: "rgba(0,0,0,0.7)"
            },
            betCount: {
                width: 18,
                height: 18,
                backgroundColor: "rgba(246, 48, 85, 0.94)",
                lineHeight: "18px",
                textAlign: "center",
                color: "#fff",
                borderRadius: 18,
                fontSize: 10
            },
            betTypeItemActive: {
                backgroundColor: "#f5f7f9",
            },
            betBallInnerBox: {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: 5,
            },
            betNumberBox: {
                display: "flex",
                flexDirection: "row",
                margin: 5,
                paddingLeft: 8,
                paddingRight: 8,
                height: 40,
                alignItems: "dashed",
                border: "1px solid rgba(0,0,0,0.15)",
                justifyContent: "space-between",
                borderRadius: 0.1,
                width: 120,
            },
            betNumberActive: {
                borderColor: "1px solid " + style['primaryColor' + model.SKINNAME],
                backgroundColor: style['primaryColor' + model.SKINNAME]
            },
            betNameActive: {
                color: "#fff"
            },
            betAmountActive: {
                color: "#fff"
            },
            betOddsActive: {
                color: "#fcff23"
            },
            betName: {
                fontSize: 14,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "red"
            },
            betAmount: {
                fontSize: 13,
                color: "#000",
                textAlign: "center",
                // backgroundColor: "green"
            },
            betOdds: {
                fontSize: 12,
                color: "rgba(246, 48, 85, 0.94)",
                textAlign: "center",
                // backgroundColor: "blue"
            },
        };
        return (
            <Container title={this._checkTitle()}
                       headerBackgroundColor={"linear-gradient(to right, " + style['linearGradientStart' + model.SKINNAME] + ", " + style['linearGradientEnd' + model.SKINNAME] + ")"}
                       headerFontColor={"#fff"}
                       showMenuButton={true}
                       menuArray={[{
                           name: "近期开奖",
                           icon: "jqkj",
                           href: 'RecentLotteryOpen',
                           parma: {code: this.is_code, templateType: "LHC"}
                       }, {
                           name: "投注记录",
                           icon: "tzjl",
                           href: 'LotteryBetRecord'
                       }, {
                           name: "玩法说明",
                           icon: "wfsm",
                           href: 'LotteryIntroduction',
                           parma: {code: this.is_code,name:this._checkTitle()}
                       }]}
                       onPress={() => {
                           this.setState({showBetBox: false})
                           // this._hideBetBox();
                       }}>
                <div style={styles.lotteryBox}>
                    <div style={styles.topBox}>
                        {/*<RecentOpenList code={this.is_code} lottery_no={this.lotteryInfo.lottery_no}/>*/}
                        <TimeBox templateType={'LHC'} lotteryInfo={this.lotteryInfo} code={this.is_code}
                                 countDownModel={'day'}
                                 lottery_no={this.lotteryInfo.lottery_no} openSecondCountdownEnd={() => {
                            this._init_();
                        }}
                                 stopSecondCountdownEnd={(lotteryInfo) => {
                                     this.lotteryInfo = lotteryInfo;
                                 }}/>
                    </div>
                    <div
                        style={{...betInfoStyles.betInfoBox}}>
                        <div style={{display: "flex", flex: 1, flexDirection: "row",height:'100%'}}>
                            <div className={'scorll'} style={betInfoStyles.betTypeBox}>
                                <div
                                    style={{...betInfoStyles.scrollBox, ...{maxHeight: this._getNavHeight()}}}>
                                    <BetTypeNav betTemplate={this.betTemplate} onPress={(i, item) => {
                                        this._changeBetTemplate(i, item);
                                    }}/>
                                </div>
                            </div>
                            <div className={'scorll'} style={betInfoStyles.betBallBox}>
                                {
                                    this.currentBetTemplate.subPlayList ?
                                        <div style={styles.typeOptionBtn}>
                                            <div style={styles.typeOptionInnerBtn} onClick={() => {
                                                // this._changeSubPlayType();
                                                this.setState({showSubPlayTypeFliter: true});
                                            }}>
                                                <div style={styles.typeOptionItem}>
                                                    <div
                                                        style={styles.typeOptionItemText}>{this.currentBetTemplate.subPlayInfo.label}</div>
                                                    <div style={{marginTop: -5}}>
                                                        <Icon icon={'sjjt'} size={14} color={'rgba(0,0,0,0.7)'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.currentBetTemplate.secondSubPlayInfo ?
                                                    <div style={styles.typeOptionInnerBtn} onClick={() => {
                                                        // this._changeSubPlayType();
                                                        this.setState({showSecondSubPlayTypeFliter: true});
                                                    }}>
                                                        <div style={styles.typeOptionItem}>
                                                            <div
                                                                style={styles.typeOptionItemText}>{this.currentBetTemplate.secondSubPlayInfo.label}</div>
                                                            <div style={{marginTop: -5}}>
                                                                <Icon icon={'sjjt'} size={14}
                                                                      color={'rgba(0,0,0,0.7)'}/>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                            }
                                        </div> : null
                                }
                                <div
                                    style={{}}>
                                    <BetNumberlist templateType={'LHC'}
                                                   betTemplate={this.currentBetTemplate}
                                                   style={{maxHeight: this._getBetHeight()}}
                                                   className={'scorll'}
                                                   currentBetNumber={this.currentBetNumber} code={this.is_code}
                                                   playName={this.currentBetTemplate.name}
                                                   data={this.currentBetTemplate.nums}
                                                   onClosePress={(item) => {
                                                       this._clearBetNumber(item);
                                                   }}
                                                   onPress={(item, parentItem) => {
                                                       this._betNumberClick(item, parentItem);
                                                   }}/>
                                </div>

                            </div>
                        </div>

                    </div>

                    {/* 投注弹窗 */}
                    <Betbox templateType={'LHC'}
                            lotteryInfo={this.lotteryInfo}
                            betAmount={this.currentBetNumber.amount}
                            onBlur={(text) => {
                                this.hasSetAmount = !!text;
                            }}
                            code={this.is_code}
                            total={this.total}
                            betTemplateList={this.betTemplate}
                            betTemplate={this.currentBetTemplate}
                            betContent={this.currentBetNumber}
                            disabled={!!this.lotteryInfo.state || !this.canBet}
                            show={this.state.showBetBox}
                            hideDialog={(newBetTemplate) => {
                                this.currentBetTemplate = newBetTemplate;
                                this.setState({showBetBox: false});
                            }}
                            clearBetInfoCallbck={(newBetTemplate) => {
                                this.total = {count: 0, amount: 0};
                                this.currentBetNumber = {};
                                this.betTemplate = newBetTemplate;
                                this.setState({showBetBox: false});
                            }}
                            onChangeText={(value) => {
                                this._betAmount(value);
                            }}
                            amountInputFn={(value) => {
                                this.hasSetAmount = !!value;
                                this._betAmount(value)
                            }}/>
                    <Footer templateType={'LHC'}
                            currentBetTemplate={this.currentBetTemplate}
                            currentBetNumber={this.currentBetNumber}
                            betOnpress={() => {

                                const currentBetTemplate = this.currentBetTemplate;
                                if (currentBetTemplate.name === "连码" || currentBetTemplate.name === "合肖" || currentBetTemplate.name === "生肖连" || currentBetTemplate.name === "尾数连" || currentBetTemplate.name === "全不中") {
                                    this.setState({showBetBox: true});
                                }
                            }}
                            code={this.is_code}
                            lotteryInfo={this.lotteryInfo}
                            total={this.total}
                            betTemplate={this.betTemplate}
                            clearBetInfoCallbck={(newBetTemplate) => {
                                this.total = {count: 0, amount: 0};
                                this.currentBetNumber = {};
                                this.betTemplate = newBetTemplate;
                                this.setState({showBetBox: false});
                            }}
                    />
                    {
                        this.currentBetTemplate.subPlayList ?
                            <MyPicker
                                title={'选择玩法'}
                                model={this.currentBetTemplate.subPlayList}
                                display={this.state.showSubPlayTypeFliter}
                                onHiden={() => {
                                    this.setState({showSubPlayTypeFliter: false})
                                }}
                                onItemPress={(item) => {
                                    this._changeSubPlayType(item.value[0].value);
                                    // this._changeSubPlayType(item);
                                    // this.setState({showSubPlayTypeFliter: false}, () => {
                                    //     this._fetchMemberChangeList();
                                    // });
                                }}
                            /> : null
                    }
                    {
                        this.currentBetTemplate.secondSubPlayList ?
                            <MyPicker
                                title={'选择玩法'}
                                modelFunc={() => {
                                    let modeList = [];
                                    this.currentBetTemplate.secondSubPlayList.map((item, key) => {
                                        if (item.range.indexOf(this.currentBetTemplate.subPlayInfo.value) > -1) {
                                            modeList.push(item);
                                        }
                                    });
                                    return modeList;
                                }}
                                display={this.state.showSecondSubPlayTypeFliter}
                                onHiden={() => {
                                    this.setState({showSecondSubPlayTypeFliter: false})
                                }}
                                onItemPress={(item) => {
                                    this._changeSecondSubPlayType(item.value[0].value);
                                    // this._changeSubPlayType(item);
                                    // this.setState({showSubPlayTypeFliter: false}, () => {
                                    //     this._fetchMemberChangeList();
                                    // });
                                }}
                            /> : null
                    }
                </div>
            </Container>
        )
    }
}
