let global = {
    ID: window.id,
    AGENT_URL: "https://newpic.hdyb33.com/new/beagent/index.html",
    // WAP_API_URL: "https://twpubapi.7171r.com/" + window.id + "/",
    // APP_API_URL: ["https://twpubapi.7171r.com/","https://twpubapi2.7171r.com/","https://twpubapi3.7171r.com/"],
    APP_API_URL: {
        status: 0,
        name: "主线路",
        url: "https://pubapi.apsl44.com/",
        delay: 0,
        type: 1
    },
    ACTIVE_URL: "https://newpic.hdyb33.com/new",
    RES_URL: "https://newpic.hdyb33.com/",
    ANDROID_DOWNLOAD_URL: "https://appnew.hdyb33.com/new/" + window.id + "/" + window.id + ".apk",
    IOS_DOWNLOAD_URL: "https://appnew.hdyb33.com/code/" + window.id + "/down.html",
    HCP_URL: "http://154.222.141.162:3000/",//热更新地址
    DEVICE: "2",//设备 1：PC，2：H5，3：安卓，4：苹果
    MODEL: "dev",
    NAME: "测试",
    SKIN: "Skin1",
    THEME: "default",
    GAME_IMG_SKIN: "skin-5",
    SERVICES_URL: "http://mobile.56k.cc:50030/mobile/service",
    syObj:{},
    dsqxb:0, // 订时器下标
};




switch (global.ID) {
    // case "cs":
    //     global = Object.assign(global, {
    //         SKIN: "Skin3",
    //         THEME: "theme1"
    //     });
    //     break;
    case "bk":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "b1":
        global = Object.assign(global, {SKIN: "Skin2"});
        break;
    case "b3":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "av":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "ai":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "ae":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "aw":
        global = Object.assign(global, {
            SKIN: "Skin3",
            THEME: "theme1"
        });
        break;
    case "bw":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "x3":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "4g":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "yl":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;
    case "y5":
        global = Object.assign(global, {SKIN: "Skin3"});
        break;

  case "b8":
    global = Object.assign(global, {SKIN: "Skin9"});
    break;

    case "x9":
      global = Object.assign(global, {
        SKIN: "Skin7",
        THEME: "theme1"
      });
        break;
    case "y8":
        global = Object.assign(global, {SKIN: "Skin6"});
        break;
    case "b6":
        global = Object.assign(global, {SKIN: "Skin2"});
        break;

    case "y1":
        global = Object.assign(global, {
            SKIN: "Skin3",
            THEME: "theme1"
        });
        break;

    case "w7":
        global = Object.assign(global, {SKIN: "Skin8", THEME: "theme1"});
        // global = Object.assign(global, {SKIN: "Skin6"});
        break;

    case "cs":
        global = Object.assign(global, {SKIN: "Skin6"});
        break;
    case "v1":
        global = Object.assign(global, {SKIN: "Skin2"});
        break;
    case "b5":
        global = Object.assign(global, {
        SKIN: "Skin3",
        THEME: "theme1"
        });
    break;
    case "ff":
        global = Object.assign(global, {
            SKIN: "Skin3",
            THEME: "theme1"
        });
        break;
}
export default global;
