import global from "../global";


let gameJSON = [{
  "name": "体育",
  "type_id": 1,
  "gamelist": [{
    "type": 1,
    "game_id": 101,
    "game_api": "",
    "name": "皇冠体育",
    "imgPath": "https://newpic.hdyb33.com/sport/xtty.png",
    "icon": "logo-hg",
    "memo1": "平台特色：火爆赛事，玩法多样",
    "memo2": "包含游戏：皇冠体育、各种球类下注、串关",
    "labels": "#体育#皇冠#足球",
    "hot_type": "hot",
    "showTitle": 1,
  }, {
    "type": 1,
    "game_id": 107,
    "game_api": "XJ188",
    "name": "188体育",
    "imgPath": "https://newpic.hdyb33.com/sport/xj188.png",
    "icon": "188",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：波音体育",
    "labels": "#体育",

  }, {
    "type": 1,
    "game_id": 109,
    "game_api": "SS",
    "name": "三昇体育",
    "imgPath": "https://newpic.hdyb33.com/sport/ss.png",
    "icon": "ss",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：三昇体育",
    "labels": "#体育",

  }, {
    "type": 1,
    "game_id": 102,
    "game_api": "SB",
    "name": "沙巴体育",
    "imgPath": "https://newpic.hdyb33.com/sport/sbty.png",
    "icon": "logo-sb",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：沙巴体育",
    "labels": "#体育#m8#足球#篮球#羽毛球#棒球#乒乓球#排球#网球#滚球#冠军#串关",
    "hot_type": "hot",
    "showTitle": 1,

  }, {
    "type": 1,
    "game_id": 105,
    "game_api": "FY",
    "name": "开心电竞",
    "imgPath": "https://newpic.hdyb33.com/sport/fydj.png",
    "icon": "fy",
    "memo1": "平台特色：火爆赛事，玩法齐全",
    "memo2": "包含游戏：开心电竞",
    "labels": "#体育#电竞",

  }],
  "icon": "zuqiu-1"
},
  {
  "name": "彩票", "type_id": 2, "gamelist": [{
    "type": 2,
    "game_id": 201,
    "game_api": "",
    "name": "热门彩票",
    "imgPath": "https://newpic.hdyb33.com/lottery/cpyx.png",
    "icon": "logo-xt",
    "memo1": "平台特色：高颜色美女陪您玩彩票",
    "memo2": "包含游戏：六合彩、PC蛋蛋、时时彩、PK拾、快三",
    "labels": "#彩票#六合彩#PC蛋蛋#时时彩#PK拾#快三",
    "gamelist": [{
      "target": "lottery",
      "game_id": 7001,
      "game_type": "_self_lottery",
      "name": "PC蛋蛋",
      "icon": "pcdd.png",
      "gamelist":[{
        "target": "lottery",
        "game_id": 7001,
        "game_type": "_self_lottery",
        "name": "PC蛋蛋",
        "icon": "pcdd.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 6001,
      "game_type": "_self_lottery",
      "name": "北京PK拾",
      "icon": "pk10.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 6001,
        "game_type": "_self_lottery",
        "name": "北京PK拾",
        "icon": "pk10.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 6002,
      "game_type": "_self_lottery",
      "name": "幸运飞艇",
      "icon": "xyft.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 6002,
        "game_type": "_self_lottery",
        "name": "幸运飞艇",
        "icon": "xyft.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 1001,
      "game_type": "_self_lottery",
      "name": "六合彩",
      "icon": "lhc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 1001,
        "game_type": "_self_lottery",
        "name": "六合彩",
        "icon": "lhc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": "",
      "game_type": "_self_lottery",
      "name": "山东11选5",
      "icon": "sd115.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": "",
        "game_type": "_self_lottery",
        "name": "山东11选5",
        "icon": "sd115.png",
      }]
    }, {
      "target": "lottery",
      "game_id": "",
      "game_type": "_self_lottery",
      "name": "广东11选5",
      "icon": "gd115.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": "",
        "game_type": "_self_lottery",
        "name": "广东11选5",
        "icon": "gd115.png",
      }]
    }, {
      "target": "lottery",
      "game_id": "",
      "game_type": "_self_lottery",
      "name": "江西11选5",
      "icon": "jx115.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": "",
        "game_type": "_self_lottery",
        "name": "江西11选5",
        "icon": "jx115.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 2003,
      "game_type": "_self_lottery",
      "name": "新疆时时彩",
      "icon": "xjssc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 2003,
        "game_type": "_self_lottery",
        "name": "新疆时时彩",
        "icon": "xjssc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 2001,
      "game_type": "_self_lottery",
      "name": "重庆时时彩",
      "icon": "cqssc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 2001,
        "game_type": "_self_lottery",
        "name": "重庆时时彩",
        "icon": "cqssc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 2002,
      "game_type": "_self_lottery",
      "name": "天津时时彩",
      "icon": "tjssc.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 2002,
        "game_type": "_self_lottery",
        "name": "天津时时彩",
        "icon": "tjssc.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 4002,
      "game_type": "_self_lottery",
      "name": "重庆快乐十分",
      "icon": "cqkl10.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 4002,
        "game_type": "_self_lottery",
        "name": "重庆快乐十分",
        "icon": "cqkl10.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 4001,
      "game_type": "_self_lottery",
      "name": "广东快乐十分",
      "icon": "gdkl10.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 4001,
        "game_type": "_self_lottery",
        "name": "广东快乐十分",
        "icon": "gdkl10.png",
      }]
    }, {
      "target": "lottery",
      "game_id": 3002,
      "game_type": "_self_lottery",
      "name": "广西快三",
      "icon": "gxks.png",
      "gamelist": [{
        "target": "lottery",
        "game_id": 3002,
        "game_type": "_self_lottery",
        "name": "广西快三",
        "icon": "gxks.png",
      }]
    },
      {
        "target": "lottery", "game_id": 3003, "name": "安徽快三", "icon": "ahks.png",  "gamelist": [{
          "target": "lottery", "game_id": 3003, "name": "安徽快三", "icon": "ahks.png",
        }]
      },
      {
        "target": "lottery",
        "game_id": 3001,
        "game_type": "_self_lottery",
        "name": "江苏快三",
        "icon": "ahks.png",
        "gamelist": [{
          "target": "lottery",
          "game_id": 3001,
          "game_type": "_self_lottery",
          "name": "江苏快三",
          "icon": "ahks.png",
        }]
      }],
    "hot_type": "recommend",
    "showTitle": 1
  }, {
    "type": 2,
    "game_id": 204,
    "game_api": "HC",
    "name": "豪彩彩票",
    "imgPath": "https://newpic.hdyb33.com/lottery/hccp.png",
    "icon": "hccp",
    "memo1": "平台特色：彩种齐全，玩法多样",
    "memo2": "包含游戏：11选5、快三、时时彩、福彩3D、排列三（五）、分分彩",
    "labels": "#彩票#六合彩#PC蛋蛋#时时彩#PK拾#快三#排列三",

  }, {
    "type": 2,
    "game_id": 203,
    "game_api": "VR",
    "name": "VR彩票",
    "imgPath": "https://newpic.hdyb33.com/lottery/vrcp.png",
    "icon": "logo-vr",
    "memo1": "平台特色：彩种齐全，玩法多样",
    "memo2": "包含游戏：VR11选5、VR快三、VR幸运28、VR10分彩、VR赛车、VR游泳",
    "labels": "#彩票#六合彩#时时彩#PK拾#快三#VR#赛车#游泳",

  }, {
    "type": 2,
    "game_id": 205,
    "game_api": "DS",
    "name": "DS彩票",
    "imgPath": "https://newpic.hdyb33.com/lottery/dscp.png",
    "icon": "logo-ds",
    "memo1": "平台特色：彩种齐全，玩法多样",
    "memo2": "包含游戏：DS11选5、DS快三、DS幸运5、DS赛车、快乐8",
    "labels": "#彩票#六合彩#时时彩#PC28#快三#赛车#快乐8",

  }], "icon": "caipiao"
},
  {
  "name": "真人",
  "type_id": 3,
  "gamelist": [{
    "type": 3,
    "game_id": 309,
    "game_api": "VIP",
    "name": "VIP国际厅",
    "imgPath": "https://newpic.hdyb33.com/real/vip.png",
    "icon": "vip",
    "memo1": "平台特色：亚洲最知名游戏平台",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、竞咪、街机、视讯",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",
    "hot_type": "recommend",
    "showTitle": 1,

  }, {
    "type": 3,
    "game_id": 303,
    "game_api": "BBIN",
    "name": "BB娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/bbin.png",
    "icon": "logo-bbin",
    "memo1": "平台特色：全新BB，提供全视讯品项",
    "memo2": "包含游戏：百家乐、幸运转轮、主题百家乐",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 301,
    "game_api": "OB",
    "name": "OB娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/ob.png",
    "icon": "logo-ob",
    "memo1": "\"平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 304,
    "game_api": "BG",
    "name": "BG娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/bg.png",
    "icon": "logo-bg",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：传统百家乐、特色百家乐、轮盘&骰宝、龙虎&牛牛、炸金花",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 305,
    "game_api": "DG",
    "name": "DG娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/dg.png",
    "icon": "logo-dg",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：DG梦幻厅、皇冠巴域厅、皇冠波贝厅、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",
    "hot_type": "new",
    "showTitle": 1,

  }, {
    "type": 3,
    "game_id": 307,
    "game_api": "MG",
    "name": "MG娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/mg.png",
    "icon": "logo-mg",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 308,
    "game_api": "OG",
    "name": "OG娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/og.png",
    "icon": "logo-og",
    "memo1": "平台特色：美女荷官在线发牌",
    "memo2": "包含游戏：百家乐、扎金花、牛牛、龙虎、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 310,
    "game_api": "WM",
    "name": "WM娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/wm.png",
    "icon": "logo-wm",
    "memo1": "平台特色：专业的真人娱乐城",
    "memo2": "包含游戏：百家乐、龙虎、牛牛、三公、鱼虾蟹、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 312,
    "game_api": "CQ9",
    "name": "CQ9娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/cq9.png",
    "icon": "cq9",
    "memo1": "平台特色：专业的真人娱乐城",
    "memo2": "包含游戏：百家乐、龙虎、牛牛、三公、鱼虾蟹、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }, {
    "type": 3,
    "game_id": 313,
    "game_api": "BBIN",
    "name": "XBB娱乐场",
    "imgPath": "https://newpic.hdyb33.com/real/xbb.png",
    "icon": "xbb",
    "memo1": "平台特色：专业的真人娱乐城",
    "memo2": "包含游戏：百家乐、龙虎、牛牛、三公、鱼虾蟹、轮盘、骰宝",
    "labels": "#真人#百家乐#扎金花#牛牛#龙虎#轮盘#骰宝",

  }],
  "icon": "tubiao"
},
  {
  "name": "老虎机",
  "type_id": 4,
  "gamelist": [{
    "type": 4,
    "game_id": 407,
    "game_api": "VIP",
    "name": "VIP老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/vip.png",
    "icon": "vip",
    "game_list_json": "VIP_game_list",
    "game_list_img_path": "/new/slot_res/vip/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：推筒子、飞禽走兽、森林舞会、斗三公、红黑梅方、疯狂德州...",
    "labels": "#老虎机#推筒子#飞禽走兽#森林舞会#斗三公",
    "hot_type": "new",
    "showTitle": 1,
    gamelist: [
      {
        "EN_Name": "Night of Wonder",
        "CN_Name": "奇幻马戏团",
        "HTML5_Game_ID": "MA27",
        "Button_Image_Name": "MA27.png"
      },
      {
        "EN_Name": "Aromatic Harbor",
        "CN_Name": "街头食神",
        "HTML5_Game_ID": "MA22",
        "Button_Image_Name": "MA22.png"
      },
      {
        "EN_Name": "Fortune Dragon Deluxe",
        "CN_Name": "神龙报喜",
        "HTML5_Game_ID": "AP15",
        "Button_Image_Name": "AP15.png"
      },
      {
        "EN_Name": "Jackpot Frenzy",
        "CN_Name": "积宝透透乐",
        "HTML5_Game_ID": "MJ00",
        "Button_Image_Name": "MJ00.png"
      },
      {
        "EN_Name": "Railway Bandits",
        "CN_Name": "铁盗枭雄",
        "HTML5_Game_ID": "MJ01",
        "Button_Image_Name": "MJ01.png"
      },
      {
        "EN_Name": "Mystic Realm Guardian",
        "CN_Name": "四灵奇谭",
        "HTML5_Game_ID": "MJ02",
        "Button_Image_Name": "MJ02.png"
      },
      {
        "EN_Name": "Shogun Honor",
        "CN_Name": "武士荣耀",
        "HTML5_Game_ID": "MA16",
        "Button_Image_Name": "MA16.png"
      },
      {
        "EN_Name": "Toys City",
        "CN_Name": "玩具城",
        "HTML5_Game_ID": "MA06",
        "Button_Image_Name": "MA06.png"
      },
      {
        "EN_Name": "Fortune Roar",
        "CN_Name": "狮来运转",
        "HTML5_Game_ID": "MA15",
        "Button_Image_Name": "MA15.png"
      },
      {
        "EN_Name": "City of Fame",
        "CN_Name": "名光之城",
        "HTML5_Game_ID": "MA04",
        "Button_Image_Name": "MA04.png"
      },
      {
        "EN_Name": "Elven Forest",
        "CN_Name": "森林精灵",
        "HTML5_Game_ID": "MA33",
        "Button_Image_Name": "MA33.png"
      },
      {
        "EN_Name": "Pirate Loot",
        "CN_Name": "夺宝船奇",
        "HTML5_Game_ID": "MA08",
        "Button_Image_Name": "MA08.png"
      },
      {
        "EN_Name": "Pool Party",
        "CN_Name": "泳池派对",
        "HTML5_Game_ID": "MA01",
        "Button_Image_Name": "MA01.png"
      },
      {
        "EN_Name": "Wild Koi",
        "CN_Name": "百搭锦鲤",
        "HTML5_Game_ID": "SB67",
        "Button_Image_Name": "SB67.png"
      },
      {
        "EN_Name": "Royal Gems",
        "CN_Name": "皇家之宝",
        "HTML5_Game_ID": "SB72",
        "Button_Image_Name": "SB72.png"
      },
      {
        "EN_Name": "Pirates Ahoy!",
        "CN_Name": "海盗历险",
        "HTML5_Game_ID": "SB68",
        "Button_Image_Name": "SB68.png"
      },
      {
        "EN_Name": "Animal Fun Park",
        "CN_Name": "森林游乐会",
        "HTML5_Game_ID": "SB70",
        "Button_Image_Name": "SB70.png"
      },
      {
        "EN_Name": "Treasure Party",
        "CN_Name": "宝藏派对",
        "HTML5_Game_ID": "SB73",
        "Button_Image_Name": "SB73.png"
      },
      {
        "EN_Name": "Lucky Express",
        "CN_Name": "幸运快餐",
        "HTML5_Game_ID": "WH58",
        "Button_Image_Name": "WH58.png"
      },
      {
        "EN_Name": "Kong Invasion",
        "CN_Name": "金刚来袭",
        "HTML5_Game_ID": "SB66",
        "Button_Image_Name": "SB66.png"
      },
      {
        "EN_Name": "Wild Gods",
        "CN_Name": "狂神对决",
        "HTML5_Game_ID": "SB62",
        "Button_Image_Name": "SB62.png"
      },
      {
        "EN_Name": "Knight and Dragon",
        "CN_Name": "勇者屠龙",
        "HTML5_Game_ID": "SB65",
        "Button_Image_Name": "SB65.png"
      },
      {
        "EN_Name": "Gold Class Deluxe",
        "CN_Name": "豪华金拉霸",
        "HTML5_Game_ID": "SC07",
        "Button_Image_Name": "SC07.png"
      },
      {
        "EN_Name": "Pillar of Fortune",
        "CN_Name": "翻倍宝器",
        "HTML5_Game_ID": "WH48",
        "Button_Image_Name": "WH48.png"
      },
      {
        "EN_Name": "Lucky Riches",
        "CN_Name": "财气冲天",
        "HTML5_Game_ID": "SB64",
        "Button_Image_Name": "SB64.png"
      },
      {
        "EN_Name": "Mermaid Treasure",
        "CN_Name": "美人鱼",
        "HTML5_Game_ID": "WH56",
        "Button_Image_Name": "WH56.png"
      },
      {
        "EN_Name": "Beast Party",
        "CN_Name": "飞禽走兽拉霸",
        "HTML5_Game_ID": "SC06",
        "Button_Image_Name": "SC06.png"
      },
      {
        "EN_Name": "Bingo Slot",
        "CN_Name": "宾果拉霸",
        "HTML5_Game_ID": "SB63",
        "Button_Image_Name": "SB63.png"
      },
      {
        "EN_Name": "Kung Fu All Stars",
        "CN_Name": "功夫传奇",
        "HTML5_Game_ID": "SB59",
        "Button_Image_Name": "SB59.png"
      },
      {
        "EN_Name": "Bao Zu Po",
        "CN_Name": "包租婆",
        "HTML5_Game_ID": "WH52",
        "Button_Image_Name": "WH52.png"
      },
      {
        "EN_Name": "Sex and Zen",
        "CN_Name": "肉蒲团",
        "HTML5_Game_ID": "WH62",
        "Button_Image_Name": "WH62.png"
      },
      {
        "EN_Name": "Ace Heist",
        "CN_Name": "王牌狂徒",
        "HTML5_Game_ID": "SB60",
        "Button_Image_Name": "SB60.png"
      },
      {
        "EN_Name": "Golden Dragon",
        "CN_Name": "巨龙宝藏",
        "HTML5_Game_ID": "WH31",
        "Button_Image_Name": "WH31.png"
      },
      {
        "EN_Name": "Lucha Heroes",
        "CN_Name": "摔跤英雄",
        "HTML5_Game_ID": "WH49",
        "Button_Image_Name": "WH49.png"
      },
      {
        "EN_Name": "The Nutcracker",
        "CN_Name": "胡桃夹子",
        "HTML5_Game_ID": "WH25",
        "Button_Image_Name": "WH25.png"
      },
      {
        "EN_Name": "Temple Raider",
        "CN_Name": "神庙丽影",
        "HTML5_Game_ID": "WH65",
        "Button_Image_Name": "WH65.png"
      },
      {
        "EN_Name": "Vegas Vegas",
        "CN_Name": "赌城之夜",
        "HTML5_Game_ID": "SB56",
        "Button_Image_Name": "SB56.png"
      },
      {
        "EN_Name": "Ultra Shift",
        "CN_Name": "极品飙车",
        "HTML5_Game_ID": "WH54",
        "Button_Image_Name": "WH54.png"
      },
      {
        "EN_Name": "Sushi Nights",
        "CN_Name": "寿司大食客",
        "HTML5_Game_ID": "WH26",
        "Button_Image_Name": "WH26.png"
      },
      {
        "EN_Name": "Peking Opera",
        "CN_Name": "皇家戏台",
        "HTML5_Game_ID": "WH40",
        "Button_Image_Name": "WH40.png"
      },
      {
        "EN_Name": "Steampunk War",
        "CN_Name": "蒸汽战争",
        "HTML5_Game_ID": "WH44",
        "Button_Image_Name": "WH44.png"
      },
      {
        "EN_Name": "Sexy Succubus",
        "CN_Name": "魅惑魔女",
        "HTML5_Game_ID": "SB58",
        "Button_Image_Name": "SB58.png"
      },
      {
        "EN_Name": "One Percenter",
        "CN_Name": "横行霸道",
        "HTML5_Game_ID": "WH36",
        "Button_Image_Name": "WH36.png"
      },
      {
        "EN_Name": "Young and Dangerous",
        "CN_Name": "古惑仔",
        "HTML5_Game_ID": "WH42",
        "Button_Image_Name": "WH42.png"
      },
      {
        "EN_Name": "Money Monster",
        "CN_Name": "神奇宠物",
        "HTML5_Game_ID": "WH55",
        "Button_Image_Name": "WH55.png"
      },
      {
        "EN_Name": "Evil Dragons",
        "CN_Name": "魔龙",
        "HTML5_Game_ID": "SB57",
        "Button_Image_Name": "SB57.png"
      },
      {
        "EN_Name": "Treasure Tarot",
        "CN_Name": "财宝塔罗",
        "HTML5_Game_ID": "WH19",
        "Button_Image_Name": "WH19.png"
      },
      {
        "EN_Name": "Egyptian Treasure",
        "CN_Name": "埃及宝藏",
        "HTML5_Game_ID": "WH28",
        "Button_Image_Name": "WH28.png"
      },
      {
        "EN_Name": "Kabuki Gold",
        "CN_Name": "和风剧院",
        "HTML5_Game_ID": "WH27",
        "Button_Image_Name": "WH27.png"
      },
      {
        "EN_Name": "Fortune Alchemy",
        "CN_Name": "点石成金",
        "HTML5_Game_ID": "WH30",
        "Button_Image_Name": "WH30.png"
      },
      {
        "EN_Name": "Fengshen Yanyi",
        "CN_Name": "封神演义",
        "HTML5_Game_ID": "WH23",
        "Button_Image_Name": "WH23.png"
      },
      {
        "EN_Name": "Festival Lions",
        "CN_Name": "五狮进宝",
        "HTML5_Game_ID": "WH07",
        "Button_Image_Name": "WH07.png"
      },
      {
        "EN_Name": "Joan of Arc",
        "CN_Name": "圣女贞德",
        "HTML5_Game_ID": "WH02",
        "Button_Image_Name": "WH02.png"
      },
      {
        "EN_Name": "Panda's Gold",
        "CN_Name": "发财熊猫",
        "HTML5_Game_ID": "WH12",
        "Button_Image_Name": "WH12.png"
      },
      {
        "EN_Name": "Koi Treasure",
        "CN_Name": "招财锦鲤",
        "HTML5_Game_ID": "WH35",
        "Button_Image_Name": "WH35_ZH.png"
      },
      {
        "EN_Name": "Chinese Zodiac",
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "WH38",
        "Button_Image_Name": "WH38_ZH.png"
      },
      {
        "EN_Name": "Huluwa",
        "CN_Name": "葫芦兄弟",
        "HTML5_Game_ID": "WH20",
        "Button_Image_Name": "WH20_ZH.png"
      },
      {
        "EN_Name": "TanWanLanYue",
        "CN_Name": "贪玩蓝月",
        "HTML5_Game_ID": "WH32",
        "Button_Image_Name": "WH32_ZH.png"
      },
      {
        "EN_Name": "Touchdown Hottie",
        "CN_Name": "内衣橄榄球",
        "HTML5_Game_ID": "WH34",
        "Button_Image_Name": "WH34.png"
      },
      {
        "EN_Name": "Snow White",
        "CN_Name": "白雪公主",
        "HTML5_Game_ID": "WH18",
        "Button_Image_Name": "WH18_ZH.png"
      },
      {
        "EN_Name": "Lucky Combo",
        "CN_Name": "多宝鱼虾蟹",
        "HTML5_Game_ID": "SB55",
        "Button_Image_Name": "SB55_ZH.png"
      },
      {
        "EN_Name": "Happy Jump",
        "CN_Name": "跳跳乐",
        "HTML5_Game_ID": "WC01",
        "Button_Image_Name": "WC01_ZH.png"
      },
      {
        "EN_Name": "The Living Dead",
        "CN_Name": "僵尸来袭",
        "HTML5_Game_ID": "WH24",
        "Button_Image_Name": "jslaixi.png"
      },
      {
        "EN_Name": "Bloody Kisses",
        "CN_Name": "永恒之吻",
        "HTML5_Game_ID": "WH21",
        "Button_Image_Name": "yhzw123.png"
      },
      {
        "EN_Name": "Witch's Quest",
        "CN_Name": "狂野女巫",
        "HTML5_Game_ID": "WH29",
        "Button_Image_Name": "kuangyenv.png"
      },
      {
        "EN_Name": "Dark Joker",
        "CN_Name": "恐怖嘉年华",
        "HTML5_Game_ID": "WH22",
        "Button_Image_Name": "kbjiahua.png"
      },
      {
        "EN_Name": "Wild 777",
        "CN_Name": "百搭777",
        "HTML5_Game_ID": "SC05",
        "Button_Image_Name": "bd777.png"
      },
      {
        "EN_Name": "Diamond Queen",
        "CN_Name": "钻石女王",
        "HTML5_Game_ID": "WA01",
        "Button_Image_Name": "WA01.png"
      },
      {
        "EN_Name": "The Moon Lady",
        "CN_Name": "嫦娥奔月",
        "HTML5_Game_ID": "WH17",
        "Button_Image_Name": "CEBY.png"
      },
      {
        "EN_Name": "Alice's Adventures",
        "CN_Name": "爱丽丝大冒险",
        "HTML5_Game_ID": "WH10",
        "Button_Image_Name": "ALSDMX9.png"
      },
      {
        "EN_Name": "The Storm",
        "CN_Name": "战火风云",
        "HTML5_Game_ID": "WH11",
        "Button_Image_Name": "ZHFY9.png"
      },
      {
        "EN_Name": "King Arthur",
        "CN_Name": "亚瑟王",
        "HTML5_Game_ID": "WH06",
        "Button_Image_Name": "YSW9.png"
      },
      {
        "EN_Name": "Mucha Goddesses",
        "CN_Name": "穆夏女神",
        "HTML5_Game_ID": "WH04",
        "Button_Image_Name": "WH04_ZH.png"
      },
      {
        "EN_Name": "DragonTiger",
        "CN_Name": "龙虎",
        "HTML5_Game_ID": "EP02",
        "Button_Image_Name": "EP02_ZH.png"
      },
      {
        "EN_Name": "Real Champions",
        "CN_Name": "冠军足球",
        "HTML5_Game_ID": "WH03",
        "Button_Image_Name": "WH03_ZH.png"
      },
      {
        "EN_Name": "King of Glory",
        "CN_Name": "王者传说",
        "HTML5_Game_ID": "SB51",
        "Button_Image_Name": "SB51_ZH.png"
      },
      {
        "EN_Name": "Sicbo",
        "CN_Name": "双倍骰宝",
        "HTML5_Game_ID": "EP03",
        "Button_Image_Name": "EP03.png"
      },
      {
        "EN_Name": "Alibaba",
        "CN_Name": "阿里巴巴",
        "HTML5_Game_ID": "WH01",
        "Button_Image_Name": "WH01.png"
      },
      {
        "EN_Name": "Mystic Gems",
        "CN_Name": "神奇宝石",
        "HTML5_Game_ID": "SB47",
        "Button_Image_Name": "SB47_ZH.png"
      },
      {
        "EN_Name": "Mr XIN",
        "CN_Name": "XIN哥来了",
        "HTML5_Game_ID": "SB50",
        "Button_Image_Name": "SB50_ZH.png"
      },
      {
        "EN_Name": "The Dragon Pearl Gold",
        "CN_Name": "金龙珠",
        "HTML5_Game_ID": "SB49",
        "Button_Image_Name": "SB49_ZH.png"
      },
      {
        "EN_Name": "Legend of the Dragon",
        "CN_Name": "猛龙传奇",
        "HTML5_Game_ID": "SB45",
        "Button_Image_Name": "SB45_ZH.png"
      },
      {
        "EN_Name": "Gold Class",
        "CN_Name": "金拉霸",
        "HTML5_Game_ID": "SC03",
        "Button_Image_Name": "SC03_ZH.png"
      },
      {
        "EN_Name": "Street Battle",
        "CN_Name": "街头烈战",
        "HTML5_Game_ID": "SX02",
        "Button_Image_Name": "SX02_ZH.png"
      },
      {
        "EN_Name": "Fantasies",
        "CN_Name": "美女大格斗",
        "HTML5_Game_ID": "XG13",
        "Button_Image_Name": "XG13_ZH.gif"
      },
      {
        "EN_Name": "Moon Festival",
        "CN_Name": "中秋佳节",
        "HTML5_Game_ID": "XG11",
        "Button_Image_Name": "XG11_ZH.gif"
      },
      {
        "EN_Name": "K-Pop",
        "CN_Name": "韩风劲舞",
        "HTML5_Game_ID": "XG12",
        "Button_Image_Name": "XG12_ZH.gif"
      },
      {
        "EN_Name": "Kick Off",
        "CN_Name": "黄金对垒",
        "HTML5_Game_ID": "XG16",
        "Button_Image_Name": "XG16_ZH.gif"
      },
      {
        "EN_Name": "Dragon Boat Festival",
        "CN_Name": "龙舟竞渡",
        "HTML5_Game_ID": "XG10",
        "Button_Image_Name": "XG10_ZH.gif"
      },
      {
        "EN_Name": "Ice Crush",
        "CN_Name": "冰河世界",
        "HTML5_Game_ID": "SB34",
        "Button_Image_Name": "SB34_ZH.gif"
      },
      {
        "EN_Name": "Candy Quest",
        "CN_Name": "糖果碰碰乐",
        "HTML5_Game_ID": "SB33",
        "Button_Image_Name": "SB33_ZH.gif"
      },
      {
        "EN_Name": "Fruit Slot 2",
        "CN_Name": "水果拉霸2",
        "HTML5_Game_ID": "FRU2",
        "Button_Image_Name": "FRU2_ZH.gif"
      },
      {
        "EN_Name": "Shanghai Bund",
        "CN_Name": "上海百乐门",
        "HTML5_Game_ID": "SB37",
        "Button_Image_Name": "SB37_ZH.gif"
      },
      {
        "EN_Name": "Deuces Wild",
        "CN_Name": "百搭二王",
        "HTML5_Game_ID": "PKBD",
        "Button_Image_Name": "PKBD.png"
      },
      {
        "EN_Name": "Bonus Deuces Wild",
        "CN_Name": "红利百搭",
        "HTML5_Game_ID": "PKBB",
        "Button_Image_Name": "PKBB.png"
      },
      {
        "EN_Name": "Dragons Pearl",
        "CN_Name": "龙珠",
        "HTML5_Game_ID": "XG01",
        "Button_Image_Name": "XG01_ZH.gif"
      },
      {
        "EN_Name": "Lucky 8",
        "CN_Name": "幸运8",
        "HTML5_Game_ID": "XG02",
        "Button_Image_Name": "XG02_ZH.gif"
      },
      {
        "EN_Name": "Bling Bling",
        "CN_Name": "闪亮女郎",
        "HTML5_Game_ID": "XG03",
        "Button_Image_Name": "XG03_ZH.gif"
      },
      {
        "EN_Name": "Goldfish",
        "CN_Name": "金鱼",
        "HTML5_Game_ID": "XG04",
        "Button_Image_Name": "XG04_ZH.gif"
      },
      {
        "EN_Name": "Chinese New Year",
        "CN_Name": "中国新年",
        "HTML5_Game_ID": "XG05",
        "Button_Image_Name": "XG05_ZH.gif"
      },
      {
        "EN_Name": "Pirates",
        "CN_Name": "海盗王",
        "HTML5_Game_ID": "XG06",
        "Button_Image_Name": "XG06_ZH.gif"
      },
      {
        "EN_Name": "Fruitmania",
        "CN_Name": "鲜果狂热",
        "HTML5_Game_ID": "XG07",
        "Button_Image_Name": "XG07_ZH.gif"
      },
      {
        "EN_Name": "Red Panda",
        "CN_Name": "小熊猫",
        "HTML5_Game_ID": "XG08",
        "Button_Image_Name": "XG08_ZH.gif"
      },
      {
        "EN_Name": "High Roller",
        "CN_Name": "大豪客",
        "HTML5_Game_ID": "XG09",
        "Button_Image_Name": "XG09_ZH.gif"
      },
      {
        "EN_Name": "Year of the Monkey",
        "CN_Name": "灵猴献瑞",
        "HTML5_Game_ID": "SB30",
        "Button_Image_Name": "SB30_ZH.gif"
      },
      {
        "EN_Name": "God of Wu Fortune",
        "CN_Name": "武财神",
        "HTML5_Game_ID": "SB28",
        "Button_Image_Name": "SB28_ZH.gif"
      },
      {
        "EN_Name": "Chinese Chess Slot",
        "CN_Name": "象棋老虎机",
        "HTML5_Game_ID": "SB07",
        "Button_Image_Name": "SB07_ZH.gif"
      },
      {
        "EN_Name": "Chess Slot",
        "CN_Name": "西洋棋老虎机",
        "HTML5_Game_ID": "SB09",
        "Button_Image_Name": "SB09.png"
      },
      {
        "EN_Name": "Happy Farm",
        "CN_Name": "开心农场",
        "HTML5_Game_ID": "SB10",
        "Button_Image_Name": "SB10_ZH.gif"
      },
      {
        "EN_Name": "Mahjong Slot",
        "CN_Name": "麻将老虎机",
        "HTML5_Game_ID": "SB08",
        "Button_Image_Name": "SB08.png"
      },
      {
        "EN_Name": "Summer Campsite",
        "CN_Name": "夏日营地",
        "HTML5_Game_ID": "SB11",
        "Button_Image_Name": "SB11_ZH.gif"
      },
      {
        "EN_Name": "Space Odyssey",
        "CN_Name": "太空漫游",
        "HTML5_Game_ID": "SB01",
        "Button_Image_Name": "SB01.png"
      },
      {
        "EN_Name": "Samurai",
        "CN_Name": "日本武士",
        "HTML5_Game_ID": "SB06",
        "Button_Image_Name": "SB06_ZH.gif"
      },
      {
        "EN_Name": "Garden Vintage",
        "CN_Name": "复古花园",
        "HTML5_Game_ID": "SB02",
        "Button_Image_Name": "SB02.png"
      },
      {
        "EN_Name": "Video poker2",
        "CN_Name": "杰克高手",
        "HTML5_Game_ID": "PKBJ",
        "Button_Image_Name": "PKBJ_ZH.gif"
      },
      {
        "EN_Name": "Fruit Slot",
        "CN_Name": "水果拉霸",
        "HTML5_Game_ID": "FRU",
        "Button_Image_Name": "YP805.gif"
      },
      {
        "EN_Name": "Forest Dance",
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "YP801",
        "Button_Image_Name": "YP810.gif"
      },
      {
        "EN_Name": "Benz",
        "CN_Name": "奔驰宝马",
        "HTML5_Game_ID": "YP802",
        "Button_Image_Name": "YP802.gif"
      },
      {
        "EN_Name": "Guess",
        "CN_Name": "猜猜乐",
        "HTML5_Game_ID": "YP804",
        "Button_Image_Name": "YP804.gif"
      },
      {
        "EN_Name": "Fruit Slot",
        "CN_Name": "水果拉霸",
        "HTML5_Game_ID": "YP805",
        "Button_Image_Name": "YP805.gif"
      },
      {
        "EN_Name": "Texas Cowboy",
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "YP806",
        "Button_Image_Name": "YP806.gif"
      },
      {
        "EN_Name": "Birds & Beasts",
        "CN_Name": "飞禽走兽",
        "HTML5_Game_ID": "YP807",
        "Button_Image_Name": "YP807.gif"
      },
      {
        "EN_Name": "Fruit Party",
        "CN_Name": "水果派对",
        "HTML5_Game_ID": "YP808",
        "Button_Image_Name": "YP808.gif"
      },
      {
        "EN_Name": "Forest Dance Multiplayer",
        "CN_Name": "森林舞会多人版",
        "HTML5_Game_ID": "YP810",
        "Button_Image_Name": "YP810.gif"
      },
      {
        "EN_Name": "Fruit Slot Multiplayer",
        "CN_Name": "水果拉霸多人版",
        "HTML5_Game_ID": "YP813",
        "Button_Image_Name": "YP813.gif"
      },
      {
        "EN_Name": "Yo Bull Multiplayer",
        "CN_Name": "百人牛牛",
        "HTML5_Game_ID": "YP817",
        "Button_Image_Name": "YP817.gif"
      },
      {
        "EN_Name": "Treasure Fruit Slot",
        "CN_Name": "多宝水果拉霸",
        "HTML5_Game_ID": "YP818",
        "Button_Image_Name": "YP818.gif"
      },
      {
        "EN_Name": "Jackpot Fruit Slot",
        "CN_Name": "彩金水果拉霸",
        "HTML5_Game_ID": "YP820",
        "Button_Image_Name": "YP820.gif"
      },
      {
        "EN_Name": "Birds & Beasts Multiplayer",
        "CN_Name": "飞禽走兽多人版",
        "HTML5_Game_ID": "YP814",
        "Button_Image_Name": "YP814.gif"
      },
      {
        "EN_Name": "Bull Battle Multiplayer",
        "CN_Name": "牛牛对战",
        "HTML5_Game_ID": "YP821",
        "Button_Image_Name": "YP821.gif"
      },
      {
        "EN_Name": "Benz Multiplayer",
        "CN_Name": "奔驰宝马多人版",
        "HTML5_Game_ID": "YP811",
        "Button_Image_Name": "YP811.gif"
      },
      {
        "EN_Name": "Animals Carnival Multiplayer",
        "CN_Name": "动物狂欢",
        "HTML5_Game_ID": "YP823",
        "Button_Image_Name": "YP823.gif"
      },
      {
        "EN_Name": "Western Journey Multiplayer",
        "CN_Name": "西游争霸",
        "HTML5_Game_ID": "YP824",
        "Button_Image_Name": "YP824.gif"
      },
      {
        "EN_Name": "Double Win 3 Cards Multiplayer",
        "CN_Name": "翻倍炸金花",
        "HTML5_Game_ID": "YP822",
        "Button_Image_Name": "YP822.gif"
      },
      {
        "EN_Name": "Battlefield Multiplayer",
        "CN_Name": "刺激战场",
        "HTML5_Game_ID": "YP826",
        "Button_Image_Name": "ymbf.gif"
      },
      {
        "EN_Name": "San Gong Multiplayer",
        "CN_Name": "斗三公",
        "HTML5_Game_ID": "YP825",
        "Button_Image_Name": "YP825.gif"
      },
      {
        "EN_Name": "Double Jokers Multiplayer",
        "CN_Name": "红黑梅方",
        "HTML5_Game_ID": "YP827",
        "Button_Image_Name": "YP827.gif"
      },
      {
        "EN_Name": "Jackpot Treasure World",
        "CN_Name": "彩金宝藏世界",
        "HTML5_Game_ID": "YP828",
        "Button_Image_Name": "YP828.gif"
      },
      {
        "EN_Name": "Crazy Texas Multiplayer",
        "CN_Name": "疯狂德州",
        "HTML5_Game_ID": "YP830",
        "Button_Image_Name": "YP830.gif"
      },
      {
        "EN_Name": "Jackpot Benz",
        "CN_Name": "彩金奔驰宝马",
        "HTML5_Game_ID": "YP831",
        "Button_Image_Name": "YP831.gif"
      },
      {
        "EN_Name": "Sea & Land War Multiplayer",
        "CN_Name": "海陆争霸",
        "HTML5_Game_ID": "YP832",
        "Button_Image_Name": "YP832.gif"
      },
      {
        "EN_Name": "Dragon Dots Multiplayer",
        "CN_Name": "百人推筒子",
        "HTML5_Game_ID": "YP834",
        "Button_Image_Name": "YP834.gif"
      }
    ]
  }, {
    "type": 4,
    "game_id": 411,
    "game_api": "FG",
    "name": "FG老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/pg.png",
    "icon": "fg",
    "game_list_json": "FG_game_list",
    "game_list_img_path": "/new/slot_res/fg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：变形金刚、淘金热、66路、狂欢、埃及、接头霸王、东方国度、西部牛仔..",
    "labels": "#老虎机#变形金刚#淘金热#66路#狂欢#埃及#接头霸王#东方国度#西部牛仔",
    gamelist: [
      {
        "CN_Name": "变形金刚",
        "HTML5_Game_ID": "bxjg",
        "EN_Name": "Transformers",
        "Button_Image_Name": "bxjg.png"
      },
      {
        "CN_Name": "摸金校尉",
        "HTML5_Game_ID": "mjxw",
        "EN_Name": "Grave Robbers",
        "Button_Image_Name": "mjxw.png"
      },
      {
        "CN_Name": "金刚",
        "HTML5_Game_ID": "jg",
        "EN_Name": "King Kong",
        "Button_Image_Name": "jg.png"
      },
      {
        "CN_Name": "淘金热",
        "HTML5_Game_ID": "tjr",
        "EN_Name": "Gold Rush",
        "Button_Image_Name": "tjr.png"
      },
      {
        "CN_Name": "66路",
        "HTML5_Game_ID": "Route66",
        "EN_Name": "Route 66",
        "Button_Image_Name": "Route66.png"
      },
      {
        "CN_Name": "阿兹特克",
        "HTML5_Game_ID": "azteke",
        "EN_Name": "Azteke",
        "Button_Image_Name": "azteke.png"
      },
      {
        "CN_Name": "埃及",
        "HTML5_Game_ID": "Egypt",
        "EN_Name": "Egypt",
        "Button_Image_Name": "Egypt.png"
      },
      {
        "CN_Name": "狂欢",
        "HTML5_Game_ID": "kh",
        "EN_Name": "Carnival",
        "Button_Image_Name": "kh.png"
      },
      {
        "CN_Name": "中世纪特权",
        "HTML5_Game_ID": "zsjtq",
        "EN_Name": "Medieval Thrones",
        "Button_Image_Name": "zsjtq.png"
      },
      {
        "CN_Name": "大秦帝国",
        "HTML5_Game_ID": "dqdg",
        "EN_Name": "The Qin Empire",
        "Button_Image_Name": "dqdg.png"
      },
      {
        "CN_Name": "火影忍者",
        "HTML5_Game_ID": "hyrz",
        "EN_Name": "Naruto",
        "Button_Image_Name": "hyrz.png"
      },
      {
        "CN_Name": "欢乐水果",
        "HTML5_Game_ID": "sg",
        "EN_Name": "Joyful Fruit",
        "Button_Image_Name": "sg.png"
      },
      {
        "CN_Name": "街头霸王",
        "HTML5_Game_ID": "jtbw",
        "EN_Name": "Street Fighter",
        "Button_Image_Name": "jtbw.png"
      },
      {
        "CN_Name": "战舰少女",
        "HTML5_Game_ID": "zjsn",
        "EN_Name": "Warship Maiden",
        "Button_Image_Name": "zjsn.png"
      },
      {
        "CN_Name": "东方国度",
        "HTML5_Game_ID": "zgf",
        "EN_Name": "Eastern Countries",
        "Button_Image_Name": "zgf.png"
      },
      {
        "CN_Name": "西部牛仔",
        "HTML5_Game_ID": "nz",
        "EN_Name": "Western Cowboy",
        "Button_Image_Name": "nz.png"
      },
      {
        "CN_Name": "幻想大陆",
        "HTML5_Game_ID": "hxdl",
        "EN_Name": "Fantasy Earth",
        "Button_Image_Name": "hxdl.png"
      },
      {
        "CN_Name": "拉斯维加斯",
        "HTML5_Game_ID": "wjs",
        "EN_Name": "Las Vegas",
        "Button_Image_Name": "wjs.png"
      },
      {
        "CN_Name": "荒野大镖客",
        "HTML5_Game_ID": "nz2",
        "EN_Name": "Wilderness Escort",
        "Button_Image_Name": "nz2.png"
      },
      {
        "CN_Name": "埃及艳后",
        "HTML5_Game_ID": "mny",
        "EN_Name": "Cleopatra",
        "Button_Image_Name": "mny.png"
      },
      {
        "CN_Name": "仙剑",
        "HTML5_Game_ID": "xj",
        "EN_Name": "Xian Jian",
        "Button_Image_Name": "xj.png"
      },
      {
        "CN_Name": "梦游仙境",
        "HTML5_Game_ID": "myxj",
        "EN_Name": "Alice",
        "Button_Image_Name": "myxj.png"
      },
      {
        "CN_Name": "80天旅行",
        "HTML5_Game_ID": "80tlx",
        "EN_Name": "80 Days Travel",
        "Button_Image_Name": "80tlx.png"
      },
      {
        "CN_Name": "海盗财富",
        "HTML5_Game_ID": "hdcf",
        "EN_Name": "Pirates Fortune",
        "Button_Image_Name": "hdcf.png"
      },
      {
        "CN_Name": "西游",
        "HTML5_Game_ID": "xy",
        "EN_Name": "Xi You",
        "Button_Image_Name": "xy.png"
      },
      {
        "CN_Name": "白蛇传",
        "HTML5_Game_ID": "bsz",
        "EN_Name": "Bai She Zhuan",
        "Button_Image_Name": "bsz.png"
      },
      {
        "CN_Name": "指环王",
        "HTML5_Game_ID": "zhw",
        "EN_Name": "Lord Of The Ring",
        "Button_Image_Name": "zhw.png"
      },
      {
        "CN_Name": "封神榜",
        "HTML5_Game_ID": "fsb",
        "EN_Name": "Feng Shen Bang",
        "Button_Image_Name": "fsb.png"
      },
      {
        "CN_Name": "荣耀王者",
        "HTML5_Game_ID": "rywz",
        "EN_Name": "King Of Glory",
        "Button_Image_Name": "rywz.png"
      },
      {
        "CN_Name": "怪物命运",
        "HTML5_Game_ID": "gwmy",
        "EN_Name": "Freaks Fortune",
        "Button_Image_Name": "gwmy.png"
      },
      {
        "CN_Name": "埃菲尔",
        "HTML5_Game_ID": "Eiffel",
        "EN_Name": "Eiffel",
        "Button_Image_Name": "Eiffel.png"
      },
      {
        "CN_Name": "新年符号",
        "HTML5_Game_ID": "Nysymbols",
        "EN_Name": "Ny Symbols",
        "Button_Image_Name": "Nysymbols.png"
      },
      {
        "CN_Name": "性感女神",
        "HTML5_Game_ID": "Pinup",
        "EN_Name": "Marilyn Monroe",
        "Button_Image_Name": "Pinup.png"
      },
      {
        "CN_Name": "世界末日",
        "HTML5_Game_ID": "Doomsday",
        "EN_Name": "Doomsday",
        "Button_Image_Name": "Doomsday.png"
      },
      {
        "CN_Name": "现代战争",
        "HTML5_Game_ID": "Mowf",
        "EN_Name": "Modern War",
        "Button_Image_Name": "Mowf.png"
      },
      {
        "CN_Name": "甜水绿洲",
        "HTML5_Game_ID": "Lushwater",
        "EN_Name": "Sweet Water Oasis",
        "Button_Image_Name": "Lushwater.png"
      },
      {
        "CN_Name": "加勒比海盗",
        "HTML5_Game_ID": "Pirates",
        "EN_Name": "Caribbean Pirates",
        "Button_Image_Name": "Pirates.png"
      },
      {
        "CN_Name": "功夫熊猫",
        "HTML5_Game_ID": "Panda",
        "EN_Name": "Kung Fu Panda",
        "Button_Image_Name": "Panda.png"
      },
      {
        "CN_Name": "侏罗纪",
        "HTML5_Game_ID": "Jurassic",
        "EN_Name": "Jurassic",
        "Button_Image_Name": "Jurassic.png"
      },
      {
        "CN_Name": "植物大战僵尸",
        "HTML5_Game_ID": "PVZ",
        "EN_Name": "PVZ",
        "Button_Image_Name": "PVZ.png"
      },
      {
        "CN_Name": "守望英雄",
        "HTML5_Game_ID": "Watch",
        "EN_Name": "Overwatch",
        "Button_Image_Name": "Watch.png"
      },
      {
        "CN_Name": "阿凡达",
        "HTML5_Game_ID": "Avatar",
        "EN_Name": "Avatar",
        "Button_Image_Name": "Avatar.png"
      },
      {
        "CN_Name": "希腊传说",
        "HTML5_Game_ID": "xlcs",
        "EN_Name": "Greek Legend",
        "Button_Image_Name": "xlcs.png"
      },
      {
        "CN_Name": "吸血鬼PK狼人",
        "HTML5_Game_ID": "xxgpklr",
        "EN_Name": "Bloodz VS Wolvez",
        "Button_Image_Name": "xxgpklr.png"
      },
      {
        "CN_Name": "梦幻西游",
        "HTML5_Game_ID": "mhxy",
        "EN_Name": "Dream Journey",
        "Button_Image_Name": "mhxy.png"
      },
      {
        "CN_Name": "女校足球队",
        "HTML5_Game_ID": "nxzqd",
        "EN_Name": "Girls Football",
        "Button_Image_Name": "nxzqd.png"
      },
      {
        "CN_Name": "女校橄榄球",
        "HTML5_Game_ID": "nxglq",
        "EN_Name": "Girls Rugby",
        "Button_Image_Name": "nxglq.png"
      },
      {
        "CN_Name": "女校剑道部",
        "HTML5_Game_ID": "nxjdb",
        "EN_Name": "Girls Kendo Club",
        "Button_Image_Name": "nxjdb.png"
      },
      {
        "CN_Name": "武圣关云长",
        "HTML5_Game_ID": "wsgyc",
        "EN_Name": "Guan Yun Chang",
        "Button_Image_Name": "wsgyc.png"
      },
      {
        "CN_Name": "刺客信条",
        "HTML5_Game_ID": "ckxt",
        "EN_Name": "Assassins Creed",
        "Button_Image_Name": "ckxt.png"
      },
      {
        "CN_Name": "古墓丽影",
        "HTML5_Game_ID": "gmly",
        "EN_Name": "Tomb Raider",
        "Button_Image_Name": "gmly.png"
      },
      {
        "CN_Name": "绿野仙踪",
        "HTML5_Game_ID": "lyxz",
        "EN_Name": "The Wizard Of Oz",
        "Button_Image_Name": "lyxz.png"
      },
      {
        "CN_Name": "剑侠情缘",
        "HTML5_Game_ID": "jxqy",
        "EN_Name": "Sword Heroes",
        "Button_Image_Name": "jxqy.png"
      },
      {
        "CN_Name": "格斗之王",
        "HTML5_Game_ID": "gdzw",
        "EN_Name": "King Of Fighters",
        "Button_Image_Name": "gdzw.png"
      },
      {
        "CN_Name": "黑珍珠号",
        "HTML5_Game_ID": "hzzh",
        "EN_Name": "Black Pearl",
        "Button_Image_Name": "hzzh.png"
      },
      {
        "CN_Name": "富饶农场",
        "HTML5_Game_ID": "frnc",
        "EN_Name": "Rich Farm",
        "Button_Image_Name": "frnc.png"
      },
      {
        "CN_Name": "玛雅",
        "HTML5_Game_ID": "my",
        "EN_Name": "Maya",
        "Button_Image_Name": "my.png"
      },
      {
        "CN_Name": "印第安",
        "HTML5_Game_ID": "yda",
        "EN_Name": "India Dreams",
        "Button_Image_Name": "yda.png"
      },
      {
        "CN_Name": "神秘东方",
        "HTML5_Game_ID": "smdf",
        "EN_Name": "Mysterious East",
        "Button_Image_Name": "smdf.png"
      },
      {
        "CN_Name": "战争",
        "HTML5_Game_ID": "zz",
        "EN_Name": "Tanks",
        "Button_Image_Name": "zz.png"
      },
      {
        "CN_Name": "哪吒闹海",
        "HTML5_Game_ID": "nznh",
        "EN_Name": "Ne Zha Nao Hai",
        "Button_Image_Name": "nznh.png"
      },
      {
        "CN_Name": "七夕情缘",
        "HTML5_Game_ID": "qxqy",
        "EN_Name": "Tanabata Love",
        "Button_Image_Name": "qxqy.png"
      },
      {
        "CN_Name": "四大美女",
        "HTML5_Game_ID": "sdmn",
        "EN_Name": "Four Beauties",
        "Button_Image_Name": "sdmn.png"
      },
      {
        "CN_Name": "金瓶梅",
        "HTML5_Game_ID": "jpm",
        "EN_Name": "Jin Ping Mei",
        "Button_Image_Name": "jpm.png"
      },
      {
        "CN_Name": "红楼梦",
        "HTML5_Game_ID": "hlm",
        "EN_Name": "Hong Lou Meng",
        "Button_Image_Name": "hlm.png"
      },
      {
        "CN_Name": "武松打虎",
        "HTML5_Game_ID": "wsdf",
        "EN_Name": "Wu Song Da Hu",
        "Button_Image_Name": "wsdf.png"
      },
      {
        "CN_Name": "女校游泳队",
        "HTML5_Game_ID": "sst",
        "EN_Name": "Girls Swim Team",
        "Button_Image_Name": "sst.png"
      },
      {
        "CN_Name": "女校啦啦队",
        "HTML5_Game_ID": "scs",
        "EN_Name": "Cheerleaders",
        "Button_Image_Name": "scs.png"
      },
      {
        "CN_Name": "女校体操队",
        "HTML5_Game_ID": "sgt",
        "EN_Name": "Girls Gymnastics",
        "Button_Image_Name": "sgt.png"
      },
      {
        "CN_Name": "天龙八部",
        "HTML5_Game_ID": "wtlbb",
        "EN_Name": "Tian Long Ba Bu",
        "Button_Image_Name": "wtlbb.png"
      },
      {
        "CN_Name": "鹿鼎记",
        "HTML5_Game_ID": "wldj",
        "EN_Name": "Lu Ding Ji",
        "Button_Image_Name": "wldj.png"
      },
      {
        "CN_Name": "笑傲江湖",
        "HTML5_Game_ID": "wxajh",
        "EN_Name": "Swordsman",
        "Button_Image_Name": "wxajh.png"
      },
      {
        "CN_Name": "神雕侠侣",
        "HTML5_Game_ID": "wsdxl",
        "EN_Name": "Shen Diao Xia Lv",
        "Button_Image_Name": "wsdxl.png"
      },
      {
        "CN_Name": "幸运水果机",
        "HTML5_Game_ID": "mlfm",
        "EN_Name": "Lucky Fruit",
        "Button_Image_Name": "mlfm.png"
      },
      {
        "CN_Name": "钻石之恋",
        "HTML5_Game_ID": "mdl",
        "EN_Name": "Diamond Love",
        "Button_Image_Name": "mdl.png"
      },
      {
        "CN_Name": "众神之王",
        "HTML5_Game_ID": "mmm",
        "EN_Name": "Zeus",
        "Button_Image_Name": "mmm.png"
      },
      {
        "CN_Name": "粉红女郎",
        "HTML5_Game_ID": "mpg",
        "EN_Name": "Pink Lady",
        "Button_Image_Name": "mpg.png"
      },
      {
        "CN_Name": "鸟叔",
        "HTML5_Game_ID": "mpjs",
        "EN_Name": "PSY",
        "Button_Image_Name": "mpjs.png"
      },
      {
        "CN_Name": "十二星座",
        "HTML5_Game_ID": "Constellation",
        "EN_Name": "Constellation",
        "Button_Image_Name": "Constellation.png"
      },
      {
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "Zodiac",
        "EN_Name": "Chinese Zodiac",
        "Button_Image_Name": "Zodiac.png"
      },
      {
        "CN_Name": "愤怒的小鸟",
        "HTML5_Game_ID": "Angrybirds",
        "EN_Name": "Angry Birds",
        "Button_Image_Name": "Angrybirds.png"
      },
      {
        "CN_Name": "捕鱼达人",
        "HTML5_Game_ID": "Fishing",
        "EN_Name": "Fishing Joy",
        "Button_Image_Name": "Fishing.png"
      },
      {
        "CN_Name": "瑞狗迎春",
        "HTML5_Game_ID": "rgyc",
        "EN_Name": "Lucky Dog",
        "Button_Image_Name": "rgyc.png"
      },
      {
        "CN_Name": "哥谭魅影猫女",
        "HTML5_Game_ID": "gtmymn",
        "EN_Name": "Catwoman",
        "Button_Image_Name": "gtmymn.png"
      },
      {
        "CN_Name": "招财进宝",
        "HTML5_Game_ID": "zcjb",
        "EN_Name": "Zhao Cai Jin Bao",
        "Button_Image_Name": "zcjb.png"
      },
      {
        "CN_Name": "湛蓝深海",
        "HTML5_Game_ID": "xsjr",
        "EN_Name": "Great Blue",
        "Button_Image_Name": "xsjr.png"
      },
      {
        "CN_Name": "金狗旺财",
        "HTML5_Game_ID": "jgwc",
        "EN_Name": "Jin Gou Wang Cai",
        "Button_Image_Name": "jgwc.png"
      },
      {
        "CN_Name": "恭贺新春",
        "HTML5_Game_ID": "ghxc",
        "EN_Name": "Gong He Xin Chun",
        "Button_Image_Name": "ghxc.png"
      },
      {
        "CN_Name": "角斗士",
        "HTML5_Game_ID": "jiaods",
        "EN_Name": "Gladiatus",
        "Button_Image_Name": "jiaods.png"
      },
      {
        "CN_Name": "埃及女王",
        "HTML5_Game_ID": "aijinw",
        "EN_Name": "Egypt Queen",
        "Button_Image_Name": "aijinw.png"
      },
      {
        "CN_Name": "灌篮高手",
        "HTML5_Game_ID": "msd",
        "EN_Name": "Slamdunk",
        "Button_Image_Name": "msd.png"
      },
      {
        "CN_Name": "抢红包",
        "HTML5_Game_ID": "mqhb",
        "EN_Name": "Qiang Hong Bao",
        "Button_Image_Name": "mqhb.png"
      },
      {
        "CN_Name": "闹元宵",
        "HTML5_Game_ID": "mnys",
        "EN_Name": "Lantern Festival",
        "Button_Image_Name": "mnys.png"
      },
      {
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "mslwh",
        "EN_Name": "Forest Dance",
        "Button_Image_Name": "mslwh.png"
      },
      {
        "CN_Name": "金球争霸",
        "HTML5_Game_ID": "jqzb",
        "EN_Name": "Golden Globe",
        "Button_Image_Name": "jqzb.png"
      },
      {
        "CN_Name": "黄金右脚",
        "HTML5_Game_ID": "hjyj",
        "EN_Name": "Golden Foot",
        "Button_Image_Name": "hjyj.png"
      },
      {
        "CN_Name": "世界杯吉祥物",
        "HTML5_Game_ID": "sjbjxw",
        "EN_Name": "Fifa World",
        "Button_Image_Name": "sjbjxw.png"
      },
      {
        "CN_Name": "潘帕斯雄鹰",
        "HTML5_Game_ID": "panpasixiongying",
        "EN_Name": "Argentina",
        "Button_Image_Name": "panpasixiongying.png"
      },
      {
        "CN_Name": "群星闪耀",
        "HTML5_Game_ID": "qunxshanyao",
        "EN_Name": "Stars Shine",
        "Button_Image_Name": "qunxshanyao.png"
      },
      {
        "CN_Name": "金靴争霸",
        "HTML5_Game_ID": "mjxzb",
        "EN_Name": "The Golden Boot",
        "Button_Image_Name": "mjxzb.png"
      },
      {
        "CN_Name": "激情球迷",
        "HTML5_Game_ID": "mjqqm",
        "EN_Name": "Passion Fans",
        "Button_Image_Name": "mjqqm.png"
      },
      {
        "CN_Name": "激情世界杯",
        "HTML5_Game_ID": "mjqsjb",
        "EN_Name": "World Cup",
        "Button_Image_Name": "mjqsjb.png"
      },
      {
        "CN_Name": "人猿泰山",
        "HTML5_Game_ID": "ryts",
        "EN_Name": "Tarzan",
        "Button_Image_Name": "ryts.png"
      },
      {
        "CN_Name": "船长宝藏",
        "HTML5_Game_ID": "mczbz",
        "EN_Name": "Captain's Treasure",
        "Button_Image_Name": "mczbz.png"
      },
      {
        "CN_Name": "疯狂七",
        "HTML5_Game_ID": "mcrazy7",
        "EN_Name": "Crazy7",
        "Button_Image_Name": "mcrazy7.png"
      },
      {
        "CN_Name": "鹊桥会",
        "HTML5_Game_ID": "qqh",
        "EN_Name": "Magpie Bridge",
        "Button_Image_Name": "qqh.png"
      },
      {
        "CN_Name": "泰坦尼克号",
        "HTML5_Game_ID": "mtitanic",
        "EN_Name": "Titanic",
        "Button_Image_Name": "mtitanic.png"
      },
      {
        "CN_Name": "蜘蛛侠",
        "HTML5_Game_ID": "zzx",
        "EN_Name": "Spider Man",
        "Button_Image_Name": "zzx.png"
      },
      {
        "CN_Name": "速度与激情",
        "HTML5_Game_ID": "fastf",
        "EN_Name": "Fast & Furious",
        "Button_Image_Name": "fastf.png"
      },
      {
        "CN_Name": "古怪猴子",
        "HTML5_Game_ID": "gghz",
        "EN_Name": "Funky Monkey",
        "Button_Image_Name": "gghz.png"
      },
      {
        "CN_Name": "海岛奇兵",
        "HTML5_Game_ID": "mboombeach",
        "EN_Name": "BoomBeach",
        "Button_Image_Name": "mboombeach.png"
      },
      {
        "CN_Name": "水果派对",
        "HTML5_Game_ID": "party",
        "EN_Name": "Fruit_Party",
        "Button_Image_Name": "party.png"
      },
      {
        "CN_Name": "百变猴子",
        "HTML5_Game_ID": "mcm",
        "EN_Name": "WILDMonkey",
        "Button_Image_Name": "mcm.png"
      },
      {
        "CN_Name": "新年",
        "HTML5_Game_ID": "ny",
        "EN_Name": "New Year",
        "Button_Image_Name": "ny.png"
      },
      {
        "CN_Name": "多福多财",
        "HTML5_Game_ID": "dfdc",
        "EN_Name": "Bless&Wealth",
        "Button_Image_Name": "dfdc.png"
      },
      {
        "CN_Name": "街机水浒传",
        "HTML5_Game_ID": "mawm",
        "EN_Name": "Arcade Water Margin",
        "Button_Image_Name": "mawm.png"
      },
      {
        "CN_Name": "发发发",
        "HTML5_Game_ID": "fff",
        "EN_Name": "Fafafa",
        "Button_Image_Name": "fff.png"
      },

      {
        "CN_Name": "金瓶梅2",
        "HTML5_Game_ID": "xjpm",
        "EN_Name": "Jin Ping Mei 2",
        "Button_Image_Name": "xjpm.png"
      },
      {
        "CN_Name": "抢红包2",
        "HTML5_Game_ID": "qhb",
        "EN_Name": "Red Envelope2",
        "Button_Image_Name": "qhb.png"
      },
      {
        "CN_Name": "美人捕鱼",
        "HTML5_Game_ID": "fish_mm",
        "EN_Name": "Beauty Fishing",
        "Button_Image_Name": "fish_mm.png"
      },
      {
        "CN_Name": "雷霆战警",
        "HTML5_Game_ID": "fish_zj",
        "EN_Name": "X-Men",
        "Button_Image_Name": "fish_zj.png"
      },
      {
        "CN_Name": "捕鸟达人",
        "HTML5_Game_ID": "fish_bn",
        "EN_Name": "Birds Hunter",
        "Button_Image_Name": "fish_bn.png"
      },
      {
        "CN_Name": "欢乐捕鱼",
        "HTML5_Game_ID": "fish_hl",
        "EN_Name": "Fish Reef",
        "Button_Image_Name": "fish_hl.png"
      },
      {
        "CN_Name": "天天捕鱼",
        "HTML5_Game_ID": "fish_tt",
        "EN_Name": "Daily Fishing",
        "Button_Image_Name": "fish_tt.png"
      },
      {
        "CN_Name": "捕鱼来了3D",
        "HTML5_Game_ID": "fish_3D",
        "EN_Name": "Power Fishing 3D",
        "Button_Image_Name": "fish_3D.png"
      },
      {
        "CN_Name": "魔法王者",
        "HTML5_Game_ID": "fish_mfwz",
        "EN_Name": "The Magicians",
        "Button_Image_Name": "fish_mfwz.png"
      },
      {
        "CN_Name": "猎鼠达人3D",
        "HTML5_Game_ID": "fish_lsdr",
        "EN_Name": "Mole Rat Catcher3D",
        "Button_Image_Name": "fish_lsdr.png"
      },
      {
        "CN_Name": "美人鱼传说",
        "HTML5_Game_ID": "fish_mrycs",
        "EN_Name": "The Legend of Mermaid",
        "Button_Image_Name": "fish_mrycs.png"
      },
      {
        "CN_Name": "百人牛牛",
        "HTML5_Game_ID": "HB",
        "EN_Name": "Hundreds of Bull",
        "Button_Image_Name": "HB.png"
      },

      {
        "CN_Name": "骰宝",
        "HTML5_Game_ID": "tz",
        "EN_Name": "Sic Bo",
        "Button_Image_Name": "tz.png"
      },
      {
        "CN_Name": "百家乐",
        "HTML5_Game_ID": "bjl",
        "EN_Name": "Baccarat",
        "Button_Image_Name": "bjl.png"
      },
      {
        "CN_Name": "皇家宫殿",
        "HTML5_Game_ID": "RC",
        "EN_Name": "Royal Palace",
        "Button_Image_Name": "RC.png"
      },

      {
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "TexasCb",
        "EN_Name": "Texas Cowboy",
        "Button_Image_Name": "TexasCb.png"
      },
      {
        "CN_Name": "二人麻将",
        "HTML5_Game_ID": "mj",
        "EN_Name": "Two People Mahjong",
        "Button_Image_Name": "mj.png"
      },
      {
        "CN_Name": "抢庄牛牛",
        "HTML5_Game_ID": "cqznn",
        "EN_Name": "Rob Banker Of Bull",
        "Button_Image_Name": "cqznn.png"
      },
      {
        "CN_Name": "三公",
        "HTML5_Game_ID": "csg",
        "EN_Name": "San_Gong",
        "Button_Image_Name": "csg.png"
      },
      {
        "CN_Name": "斗地主",
        "HTML5_Game_ID": "ddz",
        "EN_Name": "Landlord",
        "Button_Image_Name": "ddz.png"
      },
      {
        "CN_Name": "经典炸金花",
        "HTML5_Game_ID": "zjhdr",
        "EN_Name": "Classical Win Three Cards",
        "Button_Image_Name": "zjhdr.png"
      },
      {
        "CN_Name": "梭哈",
        "HTML5_Game_ID": "ShowHand",
        "EN_Name": "ShowHand",
        "Button_Image_Name": "ShowHand.png"
      },
      {
        "CN_Name": "通比牛牛",
        "HTML5_Game_ID": "tbnn",
        "EN_Name": "Tongbi Cattle",
        "Button_Image_Name": "tbnn.png"
      },
      {
        "CN_Name": "十三水",
        "HTML5_Game_ID": "sss",
        "EN_Name": "Open-face Chinese Poker",
        "Button_Image_Name": "sss.png"
      },
      {
        "CN_Name": "二八杠",
        "HTML5_Game_ID": "ebg",
        "EN_Name": "Mahjong",
        "Button_Image_Name": "ebg.png"
      },
      {
        "CN_Name": "抢庄牌九",
        "HTML5_Game_ID": "qzpj",
        "EN_Name": "Pai Gow",
        "Button_Image_Name": "qzpj.png"
      },
      {
        "CN_Name": "极速炸金花",
        "HTML5_Game_ID": "jszjh",
        "EN_Name": "Topspeed Win Three Cards",
        "Button_Image_Name": "jszjh.png"
      },
      {
        "CN_Name": "欢乐德州",
        "HTML5_Game_ID": "iPoker",
        "EN_Name": "Fun Poker",
        "Button_Image_Name": "iPoker.png"
      },
      {
        "CN_Name": "欢乐红包",
        "HTML5_Game_ID": "hlhb",
        "EN_Name": "Happy Red Packet",
        "Button_Image_Name": "hlhb.png"
      },
      {
        "CN_Name": "欢乐麻将",
        "HTML5_Game_ID": "xzdd",
        "EN_Name": "Happy mahjong",
        "Button_Image_Name": "xzdd.png"
      },



      {
        "CN_Name": "欢乐麻将3D",
        "HTML5_Game_ID": "hlmj3D",
        "EN_Name": "Happy Mahjong 3D",
        "Button_Image_Name": "hlmj3D.png"
      },

      {
        "CN_Name": "关秦龙虎斗",
        "HTML5_Game_ID": "ggdqq",
        "EN_Name": "Guan Qin Dragon Tiger War",
        "Button_Image_Name": "ggdqq.png"
      },


      {
        "CN_Name": "水果机",
        "HTML5_Game_ID": "frt",
        "EN_Name": "Fruit",
        "Button_Image_Name": "frt.png"
      },
      {
        "CN_Name": "飞禽走兽",
        "HTML5_Game_ID": "bbs",
        "EN_Name": "Birds And Beasts",
        "Button_Image_Name": "bbs.png"
      },
      {
        "CN_Name": "奔驰宝马",
        "HTML5_Game_ID": "bzb",
        "EN_Name": "Benz And BMW",
        "Button_Image_Name": "bzb.png"
      },
      {
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "fod",
        "EN_Name": "Forest Dance",
        "Button_Image_Name": "fod.png"
      },
      {
        "CN_Name": "十二星座",
        "HTML5_Game_ID": "con",
        "EN_Name": "Constellation",
        "Button_Image_Name": "con.png"
      },
      {
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "chz",
        "EN_Name": "Chinese Zodiac",
        "Button_Image_Name": "chz.png"
      },
      {
        "CN_Name": "燃烧吧，足球",
        "HTML5_Game_ID": "bif",
        "EN_Name": "Burn It Football",
        "Button_Image_Name": "bif.png"
      },
      {
        "CN_Name": "巅峰篮球",
        "HTML5_Game_ID": "pbb",
        "EN_Name": "Peak Basketball",
        "Button_Image_Name": "pbb.png"
      },
      {
        "CN_Name": "黄金大转轮",
        "HTML5_Game_ID": "gw",
        "EN_Name": "Golden Whell",
        "Button_Image_Name": "gw.png"
      },
      {
        "CN_Name": "水果转盘",
        "HTML5_Game_ID": "fw",
        "EN_Name": "Fruit Turntable",
        "Button_Image_Name": "fw.png"
      },
      {
        "CN_Name": "三国赛马",
        "HTML5_Game_ID": "hr",
        "EN_Name": "Horse Race",
        "Button_Image_Name": "hr.png"
      },
      {
        "CN_Name": "连环夺宝",
        "HTML5_Game_ID": "si",
        "EN_Name": "Serial Indiana",
        "Button_Image_Name": "si.png"
      },
      {
        "CN_Name": "幸运5",
        "HTML5_Game_ID": "lucky",
        "EN_Name": "Lucky 5",
        "Button_Image_Name": "lucky.png"
      },
      {
        "CN_Name": "好运射击",
        "HTML5_Game_ID": "gls",
        "EN_Name": "Luck Shooting",
        "Button_Image_Name": "gls.png"
      },
      {
        "CN_Name": "猴子爬树",
        "HTML5_Game_ID": "mr",
        "EN_Name": "Monkey Race",
        "Button_Image_Name": "mr.png"
      },
      {
        "CN_Name": "浮岛历险记",
        "HTML5_Game_ID": "fia",
        "EN_Name": "Adventures",
        "Button_Image_Name": "fia.png"
      },
      {
        "CN_Name": "水果传奇",
        "HTML5_Game_ID": "fl",
        "EN_Name": "Fruit Legend",
        "Button_Image_Name": "fl.png"
      },
      {
        "CN_Name": "萌宠夺宝",
        "HTML5_Game_ID": "api",
        "EN_Name": "Adorable Pet Indiana",
        "Button_Image_Name": "api.png"
      },
      {
        "CN_Name": "王者足球",
        "HTML5_Game_ID": "tkof",
        "EN_Name": "The King Of Football",
        "Button_Image_Name": "tkof.png"
      },
      {
        "CN_Name": "糖果派对",
        "HTML5_Game_ID": "cp",
        "EN_Name": "Candy Party",
        "Button_Image_Name": "cp.png"
      },
      {
        "CN_Name": "龙珠探宝",
        "HTML5_Game_ID": "lztb",
        "EN_Name": "Dragon Treasure",
        "Button_Image_Name": "lztb.png"
      },


      {
        "CN_Name": "水果派对2",
        "HTML5_Game_ID": "party2",
        "EN_Name": "Fruit_Party2",
        "Button_Image_Name": "party2.png"
      },


      {
        "CN_Name": "全民捕鱼",
        "HTML5_Game_ID": "fish_qm",
        "EN_Name": "World Of Hunter",
        "Button_Image_Name": "fish_qm.png"
      },
      {
        "CN_Name": "森林舞会2",
        "HTML5_Game_ID": "mslwh2",
        "EN_Name": "Forest Dance2",
        "Button_Image_Name": "mslwh2.png"
      },







    ]
  }, {
    "type": 4,
    "game_id": 414,
    "game_api": "HCDZ",
    "name": "GB老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/hcdz.png",
    "icon": "logo-hcdz",
    "game_list_json": "HCDZ_game_list",
    "game_list_img_path": "/new/slot_res/hcdz/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：富贵吉祥,爆骰,鱼虾蟹,糖果世界,宝石大亨,欢乐地鼠,齐天大圣,圣域传说",
    "labels": "",
    gamelist:[
      {
        "CN_Name": "聚宝象财神",
        "HTML5_Game_ID": "slots-WealthGanesha",
        "EN_Name": "Ganesha Wealth",
        "Button_Image_Name": "slots-WealthGanesha.png"
      },
      {
        "CN_Name": "麻将胡了",
        "HTML5_Game_ID": "slots-MahjongWays",
        "EN_Name": "Mahjong Ways",
        "Button_Image_Name": "slots-MahjongWays.png"
      },
      {
        "CN_Name": "赏金女皇",
        "HTML5_Game_ID": "slots-PirateTreasure",
        "EN_Name": "Queen of Bounty",
        "Button_Image_Name": "slots-PirateTreasure.png"
      },

      {
        "CN_Name": "赏金对决",
        "HTML5_Game_ID": "slots-BountyShowdown",
        "EN_Name": "The Bounty Showdown",
        "Button_Image_Name": "slots-BountyShowdown.png"
      },
      {
        "CN_Name": "哥布林矿工",
        "HTML5_Game_ID": "slots-GoblinQuest",
        "EN_Name": "Goblin's Quest",
        "Button_Image_Name": "slots-GoblinQuest.png"
      },
      {
        "CN_Name": "财神夺宝",
        "HTML5_Game_ID": "slots-OrientalFortune",
        "EN_Name": "Oriental Fortune",
        "Button_Image_Name": "slots-OrientalFortune.png"
      },
      {
        "CN_Name": "埃及艳后",
        "HTML5_Game_ID": "slots-Cleopatra",
        "EN_Name": "Cleopatra",
        "Button_Image_Name": "slots-Cleopatra.png"
      },
      {
        "CN_Name": "招财猫",
        "HTML5_Game_ID": "slots-LuckyKitty",
        "EN_Name": "LuckyKitty",
        "Button_Image_Name": "slots-LuckyKitty.png"
      },
      {
        "CN_Name": "奥林匹斯",
        "HTML5_Game_ID": "slots-TheOlympus",
        "EN_Name": "The Olympus",
        "Button_Image_Name": "slots-TheOlympus.png"
      },
      {
        "CN_Name": "麻将胡了Plus",
        "HTML5_Game_ID": "slots-MahjongWaysPlus",
        "EN_Name": "Mahjong Ways Plus",
        "Button_Image_Name": "slots-MahjongWaysPlus.png"
      },
      {
        "CN_Name": "湿婆传说",
        "HTML5_Game_ID": "slots-Shiva",
        "EN_Name": "Legends of india",
        "Button_Image_Name": "slots-Shiva.png"
      },
      {
        "CN_Name": "宙斯",
        "HTML5_Game_ID": "Slots-Zeus",
        "EN_Name": "Zeus",
        "Button_Image_Name": "Slots-Zeus.png"
      },
      {
        "CN_Name": "水果777",
        "HTML5_Game_ID": "\n777res",
        "EN_Name": "Fruits 777",
        "Button_Image_Name": "\n777res.png"
      },
      {
        "CN_Name": "水果小玛丽",
        "HTML5_Game_ID": "SuperFruitSlots",
        "EN_Name": "Fruit Mary",
        "Button_Image_Name": "SuperFruitSlots.png"
      },
      {
        "CN_Name": "财神到",
        "HTML5_Game_ID": "SlotsCaiShen",
        "EN_Name": "God of Wealth",
        "Button_Image_Name": "SlotsCaiShen.png"
      },
      {
        "CN_Name": "拳皇",
        "HTML5_Game_ID": "QHSlotsRes",
        "EN_Name": "King of Fighters",
        "Button_Image_Name": "QHSlotsRes.png"
      },
      {
        "CN_Name": "五龙争霸",
        "HTML5_Game_ID": "WLZBslots",
        "EN_Name": "Dragons War",
        "Button_Image_Name": "WLZBslots.png"
      },
      {
        "CN_Name": "大三元",
        "HTML5_Game_ID": "slots-Mahjong3",
        "EN_Name": "Mahjong Warrior",
        "Button_Image_Name": "slots-Mahjong3.png"
      },
      {
        "CN_Name": "水浒传",
        "HTML5_Game_ID": "WaterMargin",
        "EN_Name": "Water Margin",
        "Button_Image_Name": "WaterMargin.png"
      },
      {
        "CN_Name": "纸牌老虎机",
        "HTML5_Game_ID": "CardSlots",
        "EN_Name": "Card Slots",
        "Button_Image_Name": "CardSlots.png"
      },
      {
        "CN_Name": "功夫熊猫",
        "HTML5_Game_ID": "slotPanda",
        "EN_Name": "Kung Fu Panda",
        "Button_Image_Name": "slotPanda.png"
      },
      {
        "CN_Name": "火箭升空",
        "HTML5_Game_ID": "Rocket",
        "EN_Name": "ROCKET",
        "Button_Image_Name": "Rocket.png"
      },
      {
        "CN_Name": "埃及宝藏",
        "HTML5_Game_ID": "slots-Egypt-jewel",
        "EN_Name": "Egyptian Treasure",
        "Button_Image_Name": "slots-Egypt-jewel.png"
      },
      {
        "CN_Name": "西部牛仔",
        "HTML5_Game_ID": "slots-west-cowboy",
        "EN_Name": "Western Cowboy",
        "Button_Image_Name": "slots-west-cowboy.png"
      },
      {
        "CN_Name": "点球大战",
        "HTML5_Game_ID": "SlotsFootball",
        "EN_Name": "Penalty Shootout",
        "Button_Image_Name": "SlotsFootball.png"
      },
      {
        "CN_Name": "西游记",
        "HTML5_Game_ID": "slots-Xiyouji",
        "EN_Name": "The Journey to the West",
        "Button_Image_Name": "slots-Xiyouji.png"
      },

      {
        "CN_Name": "侏罗纪公园",
        "HTML5_Game_ID": "slots-JurassicPark",
        "EN_Name": "Jurassic Park",
        "Button_Image_Name": "slots-JurassicPark.png"
      },
      {
        "CN_Name": "超级777",
        "HTML5_Game_ID": "slots-Super777",
        "EN_Name": "Super 777",
        "Button_Image_Name": "slots-Super777.png"
      },
      {
        "CN_Name": "象财神",
        "HTML5_Game_ID": "slots-Ganesha",
        "EN_Name": "Ganesha",
        "Button_Image_Name": "slots-Ganesha.png"
      },
      {
        "CN_Name": "超级玛丽奥",
        "HTML5_Game_ID": "slots-SuperMary",
        "EN_Name": "Super Mario",
        "Button_Image_Name": "slots-SuperMary.png"
      },
      {
        "CN_Name": "锁定小玛丽",
        "HTML5_Game_ID": "slots-LockMary",
        "EN_Name": "LockMary",
        "Button_Image_Name": "slots-LockMary.png"
      },
      {
        "CN_Name": "幸运大翻倍",
        "HTML5_Game_ID": "DoubleLucky",
        "EN_Name": "Double Lucky",
        "Button_Image_Name": "DoubleLucky.png"
      },
      {
        "CN_Name": "一球千金",
        "HTML5_Game_ID": "GoalForGold",
        "EN_Name": "GoalForGold",
        "Button_Image_Name": "GoalForGold.png"
      },
      {
        "CN_Name": "楚河汉界",
        "HTML5_Game_ID": "ChessSlots2",
        "EN_Name": "Chinese chess",
        "Button_Image_Name": "ChessSlots2.png"
      },
      {
        "CN_Name": "火箭升空II",
        "HTML5_Game_ID": "RocketII",
        "EN_Name": "ROCKETII",
        "Button_Image_Name": "RocketII.png"
      },
      {
        "CN_Name": "冰雪女王",
        "HTML5_Game_ID": "SlotsSnowQueen",
        "EN_Name": "Snow Queen",
        "Button_Image_Name": "SlotsSnowQueen.png"
      },
      {
        "CN_Name": "疯狂小玛丽",
        "HTML5_Game_ID": "slots-CrazyMary",
        "EN_Name": "Crazy Mary",
        "Button_Image_Name": "slots-CrazyMary.png"
      },
      {
        "CN_Name": "极速版纸牌老虎机",
        "HTML5_Game_ID": "CardSlots_Fast",
        "EN_Name": "Card Slots Speed",
        "Button_Image_Name": "CardSlots_Fast.png"
      },
      {
        "CN_Name": "极速版水果777",
        "HTML5_Game_ID": "777res_Fast",
        "EN_Name": "Fruits 777 Speed",
        "Button_Image_Name": "777res_Fast.png"
      },
      {
        "CN_Name": "极速版水果小玛丽",
        "HTML5_Game_ID": "SuperFruitSlots_Fast",
        "EN_Name": "Fruit Mary Speed",
        "Button_Image_Name": "SuperFruitSlots_Fast.png"
      },
      {
        "CN_Name": "夜戏貂蝉",
        "HTML5_Game_ID": "NightShowSlots2",
        "EN_Name": "Ye Xi Diao Chan",
        "Button_Image_Name": "NightShowSlots2.png"
      },
      {
        "CN_Name": "淘金者",
        "HTML5_Game_ID": "GoldRushMaster",
        "EN_Name": "Lode Runner",
        "Button_Image_Name": "GoldRushMaster.png"
      },
      {
        "CN_Name": "武松传2",
        "HTML5_Game_ID": "SlotsWuSongZhuan",
        "EN_Name": "Wu Song Biography II",
        "Button_Image_Name": "SlotsWuSongZhuan.png"
      },
      {
        "CN_Name": "万圣节",
        "HTML5_Game_ID": "Halloween",
        "EN_Name": "Halloween",
        "Button_Image_Name": "Halloween.png"
      },
      {
        "CN_Name": "欢乐圣诞",
        "HTML5_Game_ID": "SlotsChristmas2",
        "EN_Name": "Merry Christmas",
        "Button_Image_Name": "SlotsChristmas2.png"
      },
      {
        "CN_Name": "军师",
        "HTML5_Game_ID": "SlotsCounselor",
        "EN_Name": "Counselor",
        "Button_Image_Name": "SlotsCounselor.png"
      },
      {
        "CN_Name": "鬼新娘",
        "HTML5_Game_ID": "SlotsGhostBride",
        "EN_Name": "Ghost Bride",
        "Button_Image_Name": "SlotsGhostBride.png"
      },
      {
        "CN_Name": "约会之夜",
        "HTML5_Game_ID": "slots-date-night",
        "EN_Name": "Sweetheart Night",
        "Button_Image_Name": "slots-date-night.png"
      },
      {
        "CN_Name": "夏洛克",
        "HTML5_Game_ID": "SlotsShyLock",
        "EN_Name": "Sherlock",
        "Button_Image_Name": "SlotsShyLock.png"
      },
      {
        "CN_Name": "巨钻来了",
        "HTML5_Game_ID": "Slots-Diamonds",
        "EN_Name": "Diamond Coming",
        "Button_Image_Name": "Slots-Diamonds.png"
      },
      {
        "CN_Name": "夜店之王",
        "HTML5_Game_ID": "slots-king-of-club",
        "EN_Name": "King of Club",
        "Button_Image_Name": "slots-king-of-club.png"
      },
      {
        "CN_Name": "寻找彩蛋",
        "HTML5_Game_ID": "slots-egg-hunt",
        "EN_Name": "Seek Extras",
        "Button_Image_Name": "slots-egg-hunt.png"
      },
      {
        "CN_Name": "福禄寿",
        "HTML5_Game_ID": "slots-fortune",
        "EN_Name": "Fortune",
        "Button_Image_Name": "slots-fortune.png"
      },
      {
        "CN_Name": "烈焰女王",
        "HTML5_Game_ID": "SlotsQueenOfFire",
        "EN_Name": "Fire Queen",
        "Button_Image_Name": "SlotsQueenOfFire.png"
      },
      {
        "CN_Name": "路飞传奇2",
        "HTML5_Game_ID": "SlotsLuffyLegend2",
        "EN_Name": "Luffy of Legend2",
        "Button_Image_Name": "SlotsLuffyLegend2.png"
      },
      {
        "CN_Name": "猫剑客",
        "HTML5_Game_ID": "SlotsTheCatSwordsman",
        "EN_Name": "Puss Swords Man",
        "Button_Image_Name": "SlotsTheCatSwordsman.png"
      },
      {
        "CN_Name": "点球大战3",
        "HTML5_Game_ID": "SlotsFootball-njk",
        "EN_Name": "Penalty Shootout3",
        "Button_Image_Name": "SlotsFootball-njk.png"
      },
      {
        "CN_Name": "皇城年夜3",
        "HTML5_Game_ID": "SlotsYearsEve-njk",
        "EN_Name": "New Year's Eve3",
        "Button_Image_Name": "SlotsYearsEve-njk.png"
      },
      {
        "CN_Name": "十二生肖3",
        "HTML5_Game_ID": "slotZodiac-njk",
        "EN_Name": "Zodiac3",
        "Button_Image_Name": "slotZodiac-njk.png"
      },
      {
        "CN_Name": "财神到3",
        "HTML5_Game_ID": "SlotsCaiShen-njk",
        "EN_Name": "God of Wealth3",
        "Button_Image_Name": "SlotsCaiShen-njk.png"
      },
      {
        "CN_Name": "福临门3",
        "HTML5_Game_ID": "FuLinMen-njk",
        "EN_Name": "Blessings Coming3",
        "Button_Image_Name": "FuLinMen-njk.png"
      },
      {
        "CN_Name": "盗贼之海",
        "HTML5_Game_ID": "slots-SeaOfThieves",
        "EN_Name": "Sea of Thieves",
        "Button_Image_Name": "slots-SeaOfThieves.png"
      },
      {
        "CN_Name": "阿拉丁",
        "HTML5_Game_ID": "slots-Aladdin",
        "EN_Name": "Aladdin",
        "Button_Image_Name": "slots-Aladdin.png"
      },
      {
        "CN_Name": "四圣兽",
        "HTML5_Game_ID": "slots-four-beasts",
        "EN_Name": "Four Divine Beasts",
        "Button_Image_Name": "slots-four-beasts.png"
      },
      {
        "CN_Name": "福临门MegaBonus",
        "HTML5_Game_ID": "FuLinMen-Free",
        "EN_Name": "Blessings Coming MegaBonus",
        "Button_Image_Name": "FuLinMen-Free.png"
      },
      {
        "CN_Name": "五龙争霸MegaBonus",
        "HTML5_Game_ID": "WLZBslots-Free",
        "EN_Name": "Dragons War MegaBonus",
        "Button_Image_Name": "WLZBslots-Free.png"
      },
      {
        "CN_Name": "约会之夜MegaBonus",
        "HTML5_Game_ID": "slots-date-night-Free",
        "EN_Name": "Sweetheart Night MegaBonus",
        "Button_Image_Name": "slots-date-night-Free.png"
      },
      {
        "CN_Name": "楚河汉界MegaBonus",
        "HTML5_Game_ID": "ChessSlots2-Free",
        "EN_Name": "Chinese chess MegaBonus",
        "Button_Image_Name": "ChessSlots2-Free.png"
      }
    ]
  }, {
    "type": 4,
    "game_id": 408,
    "game_api": "BBIN",
    "name": "BB老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/bbin.png",
    "icon": "logo-bbin",
    "game_list_json": "BBIN_game_list",
    "game_list_img_path": "/new/slot_res/bbin/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：魔光幻音、熊猫乐园、糖果派对、连环夺宝、多福多财、斗鸡、趣味台球...",
    "labels": "#老虎机#魔光幻音#熊猫乐园#糖果派对#连环夺宝#多福多财#斗鸡#趣味台球",
    gamelist: [
      {"EN_Name": "Chinese Mammon", "CN_Name": "幸运财神", "HTML5_Game_ID": "5030", "Button_Image_Name": "5030.png"},
      {"EN_Name": "3D Animal Party", "CN_Name": "森林舞会", "HTML5_Game_ID": "5045", "Button_Image_Name": "5045.png"},
      {"EN_Name": "Crazy Fruit", "CN_Name": "疯狂水果盘", "HTML5_Game_ID": "5058", "Button_Image_Name": "5058.png"},
      {"EN_Name": "A Chinese Odyssey", "CN_Name": "大话西游", "HTML5_Game_ID": "5067", "Button_Image_Name": "5067.png"},
      {"EN_Name": "Lucky Number", "CN_Name": "数字大转轮", "HTML5_Game_ID": "5076", "Button_Image_Name": "5076.png"},
      {"EN_Name": "3D Lucky Number", "CN_Name": "3D数字大转轮", "HTML5_Game_ID": "5079", "Button_Image_Name": "5079.png"},
      {"EN_Name": "Jin Ping Mai 2", "CN_Name": "金瓶梅2", "HTML5_Game_ID": "5094", "Button_Image_Name": "5094.png"},
      {"EN_Name": "Wu Xing", "CN_Name": "五行", "HTML5_Game_ID": "5096", "Button_Image_Name": "5096.png"},
      {"EN_Name": "WUFU LINMEN", "CN_Name": "五福临门", "HTML5_Game_ID": "5098", "Button_Image_Name": "5098.png"},
      {"EN_Name": "Good Fortune", "CN_Name": "多福多财", "HTML5_Game_ID": "5128", "Button_Image_Name": "5128.png"},
      {"EN_Name": "Candy Party3", "CN_Name": "糖果派对3", "HTML5_Game_ID": "5143", "Button_Image_Name": "5143.png"},
      {"EN_Name": "Jiu Wei Hu", "CN_Name": "九尾狐", "HTML5_Game_ID": "5153", "Button_Image_Name": "5153.png"},
      {"EN_Name": "Fire Of Huluwa", "CN_Name": "葫芦娃", "HTML5_Game_ID": "5166", "Button_Image_Name": "5166.png"},
      {"EN_Name": "Candy Party-Fast", "CN_Name": "糖果派对-极速版", "HTML5_Game_ID": "5171", "Button_Image_Name": "5171.png"},
      {"EN_Name": "ALL STARS", "CN_Name": "满天星", "HTML5_Game_ID": "5175", "Button_Image_Name": "5175.png"},
      {"EN_Name": "TikTok DJ", "CN_Name": "抖音DJ", "HTML5_Game_ID": "5182", "Button_Image_Name": "5182.png"},
      {"EN_Name": "Carp Turn Dragon", "CN_Name": "鱼跃龙门", "HTML5_Game_ID": "5184", "Button_Image_Name": "5184.png"},
      {"EN_Name": "Rich Harvest", "CN_Name": "大丰收", "HTML5_Game_ID": "5185", "Button_Image_Name": "5185.png"},
      {"EN_Name": "Piggy Bank", "CN_Name": "猪宝满满", "HTML5_Game_ID": "5191", "Button_Image_Name": "5191.png"},
      {"EN_Name": "Candy Bar", "CN_Name": "糖果吧", "HTML5_Game_ID": "5193", "Button_Image_Name": "5193.png"},
      {"EN_Name": "The Legend of Halong Bay", "CN_Name": "下龙湾神话", "HTML5_Game_ID": "5198", "Button_Image_Name": "5198.png"},
      {"EN_Name": "Hoo Hey How", "CN_Name": "鱼虾蟹开了", "HTML5_Game_ID": "5200", "Button_Image_Name": "5200.png"},
      {"EN_Name": "SAFARI", "CN_Name": "草原拍拍", "HTML5_Game_ID": "5209", "Button_Image_Name": "5209.png"},
      {"EN_Name": "Candy Pop", "CN_Name": "糖果缤纷乐", "HTML5_Game_ID": "5210", "Button_Image_Name": "5210.png"},
      {"EN_Name": "Golden Toad", "CN_Name": "富贵金蟾", "HTML5_Game_ID": "5213", "Button_Image_Name": "5213.png"},
      {"EN_Name": "BEIJING OPERA", "CN_Name": "今晚有戏", "HTML5_Game_ID": "5215", "Button_Image_Name": "5215.png"},
      {"EN_Name": "Mermaid", "CN_Name": "人鱼秘宝", "HTML5_Game_ID": "5216", "Button_Image_Name": "5216.png"},
      {"EN_Name": "Diamond Fortune", "CN_Name": "任你钻", "HTML5_Game_ID": "5219", "Button_Image_Name": "5219.png"},
      {"EN_Name": "Spider Spirits", "CN_Name": "极乐盘丝洞", "HTML5_Game_ID": "5220", "Button_Image_Name": "5220.png"},
      {"EN_Name": "Crazy Jam Jar", "CN_Name": "疯狂果酱罐", "HTML5_Game_ID": "5222", "Button_Image_Name": "5222.png"},
      {"EN_Name": "Chibi", "CN_Name": "火烧连环船", "HTML5_Game_ID": "5225", "Button_Image_Name": "5225.png"},
      {"EN_Name": "Elimination 1024", "CN_Name": "连消1024", "HTML5_Game_ID": "5226", "Button_Image_Name": "5226.png"},
      {"EN_Name": "Treasure Pot Puzzle", "CN_Name": "聚宝消消乐", "HTML5_Game_ID": "5231", "Button_Image_Name": "5231.png"},
      {"EN_Name": "Awesome Buffalo", "CN_Name": "超牛逼", "HTML5_Game_ID": "5234", "Button_Image_Name": "5234.png"},
      {"EN_Name": "Lions Find Treasure", "CN_Name": "舞狮夺宝", "HTML5_Game_ID": "5242", "Button_Image_Name": "5242.png"},
      {"EN_Name": "Too Rich", "CN_Name": "太有财", "HTML5_Game_ID": "5243", "Button_Image_Name": "5243.png"},
      {"EN_Name": "Go!Go! Somersault Cloud", "CN_Name": "Go!Go!筋斗云", "HTML5_Game_ID": "5912", "Button_Image_Name": "5912.png"},
      {"EN_Name": "Unlimited 1024", "CN_Name": "无限1024", "HTML5_Game_ID": "5247", "Button_Image_Name": "5247.png"},
      {"EN_Name": "Minecart", "CN_Name": "采矿飞车", "HTML5_Game_ID": "5250", "Button_Image_Name": "5250.png"},
      {"EN_Name": "Clearance", "CN_Name": "一杆清台", "HTML5_Game_ID": "5251", "Button_Image_Name": "5251.png"},
      {"EN_Name": "Leprechaun", "CN_Name": "矮精灵", "HTML5_Game_ID": "5252", "Button_Image_Name": "5252.png"},
      {"EN_Name": "Parvenu", "CN_Name": "爆发富", "HTML5_Game_ID": "5253", "Button_Image_Name": "5253.png"},
      {"EN_Name": "Firecrackers", "CN_Name": "爆爆生财", "HTML5_Game_ID": "5256", "Button_Image_Name": "5256.png"},
      {"EN_Name": "IP MAN", "CN_Name": "叶问", "HTML5_Game_ID": "5257", "Button_Image_Name": "5257.png"},
      {"EN_Name": "Gold King Kong", "CN_Name": "爆利金刚", "HTML5_Game_ID": "5259", "Button_Image_Name": "5259.png"},
      {"EN_Name": "Fortune Cat", "CN_Name": "招财喵喵", "HTML5_Game_ID": "5261", "Button_Image_Name": "5261.png"},
      {"EN_Name": "Dragon 888", "CN_Name": "金龙发发发", "HTML5_Game_ID": "5264", "Button_Image_Name": "5264.png"},
      {"EN_Name": "Beauty Party", "CN_Name": "AV派对", "HTML5_Game_ID": "5267", "Button_Image_Name": "5267.png"},
      {"EN_Name": "Twins Wild", "CN_Name": "百搭双星", "HTML5_Game_ID": "5268", "Button_Image_Name": "5268.png"},
      {"EN_Name": "Big Wings", "CN_Name": "大鹏展翅", "HTML5_Game_ID": "5269", "Button_Image_Name": "5269.png"},
      {"EN_Name": "Crash", "CN_Name": "Crash", "HTML5_Game_ID": "5270", "Button_Image_Name": "5270.png"},
      {"EN_Name": "Lucky Dog", "CN_Name": "财犬", "HTML5_Game_ID": "5272", "Button_Image_Name": "5272.png"},
      {"EN_Name": "Hello Tiki", "CN_Name": "Hello Tiki", "HTML5_Game_ID": "5273", "Button_Image_Name": "5273.png"},
      {"EN_Name": "Zeus Rise", "CN_Name": "财富宙升", "HTML5_Game_ID": "5274", "Button_Image_Name": "5274.png"},
      {"EN_Name": "Mahjong Ways", "CN_Name": "麻将胡了", "HTML5_Game_ID": "5275", "Button_Image_Name": "5275.png"},
      {"EN_Name": "Mahjong", "CN_Name": "碰碰胡", "HTML5_Game_ID": "5276", "Button_Image_Name": "5276.png"},
      {"EN_Name": "LuckyAce", "CN_Name": "幸运王牌", "HTML5_Game_ID": "5277", "Button_Image_Name": "5277.png"},
      {"EN_Name": "Flipped Temple", "CN_Name": "翻转神庙", "HTML5_Game_ID": "5311", "Button_Image_Name": "5311.png"},
      {"EN_Name": "Adventure of Mystical Land", "CN_Name": "秘境冒险", "HTML5_Game_ID": "5601", "Button_Image_Name": "5601.png"},
      {"EN_Name": "Big Prosperity", "CN_Name": "发大财", "HTML5_Game_ID": "5823", "Button_Image_Name": "5823.png"},
      {"EN_Name": "Happy Golden Ox Of Happiness", "CN_Name": "喜福牛年", "HTML5_Game_ID": "5835", "Button_Image_Name": "5835.png"},
      {"EN_Name": "Happy Golden Monkey Of Happiness", "CN_Name": "喜福猴年", "HTML5_Game_ID": "5837", "Button_Image_Name": "5837.png"},
      {"EN_Name": "Duo Bao", "CN_Name": "连环夺宝", "HTML5_Game_ID": "5901", "Button_Image_Name": "5901.png"},
      {"EN_Name": "Candy Party", "CN_Name": "糖果派对", "HTML5_Game_ID": "5902", "Button_Image_Name": "5902.png"},
      {"EN_Name": "Tomb of Dragon Emperor", "CN_Name": "秦皇秘宝", "HTML5_Game_ID": "5903", "Button_Image_Name": "5903.png"},
      {"EN_Name": "Candy Party2", "CN_Name": "糖果派对2", "HTML5_Game_ID": "5908", "Button_Image_Name": "5908.png"},
      {"EN_Name": "Duo Bao2", "CN_Name": "连环夺宝2", "HTML5_Game_ID": "5912", "Button_Image_Name": "5912.png"},


    ]
  }, {
    "type": 4,
    "game_id": 406,
    "game_api": "CQ9",
    "name": "CQ9老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/cq9.png",
    "icon": "cq9",
    "game_list_json": "CQ9_game_list",
    "game_list_img_path": "/new/slot_res/cq9/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：盗法老墓、妙笔生财、喵财进宝、鱼跃龙门、丛林宝藏、喵喵、老司机...",
    "labels": "#老虎机#盗法老墓#妙笔生财#喵财进宝#鱼跃龙门",
    gamelist: [

      {"EN_Name": "JumpHigh", "CN_Name": "跳高高2", "HTML5_Game_ID": "179", "Button_Image_Name": "tgaogao2.png"},
      {
        "EN_Name": "city that never sleeps",
        "CN_Name": "不夜城",
        "HTML5_Game_ID": "229",
        "Button_Image_Name": "229.png"
      },

      {
        "EN_Name": "God of Fortune",
        "CN_Name": "发发发财神",
        "HTML5_Game_ID": "227",
        "Button_Image_Name": "227.png"
      },



      {
        "EN_Name": "crazy money making",
        "CN_Name": "疯狂赚钞票",
        "HTML5_Game_ID": "BA203",
        "Button_Image_Name": "fkzcp.png"
      },

      {
        "EN_Name": "Five Ghosts Luck Fortune",
        "CN_Name": "五鬼運財",
        "HTML5_Game_ID": "AR11",
        "Button_Image_Name": "AR11.png"
      },

      {
        "EN_Name": "Typehoon Cash",
        "CN_Name": "8級颱風",
        "HTML5_Game_ID": "AR12",
        "Button_Image_Name": "AR12_8Typhoon.png"
      },
      {
        "EN_Name": "Rabbit Rampage",
        "CN_Name": "守株待兔",
        "HTML5_Game_ID": "AR16",
        "Button_Image_Name": "AR16_Rabbit.png"
      },
      {"EN_Name": "Pyramid Raider", "CN_Name": "盗法老墓", "HTML5_Game_ID": "112", "Button_Image_Name": "dflm.png"},
      {
        "EN_Name": "Invincible Elephant",
        "CN_Name": "锁象无敌",
        "HTML5_Game_ID": "124",
        "Button_Image_Name": "sxwd.png"
      },
      {"EN_Name": "The Magic Brush", "CN_Name": "妙笔生财", "HTML5_Game_ID": "AR01", "Button_Image_Name": "mbsc.png"},
      {"EN_Name": "Fortune Cats", "CN_Name": "喵财进宝", "HTML5_Game_ID": "AR02", "Button_Image_Name": "mcjb.png"},
      {
        "EN_Name": "Backyard Gold",
        "CN_Name": "此地无银300两",
        "HTML5_Game_ID": "AR03",
        "Button_Image_Name": "cdwysbl.png"
      },
      {"EN_Name": "Dou Di Zhu", "CN_Name": "斗地主经典版", "HTML5_Game_ID": "AR04", "Button_Image_Name": "ddzjdb.png"},
      {"EN_Name": "Dragon Gate", "CN_Name": "鱼跃龙门", "HTML5_Game_ID": "AR05", "Button_Image_Name": "yylm.png"},
      {"EN_Name": "Jungle Treasure", "CN_Name": "丛林宝藏", "HTML5_Game_ID": "AR06", "Button_Image_Name": "clbz.png"},
      {"EN_Name": "Kickin' Kash", "CN_Name": "功夫小神通", "HTML5_Game_ID": "AR07", "Button_Image_Name": "gfxst.png"},
      {"EN_Name": "Baccarat", "CN_Name": "百家樂", "HTML5_Game_ID": "BG01", "Button_Image_Name": "bjl.png"},
      {"EN_Name": "Flying Cai Shen", "CN_Name": "飞天财神", "HTML5_Game_ID": "113", "Button_Image_Name": "ftcs.png"},
      {"EN_Name": "Wonderland", "CN_Name": "梦游仙境", "HTML5_Game_ID": "116", "Button_Image_Name": "myxj.png"},
      {"EN_Name": "SkrSkr", "CN_Name": "老司机", "HTML5_Game_ID": "118", "Button_Image_Name": "lsj.png"},
      {"EN_Name": "ZumaWild", "CN_Name": "印加祖玛", "HTML5_Game_ID": "122", "Button_Image_Name": "yjzm.png"},
      {"EN_Name": "Meow Meow", "CN_Name": "喵喵", "HTML5_Game_ID": "AU01", "Button_Image_Name": "mm.png"},
      {"EN_Name": "Feed the Zombie", "CN_Name": "丧尸大餐", "HTML5_Game_ID": "AU02", "Button_Image_Name": "ssdc.png"},
      {
        "EN_Name": "Strange Encounter",
        "CN_Name": "异外来客",
        "HTML5_Game_ID": "AU05",
        "Button_Image_Name": "ywlk.png"
      },
      {"EN_Name": "Poker Bull", "CN_Name": "抢庄牛牛", "HTML5_Game_ID": "AF3", "Button_Image_Name": "qznn.png"},
      {
        "EN_Name": "Poker Bull for Hundreds",
        "CN_Name": "超級百人牛牛",
        "HTML5_Game_ID": "AF4",
        "Button_Image_Name": "cjbrnn.png"
      },
      {
        "EN_Name": "Landlord Fights Carnival",
        "CN_Name": "加倍斗地主",
        "HTML5_Game_ID": "AF9",
        "Button_Image_Name": "jbddz.png"
      },
      {"EN_Name": "Demon Archive", "CN_Name": "恶魔侦探", "HTML5_Game_ID": "AN01", "Button_Image_Name": "emzt.png"},
      {"EN_Name": "Powerful Thor", "CN_Name": "雷神托尔", "HTML5_Game_ID": "AN02", "Button_Image_Name": "lste.png"},
      {"EN_Name": "God of Fortune", "CN_Name": "财神", "HTML5_Game_ID": "AN03", "Button_Image_Name": "cs.png"},
      {"EN_Name": "Colosseum", "CN_Name": "罗马竞技场", "HTML5_Game_ID": "AN04", "Button_Image_Name": "lmjjc.png"},
      {
        "EN_Name": "Treasure Island2",
        "CN_Name": "金银岛2",
        "HTML5_Game_ID": "AN05",
        "Button_Image_Name": "jyd2.png"
      },
      {"EN_Name": "Happy Farm", "CN_Name": "开心农场", "HTML5_Game_ID": "AN06", "Button_Image_Name": "kxlc.png"},
      {"EN_Name": "JumpHigh", "CN_Name": "跳高高", "HTML5_Game_ID": "52", "Button_Image_Name": "tgaogao.png"},
      {"EN_Name": "RaveJump", "CN_Name": "跳起来", "HTML5_Game_ID": "7", "Button_Image_Name": "tql.png"},
      {"EN_Name": "LuckyBats", "CN_Name": "五福临门", "HTML5_Game_ID": "10", "Button_Image_Name": "wflm.png"},
      {"EN_Name": "Paradise", "CN_Name": "皇金渔场", "HTML5_Game_ID": "AB3", "Button_Image_Name": "hjyc.png"},
      {"EN_Name": "RaveJump2", "CN_Name": "跳起来2", "HTML5_Game_ID": "24", "Button_Image_Name": "tql2.png"},
      {"EN_Name": "Good Fortune", "CN_Name": "鸿福齐天", "HTML5_Game_ID": "50", "Button_Image_Name": "hfqt.png"},
      {"EN_Name": "God of War", "CN_Name": "武圣", "HTML5_Game_ID": "31", "Button_Image_Name": "ws.png"},
      {"EN_Name": "Zeus", "CN_Name": "宙斯", "HTML5_Game_ID": "64", "Button_Image_Name": "zs.png"},
      {"EN_Name": "JumpHigher", "CN_Name": "跳更高", "HTML5_Game_ID": "99", "Button_Image_Name": "tgg.png"},
      {"EN_Name": "FaCaiShen", "CN_Name": "发财神", "HTML5_Game_ID": "69", "Button_Image_Name": "fcs.png"},
      {"EN_Name": "DiscoNight", "CN_Name": "蹦迪", "HTML5_Game_ID": "205", "Button_Image_Name": "bd.png"},
      {"EN_Name": "Fire Queen", "CN_Name": "火之女王", "HTML5_Game_ID": "83", "Button_Image_Name": "hznw.png"},
      {"EN_Name": "ZhongKui", "CN_Name": "钟馗运财", "HTML5_Game_ID": "9", "Button_Image_Name": "zkyc.png"},
      {"EN_Name": "GuGuGu", "CN_Name": "金鸡报喜", "HTML5_Game_ID": "15", "Button_Image_Name": "jjbx.png"},
      {"EN_Name": "Fire Chibi", "CN_Name": "火烧连环船", "HTML5_Game_ID": "33", "Button_Image_Name": "hslhc.png"},
      {"EN_Name": "RaveHigh", "CN_Name": "嗨起来", "HTML5_Game_ID": "203", "Button_Image_Name": "hql.png"},
      {"EN_Name": "WaterWorld", "CN_Name": "水世界", "HTML5_Game_ID": "29", "Button_Image_Name": "ssj.png"},
      {"EN_Name": "SoSweet", "CN_Name": "甜蜜蜜", "HTML5_Game_ID": "8", "Button_Image_Name": "tmm.png"},
      {"EN_Name": "THOR", "CN_Name": "雷神", "HTML5_Game_ID": "89", "Button_Image_Name": "ls.png"},
      {"EN_Name": "jumping mobile", "CN_Name": "单手跳高高", "HTML5_Game_ID": "105", "Button_Image_Name": "dstgg.png"},
      {"EN_Name": "FlyOut", "CN_Name": "飞起来", "HTML5_Game_ID": "111", "Button_Image_Name": "fql.png"},
      {"EN_Name": "GuGuGu2", "CN_Name": "金鸡报喜2", "HTML5_Game_ID": "58", "Button_Image_Name": "jjbx2.png"},
      {
        "EN_Name": "Jump Higher mobile",
        "CN_Name": "直式跳更高",
        "HTML5_Game_ID": "108",
        "Button_Image_Name": "zstgg.png"
      },
      {"EN_Name": "FruitKing", "CN_Name": "钻石水果王", "HTML5_Game_ID": "1", "Button_Image_Name": "zssgw.png"},
      {"EN_Name": "DiscoNight M", "CN_Name": "直式蹦迪", "HTML5_Game_ID": "137", "Button_Image_Name": "zsbd.png"},
      {"EN_Name": "Chameleon", "CN_Name": "变色龙", "HTML5_Game_ID": "79", "Button_Image_Name": "bsl.png"},
      {"EN_Name": "Jungle Party", "CN_Name": "丛林舞会", "HTML5_Game_ID": "60", "Button_Image_Name": "clwh.png"},
      {"EN_Name": "Super5", "CN_Name": "五行", "HTML5_Game_ID": "16", "Button_Image_Name": "wx.png"},
      {"EN_Name": "HappyRichYear", "CN_Name": "好运年年", "HTML5_Game_ID": "72", "Button_Image_Name": "hynn.png"},
      {"EN_Name": "Golden Egg", "CN_Name": "赚金蛋", "HTML5_Game_ID": "67", "Button_Image_Name": "zjd.png"},
      {"EN_Name": "Wolf Moon", "CN_Name": "狼月", "HTML5_Game_ID": "46", "Button_Image_Name": "ly.png"},
      {"EN_Name": "MuayThai", "CN_Name": "拳霸", "HTML5_Game_ID": "201", "Button_Image_Name": "qb.png"},
      {
        "EN_Name": "Rave Jump mobile",
        "CN_Name": "单手跳起來",
        "HTML5_Game_ID": "109",
        "Button_Image_Name": "dstql.png"
      },
      {"EN_Name": "TreasureBowl", "CN_Name": "聚宝盆", "HTML5_Game_ID": "74", "Button_Image_Name": "jbp.png"},
      {"EN_Name": "Dracula", "CN_Name": "黯夜公爵", "HTML5_Game_ID": "56", "Button_Image_Name": "aygj.png"},
      {"EN_Name": "Dragon OnHeart", "CN_Name": "魔龙传奇", "HTML5_Game_ID": "55", "Button_Image_Name": "mlcq.png"},
      {"EN_Name": "Football star", "CN_Name": "世界杯明星", "HTML5_Game_ID": "93", "Button_Image_Name": "sjbmx.png"},
      {"EN_Name": "Zeus M", "CN_Name": "直式宙斯", "HTML5_Game_ID": "125", "Button_Image_Name": "zszs.png"},
      {"EN_Name": "Funny Alpaca", "CN_Name": "火草泥马", "HTML5_Game_ID": "54", "Button_Image_Name": "hcnm.png"},
      {"EN_Name": "LuckyBats M", "CN_Name": "直式五福临门", "HTML5_Game_ID": "123", "Button_Image_Name": "zswflm.png"},
      {"EN_Name": "TreasureHouse", "CN_Name": "金玉满堂", "HTML5_Game_ID": "12", "Button_Image_Name": "jymt.png"},
      {
        "EN_Name": "Fire Chibi M",
        "CN_Name": "直式火烧连环船",
        "HTML5_Game_ID": "139",
        "Button_Image_Name": "zshslhc.png"
      },
      {"EN_Name": "Apollo", "CN_Name": "阿波罗", "HTML5_Game_ID": "78", "Button_Image_Name": "abl.png"},
      {"EN_Name": "The Beast War", "CN_Name": "神兽争霸", "HTML5_Game_ID": "57", "Button_Image_Name": "sszb.png"},
      {"EN_Name": "Mr.Rich", "CN_Name": "金大款", "HTML5_Game_ID": "5", "Button_Image_Name": "jdk.png"},
      {"EN_Name": "Gemstone", "CN_Name": "钻饱宝", "HTML5_Game_ID": "114", "Button_Image_Name": "zbb.png"},
      {"EN_Name": "OrientalBeauty", "CN_Name": "舞媚娘", "HTML5_Game_ID": "202", "Button_Image_Name": "wmn.png"},
      {"EN_Name": "The Ghouls", "CN_Name": "寻龙诀", "HTML5_Game_ID": "63", "Button_Image_Name": "xlj.png"},
      {"EN_Name": "God of War M", "CN_Name": "直式武圣", "HTML5_Game_ID": "127", "Button_Image_Name": "zsws.png"},
      {"EN_Name": "FruitKingII", "CN_Name": "豪华水果王", "HTML5_Game_ID": "44", "Button_Image_Name": "hhsgw.png"},
      {"EN_Name": "RaveJump2 M", "CN_Name": "直式跳起来2", "HTML5_Game_ID": "121", "Button_Image_Name": "zstql2.png"},
      {"EN_Name": "LuckyBatsJP", "CN_Name": "五福临门JP", "HTML5_Game_ID": "1010", "Button_Image_Name": "wflmjp.png"},
      {"EN_Name": "WildTarzan", "CN_Name": "森林泰后", "HTML5_Game_ID": "4", "Button_Image_Name": "slth.png"},
      {"EN_Name": "RunningToro", "CN_Name": "牛逼快跑", "HTML5_Game_ID": "86", "Button_Image_Name": "nbkp.png"},
      {"EN_Name": "SuperDiamonds", "CN_Name": "非常钻", "HTML5_Game_ID": "62", "Button_Image_Name": "fcz.png"},
      {"EN_Name": "VampireKiss", "CN_Name": "血之吻", "HTML5_Game_ID": "3", "Button_Image_Name": "xzw.png"},
      {"EN_Name": "Apsaras", "CN_Name": "飞天", "HTML5_Game_ID": "39", "Button_Image_Name": "ft.png"},
      {
        "EN_Name": "Detective Dee2",
        "CN_Name": "狄仁杰四大天王",
        "HTML5_Game_ID": "221",
        "Button_Image_Name": "drjsdtw.png"
      },
      {"EN_Name": "YuanBao", "CN_Name": "金元宝", "HTML5_Game_ID": "23", "Button_Image_Name": "jyb.png"},
      {"EN_Name": "ChickyParmParm", "CN_Name": "海滨消消乐", "HTML5_Game_ID": "104", "Button_Image_Name": "hbxxl.png"},
      {"EN_Name": "FruityCarnival", "CN_Name": "水果派对", "HTML5_Game_ID": "102", "Button_Image_Name": "sgpd.png"},
      {
        "EN_Name": "Darts Championship",
        "CN_Name": "镖王争霸",
        "HTML5_Game_ID": "40",
        "Button_Image_Name": "bwzb.png"
      },
      {"EN_Name": "WuKong&Peaches", "CN_Name": "悟空偷桃", "HTML5_Game_ID": "68", "Button_Image_Name": "wktt.png"},
      {
        "EN_Name": "Good Fortune M",
        "CN_Name": "直式洪福齐天",
        "HTML5_Game_ID": "133",
        "Button_Image_Name": "zshfqt.png"
      },
      {"EN_Name": "1945", "CN_Name": "1945", "HTML5_Game_ID": "6", "Button_Image_Name": "1945.png"},
      {"EN_Name": "RedPhoenix", "CN_Name": "火凤凰", "HTML5_Game_ID": "77", "Button_Image_Name": "hfh.png"},
      {"EN_Name": "Fire777", "CN_Name": "火爆777", "HTML5_Game_ID": "66", "Button_Image_Name": "hb777.png"},
      {"EN_Name": "Happy Magpies", "CN_Name": "金喜鹊桥", "HTML5_Game_ID": "88", "Button_Image_Name": "jxqq.png"},
      {"EN_Name": "Fa Cai Shen M", "CN_Name": "直式发财神", "HTML5_Game_ID": "131", "Button_Image_Name": "zsfcs.png"},
      {
        "EN_Name": "TreasureBowlJP",
        "CN_Name": "聚宝盆JP",
        "HTML5_Game_ID": "1074",
        "Button_Image_Name": "jbpjp.png"
      },
      {"EN_Name": "888", "CN_Name": "发发发", "HTML5_Game_ID": "20", "Button_Image_Name": "fff.png"},
      {"EN_Name": "777", "CN_Name": "777", "HTML5_Game_ID": "26", "Button_Image_Name": "777.png"},
      {"EN_Name": "Warrior legend", "CN_Name": "三国序", "HTML5_Game_ID": "30", "Button_Image_Name": "sgx.png"},
      {"EN_Name": "TreasureIsland", "CN_Name": "金银岛", "HTML5_Game_ID": "81", "Button_Image_Name": "jyd.png"},
      {"EN_Name": "Lotus", "CN_Name": "莲", "HTML5_Game_ID": "48", "Button_Image_Name": "l.png"},
      {"EN_Name": "HotSpin", "CN_Name": "风火轮", "HTML5_Game_ID": "19", "Button_Image_Name": "fhl.png"},
      {"EN_Name": "Gong He Xin Xi", "CN_Name": "恭贺新禧", "HTML5_Game_ID": "43", "Button_Image_Name": "ghxx.png"},
      {"EN_Name": "All Star Team", "CN_Name": "世界杯全明星", "HTML5_Game_ID": "98", "Button_Image_Name": "sjbqmx.png"},
      {"EN_Name": "GreatLion", "CN_Name": "祥狮献瑞", "HTML5_Game_ID": "17", "Button_Image_Name": "xsxr.png"},
      {"EN_Name": "All Wilds", "CN_Name": "舞力全开", "HTML5_Game_ID": "38", "Button_Image_Name": "wlqk.png"},
      {"EN_Name": "JewelLuxury", "CN_Name": "宝石配对", "HTML5_Game_ID": "103", "Button_Image_Name": "bspd.png"},
      {"EN_Name": "Water Balloons", "CN_Name": "水球大战", "HTML5_Game_ID": "41", "Button_Image_Name": "sqdz.png"},
      {"EN_Name": "MahjongKing", "CN_Name": "雀王", "HTML5_Game_ID": "18", "Button_Image_Name": "qw.png"},
      {"EN_Name": "WanBaoDino", "CN_Name": "万饱龙", "HTML5_Game_ID": "70", "Button_Image_Name": "wbl.png"},
      {
        "EN_Name": "Gu Gu Gu 2 M",
        "CN_Name": "直式金鸡报喜2",
        "HTML5_Game_ID": "129",
        "Button_Image_Name": "zsjjbx2.png"
      },
      {"EN_Name": "Lonely Planet", "CN_Name": "寂寞星球", "HTML5_Game_ID": "49", "Button_Image_Name": "jmxq.png"},
      {"EN_Name": "PokerSLOT", "CN_Name": "扑克拉霸", "HTML5_Game_ID": "25", "Button_Image_Name": "pklb.png"},
      {"EN_Name": "Love Night", "CN_Name": "来电99", "HTML5_Game_ID": "53", "Button_Image_Name": "ld99.png"},
      {
        "EN_Name": "JellypopHumming",
        "CN_Name": "宾果消消消",
        "HTML5_Game_ID": "100",
        "Button_Image_Name": "bgxxx.png"
      },
      {"EN_Name": "StarMatching", "CN_Name": "星星消消乐", "HTML5_Game_ID": "101", "Button_Image_Name": "xxxxx.png"},
      {"EN_Name": "Xmas", "CN_Name": "圣诞来啦", "HTML5_Game_ID": "141", "Button_Image_Name": "sdll.png"},
      {"EN_Name": "Detective Dee", "CN_Name": "通天神探狄仁杰", "HTML5_Game_ID": "32", "Button_Image_Name": "drj.png"},
      {"EN_Name": "GettingEnergies", "CN_Name": "集电宝", "HTML5_Game_ID": "87", "Button_Image_Name": "jdb.png"},
      {"EN_Name": "GodOfChess", "CN_Name": "棋圣", "HTML5_Game_ID": "2", "Button_Image_Name": "qs.png"},
      {
        "EN_Name": "Treasure of Seti",
        "CN_Name": "塞特的宝藏",
        "HTML5_Game_ID": "AS17",
        "Button_Image_Name": "stdbz.png"
      },
      {"EN_Name": "Ecstatic Circus", "CN_Name": "嗨爆大马戏", "HTML5_Game_ID": "51", "Button_Image_Name": "hbdmx.png"},
      {"EN_Name": "Crazy NuoZha", "CN_Name": "疯狂哪咤", "HTML5_Game_ID": "35", "Button_Image_Name": "fknz.png"},
      {"EN_Name": "SummerMood", "CN_Name": "夏日猩情", "HTML5_Game_ID": "59", "Button_Image_Name": "xrxq.png"},
      {"EN_Name": "Super8", "CN_Name": "超级发", "HTML5_Game_ID": "45", "Button_Image_Name": "cjf.png"},
      {"EN_Name": "Poseidon", "CN_Name": "传奇海神", "HTML5_Game_ID": "80", "Button_Image_Name": "cqhs.png"},
      {"EN_Name": "Sherlock Holmes", "CN_Name": "福尔摩斯", "HTML5_Game_ID": "42", "Button_Image_Name": "fems.png"},
      {"EN_Name": "Gophers War", "CN_Name": "地鼠战役", "HTML5_Game_ID": "34", "Button_Image_Name": "dszy.png"},
      {"EN_Name": "Magic World", "CN_Name": "魔法世界", "HTML5_Game_ID": "27", "Button_Image_Name": "mfsj.png"},
      {"EN_Name": "LuckyBoxes", "CN_Name": "百宝箱", "HTML5_Game_ID": "204", "Button_Image_Name": "bbx.png"},
      {"EN_Name": "Xmas Tales", "CN_Name": "圣诞故事", "HTML5_Game_ID": "AS19", "Button_Image_Name": "sdgs.png"},
      {
        "EN_Name": "Football Jerseys",
        "CN_Name": "世界杯球衣",
        "HTML5_Game_ID": "94",
        "Button_Image_Name": "sjbqy.png"
      },
      {"EN_Name": "Lucky 3", "CN_Name": "幸运3", "HTML5_Game_ID": "AS10", "Button_Image_Name": "xy3.png"},
      {"EN_Name": "RichWitch", "CN_Name": "绝赢巫师", "HTML5_Game_ID": "14", "Button_Image_Name": "jyws.png"},
      {"EN_Name": "Mr.Bean", "CN_Name": "天天吃豆", "HTML5_Game_ID": "61", "Button_Image_Name": "ttcd.png"},
      {"EN_Name": "SakuraLegend", "CN_Name": "樱花妹子", "HTML5_Game_ID": "13", "Button_Image_Name": "yhmz.png"},
      {"EN_Name": "Wonderland2", "CN_Name": "梦游仙境2", "HTML5_Game_ID": "11", "Button_Image_Name": "myxj2.png"},
      {"EN_Name": "Football Baby", "CN_Name": "足球宝贝", "HTML5_Game_ID": "96", "Button_Image_Name": "zqbb.png"},
      {"EN_Name": "Paradise2", "CN_Name": "皇金渔场2", "HTML5_Game_ID": "AB1", "Button_Image_Name": "hjychw.png"},
      {"EN_Name": "Wild Magic", "CN_Name": "奇幻魔术", "HTML5_Game_ID": "84", "Button_Image_Name": "qhms.png"},
      {"EN_Name": "God of Cookery", "CN_Name": "食神", "HTML5_Game_ID": "28", "Button_Image_Name": "ss.png"},
      {"EN_Name": "Pharaoh's Gold", "CN_Name": "法老宝藏", "HTML5_Game_ID": "47", "Button_Image_Name": "flbz.png"},
      {"EN_Name": "GoldenEggsJP", "CN_Name": "赚金蛋JP", "HTML5_Game_ID": "1067", "Button_Image_Name": "zjd.png"},
      {"EN_Name": "Gu Gu Gu M", "CN_Name": "直式金鸡报喜", "HTML5_Game_ID": "135", "Button_Image_Name": "zsjjbx.png"},
      {"EN_Name": "BigWolf", "CN_Name": "野狼传说", "HTML5_Game_ID": "21", "Button_Image_Name": "ylcs.png"},
      {
        "EN_Name": "World Cup Russia 2018",
        "CN_Name": "2018世界杯",
        "HTML5_Game_ID": "92",
        "Button_Image_Name": "sjb.png"
      },
      {"EN_Name": "Football Boots", "CN_Name": "世界杯球鞋", "HTML5_Game_ID": "95", "Button_Image_Name": "sjbqx.png"},
      {"EN_Name": "Queen Of Dead", "CN_Name": "死亡女王", "HTML5_Game_ID": "AS20", "Button_Image_Name": "swnw.png"},
      {"EN_Name": "Wild Fudge", "CN_Name": "疯狂软糖", "HTML5_Game_ID": "AS18", "Button_Image_Name": "fkrt.png"},
      {
        "EN_Name": "Hollywood Pets",
        "CN_Name": "好莱坞宠物",
        "HTML5_Game_ID": "AS09",
        "Button_Image_Name": "hlwcw.png"
      },
      {"EN_Name": "WorldCup Field", "CN_Name": "世界杯球场", "HTML5_Game_ID": "97", "Button_Image_Name": "sjbqc.png"},
      {
        "EN_Name": "MonkeyOfficeLegend",
        "CN_Name": "庶务西游二课",
        "HTML5_Game_ID": "22",
        "Button_Image_Name": "swxy.png"
      },
      {"EN_Name": "WonWonWon", "CN_Name": "旺旺旺", "HTML5_Game_ID": "76", "Button_Image_Name": "www.png"},
      {"EN_Name": "Pub Tycoon", "CN_Name": "夜店大亨", "HTML5_Game_ID": "36", "Button_Image_Name": "yddh.png"},
      {
        "EN_Name": "Fishing Master",
        "CN_Name": "钓鱼高手",
        "HTML5_Game_ID": "AMfish",
        "Button_Image_Name": "dygs.png"
      },
      {"EN_Name": "Golden Kick", "CN_Name": "足球世界杯", "HTML5_Game_ID": "65", "Button_Image_Name": "zqsjb.png"},
      {
        "EN_Name": "Fight the Landlord",
        "CN_Name": "斗地主",
        "HTML5_Game_ID": "AD03",
        "Button_Image_Name": "ddz.png"
      },
      {"EN_Name": "Niuniu", "CN_Name": "百人牛牛", "HTML5_Game_ID": "AQ01", "Button_Image_Name": "brnn.png"},
      {"EN_Name": "ScMahjong", "CN_Name": "四川麻将", "HTML5_Game_ID": "AD02", "Button_Image_Name": "scmj.png"},
      {"EN_Name": "Happy fruit", "CN_Name": "快乐水果机", "HTML5_Game_ID": "BC01", "Button_Image_Name": "klsgj.png"},
      {"EN_Name": "Heracles", "CN_Name": "大力神", "HTML5_Game_ID": "161", "Button_Image_Name": "dls.png"},
      {"EN_Name": "Ne Zha Advent", "CN_Name": "哪吒再临", "HTML5_Game_ID": "163", "Button_Image_Name": "nzzl.png"},

      // {"EN_Name": "Fortune Beasts", "CN_Name": "东方福兽", "HTML5_Game_ID": "167", "Button_Image_Name": "dffs.png"},
      // {"EN_Name": "Last Roll", "CN_Name": "夺镖", "HTML5_Game_ID": "BT01", "Button_Image_Name": "duobiao.png"},
      // {"EN_Name": "Gate Of The Gods", "CN_Name": "众神之门", "HTML5_Game_ID": "171", "Button_Image_Name": "zszm.png"},
      {"EN_Name": "Toros", "CN_Name": "666_6", "HTML5_Game_ID": "173", "Button_Image_Name": "666_6.png"},
      {"EN_Name": "Gu Gu Gu 3", "CN_Name": "金鸡报囍3", "HTML5_Game_ID": "180", "Button_Image_Name": "jjbx3.png"},
    ]
  }, {
    "type": 4,
    "game_id": 403,
    "game_api": "HB",
    "name": "HB老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/hb.png",
    "icon": "hb",
    "game_list_json": "HB_game_list",
    "game_list_img_path": "/new/slot_res/hb/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：欢乐喜狮、疯狂水果、快乐圣诞、快乐南瓜、神秘埃及、寻宝记...",
    "labels": "#老虎机#欢乐喜狮#疯狂水果#快乐圣诞#快乐南瓜",
    gamelist: [
      {
        "CN_Name": "嘉年华湾",
        "HTML5_Game_ID": "SGCarnivalCove",
        "EN_Name": "Carnival Cove",
        "Button_Image_Name": "SGCarnivalCove.png"
      },
      {
        "CN_Name": "巴巴亚加",
        "HTML5_Game_ID": "SGBabaYaga",
        "EN_Name": "Baba Yaga",
        "Button_Image_Name": "SGBabaYaga.png"
      },
      {
        "CN_Name": "波塞冬",
        "HTML5_Game_ID": "SGPoseidon",
        "EN_Name": "Poseidon",
        "Button_Image_Name": "SGPoseidon.png"
      },
      {
        "CN_Name": "疯狂百搭2",
        "HTML5_Game_ID": "SGJump2",
        "EN_Name": "Jump! 2",
        "Button_Image_Name": "SGJump2.png"
      },
      {
        "CN_Name": "宝藏海湾",
        "HTML5_Game_ID": "SGHauntedHarbor",
        "EN_Name": "Haunted Harbor",
        "Button_Image_Name": "SGHauntedHarbor.png"
      },
      {
        "CN_Name": "荣耀之战",
        "HTML5_Game_ID": "SGGloryOfRome",
        "EN_Name": "Glory Of Rome",
        "Button_Image_Name": "SGGloryOfRome.png"
      },
      {
        "CN_Name": "宝石鲤鱼",
        "HTML5_Game_ID": "SGKoiKoiTreasure",
        "EN_Name": "Koi Koi Treasure",
        "Button_Image_Name": "SGKoiKoiTreasure.png"
      },
      {
        "CN_Name": "华丽舞会",
        "HTML5_Game_ID": "SGWaltzBeauty",
        "EN_Name": "Waltz Beauty",
        "Button_Image_Name": "SGWaltzBeauty.png"
      },
      {
        "CN_Name": "神秘图腾",
        "HTML5_Game_ID": "SGTotemWarrior",
        "EN_Name": "Totem Warrior",
        "Button_Image_Name": "SGTotemWarrior.png"
      },
      {
        "CN_Name": "雪狐世界",
        "HTML5_Game_ID": "SGArcticHunt",
        "EN_Name": "Arctic Hunt",
        "Button_Image_Name": "SGArcticHunt.png"
      },
      {
        "CN_Name": "超级哞哞",
        "HTML5_Game_ID": "SGMooMooCow",
        "EN_Name": "Moo Moo Cow",
        "Button_Image_Name": "SGMooMooCow.png"
      },
      {
        "CN_Name": "泰姬宝藏",
        "HTML5_Game_ID": "SGGoldenTajMahal",
        "EN_Name": "Golden Taj Mahal",
        "Button_Image_Name": "SGGoldenTajMahal.png"
      },
      {
        "CN_Name": "动物森林",
        "HTML5_Game_ID": "SGWildFlow",
        "EN_Name": "Wild Flow",
        "Button_Image_Name": "SGWildFlow.png"
      },
      {
        "CN_Name": "噬血一族",
        "HTML5_Game_ID": "SGVampiresFate",
        "EN_Name": "Vampire's Fate",
        "Button_Image_Name": "SGVampiresFate.png"
      },
      {
        "CN_Name": "疯狂夏季",
        "HTML5_Game_ID": "SGHotHotSummer",
        "EN_Name": "Hot Hot Summer",
        "Button_Image_Name": "SGHotHotSummer.png"
      },
      {
        "CN_Name": "恋爱季节",
        "HTML5_Game_ID": "SGValentineMonchy",
        "EN_Name": "Valentine Monchy",
        "Button_Image_Name": "SGValentineMonchy.png"
      },
      {
        "CN_Name": "玛雅水果",
        "HTML5_Game_ID": "SGFruityMayan",
        "EN_Name": "Fruity Mayan",
        "Button_Image_Name": "SGFruityMayan.png"
      },
      {
        "CN_Name": "圣诞旅馆",
        "HTML5_Game_ID": "SGSantasInn",
        "EN_Name": "Santa's Inn",
        "Button_Image_Name": "SGSantasInn.png"
      },
      {
        "CN_Name": "雷霆宙斯",
        "HTML5_Game_ID": "SGZeusDeluxe",
        "EN_Name": "Zeus Deluxe",
        "Button_Image_Name": "SGZeusDeluxe.png"
      },
      {
        "CN_Name": "捣蛋果果",
        "HTML5_Game_ID": "SGFruityHalloween",
        "EN_Name": "Fruity Halloween",
        "Button_Image_Name": "SGFruityHalloween.png"
      },
      {
        "CN_Name": "史莱姆",
        "HTML5_Game_ID": "SGSlimeParty",
        "EN_Name": "Slime Party",
        "Button_Image_Name": "SGSlimeParty.png"
      },
      {
        "CN_Name": "猜拳大作战",
        "HTML5_Game_ID": "SGMeowJanken",
        "EN_Name": "Meow Janken",
        "Button_Image_Name": "SGMeowJanken.png"
      },
      {
        "CN_Name": "比基尼岛",
        "HTML5_Game_ID": "SGBikiniIslandDeluxe",
        "EN_Name": "Bikini Island Deluxe",
        "Button_Image_Name": "SGBikiniIslandDeluxe.png"
      },
      {
        "CN_Name": "惊人的猫派对",
        "HTML5_Game_ID": "SGAtomicKittens",
        "EN_Name": "Atomic Kittens",
        "Button_Image_Name": "SGAtomicKittens.png"
      },
      {
        "CN_Name": "巫师秘籍",
        "HTML5_Game_ID": "SGWitchesTome",
        "EN_Name": "Witches Tome",
        "Button_Image_Name": "SGWitchesTome.png"
      },
      {
        "CN_Name": "哪吒传奇",
        "HTML5_Game_ID": "SGLegendOfNezha",
        "EN_Name": "Legend of Nezha",
        "Button_Image_Name": "SGLegendOfNezha.png"
      },
      {
        "CN_Name": "果果乐园",
        "HTML5_Game_ID": "SGTootyFruityFruits",
        "EN_Name": "Tooty Fruity Fruits",
        "Button_Image_Name": "SGTootyFruityFruits.png"
      },
      {
        "CN_Name": "人鱼呼唤",
        "HTML5_Game_ID": "SGSirensSpell",
        "EN_Name": "Siren’s Spell",
        "Button_Image_Name": "SGSirensSpell.png"
      },
      {
        "CN_Name": "神秘宝石",
        "HTML5_Game_ID": "SGCrystopia",
        "EN_Name": "Crystopia",
        "Button_Image_Name": "SGCrystopia.png"
      },
      {
        "CN_Name": "豪门赌厅",
        "HTML5_Game_ID": "SGTheBigDealDeluxe",
        "EN_Name": "The Big Deal Deluxe",
        "Button_Image_Name": "SGTheBigDealDeluxe.png"
      },
      {
        "CN_Name": "悟空游",
        "HTML5_Game_ID": "SGNaughtyWukong",
        "EN_Name": "Naughty Wukong",
        "Button_Image_Name": "SGNaughtyWukong.png"
      },
      {
        "CN_Name": "吉祥宝宝",
        "HTML5_Game_ID": "SGLegendaryBeasts",
        "EN_Name": "Legendary Beasts",
        "Button_Image_Name": "SGLegendaryBeasts.png"
      },
      {
        "CN_Name": "彩虹森林",
        "HTML5_Game_ID": "SGRainbowmania",
        "EN_Name": "Rainbowmania",
        "Button_Image_Name": "SGRainbowmania.png"
      },
      {
        "CN_Name": "龙虎門",
        "HTML5_Game_ID": "SGDragonTigerGate",
        "EN_Name": "Dragon Tiger gate",
        "Button_Image_Name": "SGDragonTigerGate.png"
      },
      {
        "CN_Name": "炸弹烧酒",
        "HTML5_Game_ID": "SGSojuBomb",
        "EN_Name": "Soju Bomb",
        "Button_Image_Name": "SGSojuBomb.png"
      },
      {
        "CN_Name": "嘟嘟游",
        "HTML5_Game_ID": "SGTukTukThailand",
        "EN_Name": "Tuk Tuk Thailand",
        "Button_Image_Name": "SGTukTukThailand.png"
      },
      {
        "CN_Name": "吉太鼓",
        "HTML5_Game_ID": "SGTaikoBeats",
        "EN_Name": "Taiko Beats",
        "Button_Image_Name": "SGTaikoBeats.png"
      },
      {
        "CN_Name": "笑佛降临",
        "HTML5_Game_ID": "SGLaughingBuddha",
        "EN_Name": "Laughing Buddha",
        "Button_Image_Name": "SGLaughingBuddha.png"
      },
      {
        "CN_Name": "宇宙怪咖",
        "HTML5_Game_ID": "SGSpaceGoonz",
        "EN_Name": "Space Goonz",
        "Button_Image_Name": "SGSpaceGoonz.png"
      },
      {
        "CN_Name": "黄金独角兽",
        "HTML5_Game_ID": "SGGoldenUnicornDeluxe",
        "EN_Name": "Golden Unicorn Deluxe",
        "Button_Image_Name": "SGGoldenUnicornDeluxe.png"
      },
      {
        "CN_Name": "轰炸工厂",
        "HTML5_Game_ID": "SGBombRunner",
        "EN_Name": "Bomb Runner",
        "Button_Image_Name": "SGBombRunner.png"
      },
      {
        "CN_Name": "蛇发女妖",
        "HTML5_Game_ID": "SGMightyMedusa",
        "EN_Name": "Mighty Medusa",
        "Button_Image_Name": "SGMightyMedusa.png"
      },
      {
        "CN_Name": "劲舞电音",
        "HTML5_Game_ID": "SGDiscoBeats",
        "EN_Name": "Disco Beats",
        "Button_Image_Name": "SGDiscoBeats.png"
      },
      {
        "CN_Name": "派彩灯笼",
        "HTML5_Game_ID": "SGLanternLuck",
        "EN_Name": "Lantern Luck",
        "Button_Image_Name": "SGLanternLuck.png"
      },
      {
        "CN_Name": "水果之王",
        "HTML5_Game_ID": "SGLuckyDurian",
        "EN_Name": "Lucky Durian",
        "Button_Image_Name": "SGLuckyDurian.png"
      },
      {
        "CN_Name": "新年派对",
        "HTML5_Game_ID": "SGNewYearsBash",
        "EN_Name": "New Years Bash",
        "Button_Image_Name": "SGNewYearsBash.png"
      },
      {
        "CN_Name": "干杯吧!",
        "HTML5_Game_ID": "SGProst",
        "EN_Name": "Prost!",
        "Button_Image_Name": "SGProst.png"
      },
      {
        "CN_Name": "九尾狐 ",
        "HTML5_Game_ID": "SGNineTails",
        "EN_Name": "Nine Tails",
        "Button_Image_Name": "SGNineTails.png"
      },
      {
        "CN_Name": "飞象乐园",
        "HTML5_Game_ID": "SGFly",
        "EN_Name": "Fly!",
        "Button_Image_Name": "SGFly.png"
      },
      {
        "CN_Name": "东方之宝",
        "HTML5_Game_ID": "SGMysticFortuneDeluxe",
        "EN_Name": "Mystic Fortune Deluxe",
        "Button_Image_Name": "SGMysticFortuneDeluxe.png"
      },
      {
        "CN_Name": "爆爆骷髅",
        "HTML5_Game_ID": "SGCalaverasExplosivas",
        "EN_Name": "Calaveras Explosivas",
        "Button_Image_Name": "SGCalaverasExplosivas.png"
      },
      {
        "CN_Name": "风驰夺冠",
        "HTML5_Game_ID": "SGMarvelousFurlongs",
        "EN_Name": "Marvelous Furlongs",
        "Button_Image_Name": "SGMarvelousFurlongs.png"
      },
      {
        "CN_Name": "糖果园",
        "HTML5_Game_ID": "SGCandyTower",
        "EN_Name": "Candy Tower",
        "Button_Image_Name": "SGCandyTower.png"
      },
      {
        "CN_Name": "穿越未来",
        "HTML5_Game_ID": "SGReturnToTheFeature",
        "EN_Name": "Return To The Feature",
        "Button_Image_Name": "SGReturnToTheFeature.png"
      },
      {
        "CN_Name": "波斯王子",
        "HTML5_Game_ID": "SGBeforeTimeRunsOut",
        "EN_Name": "Before Time Runs Out",
        "Button_Image_Name": "SGBeforeTimeRunsOut.png"
      },
      {
        "CN_Name": "亚特兰蒂斯",
        "HTML5_Game_ID": "SGOrbsOfAtlantis",
        "EN_Name": "Orbs of Atlantis",
        "Button_Image_Name": "SGOrbsOfAtlantis.png"
      },
      {
        "CN_Name": "玛雅之塔",
        "HTML5_Game_ID": "SGTotemTowers",
        "EN_Name": "Totem Towers",
        "Button_Image_Name": "SGTotemTowers.png"
      },
      {
        "CN_Name": "礼物派对",
        "HTML5_Game_ID": "SGChristmasGiftRush",
        "EN_Name": "Christmas Gift Rush",
        "Button_Image_Name": "SGChristmasGiftRush.png"
      },
      {
        "CN_Name": "幽灵酒馆",
        "HTML5_Game_ID": "SGTabernaDeLosMuertos",
        "EN_Name": "Taberna De Los Muertos",
        "Button_Image_Name": "SGTabernaDeLosMuertos.png"
      },
      {
        "CN_Name": "幽灵酒馆 - 极端",
        "HTML5_Game_ID": "SGTabernaDeLosMuertosUltra",
        "EN_Name": "Taberna De Los Muertos Ultra",
        "Button_Image_Name": "SGTabernaDeLosMuertosUltra.png"
      },
      {
        "CN_Name": "快乐猿猴",
        "HTML5_Game_ID": "SGHappyApe",
        "EN_Name": "Happy Ape",
        "Button_Image_Name": "SGHappyApe.png"
      },
      {
        "CN_Name": "缤纷水母",
        "HTML5_Game_ID": "SGJellyfishFlow",
        "EN_Name": "Jellyfish Flow",
        "Button_Image_Name": "SGJellyfishFlow.png"
      },
      {
        "CN_Name": "缤纷水母 - 极端",
        "HTML5_Game_ID": "SGJellyfishFlowUltra",
        "EN_Name": "Jellyfish Flow Ultra",
        "Button_Image_Name": "SGJellyfishFlowUltra.png"
      },
      {
        "CN_Name": "财神客栈",
        "HTML5_Game_ID": "SGWealthInn",
        "EN_Name": "Wealth Inn",
        "Button_Image_Name": "SGWealthInn.png"
      },
      {
        "CN_Name": "Hey！寿司",
        "HTML5_Game_ID": "SGHeySushi",
        "EN_Name": "Hey Sushi",
        "Button_Image_Name": "SGHeySushi.png"
      },
      {
        "CN_Name": "欢乐卡片",
        "HTML5_Game_ID": "SGScopa",
        "EN_Name": "Scopa",
        "Button_Image_Name": "SGScopa.png"
      },
      {
        "CN_Name": "电球弹弹乐",
        "HTML5_Game_ID": "SGTechnoTumble",
        "EN_Name": "Techno Tumble",
        "Button_Image_Name": "SGTechnoTumble.png"
      },
      {
        "CN_Name": "招財貓咪",
        "HTML5_Game_ID": "SGLuckyFortuneCat",
        "EN_Name": "Lucky Fortune Cat",
        "Button_Image_Name": "SGLuckyFortuneCat.png"
      },
      {
        "CN_Name": "火热足球",
        "HTML5_Game_ID": "SGKnockoutFootballRush",
        "EN_Name": "Knockout Football Rush",
        "Button_Image_Name": "SGKnockoutFootballRush.png"
      },
      {
        "CN_Name": "搞怪卡通",
        "HTML5_Game_ID": "SGLoonyBlox",
        "EN_Name": "Loony Blox",
        "Button_Image_Name": "SGLoonyBlox.png"
      },
      {
        "CN_Name": "双喜财神",
        "HTML5_Game_ID": "SGFaCaiShenDeluxe",
        "EN_Name": "Fa Cai Shen Deluxe",
        "Button_Image_Name": "SGFaCaiShenDeluxe.png"
      },
      {
        "CN_Name": "狂欢圣诞",
        "HTML5_Game_ID": "SGNaughtySanta",
        "EN_Name": "Naughty Santa",
        "Button_Image_Name": "SGNaughtySanta.png"
      },
      {
        "CN_Name": "疯狂万圣夜",
        "HTML5_Game_ID": "SGHotHotHalloween",
        "EN_Name": "Hot Hot Halloween",
        "Button_Image_Name": "SGHotHotHalloween.png"
      },
      {
        "CN_Name": "魔法大战",
        "HTML5_Game_ID": "SGWizardsWantWar ",
        "EN_Name": "Wizards Want War!",
        "Button_Image_Name": "SGWizardsWantWar .png"
      },
      {
        "CN_Name": "碰碰宝石",
        "HTML5_Game_ID": "SGColossalGems",
        "EN_Name": "Colossal Gems",
        "Button_Image_Name": "SGColossalGems.png"
      },
      {
        "CN_Name": "女娲传奇",
        "HTML5_Game_ID": "SGNuwa",
        "EN_Name": "Nuwa",
        "Button_Image_Name": "SGNuwa.png"
      },
      {
        "CN_Name": "狂野卡车",
        "HTML5_Game_ID": "SGWildTrucks",
        "EN_Name": "Wild Trucks",
        "Button_Image_Name": "SGWildTrucks.png"
      },
      {
        "CN_Name": "富贵福",
        "HTML5_Game_ID": "SGLuckyLucky ",
        "EN_Name": "Lucky Lucky",
        "Button_Image_Name": "SGLuckyLucky .png"
      },
      {
        "CN_Name": "神奇橡树",
        "HTML5_Game_ID": "SGMagicOak",
        "EN_Name": "Magic Oak",
        "Button_Image_Name": "SGMagicOak.png"
      },
      {
        "CN_Name": "财宝山",
        "HTML5_Game_ID": "SGMountMazuma",
        "EN_Name": "Mount Mazuma",
        "Button_Image_Name": "SGMountMazuma.png"
      },
      {
        "CN_Name": "欢乐喜狮",
        "HTML5_Game_ID": "SG5LuckyLions",
        "EN_Name": "5 Lucky Lions",
        "Button_Image_Name": "SG5LuckyLions.png"
      },
      {
        "CN_Name": "疯狂水果",
        "HTML5_Game_ID": "SGHotHotFruit ",
        "EN_Name": "Hot Hot Fruit",
        "Button_Image_Name": "SGHotHotFruit .png"
      },
      {
        "CN_Name": "快乐圣诞",
        "HTML5_Game_ID": "SGHappiestChristmasTree",
        "EN_Name": "Happiest Christmas Tree",
        "Button_Image_Name": "SGHappiestChristmasTree.png"
      },
      {
        "CN_Name": "快乐南瓜",
        "HTML5_Game_ID": "SGPumpkinPatch",
        "EN_Name": "Pumpkin Patch",
        "Button_Image_Name": "SGPumpkinPatch.png"
      },
      {
        "CN_Name": "疯狂百搭",
        "HTML5_Game_ID": "SGJump",
        "EN_Name": "Jump!",
        "Button_Image_Name": "SGJump.png"
      },
      {
        "CN_Name": "神秘埃及",
        "HTML5_Game_ID": "SGEgyptianDreamsDeluxe",
        "EN_Name": "Egyptian Dreams Deluxe",
        "Button_Image_Name": "SGEgyptianDreamsDeluxe.png"
      },
      {
        "CN_Name": "寻宝记",
        "HTML5_Game_ID": "SGWaysOfFortune",
        "EN_Name": "Ways Of Fortune",
        "Button_Image_Name": "SGWaysOfFortune.png"
      },
      {
        "CN_Name": "黄金足球",
        "HTML5_Game_ID": "SGKnockoutFootball ",
        "EN_Name": "Knockout Football",
        "Button_Image_Name": "SGKnockoutFootball .png"
      },
      {
        "CN_Name": "富贵旺旺",
        "HTML5_Game_ID": "SGFortuneDogs",
        "EN_Name": "Fortune Dogs",
        "Button_Image_Name": "SGFortuneDogs.png"
      },
      {
        "CN_Name": "魔幻大财",
        "HTML5_Game_ID": "SGPresto",
        "EN_Name": "Presto!",
        "Button_Image_Name": "SGPresto.png"
      },
      {
        "CN_Name": "伦敦猎人",
        "HTML5_Game_ID": "SGLondonHunter",
        "EN_Name": "London Hunter",
        "Button_Image_Name": "SGLondonHunter.png"
      },
      {
        "CN_Name": "四大神兽",
        "HTML5_Game_ID": "SGFourDivineBeasts",
        "EN_Name": "Four Divine Beasts",
        "Button_Image_Name": "SGFourDivineBeasts.png"
      },
      {
        "CN_Name": "街头乐队",
        "HTML5_Game_ID": "SG5Mariachis",
        "EN_Name": "5 Mariachis",
        "Button_Image_Name": "SG5Mariachis.png"
      },
      {
        "CN_Name": "圣诞乐村",
        "HTML5_Game_ID": "SGSantasVillage",
        "EN_Name": "Santa's Village",
        "Button_Image_Name": "SGSantasVillage.png"
      },
      {
        "CN_Name": "刺猬宝宝",
        "HTML5_Game_ID": "SGRollingRoger",
        "EN_Name": "Rolling Roger",
        "Button_Image_Name": "SGRollingRoger.png"
      },
      {
        "CN_Name": "蛋糕谷",
        "HTML5_Game_ID": "SGCakeValley",
        "EN_Name": "Cake Valley",
        "Button_Image_Name": "SGCakeValley.png"
      },
      {
        "CN_Name": "欢乐帮",
        "HTML5_Game_ID": "SGScruffyScallywags",
        "EN_Name": "Scruffy Scallywags",
        "Button_Image_Name": "SGScruffyScallywags.png"
      },
      {
        "CN_Name": "死海逃生",
        "HTML5_Game_ID": "SGTheDeadEscape",
        "EN_Name": "The Dead Escape",
        "Button_Image_Name": "SGTheDeadEscape.png"
      },
      {
        "CN_Name": "双喜熊猫",
        "HTML5_Game_ID": "SGPandaPanda",
        "EN_Name": "Panda Panda",
        "Button_Image_Name": "SGPandaPanda.png"
      },
      {
        "CN_Name": "雷鸟",
        "HTML5_Game_ID": "SGBirdOfThunder",
        "EN_Name": "Bird of Thunder",
        "Button_Image_Name": "SGBirdOfThunder.png"
      },
      {
        "CN_Name": "鲤鱼门",
        "HTML5_Game_ID": "SGTheKoiGate",
        "EN_Name": "Koi Gate",
        "Button_Image_Name": "SGTheKoiGate.png"
      },
      {
        "CN_Name": "公鸡王",
        "HTML5_Game_ID": "SGFireRooster",
        "EN_Name": "Fire Rooster",
        "Button_Image_Name": "SGFireRooster.png"
      },
      {
        "CN_Name": "凤凰",
        "HTML5_Game_ID": "SGFenghuang",
        "EN_Name": "Fenghuang",
        "Button_Image_Name": "SGFenghuang.png"
      },
      {
        "CN_Name": "惊喜秀",
        "HTML5_Game_ID": "SGJugglenaut",
        "EN_Name": "Jugglenaut",
        "Button_Image_Name": "SGJugglenaut.png"
      },
      {
        "CN_Name": "海洋之音",
        "HTML5_Game_ID": "SGOceansCall",
        "EN_Name": "Ocean's Call",
        "Button_Image_Name": "SGOceansCall.png"
      },
      {
        "CN_Name": "黑手党",
        "HTML5_Game_ID": "SGGangsters",
        "EN_Name": "Gangsters",
        "Button_Image_Name": "SGGangsters.png"
      },
      {
        "CN_Name": "斯巴达",
        "HTML5_Game_ID": "SGSparta",
        "EN_Name": "Sparta",
        "Button_Image_Name": "SGSparta.png"
      },
      {
        "CN_Name": "超级龙卷风",
        "HTML5_Game_ID": "SGSuperTwister",
        "EN_Name": "Super Twister",
        "Button_Image_Name": "SGSuperTwister.png"
      },
      {
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "SG12Zodiacs",
        "EN_Name": "12 Zodiacs",
        "Button_Image_Name": "SG12Zodiacs.png"
      },
      {
        "CN_Name": "神秘元素",
        "HTML5_Game_ID": "SGArcaneElements",
        "EN_Name": "Arcane Elements",
        "Button_Image_Name": "SGArcaneElements.png"
      },
      {
        "CN_Name": "龙之宝座",
        "HTML5_Game_ID": "SGDragonsThrone",
        "EN_Name": "Dragon's Throne",
        "Button_Image_Name": "SGDragonsThrone.png"
      },
      {
        "CN_Name": "狼贼夺宝",
        "HTML5_Game_ID": "SGCoyoteCrash",
        "EN_Name": "Coyote Crash",
        "Button_Image_Name": "SGCoyoteCrash.png"
      },
      {
        "CN_Name": "罗马帝国",
        "HTML5_Game_ID": "SGRomanEmpire",
        "EN_Name": "Roman Empire",
        "Button_Image_Name": "SGRomanEmpire.png"
      },
      {
        "CN_Name": "触电的小鸟",
        "HTML5_Game_ID": "SGRuffledUp",
        "EN_Name": "Ruffled Up",
        "Button_Image_Name": "SGRuffledUp.png"
      },
      {
        "CN_Name": "发财神",
        "HTML5_Game_ID": "SGFaCaiShen",
        "EN_Name": "Fa Cai Shen",
        "Button_Image_Name": "SGFaCaiShen.png"
      },
      {
        "CN_Name": "炸弹追击",
        "HTML5_Game_ID": "SGBombsAway",
        "EN_Name": "Bombs Away",
        "Button_Image_Name": "SGBombsAway.png"
      },
      {
        "CN_Name": "淘金疯狂",
        "HTML5_Game_ID": "SGGoldRush",
        "EN_Name": "Gold Rush",
        "Button_Image_Name": "SGGoldRush.png"
      },
      {
        "CN_Name": "巫婆大财",
        "HTML5_Game_ID": "SGWickedWitch",
        "EN_Name": "Wicked Witch",
        "Button_Image_Name": "SGWickedWitch.png"
      },
      {
        "CN_Name": "银河大战",
        "HTML5_Game_ID": "SGGalacticCash",
        "EN_Name": "Galactic Cash",
        "Button_Image_Name": "SGGalacticCash.png"
      },
      {
        "CN_Name": "昆虫宝宝",
        "HTML5_Game_ID": "SGBuggyBonus",
        "EN_Name": "Buggy Bonus",
        "Button_Image_Name": "SGBuggyBonus.png"
      },
      {
        "CN_Name": "凯恩地狱",
        "HTML5_Game_ID": "SGKanesInferno",
        "EN_Name": "Kane's Inferno",
        "Button_Image_Name": "SGKanesInferno.png"
      },
      {
        "CN_Name": "三剑客",
        "HTML5_Game_ID": "SGAllForOne",
        "EN_Name": "All For One",
        "Button_Image_Name": "SGAllForOne.png"
      },
      {
        "CN_Name": "北极奇迹",
        "HTML5_Game_ID": "SGArcticWonders",
        "EN_Name": "Arctic Wonders",
        "Button_Image_Name": "SGArcticWonders.png"
      },
      {
        "CN_Name": "亚兹特兰金",
        "HTML5_Game_ID": "SGAzlandsGold",
        "EN_Name": "Aztlan's Gold",
        "Button_Image_Name": "SGAzlandsGold.png"
      },
      {
        "CN_Name": "农场现金",
        "HTML5_Game_ID": "SGBarnstormerBucks",
        "EN_Name": "Barnstormer Bucks",
        "Button_Image_Name": "SGBarnstormerBucks.png"
      },
      {
        "CN_Name": "比基尼岛",
        "HTML5_Game_ID": "SGBikiniIsland",
        "EN_Name": "Bikini Island",
        "Button_Image_Name": "SGBikiniIsland.png"
      },
      {
        "CN_Name": "黑胡子赏金",
        "HTML5_Game_ID": "SGBlackbeardsBounty",
        "EN_Name": "Blackbeard's Bounty",
        "Button_Image_Name": "SGBlackbeardsBounty.png"
      },
      {
        "CN_Name": "现金嘉年华",
        "HTML5_Game_ID": "SGCarnivalCash",
        "EN_Name": "Carnival Cash",
        "Button_Image_Name": "SGCarnivalCash.png"
      },
      {
        "CN_Name": "土豪恐龙",
        "HTML5_Game_ID": "SGCashosaurus",
        "EN_Name": "Cashosaurus",
        "Button_Image_Name": "SGCashosaurus.png"
      },
      {
        "CN_Name": "金钱礁",
        "HTML5_Game_ID": "SGCashReef",
        "EN_Name": "Cash Reef",
        "Button_Image_Name": "SGCashReef.png"
      },
      {
        "CN_Name": "舞厅飙舞",
        "HTML5_Game_ID": "SGDiscoFunk",
        "EN_Name": "Disco Funk",
        "Button_Image_Name": "SGDiscoFunk.png"
      },
      {
        "CN_Name": "双赢密探",
        "HTML5_Game_ID": "SGDoubleODollars",
        "EN_Name": "Double O Dollars",
        "Button_Image_Name": "SGDoubleODollars.png"
      },
      {
        "CN_Name": "神龙之境",
        "HTML5_Game_ID": "SGDragonsRealm",
        "EN_Name": "Dragon's Realm",
        "Button_Image_Name": "SGDragonsRealm.png"
      },
      {
        "CN_Name": "好感医生",
        "HTML5_Game_ID": "SGDrFeelgood",
        "EN_Name": "Dr Feelgood",
        "Button_Image_Name": "SGDrFeelgood.png"
      },
      {
        "CN_Name": "埃及古梦",
        "HTML5_Game_ID": "SGEgyptianDreams",
        "EN_Name": "Egyptian Dreams",
        "Button_Image_Name": "SGEgyptianDreams.png"
      },
      {
        "CN_Name": "高空飞翔",
        "HTML5_Game_ID": "SGFlyingHigh",
        "EN_Name": "Flying High",
        "Button_Image_Name": "SGFlyingHigh.png"
      },
      {
        "CN_Name": "边境之福",
        "HTML5_Game_ID": "SGFrontierFortunes",
        "EN_Name": "Frontier Fortunes",
        "Button_Image_Name": "SGFrontierFortunes.png"
      },
      {
        "CN_Name": "華麗搖滾",
        "HTML5_Game_ID": "SGGlamRock",
        "EN_Name": "Glam Rock",
        "Button_Image_Name": "SGGlamRock.png"
      },
      {
        "CN_Name": "金角兽",
        "HTML5_Game_ID": "SGGoldenUnicorn",
        "EN_Name": "Golden Unicorn",
        "Button_Image_Name": "SGGoldenUnicorn.png"
      },
      {
        "CN_Name": "葡萄越狱",
        "HTML5_Game_ID": "SGGrapeEscape",
        "EN_Name": "Grape Escape",
        "Button_Image_Name": "SGGrapeEscape.png"
      },
      {
        "CN_Name": "鬼屋",
        "HTML5_Game_ID": "SGHauntedHouse",
        "EN_Name": "Haunted House",
        "Button_Image_Name": "SGHauntedHouse.png"
      },
      {
        "CN_Name": "印第安追梦",
        "HTML5_Game_ID": "SGIndianCashCatcher",
        "EN_Name": "Indian Cash Catcher",
        "Button_Image_Name": "SGIndianCashCatcher.png"
      },
      {
        "CN_Name": "丛林怒吼",
        "HTML5_Game_ID": "SGJungleRumble",
        "EN_Name": "Jungle Rumble",
        "Button_Image_Name": "SGJungleRumble.png"
      },
      {
        "CN_Name": "图坦卡蒙之墓",
        "HTML5_Game_ID": "SGKingTutsTomb",
        "EN_Name": "King Tut's Tomb",
        "Button_Image_Name": "SGKingTutsTomb.png"
      },
      {
        "CN_Name": "太空小绿人",
        "HTML5_Game_ID": "SGLittleGreenMoney",
        "EN_Name": "Little Green Money",
        "Button_Image_Name": "SGLittleGreenMoney.png"
      },
      {
        "CN_Name": "怪物聚集",
        "HTML5_Game_ID": "SGMonsterMashCash",
        "EN_Name": "Monster Mash Cash",
        "Button_Image_Name": "SGMonsterMashCash.png"
      },
      {
        "CN_Name": "珠光宝气",
        "HTML5_Game_ID": "SGMrBling",
        "EN_Name": "Mr Bling",
        "Button_Image_Name": "SGMrBling.png"
      },
      {
        "CN_Name": "金钱木乃伊",
        "HTML5_Game_ID": "SGMummyMoney",
        "EN_Name": "Mummy Money",
        "Button_Image_Name": "SGMummyMoney.png"
      },
      {
        "CN_Name": "神秘宝藏",
        "HTML5_Game_ID": "SGMysticFortune",
        "EN_Name": "Mystic Fortune",
        "Button_Image_Name": "SGMysticFortune.png"
      },
      {
        "CN_Name": "俏佳人",
        "HTML5_Game_ID": "SGPamperMe",
        "EN_Name": "Pamper Me",
        "Button_Image_Name": "SGPamperMe.png"
      },
      {
        "CN_Name": "海盗掠宝",
        "HTML5_Game_ID": "SGPiratesPlunder",
        "EN_Name": "Pirate's Plunder",
        "Button_Image_Name": "SGPiratesPlunder.png"
      },
      {
        "CN_Name": "台球鲨鱼",
        "HTML5_Game_ID": "SGPoolShark",
        "EN_Name": "Pool Shark",
        "Button_Image_Name": "SGPoolShark.png"
      },
      {
        "CN_Name": "青蛙王子",
        "HTML5_Game_ID": "SGPuckerUpPrince",
        "EN_Name": "Pucker Up Prince",
        "Button_Image_Name": "SGPuckerUpPrince.png"
      },
      {
        "CN_Name": "女王至上1024赢法",
        "HTML5_Game_ID": "SGQueenOfQueens1024",
        "EN_Name": "Queen of Queens II",
        "Button_Image_Name": "SGQueenOfQueens1024.png"
      },
      {
        "CN_Name": "女王至上",
        "HTML5_Game_ID": "SGQueenOfQueens243",
        "EN_Name": "Queen of Queens",
        "Button_Image_Name": "SGQueenOfQueens243.png"
      },
      {
        "CN_Name": "幸运牛仔",
        "HTML5_Game_ID": "SGRideEmCowboy",
        "EN_Name": "Ride 'em Cowboy",
        "Button_Image_Name": "SGRideEmCowboy.png"
      },
      {
        "CN_Name": "罗迪欧大道",
        "HTML5_Game_ID": "SGRodeoDrive",
        "EN_Name": "Rodeo Drive",
        "Button_Image_Name": "SGRodeoDrive.png"
      },
      {
        "CN_Name": "少林宝藏100",
        "HTML5_Game_ID": "SGShaolinFortunes100",
        "EN_Name": "Shaolin Fortunes 100",
        "Button_Image_Name": "SGShaolinFortunes100.png"
      },
      {
        "CN_Name": "少林宝藏",
        "HTML5_Game_ID": "SGShaolinFortunes243",
        "EN_Name": "Shaolin Fortunes",
        "Button_Image_Name": "SGShaolinFortunes243.png"
      },
      {
        "CN_Name": "幕府之地",
        "HTML5_Game_ID": "SGShogunsLand",
        "EN_Name": "Shogun's Land",
        "Button_Image_Name": "SGShogunsLand.png"
      },
      {
        "CN_Name": "闪亮骑士",
        "HTML5_Game_ID": "SGSirBlingalot",
        "EN_Name": "Sir Blingalot",
        "Button_Image_Name": "SGSirBlingalot.png"
      },
      {
        "CN_Name": "天空之际",
        "HTML5_Game_ID": "SGSkysTheLimit",
        "EN_Name": "Sky's the Limit",
        "Button_Image_Name": "SGSkysTheLimit.png"
      },
      {
        "CN_Name": "求救信号",
        "HTML5_Game_ID": "SGSOS",
        "EN_Name": "S.O.S!",
        "Button_Image_Name": "SGSOS.png"
      },
      {
        "CN_Name": "太空宝藏",
        "HTML5_Game_ID": "SGSpaceFortune",
        "EN_Name": "Space Fortune",
        "Button_Image_Name": "SGSpaceFortune.png"
      },
      {
        "CN_Name": "好球",
        "HTML5_Game_ID": "SGSuperStrike",
        "EN_Name": "Super Strike",
        "Button_Image_Name": "SGSuperStrike.png"
      },
      {
        "CN_Name": "重要人物",
        "HTML5_Game_ID": "SGTheBigDeal",
        "EN_Name": "The Big Deal",
        "Button_Image_Name": "SGTheBigDeal.png"
      },
      {
        "CN_Name": "龙之城堡",
        "HTML5_Game_ID": "SGTheDragonCastle",
        "EN_Name": "Dragon Castle",
        "Button_Image_Name": "SGTheDragonCastle.png"
      },
      {
        "CN_Name": "披萨塔",
        "HTML5_Game_ID": "SGTowerOfPizza",
        "EN_Name": "Tower Of Pizza",
        "Button_Image_Name": "SGTowerOfPizza.png"
      },
      {
        "CN_Name": "深海寻宝",
        "HTML5_Game_ID": "SGTreasureDiver",
        "EN_Name": "Treasure Diver",
        "Button_Image_Name": "SGTreasureDiver.png"
      },
      {
        "CN_Name": "古墓宝藏",
        "HTML5_Game_ID": "SGTreasureTomb",
        "EN_Name": "Treasure Tomb",
        "Button_Image_Name": "SGTreasureTomb.png"
      },
      {
        "CN_Name": "维京掠宝",
        "HTML5_Game_ID": "SGVikingsPlunder",
        "EN_Name": "Viking's Plunder",
        "Button_Image_Name": "SGVikingsPlunder.png"
      },
      {
        "CN_Name": "科学怪人",
        "HTML5_Game_ID": "SGWeirdScience",
        "EN_Name": "Weird Science",
        "Button_Image_Name": "SGWeirdScience.png"
      },
      {
        "CN_Name": "宙斯",
        "HTML5_Game_ID": "SGZeus",
        "EN_Name": "Zeus",
        "Button_Image_Name": "SGZeus.png"
      },
      {
        "CN_Name": "宙斯2",
        "HTML5_Game_ID": "SGZeus2",
        "EN_Name": "Zeus 2",
        "Button_Image_Name": "SGZeus2.png"
      },
      {
        "CN_Name": "赌场德州扑克",
        "HTML5_Game_ID": "CaribbeanHoldem",
        "EN_Name": "Caribbean Hold'Em",
        "Button_Image_Name": "CaribbeanHoldem.png"
      },
      {
        "CN_Name": "加勒比扑克",
        "HTML5_Game_ID": "CaribbeanStud",
        "EN_Name": "Caribbean Stud",
        "Button_Image_Name": "CaribbeanStud.png"
      },
      {
        "CN_Name": "三手黑杰克",
        "HTML5_Game_ID": "BlackJack3H",
        "EN_Name": "Blackjack 3 Hand",
        "Button_Image_Name": "BlackJack3H.png"
      },
      {
        "CN_Name": "三手双重曝光",
        "HTML5_Game_ID": "BlackJack3HDoubleExposure",
        "EN_Name": "Blackjack Double Exposure 3 Hand",
        "Button_Image_Name": "BlackJack3HDoubleExposure.png"
      },

      {
        "CN_Name": "欧洲轮盘",
        "HTML5_Game_ID": "EURoulette",
        "EN_Name": "European Roulette",
        "Button_Image_Name": "EURoulette.png"
      },
      {
        "CN_Name": "美国百家乐",
        "HTML5_Game_ID": "AmericanBaccarat",
        "EN_Name": "American Baccarat",
        "Button_Image_Name": "AmericanBaccarat.png"
      },
      {
        "CN_Name": "免佣百家乐",
        "HTML5_Game_ID": "Baccarat3HZC",
        "EN_Name": "American Baccarat Zero Commission",
        "Button_Image_Name": "Baccarat3HZC.png"
      },

      {
        "CN_Name": "三张牌扑克",
        "HTML5_Game_ID": "TGThreeCardPoker",
        "EN_Name": "Three Card Poker",
        "Button_Image_Name": "TGThreeCardPoker.png"
      },
      {
        "CN_Name": "三张牌扑克豪华版",
        "HTML5_Game_ID": "TGThreeCardPokerDeluxe",
        "EN_Name": "Three Card Poker Deluxe",
        "Button_Image_Name": "TGThreeCardPokerDeluxe.png"
      },
      {
        "CN_Name": "战争",
        "HTML5_Game_ID": "TGWar",
        "EN_Name": "War",
        "Button_Image_Name": "TGWar.png"
      },
      {
        "CN_Name": "龙虎",
        "HTML5_Game_ID": "TGDragonTiger",
        "EN_Name": "Dragon Tiger",
        "Button_Image_Name": "TGDragonTiger.png"
      },
      {
        "CN_Name": "经典扑克1手",
        "HTML5_Game_ID": "AcesandEights1Hand",
        "EN_Name": "Aces & Eights 1 Hand",
        "Button_Image_Name": "AcesandEights1Hand.png"
      },
      {
        "CN_Name": "经典扑克5手",
        "HTML5_Game_ID": "AcesandEights5Hand",
        "EN_Name": "Aces & Eights 5 Hand",
        "Button_Image_Name": "AcesandEights5Hand.png"
      },
      {
        "CN_Name": "经典扑克10手",
        "HTML5_Game_ID": "AcesandEights10Hand",
        "EN_Name": "Aces & Eights 10 Hand",
        "Button_Image_Name": "AcesandEights10Hand.png"
      },
      {
        "CN_Name": "经典扑克50手",
        "HTML5_Game_ID": "AcesandEights50Hand",
        "EN_Name": "Aces & Eights 50 Hand",
        "Button_Image_Name": "AcesandEights50Hand.png"
      },
      {
        "CN_Name": "经典扑克100手",
        "HTML5_Game_ID": "AcesandEights100Hand",
        "EN_Name": "Aces & Eights 100 Hand",
        "Button_Image_Name": "AcesandEights100Hand.png"
      },
      {
        "CN_Name": "美国扑克1手",
        "HTML5_Game_ID": "AllAmericanPoker1Hand",
        "EN_Name": "All American Poker 1 Hand",
        "Button_Image_Name": "AllAmericanPoker1Hand.png"
      },
      {
        "CN_Name": "美国扑克5手",
        "HTML5_Game_ID": "AllAmericanPoker5Hand",
        "EN_Name": "All American Poker 5 Hand",
        "Button_Image_Name": "AllAmericanPoker5Hand.png"
      },
      {
        "CN_Name": "美国扑克10手",
        "HTML5_Game_ID": "AllAmericanPoker10Hand",
        "EN_Name": "All American Poker 10 Hand",
        "Button_Image_Name": "AllAmericanPoker10Hand.png"
      },
      {
        "CN_Name": "美国扑克50手",
        "HTML5_Game_ID": "AllAmericanPoker50Hand",
        "EN_Name": "All American Poker 50 Hand",
        "Button_Image_Name": "AllAmericanPoker50Hand.png"
      },
      {
        "CN_Name": "美国扑克100手",
        "HTML5_Game_ID": "AllAmericanPoker100Hand",
        "EN_Name": "All American Poker 100 Hand",
        "Button_Image_Name": "AllAmericanPoker100Hand.png"
      },
      {
        "CN_Name": "红利百搭扑克1手",
        "HTML5_Game_ID": "BonusDuecesWild1Hand",
        "EN_Name": "Bonus Deuces Wild 1 Hand",
        "Button_Image_Name": "BonusDuecesWild1Hand.png"
      },
      {
        "CN_Name": "红利百搭扑克5手",
        "HTML5_Game_ID": "BonusDuecesWild5Hand",
        "EN_Name": "Bonus Deuces Wild 5 Hand",
        "Button_Image_Name": "BonusDuecesWild5Hand.png"
      },
      {
        "CN_Name": "红利百搭扑克10手",
        "HTML5_Game_ID": "BonusDuecesWild10Hand",
        "EN_Name": "Bonus Deuces Wild 10 Hand",
        "Button_Image_Name": "BonusDuecesWild10Hand.png"
      },
      {
        "CN_Name": "红利百搭扑克50手",
        "HTML5_Game_ID": "BonusDuecesWild50Hand",
        "EN_Name": "Bonus Deuces Wild 50 Hand",
        "Button_Image_Name": "BonusDuecesWild50Hand.png"
      },
      {
        "CN_Name": "红利百搭扑克100手",
        "HTML5_Game_ID": "BonusDuecesWild100Hand",
        "EN_Name": "Bonus Deuces Wild 100 Hand",
        "Button_Image_Name": "BonusDuecesWild100Hand.png"
      },
      {
        "CN_Name": "红利扑克1手",
        "HTML5_Game_ID": "BonusPoker1Hand",
        "EN_Name": "Bonus Poker 1 Hand",
        "Button_Image_Name": "BonusPoker1Hand.png"
      },
      {
        "CN_Name": "红利扑克5手",
        "HTML5_Game_ID": "BonusPoker5Hand",
        "EN_Name": "Bonus Poker 5 Hand",
        "Button_Image_Name": "BonusPoker5Hand.png"
      },
      {
        "CN_Name": "红利扑克10手",
        "HTML5_Game_ID": "BonusPoker10Hand",
        "EN_Name": "Bonus Poker 10 Hand",
        "Button_Image_Name": "BonusPoker10Hand.png"
      },
      {
        "CN_Name": "红利扑克50手",
        "HTML5_Game_ID": "BonusPoker50Hand",
        "EN_Name": "Bonus Poker 50 Hand",
        "Button_Image_Name": "BonusPoker50Hand.png"
      },
      {
        "CN_Name": "红利扑克100手",
        "HTML5_Game_ID": "BonusPoker100Hand",
        "EN_Name": "Bonus Poker 100 Hand",
        "Button_Image_Name": "BonusPoker100Hand.png"
      },
      {
        "CN_Name": "百搭二王扑克1手",
        "HTML5_Game_ID": "DuecesWild1Hand",
        "EN_Name": "Deuces Wild 1 Hand",
        "Button_Image_Name": "DuecesWild1Hand.png"
      },
      {
        "CN_Name": "百搭二王扑克5手",
        "HTML5_Game_ID": "DuecesWild5Hand",
        "EN_Name": "Deuces Wild 5 Hand",
        "Button_Image_Name": "DuecesWild5Hand.png"
      },
      {
        "CN_Name": "百搭二王扑克10手",
        "HTML5_Game_ID": "DuecesWild10Hand",
        "EN_Name": "Deuces Wild 10 Hand",
        "Button_Image_Name": "DuecesWild10Hand.png"
      },
      {
        "CN_Name": "百搭二王扑克50手",
        "HTML5_Game_ID": "DuecesWild50Hand",
        "EN_Name": "Deuces Wild 50 Hand",
        "Button_Image_Name": "DuecesWild50Hand.png"
      },
      {
        "CN_Name": "百搭二王扑克100手",
        "HTML5_Game_ID": "DuecesWild100Hand",
        "EN_Name": "Deuces Wild 100 Hand",
        "Button_Image_Name": "DuecesWild100Hand.png"
      },
      {
        "CN_Name": "双倍红利扑克1手",
        "HTML5_Game_ID": "DoubleBonusPoker1Hand",
        "EN_Name": "Double Bonus Poker 1 Hand",
        "Button_Image_Name": "DoubleBonusPoker1Hand.png"
      },
      {
        "CN_Name": "双倍红利扑克5手",
        "HTML5_Game_ID": "DoubleBonusPoker5Hand",
        "EN_Name": "Double Bonus Poker 5 Hand",
        "Button_Image_Name": "DoubleBonusPoker5Hand.png"
      },
      {
        "CN_Name": "双倍红利扑克10手",
        "HTML5_Game_ID": "DoubleBonusPoker10Hand",
        "EN_Name": "Double Bonus Poker 10 Hand",
        "Button_Image_Name": "DoubleBonusPoker10Hand.png"
      },
      {
        "CN_Name": "双倍红利扑克50手",
        "HTML5_Game_ID": "DoubleBonusPoker50Hand",
        "EN_Name": "Double Bonus Poker 50 Hand",
        "Button_Image_Name": "DoubleBonusPoker50Hand.png"
      },
      {
        "CN_Name": "双倍红利扑克100手",
        "HTML5_Game_ID": "DoubleBonusPoker100Hand",
        "EN_Name": "Double Bonus Poker 100 Hand",
        "Button_Image_Name": "DoubleBonusPoker100Hand.png"
      },
      {
        "CN_Name": "四倍红利扑克1手",
        "HTML5_Game_ID": "DoubleDoubleBonusPoker1Hand",
        "EN_Name": "Double Double Bonus Poker 1 Hand",
        "Button_Image_Name": "DoubleDoubleBonusPoker1Hand.png"
      },
      {
        "CN_Name": "四倍红利扑克5手",
        "HTML5_Game_ID": "DoubleDoubleBonusPoker5Hand",
        "EN_Name": "Double Double Bonus Poker 5 Hand",
        "Button_Image_Name": "DoubleDoubleBonusPoker5Hand.png"
      },
      {
        "CN_Name": "四倍红利扑克10手",
        "HTML5_Game_ID": "DoubleDoubleBonusPoker10Hand",
        "EN_Name": "Double Double Bonus Poker 10 Hand",
        "Button_Image_Name": "DoubleDoubleBonusPoker10Hand.png"
      },
      {
        "CN_Name": "四倍红利扑克50手",
        "HTML5_Game_ID": "DoubleDoubleBonusPoker50Hand",
        "EN_Name": "Double Double Bonus Poker 50 Hand",
        "Button_Image_Name": "DoubleDoubleBonusPoker50Hand.png"
      },
      {
        "CN_Name": "四倍红利扑克100手",
        "HTML5_Game_ID": "DoubleDoubleBonusPoker100Hand",
        "EN_Name": "Double Double Bonus Poker 100 Hand",
        "Button_Image_Name": "DoubleDoubleBonusPoker100Hand.png"
      },
      {
        "CN_Name": "杰克高手1手",
        "HTML5_Game_ID": "JacksorBetter1Hand",
        "EN_Name": "Jacks or Better 1 Hand",
        "Button_Image_Name": "JacksorBetter1Hand.png"
      },
      {
        "CN_Name": "杰克高手5手",
        "HTML5_Game_ID": "JacksorBetter5Hand",
        "EN_Name": "Jacks or Better 5 Hand",
        "Button_Image_Name": "JacksorBetter5Hand.png"
      },
      {
        "CN_Name": "杰克高手10手",
        "HTML5_Game_ID": "JacksorBetter10Hand",
        "EN_Name": "Jacks or Better 10 Hand",
        "Button_Image_Name": "JacksorBetter10Hand.png"
      },
      {
        "CN_Name": "杰克高手50手",
        "HTML5_Game_ID": "JacksorBetter50Hand",
        "EN_Name": "Jacks or Better 50 Hand",
        "Button_Image_Name": "JacksorBetter50Hand.png"
      },
      {
        "CN_Name": "杰克高手100手",
        "HTML5_Game_ID": "JacksorBetter100Hand",
        "EN_Name": "Jacks or Better 100 Hand",
        "Button_Image_Name": "JacksorBetter100Hand.png"
      },
      {
        "CN_Name": "小丑扑克1手",
        "HTML5_Game_ID": "JokerPoker1Hand",
        "EN_Name": "Joker Poker 1 Hand",
        "Button_Image_Name": "JokerPoker1Hand.png"
      },
      {
        "CN_Name": "小丑扑克5手",
        "HTML5_Game_ID": "JokerPoker5Hand",
        "EN_Name": "Joker Poker 5 Hand",
        "Button_Image_Name": "JokerPoker5Hand.png"
      },
      {
        "CN_Name": "小丑扑克10手",
        "HTML5_Game_ID": "JokerPoker10Hand",
        "EN_Name": "Joker Poker 10 Hand",
        "Button_Image_Name": "JokerPoker10Hand.png"
      },
      {
        "CN_Name": "小丑扑克50手",
        "HTML5_Game_ID": "JokerPoker50Hand",
        "EN_Name": "Joker Poker 50 Hand",
        "Button_Image_Name": "JokerPoker50Hand.png"
      },
      {
        "CN_Name": "小丑扑克100手",
        "HTML5_Game_ID": "JokerPoker100Hand",
        "EN_Name": "Joker Poker 100 Hand",
        "Button_Image_Name": "JokerPoker100Hand.png"
      },
      {
        "CN_Name": "对十高手扑克1手",
        "HTML5_Game_ID": "TensorBetter1Hand",
        "EN_Name": "Tens Or Better 1 Hand",
        "Button_Image_Name": "TensorBetter1Hand.png"
      },
      {
        "CN_Name": "对十高手扑克5手",
        "HTML5_Game_ID": "TensorBetter5Hand",
        "EN_Name": "Tens Or Better 5 Hand",
        "Button_Image_Name": "TensorBetter5Hand.png"
      },
      {
        "CN_Name": "对十高手扑克10手",
        "HTML5_Game_ID": "TensorBetter10Hand",
        "EN_Name": "Tens Or Better 10 Hand",
        "Button_Image_Name": "TensorBetter10Hand.png"
      },
      {
        "CN_Name": "对十高手扑克50手",
        "HTML5_Game_ID": "TensorBetter50Hand",
        "EN_Name": "Tens Or Better 50 Hand",
        "Button_Image_Name": "TensorBetter50Hand.png"
      },
      {
        "CN_Name": "对十高手扑克100手",
        "HTML5_Game_ID": "TensorBetter100Hand",
        "EN_Name": "Tens Or Better 100 Hand",
        "Button_Image_Name": "TensorBetter100Hand.png"
      }
    ]
  }, {
    "type": 4,
    "game_id": 409,
    "game_api": "MG",
    "name": "MG老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/mg.png",
    "icon": "logo-mg",
    "game_list_json": "MG_game_list",
    "game_list_img_path": "/new/slot_res/mg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：108好汉、亚洲美人、大逃杀、冒险宫殿、靶心、抢劫银行、欢乐节、昆虫派对...",
    "labels": "#老虎机#108好汉#亚洲美人#大逃杀#冒险宫殿#靶心#抢劫银行#欢乐节#昆虫派对",
    gamelist: [
      {
        "CN_Name": "科学怪人",
        "HTML5_Game_ID": "SMG_frankenstein",
        "EN_Name": "Frankenstein",
        "Button_Image_Name": "SMG_frankenstein.png"
      },
      {
        "CN_Name": "巴西狂欢节",
        "HTML5_Game_ID": "SMG_carnavalFiesta",
        "EN_Name": "Carnaval Fiesta",
        "Button_Image_Name": "SMG_carnavalFiesta.png"
      },
      {
        "CN_Name": "阿斯加德之门 Power Combo",
        "HTML5_Game_ID": "SMG_gatesOfAsgardPowerCombo",
        "EN_Name": "Gates of Asgard Power Combo",
        "Button_Image_Name": "SMG_gatesOfAsgardPowerCombo.png"
      },
      {
        "CN_Name": "碰碰麻将 Jackpots",
        "HTML5_Game_ID": "SMG_pongPongMahjongJackpots",
        "EN_Name": "Pong Pong Mahjong Jackpots",
        "Button_Image_Name": "SMG_pongPongMahjongJackpots.png"
      },
      {
        "CN_Name": "现金闪电",
        "HTML5_Game_ID": "SMG_cashBlitz",
        "EN_Name": "Cash Blitz",
        "Button_Image_Name": "SMG_cashBlitz.png"
      },
      {
        "CN_Name": "阿兹特克 三倍财富 Power Combo",
        "HTML5_Game_ID": "SMG_aztecTripleRichesPowerCombo",
        "EN_Name": "Aztec Triple Riches Power Combo",
        "Button_Image_Name": "SMG_aztecTripleRichesPowerCombo.png"
      },
      {
        "CN_Name": "金来运转",
        "HTML5_Game_ID": "SMG_goldInfinity",
        "EN_Name": "Gold Infinity",
        "Button_Image_Name": "SMG_goldInfinity.png"
      },
      {
        "CN_Name": "猛犸三倍财富",
        "HTML5_Game_ID": "SMG_mammothTripleRiches",
        "EN_Name": "Mammoth Triple Riches",
        "Button_Image_Name": "SMG_mammothTripleRiches.png"
      },
      {
        "CN_Name": "狼群之书",
        "HTML5_Game_ID": "SMG_bookOfWolves",
        "EN_Name": "Book of Wolves",
        "Button_Image_Name": "SMG_bookOfWolves.png"
      },
      {
        "CN_Name": "哈迪斯 失落宝藏",
        "HTML5_Game_ID": "SMG_hadesLostTreasures",
        "EN_Name": "Hades Lost Treasures",
        "Button_Image_Name": "SMG_hadesLostTreasures.png"
      },
      {
        "CN_Name": "欢乐糖果百搭 2",
        "HTML5_Game_ID": "SMG_candyRushWilds2",
        "EN_Name": "Candy Rush Wilds 2",
        "Button_Image_Name": "SMG_candyRushWilds2.png"
      },
      {
        "CN_Name": "西亚娜 斯蒂尔",
        "HTML5_Game_ID": "SMG_siennaSteele",
        "EN_Name": "Sienna Steele",
        "Button_Image_Name": "SMG_siennaSteele.png"
      },
      {
        "CN_Name": "疯狂海底大宝箱",
        "HTML5_Game_ID": "SMG_crazyBobBonanza",
        "EN_Name": "Crazy Bob Bonanza",
        "Button_Image_Name": "SMG_crazyBobBonanza.png"
      },
      {
        "CN_Name": "3欢乐雄狮 POWER COMBO",
        "HTML5_Game_ID": "SMG_3LaughingLionsPowerCombo",
        "EN_Name": "3 Laughing Lions Power Combo",
        "Button_Image_Name": "SMG_3LaughingLionsPowerCombo.png"
      },
      {
        "CN_Name": "富贵连连",
        "HTML5_Game_ID": "SMG_treasureStacks",
        "EN_Name": "Treasure Stacks",
        "Button_Image_Name": "SMG_treasureStacks.png"
      },
      {
        "CN_Name": "万达哪吒捕鱼",
        "HTML5_Game_ID": "SFG_WDNezhaFishingLegend",
        "EN_Name": "WD Nezha Fishing Legend",
        "Button_Image_Name": "SFG_WDNezhaFishingLegend.png"
      },
      {
        "CN_Name": "全能雅典娜帝国",
        "HTML5_Game_ID": "SMG_almightyAthenaEmpire",
        "EN_Name": "Almighty Athena Empire",
        "Button_Image_Name": "SMG_almightyAthenaEmpire.png"
      },
      {
        "CN_Name": "神威熊猫",
        "HTML5_Game_ID": "SMG_mightyPanda",
        "EN_Name": "Mighty Panda",
        "Button_Image_Name": "SMG_mightyPanda.png"
      },
      {
        "CN_Name": "幸运双星 PowerClusters",
        "HTML5_Game_ID": "SMG_luckyTwinsPowerClusters",
        "EN_Name": "Lucky Twins PowerClusters",
        "Button_Image_Name": "SMG_luckyTwinsPowerClusters.png"
      },
      {
        "CN_Name": "巨额黄金",
        "HTML5_Game_ID": "SMG_massiveGold",
        "EN_Name": "Massive Gold",
        "Button_Image_Name": "SMG_massiveGold.png"
      },
      {
        "CN_Name": "铂金鲨鱼",
        "HTML5_Game_ID": "SMG_sharkPlatinum",
        "EN_Name": "Shark Platinum",
        "Button_Image_Name": "SMG_sharkPlatinum.png"
      },
      {
        "CN_Name": "瓦特博士",
        "HTML5_Game_ID": "SMG_drWattsUp",
        "EN_Name": "Dr Watts Up",
        "Button_Image_Name": "SMG_drWattsUp.png"
      },
      {
        "CN_Name": "幸运小龙",
        "HTML5_Game_ID": "SMG_luckyLittleDragons",
        "EN_Name": "Lucky Little Dragons",
        "Button_Image_Name": "SMG_luckyLittleDragons.png"
      },
      {
        "CN_Name": "开罗神后",
        "HTML5_Game_ID": "SMG_queenOfCairo",
        "EN_Name": "Queen of Cairo",
        "Button_Image_Name": "SMG_queenOfCairo.png"
      },
      {
        "CN_Name": "新水浒传",
        "HTML5_Game_ID": "SMG_108HeroesWaterMargin",
        "EN_Name": "108 Heroes Water Margin",
        "Button_Image_Name": "SMG_108HeroesWaterMargin.png"
      },
      {
        "CN_Name": "万达金福娃捕鱼",
        "HTML5_Game_ID": "SFG_WDGoldenFuwaFishing",
        "EN_Name": "WD Golden Fuwa Fishing",
        "Button_Image_Name": "SFG_WDGoldenFuwaFishing.png"
      },
      {
        "CN_Name": "全能宙斯百搭",
        "HTML5_Game_ID": "SMG_almightyZeusWilds",
        "EN_Name": "Almighty Zeus Wilds",
        "Button_Image_Name": "SMG_almightyZeusWilds.png"
      },
      {
        "CN_Name": "富豪老虎",
        "HTML5_Game_ID": "SMG_crazyRichTigers",
        "EN_Name": "Crazy Rich Tigers",
        "Button_Image_Name": "SMG_crazyRichTigers.png"
      },
      {
        "CN_Name": "金罐淘金热",
        "HTML5_Game_ID": "SMG_miningPotsOfGold",
        "EN_Name": "Mining Pots of Gold",
        "Button_Image_Name": "SMG_miningPotsOfGold.png"
      },
      {
        "CN_Name": "奥林匹斯 编年史 宙斯",
        "HTML5_Game_ID": "SMG_chroniclesOfOlympusIIZeus",
        "EN_Name": "Chronicles of Olympus II - Zeus",
        "Button_Image_Name": "SMG_chroniclesOfOlympusIIZeus.png"
      },
      {
        "CN_Name": "黄金闪电 终极版",
        "HTML5_Game_ID": "SMG_goldBlitzExtreme",
        "EN_Name": "Gold Blitz Extreme",
        "Button_Image_Name": "SMG_goldBlitzExtreme.png"
      },
      {
        "CN_Name": "众神和金字塔",
        "HTML5_Game_ID": "SMG_godsPyramidsPowerCombo",
        "EN_Name": "Gods & Pyramids Power Combo",
        "Button_Image_Name": "SMG_godsPyramidsPowerCombo.png"
      },
      {
        "CN_Name": "龙之宝藏",
        "HTML5_Game_ID": "SMG_dragonsLoot",
        "EN_Name": "Dragon's Loot Link&Win 4Tune",
        "Button_Image_Name": "SMG_dragonsLoot.png"
      },
      {
        "CN_Name": "火焰与玫瑰欢乐小丑",
        "HTML5_Game_ID": "SMG_fireAndRosesJollyJoker",
        "EN_Name": "Fire and Roses Jolly Joker",
        "Button_Image_Name": "SMG_fireAndRosesJollyJoker.png"
      },
      {
        "CN_Name": "9个魔力豆子",
        "HTML5_Game_ID": "SMG_9EnchantedBeans",
        "EN_Name": "9 Enchanted Beans",
        "Button_Image_Name": "SMG_9EnchantedBeans.png"
      },
      {
        "CN_Name": "万达快乐岛捕鱼",
        "HTML5_Game_ID": "SFG_WDMerryIslandFishing",
        "EN_Name": "WD Merry Island Fishing",
        "Button_Image_Name": "SFG_WDMerryIslandFishing.png"
      },
      {
        "CN_Name": "金罐钓钓乐 黄金 闪电",
        "HTML5_Game_ID": "SMG_fishinPotsOfGoldGoldBlitz",
        "EN_Name": "Fishin' Pots of Gold Gold Blitz",
        "Button_Image_Name": "SMG_fishinPotsOfGoldGoldBlitz.png"
      },
      {
        "CN_Name": "冰球突破十万路",
        "HTML5_Game_ID": "SMG_breakAwayMax",
        "EN_Name": "Break Away Max",
        "Button_Image_Name": "SMG_breakAwayMax.png"
      },
      {
        "CN_Name": "碰碰麻将",
        "HTML5_Game_ID": "SMG_pongPongMahjong",
        "EN_Name": "Pong Pong Mahjong",
        "Button_Image_Name": "SMG_pongPongMahjong.png"
      },
      {
        "CN_Name": "神秘宝藏",
        "HTML5_Game_ID": "SMG_legendaryTreasures",
        "EN_Name": "Legendary Treasures",
        "Button_Image_Name": "SMG_legendaryTreasures.png"
      },
      {
        "CN_Name": "太阳神 女王 POWER COMBO",
        "HTML5_Game_ID": "SMG_queensOfRa",
        "EN_Name": "Queens of Ra POWER COMBO",
        "Button_Image_Name": "SMG_queensOfRa.png"
      },
      {
        "CN_Name": "疯狂宝石烈焰",
        "HTML5_Game_ID": "SMG_gemFireFrenzy",
        "EN_Name": "Gem Fire Frenzy",
        "Button_Image_Name": "SMG_gemFireFrenzy.png"
      },
      {
        "CN_Name": "永恒寡妇",
        "HTML5_Game_ID": "SMG_theEternalWidow",
        "EN_Name": "The Eternal Widow",
        "Button_Image_Name": "SMG_theEternalWidow.png"
      },
      {
        "CN_Name": "铁砧 & 矿石",
        "HTML5_Game_ID": "SMG_anvilAndOre",
        "EN_Name": "Anvil and Ore",
        "Button_Image_Name": "SMG_anvilAndOre.png"
      },
      {
        "CN_Name": "非常 嫌疑犯",
        "HTML5_Game_ID": "SMG_unusualSuspects",
        "EN_Name": "Unusual Suspects",
        "Button_Image_Name": "SMG_unusualSuspects.png"
      },
      {
        "CN_Name": "阿斯加德之火",
        "HTML5_Game_ID": "SMG_asgardianFire",
        "EN_Name": "Asgardian Fire",
        "Button_Image_Name": "SMG_asgardianFire.png"
      },
      {
        "CN_Name": "提基 大爆炸",
        "HTML5_Game_ID": "SMG_tikiTikiBoom",
        "EN_Name": "Tiki Tiki Boom",
        "Button_Image_Name": "SMG_tikiTikiBoom.png"
      },
      {
        "CN_Name": "9罐黄金 MEGAWAYS",
        "HTML5_Game_ID": "SMG_9PotsOfGoldMegaways",
        "EN_Name": "9 Pots of Gold Megaways",
        "Button_Image_Name": "SMG_9PotsOfGoldMegaways.png"
      },
      {
        "CN_Name": "万达金霸王捕鱼",
        "HTML5_Game_ID": "SFG_WDGoldenTyrantFishing",
        "EN_Name": "WD Golden Tyrant Fishing",
        "Button_Image_Name": "SFG_WDGoldenTyrantFishing.png"
      },
      {
        "CN_Name": "奥林匹斯斗士",
        "HTML5_Game_ID": "SMG_championsOfOlympus",
        "EN_Name": "Champions of Olympus",
        "Button_Image_Name": "SMG_championsOfOlympus.png"
      },
      {
        "CN_Name": "亚马孙 失落的黄金",
        "HTML5_Game_ID": "SMG_amazonLostGold",
        "EN_Name": "Amazon - Lost Gold",
        "Button_Image_Name": "SMG_amazonLostGold.png"
      },
      {
        "CN_Name": "三伏天",
        "HTML5_Game_ID": "SMG_dogDays",
        "EN_Name": "Dog Days",
        "Button_Image_Name": "SMG_dogDays.png"
      },
      {
        "CN_Name": "安德瓦里：魔法戒指",
        "HTML5_Game_ID": "SMG_andvariTheMagicRing",
        "EN_Name": "Andvari : The Magic Ring",
        "Button_Image_Name": "SMG_andvariTheMagicRing.png"
      },
      {
        "CN_Name": "快乐渔夫",
        "HTML5_Game_ID": "SMG_fishEmUp",
        "EN_Name": "Fish Em Up",
        "Button_Image_Name": "SMG_fishEmUp.png"
      },
      {
        "CN_Name": "魔法小丑",
        "HTML5_Game_ID": "SMG_magicJokers",
        "EN_Name": "Magic Jokers",
        "Button_Image_Name": "SMG_magicJokers.png"
      },
      {
        "CN_Name": "暴富之路",
        "HTML5_Game_ID": "SMG_stormToRiches",
        "EN_Name": "Storm to Riches",
        "Button_Image_Name": "SMG_stormToRiches.png"
      },
      {
        "CN_Name": "疯狂酒馆",
        "HTML5_Game_ID": "SMG_tippyTavern",
        "EN_Name": "Tippy Tavern",
        "Button_Image_Name": "SMG_tippyTavern.png"
      },
      {
        "CN_Name": "飞翔X",
        "HTML5_Game_ID": "SMG_flyX",
        "EN_Name": "FlyX",
        "Button_Image_Name": "SMG_flyX.png"
      },
      {
        "CN_Name": "全能宙斯帝国",
        "HTML5_Game_ID": "SMG_almightyZeusEmpire",
        "EN_Name": "Almighty Zeus Empire",
        "Button_Image_Name": "SMG_almightyZeusEmpire.png"
      },
      {
        "CN_Name": "僵尸大战",
        "HTML5_Game_ID": "SMG_grannyVsZombies",
        "EN_Name": "Granny Vs Zombies",
        "Button_Image_Name": "SMG_grannyVsZombies.png"
      },
      {
        "CN_Name": "转转 糖果",
        "HTML5_Game_ID": "SMG_spinSpinSugar",
        "EN_Name": "Spin Spin Sugar",
        "Button_Image_Name": "SMG_spinSpinSugar.png"
      },
      {
        "CN_Name": "神奇法老",
        "HTML5_Game_ID": "SMG_amazingPharaoh",
        "EN_Name": "Amazing Pharaoh",
        "Button_Image_Name": "SMG_amazingPharaoh.png"
      },
      {
        "CN_Name": "甜蜜糖果热潮",
        "HTML5_Game_ID": "SMG_sugarCrazeBonanza",
        "EN_Name": "Sugar Craze Bonanza",
        "Button_Image_Name": "SMG_sugarCrazeBonanza.png"
      },
      {
        "CN_Name": "罗马：黄金之战",
        "HTML5_Game_ID": "SMG_romeFightForGold",
        "EN_Name": "Rome : Fight for Gold",
        "Button_Image_Name": "SMG_romeFightForGold.png"
      },
      {
        "CN_Name": "财富派克黄金",
        "HTML5_Game_ID": "SMG_fortunePikeGold",
        "EN_Name": "Fortune Pike Gold",
        "Button_Image_Name": "SMG_fortunePikeGold.png"
      },
      {
        "CN_Name": "趣味蜜蜂",
        "HTML5_Game_ID": "SMG_bubbleBeez",
        "EN_Name": "Bubble Beez",
        "Button_Image_Name": "SMG_bubbleBeez.png"
      },
      {
        "CN_Name": "万达金财神捕鱼",
        "HTML5_Game_ID": "SFG_WDGoldenFortuneFishing",
        "EN_Name": "WD Golden Fortune Fishing",
        "Button_Image_Name": "SFG_WDGoldenFortuneFishing.png"
      },
      {
        "CN_Name": "辣椒堆叠乐",
        "HTML5_Game_ID": "SMG_chilliPepeHotStacks",
        "EN_Name": "Chilli Pepe Hot Stacks",
        "Button_Image_Name": "SMG_chilliPepeHotStacks.png"
      },
      {
        "CN_Name": "幸运香蕉",
        "HTML5_Game_ID": "SMG_monkeyBonanza",
        "EN_Name": "Monkey Bonanza",
        "Button_Image_Name": "SMG_monkeyBonanza.png"
      },
      {
        "CN_Name": "布鲁诺 的加洛 黄金 Megaways",
        "HTML5_Game_ID": "SMG_galloGoldMegaways",
        "EN_Name": "Gallo Gold Bruno's Megaways",
        "Button_Image_Name": "SMG_galloGoldMegaways.png"
      },
      {
        "CN_Name": "妖精出击",
        "HTML5_Game_ID": "SMG_leprechaunStrike",
        "EN_Name": "Leprechaun Strike",
        "Button_Image_Name": "SMG_leprechaunStrike.png"
      },
      {
        "CN_Name": "火焰与玫瑰小丑",
        "HTML5_Game_ID": "SMG_fireAndRosesJoker",
        "EN_Name": "Fire and Roses Joker",
        "Button_Image_Name": "SMG_fireAndRosesJoker.png"
      },
      {
        "CN_Name": "快乐招财猫",
        "HTML5_Game_ID": "SMG_happyLuckyCats",
        "EN_Name": "Happy Lucky Cats",
        "Button_Image_Name": "SMG_happyLuckyCats.png"
      },
      {
        "CN_Name": "黄金宝箱: POWER COMBO",
        "HTML5_Game_ID": "SMG_chestsOfGold",
        "EN_Name": "Chests of Gold : Power Combo",
        "Button_Image_Name": "SMG_chestsOfGold.png"
      },
      {
        "CN_Name": "极度野火奖金",
        "HTML5_Game_ID": "SMG_wildfireWinsExtreme",
        "EN_Name": "Wildfire Wins Extreme",
        "Button_Image_Name": "SMG_wildfireWinsExtreme.png"
      },
      {
        "CN_Name": "欢乐糖果百搭",
        "HTML5_Game_ID": "SMG_candyRushWilds",
        "EN_Name": "Candy Rush Wilds",
        "Button_Image_Name": "SMG_candyRushWilds.png"
      },
      {
        "CN_Name": "烈焰战狼 Megaways",
        "HTML5_Game_ID": "SMG_wolfBlazeMegaways",
        "EN_Name": "Wolf Blaze Megaways",
        "Button_Image_Name": "SMG_wolfBlazeMegaways.png"
      },
      {
        "CN_Name": "黄金 闪电",
        "HTML5_Game_ID": "SMG_goldBlitz",
        "EN_Name": "Gold Blitz",
        "Button_Image_Name": "SMG_goldBlitz.png"
      },
      {
        "CN_Name": "龙之 堡垒",
        "HTML5_Game_ID": "SMG_dragonsKeep",
        "EN_Name": "Dragon's Keep",
        "Button_Image_Name": "SMG_dragonsKeep.png"
      },
      {
        "CN_Name": "心动烟花祭",
        "HTML5_Game_ID": "SMG_dokiDokiFireworks",
        "EN_Name": "Doki Doki Fireworks",
        "Button_Image_Name": "SMG_dokiDokiFireworks.png"
      },
      {
        "CN_Name": "野牛圆月",
        "HTML5_Game_ID": "SMG_bisonMoon",
        "EN_Name": "Bison Moon",
        "Button_Image_Name": "SMG_bisonMoon.png"
      },
      {
        "CN_Name": "特洛伊王国",
        "HTML5_Game_ID": "SMG_trojanKingdom",
        "EN_Name": "Trojan Kingdom",
        "Button_Image_Name": "SMG_trojanKingdom.png"
      },
      {
        "CN_Name": "奥林匹斯山之主",
        "HTML5_Game_ID": "SMG_mastersOfOlympus",
        "EN_Name": "Masters of Olympus",
        "Button_Image_Name": "SMG_mastersOfOlympus.png"
      },
      {
        "CN_Name": "Playboy 百搭",
        "HTML5_Game_ID": "SMG_playboyWilds",
        "EN_Name": "Playboy Wilds",
        "Button_Image_Name": "SMG_playboyWilds.png"
      },
      {
        "CN_Name": "冰雪猛虎",
        "HTML5_Game_ID": "SMG_tigersIce",
        "EN_Name": "Tiger's Ice",
        "Button_Image_Name": "SMG_tigersIce.png"
      },
      {
        "CN_Name": "王牌Hilo",
        "HTML5_Game_ID": "SFG_WPHilo",
        "EN_Name": "WP Hilo",
        "Button_Image_Name": "SFG_WPHilo.png"
      },
      {
        "CN_Name": "雷霆万钧猎风战士",
        "HTML5_Game_ID": "SMG_thunderstruckStormchaser",
        "EN_Name": "Thunderstruck Stormchaser",
        "Button_Image_Name": "SMG_thunderstruckStormchaser.png"
      },
      {
        "CN_Name": "金罐钓钓乐圣诞版",
        "HTML5_Game_ID": "SMG_fishinChristmasPotsOfGold",
        "EN_Name": "Fishin' Christmas Pots of Gold",
        "Button_Image_Name": "SMG_fishinChristmasPotsOfGold.png"
      },
      {
        "CN_Name": "菲奥娜的圣诞财富",
        "HTML5_Game_ID": "SMG_fionasChristmasFortune",
        "EN_Name": "Fiona's Christmas Fortune",
        "Button_Image_Name": "SMG_fionasChristmasFortune.png"
      },
      {
        "CN_Name": "幸运双星 Link&Win",
        "HTML5_Game_ID": "SMG_luckyTwinsLinkAndWin",
        "EN_Name": "Lucky Twins Link & Win",
        "Button_Image_Name": "SMG_luckyTwinsLinkAndWin.png"
      },
      {
        "CN_Name": "金罐钓钓乐升级版",
        "HTML5_Game_ID": "SMG_fishinBiggerPots",
        "EN_Name": "Fishin' Bigger Pots Of Gold",
        "Button_Image_Name": "SMG_fishinBiggerPots.png"
      },
      {
        "CN_Name": "音速连接  ",
        "HTML5_Game_ID": "SMG_sonicLinks",
        "EN_Name": "Sonic Links",
        "Button_Image_Name": "SMG_sonicLinks.png"
      },
      {
        "CN_Name": "王牌Rummy",
        "HTML5_Game_ID": "SFG_WPRummy",
        "EN_Name": "WP Rummy",
        "Button_Image_Name": "SFG_WPRummy.png"
      },
      {
        "CN_Name": "777 超级 BIG BuildUp 豪华版",
        "HTML5_Game_ID": "SMG_777superBigBuildUpDeluxe",
        "EN_Name": "777 Super BIG BuildUp Deluxe",
        "Button_Image_Name": "SMG_777superBigBuildUpDeluxe.png"
      },
      {
        "CN_Name": "王牌Pai Kang",
        "HTML5_Game_ID": "SFG_WPPaiKang",
        "EN_Name": "WP Pai Kang",
        "Button_Image_Name": "SFG_WPPaiKang.png"
      },
      {
        "CN_Name": "罗宾汉英雄",
        "HTML5_Game_ID": "SMG_robinHoodsHeroes",
        "EN_Name": "Robin Hood’s Heroes",
        "Button_Image_Name": "SMG_robinHoodsHeroes.png"
      },
      {
        "CN_Name": "深海行者",
        "HTML5_Game_ID": "SMG_aquanauts",
        "EN_Name": "Aquanauts",
        "Button_Image_Name": "SMG_aquanauts.png"
      },
      {
        "CN_Name": "星光水果",
        "HTML5_Game_ID": "SMG_starliteFruits",
        "EN_Name": "Starlite Fruits",
        "Button_Image_Name": "SMG_starliteFruits.png"
      },
      {
        "CN_Name": "翡翠勇士",
        "HTML5_Game_ID": "SMG_jadeShuriken",
        "EN_Name": "Jade Shuriken",
        "Button_Image_Name": "SMG_jadeShuriken.png"
      },
      {
        "CN_Name": "亚马逊王国",
        "HTML5_Game_ID": "SMG_amazonKingdom",
        "EN_Name": "Amazon Kingdom",
        "Button_Image_Name": "SMG_amazonKingdom.png"
      },
      {
        "CN_Name": "小仙狐大冒险",
        "HTML5_Game_ID": "SMG_kitsuneAdventure",
        "EN_Name": "Kitsune Adventure",
        "Button_Image_Name": "SMG_kitsuneAdventure.png"
      },
      {
        "CN_Name": "王牌Pok Deng",
        "HTML5_Game_ID": "SFG_WPPokDeng",
        "EN_Name": "WP Pok Deng",
        "Button_Image_Name": "SFG_WPPokDeng.png"
      },
      {
        "CN_Name": "埃及守护神",
        "HTML5_Game_ID": "SMG_arkOfRa",
        "EN_Name": "Ark of Ra",
        "Button_Image_Name": "SMG_arkOfRa.png"
      },
      {
        "CN_Name": "777 电光火石",
        "HTML5_Game_ID": "SMG_777Surge",
        "EN_Name": "777 Surge",
        "Button_Image_Name": "SMG_777Surge.png"
      },
      {
        "CN_Name": "闪电 X UP",
        "HTML5_Game_ID": "SMG_boltXUP",
        "EN_Name": "Bolt X UP",
        "Button_Image_Name": "SMG_boltXUP.png"
      },
      {
        "CN_Name": "幸运矮妖精集群奖金",
        "HTML5_Game_ID": "SMG_luckyLeprechaunClusters",
        "EN_Name": "Lucky Leprechaun Clusters",
        "Button_Image_Name": "SMG_luckyLeprechaunClusters.png"
      },
      {
        "CN_Name": "不朽情缘视频宾果",
        "HTML5_Game_ID": "SMG_immortalRomanceVideoBingo",
        "EN_Name": "Immortal Romance Video Bingo",
        "Button_Image_Name": "SMG_immortalRomanceVideoBingo.png"
      },
      {
        "CN_Name": "阿蒙的面具",
        "HTML5_Game_ID": "SMG_maskOfAmun",
        "EN_Name": "Mask of Amun",
        "Button_Image_Name": "SMG_maskOfAmun.png"
      },
      {
        "CN_Name": "神圣财富 - 赫利俄斯",
        "HTML5_Game_ID": "SMG_divineRichesHelios",
        "EN_Name": "Divine Riches Helios",
        "Button_Image_Name": "SMG_divineRichesHelios.png"
      },
      {
        "CN_Name": "王牌Gaple",
        "HTML5_Game_ID": "SFG_WPGaple",
        "EN_Name": "WP Gaple",
        "Button_Image_Name": "SFG_WPGaple.png"
      },
      {
        "CN_Name": "野火灼金",
        "HTML5_Game_ID": "SMG_wildfireWins",
        "EN_Name": "Wildfire Wins",
        "Button_Image_Name": "SMG_wildfireWins.png"
      },
      {
        "CN_Name": "⻢戏小丑累积奖金",
        "HTML5_Game_ID": "SMG_circusJugglersJackpots",
        "EN_Name": "Circus Jugglers Jackpots",
        "Button_Image_Name": "SMG_circusJugglersJackpots.png"
      },
      {
        "CN_Name": "",
        "HTML5_Game_ID": "猫咪咒语\"",
        "EN_Name": "AbraCatDabra",
        "Button_Image_Name": "猫咪咒语\".png"
      },
      {
        "CN_Name": "百搭狂野浪漫",
        "HTML5_Game_ID": "SMG_wildWildRomance",
        "EN_Name": "Wild Wild Romance",
        "Button_Image_Name": "SMG_wildWildRomance.png"
      },
      {
        "CN_Name": "闪电财富",
        "HTML5_Game_ID": "SMG_lightningFortunes",
        "EN_Name": "Lightning Fortunes",
        "Button_Image_Name": "SMG_lightningFortunes.png"
      },
      {
        "CN_Name": "25000鹰爪",
        "HTML5_Game_ID": "SMG_25000Talons",
        "EN_Name": "25000 Talons",
        "Button_Image_Name": "SMG_25000Talons.png"
      },
      {
        "CN_Name": "现金与财富 Megaways",
        "HTML5_Game_ID": "SMG_cashNRichesMegaways",
        "EN_Name": "Cash 'N Riches Megaways",
        "Button_Image_Name": "SMG_cashNRichesMegaways.png"
      },
      {
        "CN_Name": "15个三叉戟",
        "HTML5_Game_ID": "SMG_15Tridents",
        "EN_Name": "15 Tridents",
        "Button_Image_Name": "SMG_15Tridents.png"
      },
      {
        "CN_Name": "地下城与钻石",
        "HTML5_Game_ID": "SMG_dungeonsAndDiamonds",
        "EN_Name": "Dungeons and Diamonds",
        "Button_Image_Name": "SMG_dungeonsAndDiamonds.png"
      },
      {
        "CN_Name": "阿兹特克天降大奖",
        "HTML5_Game_ID": "SMG_aztecFalls",
        "EN_Name": "Aztec Falls",
        "Button_Image_Name": "SMG_aztecFalls.png"
      },
      {
        "CN_Name": "瓦尔哈拉的统治者",
        "HTML5_Game_ID": "SMG_mastersOfValhalla",
        "EN_Name": "Masters of Valhalla",
        "Button_Image_Name": "SMG_mastersOfValhalla.png"
      },
      {
        "CN_Name": "心动芭菲",
        "HTML5_Game_ID": "SMG_dokiDokiParfait",
        "EN_Name": "Doki Doki Parfait",
        "Button_Image_Name": "SMG_dokiDokiParfait.png"
      },
      {
        "CN_Name": "王牌 色碟",
        "HTML5_Game_ID": "SFG_WPXocDia",
        "EN_Name": "WP Xoc Dia",
        "Button_Image_Name": "SFG_WPXocDia.png"
      },
      {
        "CN_Name": "鬼狩夜樱",
        "HTML5_Game_ID": "SMG_oniHunterNightSakura",
        "EN_Name": "Oni Hunter Night Sakura",
        "Button_Image_Name": "SMG_oniHunterNightSakura.png"
      },
      {
        "CN_Name": "金罐钓钓乐",
        "HTML5_Game_ID": "SMG_fishinPotsOfGold",
        "EN_Name": "Fishin' Pots Of Gold",
        "Button_Image_Name": "SMG_fishinPotsOfGold.png"
      },
      {
        "CN_Name": "水晶之王",
        "HTML5_Game_ID": "SMG_kingsOfCrystals",
        "EN_Name": "Kings of Crystals",
        "Button_Image_Name": "SMG_kingsOfCrystals.png"
      },
      {
        "CN_Name": "王牌鱼虾蟹",
        "HTML5_Game_ID": "SFG_WPFishPrawnCrab",
        "EN_Name": "WP Fish Prawn Crab",
        "Button_Image_Name": "SFG_WPFishPrawnCrab.png"
      },
      {
        "CN_Name": "Jurassic Park:黄金",
        "HTML5_Game_ID": "SMG_jurassicParkGold",
        "EN_Name": "Jurassic Park Gold",
        "Button_Image_Name": "SMG_jurassicParkGold.png"
      },
      {
        "CN_Name": "5星淘汰",
        "HTML5_Game_ID": "SMG_5StarKnockout",
        "EN_Name": "5 Star Knockout",
        "Button_Image_Name": "SMG_5StarKnockout.png"
      },
      {
        "CN_Name": "特工简·布隆德最大音量",
        "HTML5_Game_ID": "SMG_agentJaneBlondeMaxVolume",
        "EN_Name": "Agent Jane Blonde Max Volume",
        "Button_Image_Name": "SMG_agentJaneBlondeMaxVolume.png"
      },
      {
        "CN_Name": "篮球巨星百搭",
        "HTML5_Game_ID": "SMG_basketballStarWilds",
        "EN_Name": "Basketball Star Wilds",
        "Button_Image_Name": "SMG_basketballStarWilds.png"
      },
      {
        "CN_Name": "科迪亚克王国",
        "HTML5_Game_ID": "SMG_kodiakKingdom",
        "EN_Name": "Kodiak Kingdom",
        "Button_Image_Name": "SMG_kodiakKingdom.png"
      },
      {
        "CN_Name": "财富大爆炸",
        "HTML5_Game_ID": "SMG_bigBoomRiches",
        "EN_Name": "Big Boom Riches",
        "Button_Image_Name": "SMG_bigBoomRiches.png"
      },
      {
        "CN_Name": "喝彩的旺财",
        "HTML5_Game_ID": "SMG_squealinRiches",
        "EN_Name": "Squealin' Riches",
        "Button_Image_Name": "SMG_squealinRiches.png"
      },
      {
        "CN_Name": "4钻石蓝调 Megaways",
        "HTML5_Game_ID": "SMG_4DiamondBlues",
        "EN_Name": "4 Diamond Blues Megaways",
        "Button_Image_Name": "SMG_4DiamondBlues.png"
      },
      {
        "CN_Name": "9 个烈焰面具 HyperSpins",
        "HTML5_Game_ID": "SMG_9masksOfFireHyperSpins",
        "EN_Name": "9 Masks of Fire HyperSpins",
        "Button_Image_Name": "SMG_9masksOfFireHyperSpins.png"
      },
      {
        "CN_Name": "猫咪部落",
        "HTML5_Game_ID": "SMG_catClans",
        "EN_Name": "Cat Clans",
        "Button_Image_Name": "SMG_catClans.png"
      },
      {
        "CN_Name": "圣诞老婆婆之书",
        "HTML5_Game_ID": "SMG_bookOfMrsClaus",
        "EN_Name": "Book of Mrs Claus",
        "Button_Image_Name": "SMG_bookOfMrsClaus.png"
      },
      {
        "CN_Name": "幸运鸡",
        "HTML5_Game_ID": "SMG_luckyClucks",
        "EN_Name": "Lucky Clucks",
        "Button_Image_Name": "SMG_luckyClucks.png"
      },
      {
        "CN_Name": "奥林匹斯編年史 X UP",
        "HTML5_Game_ID": "SMG_chroniclesOfOlympusXUP",
        "EN_Name": "Chronicles of Olympus X Up",
        "Button_Image_Name": "SMG_chroniclesOfOlympusXUP.png"
      },
      {
        "CN_Name": "WWE摔角传奇",
        "HTML5_Game_ID": "SMG_wweLegendsLinkWin",
        "EN_Name": "WWE Legends: Link & Win",
        "Button_Image_Name": "SMG_wweLegendsLinkWin.png"
      },
      {
        "CN_Name": "瑞龙献宝",
        "HTML5_Game_ID": "SMG_fortuneRush",
        "EN_Name": "Fortune Rush",
        "Button_Image_Name": "SMG_fortuneRush.png"
      },
      {
        "CN_Name": "10000个愿望",
        "HTML5_Game_ID": "SMG_10000Wishes",
        "EN_Name": "10000 Wishes",
        "Button_Image_Name": "SMG_10000Wishes.png"
      },
      {
        "CN_Name": "108好汉",
        "HTML5_Game_ID": "SMG_108Heroes",
        "EN_Name": "108 Heroes",
        "Button_Image_Name": "SMG_108Heroes.png"
      },
      {
        "CN_Name": "5轴驱动",
        "HTML5_Game_ID": "SMG_5ReelDrive",
        "EN_Name": "5 Reel Drive",
        "Button_Image_Name": "SMG_5ReelDrive.png"
      },
      {
        "CN_Name": "777绝对豪华",
        "HTML5_Game_ID": "SMG_777MegaDeluxe",
        "EN_Name": "777 Mega Deluxe",
        "Button_Image_Name": "SMG_777MegaDeluxe.png"
      },
      {
        "CN_Name": "777皇家幸运轮",
        "HTML5_Game_ID": "SMG_777RoyalWheel",
        "EN_Name": "777 Royal Wheel",
        "Button_Image_Name": "SMG_777RoyalWheel.png"
      },
      {
        "CN_Name": "9个烈焰面具",
        "HTML5_Game_ID": "SMG_9masksOfFire",
        "EN_Name": "9 Masks of Fire",
        "Button_Image_Name": "SMG_9masksOfFire.png"
      },
      {
        "CN_Name": "9罐黄金",
        "HTML5_Game_ID": "SMG_9potsOfGold",
        "EN_Name": "9 Pots of Gold",
        "Button_Image_Name": "SMG_9potsOfGold.png"
      },
      {
        "CN_Name": "黑暗阴影",
        "HTML5_Game_ID": "SMG_aDarkMatter",
        "EN_Name": "A Dark Matter",
        "Button_Image_Name": "SMG_aDarkMatter.png"
      },
      {
        "CN_Name": "精灵传说",
        "HTML5_Game_ID": "SMG_aTaleOfElves",
        "EN_Name": "A Tale of Elves",
        "Button_Image_Name": "SMG_aTaleOfElves.png"
      },
      {
        "CN_Name": "秘密行动-雪诺和塞布尔",
        "HTML5_Game_ID": "SMG_actionOpsSnowAndSable",
        "EN_Name": "ActionOps: Snow and Sable",
        "Button_Image_Name": "SMG_actionOpsSnowAndSable.png"
      },
      {
        "CN_Name": "奇遇宫殿",
        "HTML5_Game_ID": "SMG_adventurePalace",
        "EN_Name": "Adventure Palace",
        "Button_Image_Name": "SMG_adventurePalace.png"
      },
      {
        "CN_Name": "金币岛大冒险",
        "HTML5_Game_ID": "SMG_adventuresOfDoubloonIsland",
        "EN_Name": "Adventures Of Doubloon Island",
        "Button_Image_Name": "SMG_adventuresOfDoubloonIsland.png"
      },
      {
        "CN_Name": "非洲 X UP",
        "HTML5_Game_ID": "SMG_africaXUP",
        "EN_Name": "Africa X UP",
        "Button_Image_Name": "SMG_africaXUP.png"
      },
      {
        "CN_Name": "探索时代",
        "HTML5_Game_ID": "SMG_ageOfDiscovery",
        "EN_Name": "Age of Discovery",
        "Button_Image_Name": "SMG_ageOfDiscovery.png"
      },
      {
        "CN_Name": "特工简布隆德",
        "HTML5_Game_ID": "SMG_agentJaneBlonde",
        "EN_Name": "Agent Jane Blonde",
        "Button_Image_Name": "SMG_agentJaneBlonde.png"
      },
      {
        "CN_Name": "阿拉斯加钓鱼",
        "HTML5_Game_ID": "SMG_alaskanFishing",
        "EN_Name": "Alaskan Fishing",
        "Button_Image_Name": "SMG_alaskanFishing.png"
      },
      {
        "CN_Name": "炼金财宝",
        "HTML5_Game_ID": "SMG_alchemyFortunes",
        "EN_Name": "Alchemy Fortunes",
        "Button_Image_Name": "SMG_alchemyFortunes.png"
      },
      {
        "CN_Name": "古代财富 Poseidon Megaways",
        "HTML5_Game_ID": "SMG_ancientFortunesPoseidonMegaways",
        "EN_Name": "Ancient Fortunes : Poseidon Megaways",
        "Button_Image_Name": "SMG_ancientFortunesPoseidonMegaways.png"
      },
      {
        "CN_Name": "Zeus古代财富",
        "HTML5_Game_ID": "SMG_ancientFortunesZeus",
        "EN_Name": "Ancient Fortunes: Zeus",
        "Button_Image_Name": "SMG_ancientFortunesZeus.png"
      },
      {
        "CN_Name": "爱丽娜",
        "HTML5_Game_ID": "SMG_ariana",
        "EN_Name": "Ariana",
        "Button_Image_Name": "SMG_ariana.png"
      },
      {
        "CN_Name": "亚洲美人",
        "HTML5_Game_ID": "SMG_asianBeauty",
        "EN_Name": "Asian Beauty",
        "Button_Image_Name": "SMG_asianBeauty.png"
      },
      {
        "CN_Name": "刺客之月",
        "HTML5_Game_ID": "SMG_assassinMoon",
        "EN_Name": "Assassin Moon",
        "Button_Image_Name": "SMG_assassinMoon.png"
      },
      {
        "CN_Name": "太空传奇-莱拉和叶莉昂",
        "HTML5_Game_ID": "SMG_astroLegendsLyraandErion",
        "EN_Name": "Astro Legends: Lyra and Erion",
        "Button_Image_Name": "SMG_astroLegendsLyraandErion.png"
      },
      {
        "CN_Name": "极光百搭",
        "HTML5_Game_ID": "SMG_auroraWilds",
        "EN_Name": "Aurora Wilds",
        "Button_Image_Name": "SMG_auroraWilds.png"
      },
      {
        "CN_Name": "阿瓦隆",
        "HTML5_Game_ID": "SMG_avalon",
        "EN_Name": "Avalon",
        "Button_Image_Name": "SMG_avalon.png"
      },
      {
        "CN_Name": "热血羽毛球",
        "HTML5_Game_ID": "SMG_badmintonHero",
        "EN_Name": "Badminton Hero",
        "Button_Image_Name": "SMG_badmintonHero.png"
      },
      {
        "CN_Name": "香蕉奥德赛",
        "HTML5_Game_ID": "SMG_bananaOdyssey",
        "EN_Name": "Banana Odyssey",
        "Button_Image_Name": "SMG_bananaOdyssey.png"
      },
      {
        "CN_Name": "黑绵羊咩咩叫",
        "HTML5_Game_ID": "SMG_barBarBlackSheep5Reel",
        "EN_Name": "Bar Bar Black Sheep - 5 Reel",
        "Button_Image_Name": "SMG_barBarBlackSheep5Reel.png"
      },
      {
        "CN_Name": "条条纹纹",
        "HTML5_Game_ID": "SMG_BarsAndStripes",
        "EN_Name": "Bars & Stripes",
        "Button_Image_Name": "SMG_BarsAndStripes.png"
      },
      {
        "CN_Name": "篮球巨星",
        "HTML5_Game_ID": "SMG_basketballStar",
        "EN_Name": "Basketball Star",
        "Button_Image_Name": "SMG_basketballStar.png"
      },
      {
        "CN_Name": "篮球巨星豪华版",
        "HTML5_Game_ID": "SMG_basketballStarDeluxe",
        "EN_Name": "Basketball Star Deluxe",
        "Button_Image_Name": "SMG_basketballStarDeluxe.png"
      },
      {
        "CN_Name": "篮球巨星火力全开",
        "HTML5_Game_ID": "SMG_basketballStaronFire",
        "EN_Name": "Basketball Star on Fire",
        "Button_Image_Name": "SMG_basketballStaronFire.png"
      },
      {
        "CN_Name": "美丽骷髅",
        "HTML5_Game_ID": "SMG_beautifulBones",
        "EN_Name": "Beautiful Bones",
        "Button_Image_Name": "SMG_beautifulBones.png"
      },
      {
        "CN_Name": "森林之王",
        "HTML5_Game_ID": "SMG_bigKahuna",
        "EN_Name": "Big Kahuna",
        "Button_Image_Name": "SMG_bigKahuna.png"
      },
      {
        "CN_Name": "马戏团",
        "HTML5_Game_ID": "SMG_bigTop",
        "EN_Name": "Big Top",
        "Button_Image_Name": "SMG_bigTop.png"
      },
      {
        "CN_Name": "比基尼派对",
        "HTML5_Game_ID": "SMG_bikiniParty",
        "EN_Name": "Bikini Party",
        "Button_Image_Name": "SMG_bikiniParty.png"
      },
      {
        "CN_Name": "炽热猛犸象",
        "HTML5_Game_ID": "SMG_blazingMammoth",
        "EN_Name": "Blazing Mammoth",
        "Button_Image_Name": "SMG_blazingMammoth.png"
      },
      {
        "CN_Name": "金龙财宝",
        "HTML5_Game_ID": "SMG_boatofFortune",
        "EN_Name": "Boat of Fortune",
        "Button_Image_Name": "SMG_boatofFortune.png"
      },
      {
        "CN_Name": "亚瑟王之书",
        "HTML5_Game_ID": "SMG_bookOfKingArthur",
        "EN_Name": "Book of King Arthur",
        "Button_Image_Name": "SMG_bookOfKingArthur.png"
      },
      {
        "CN_Name": "Oz之书",
        "HTML5_Game_ID": "SMG_bookOfOz",
        "EN_Name": "Book Of Oz",
        "Button_Image_Name": "SMG_bookOfOz.png"
      },
      {
        "CN_Name": "Oz之书锁定并旋转",
        "HTML5_Game_ID": "SMG_bookOfOzLockNSpin",
        "EN_Name": "Book of Oz - Lock 'N Spin",
        "Button_Image_Name": "SMG_bookOfOzLockNSpin.png"
      },
      {
        "CN_Name": "好运经纪人",
        "HTML5_Game_ID": "SMG_bookieOfOdds",
        "EN_Name": "Bookie of Odds",
        "Button_Image_Name": "SMG_bookieOfOdds.png"
      },
      {
        "CN_Name": "炸弹海盗",
        "HTML5_Game_ID": "SMG_boomPirates",
        "EN_Name": "Boom Pirates",
        "Button_Image_Name": "SMG_boomPirates.png"
      },
      {
        "CN_Name": "冰球突破",
        "HTML5_Game_ID": "SMG_breakAway",
        "EN_Name": "Break Away",
        "Button_Image_Name": "SMG_breakAway.png"
      },
      {
        "CN_Name": "冰球突破豪华版",
        "HTML5_Game_ID": "SMG_breakAwayDeluxe",
        "EN_Name": "Break Away Deluxe",
        "Button_Image_Name": "SMG_breakAwayDeluxe.png"
      },
      {
        "CN_Name": "冰球突破幸运百搭",
        "HTML5_Game_ID": "SMG_breakAwayLuckyWilds",
        "EN_Name": "Break Away Lucky Wilds",
        "Button_Image_Name": "SMG_breakAwayLuckyWilds.png"
      },
      {
        "CN_Name": "冰球突破 终极全胜",
        "HTML5_Game_ID": "SMG_breakAwayUltra",
        "EN_Name": "Break Away Ultra",
        "Button_Image_Name": "SMG_breakAwayUltra.png"
      },
      {
        "CN_Name": "抢银行",
        "HTML5_Game_ID": "SMG_breakDaBank",
        "EN_Name": "Break da Bank",
        "Button_Image_Name": "SMG_breakDaBank.png"
      },
      {
        "CN_Name": "再抢银行",
        "HTML5_Game_ID": "SMG_breakDaBankAgain",
        "EN_Name": "Break da Bank Again",
        "Button_Image_Name": "SMG_breakDaBankAgain.png"
      },
      {
        "CN_Name": "再抢银行再旋转",
        "HTML5_Game_ID": "SMG_breakDaBankAgainRespin",
        "EN_Name": "Break Da Bank Again Respin",
        "Button_Image_Name": "SMG_breakDaBankAgainRespin.png"
      },
      {
        "CN_Name": "再抢银行 MEGAWAYS",
        "HTML5_Game_ID": "SMG_breakDaBankAgainMegaways",
        "EN_Name": "Break Da Bank Again MEGAWAYS",
        "Button_Image_Name": "SMG_breakDaBankAgainMegaways.png"
      },
      {
        "CN_Name": "炙热欲望",
        "HTML5_Game_ID": "SMG_burningDesire",
        "EN_Name": "Burning Desire",
        "Button_Image_Name": "SMG_burningDesire.png"
      },
      {
        "CN_Name": "丛林传讯",
        "HTML5_Game_ID": "SMG_bushTelegraph",
        "EN_Name": "Bush Telegraph",
        "Button_Image_Name": "SMG_bushTelegraph.png"
      },
      {
        "CN_Name": "爆破银行",
        "HTML5_Game_ID": "SMG_bustTheBank",
        "EN_Name": "Bust the Bank",
        "Button_Image_Name": "SMG_bustTheBank.png"
      },
      {
        "CN_Name": "嘉年华",
        "HTML5_Game_ID": "SMG_carnaval",
        "EN_Name": "Carnaval",
        "Button_Image_Name": "SMG_carnaval.png"
      },
      {
        "CN_Name": "嘉年华大奖",
        "HTML5_Game_ID": "SMG_carnavalJackpot",
        "EN_Name": "Carnaval Jackpot",
        "Button_Image_Name": "SMG_carnavalJackpot.png"
      },
      {
        "CN_Name": "富贵王国",
        "HTML5_Game_ID": "SMG_cashOfKingdoms",
        "EN_Name": "Cash of Kingdoms",
        "Button_Image_Name": "SMG_cashOfKingdoms.png"
      },
      {
        "CN_Name": "金钱蝶蛹",
        "HTML5_Game_ID": "SMG_cashapillar",
        "EN_Name": "Cashapillar",
        "Button_Image_Name": "SMG_cashapillar.png"
      },
      {
        "CN_Name": "网球冠军",
        "HTML5_Game_ID": "SMG_centreCourt",
        "EN_Name": "Centre Court",
        "Button_Image_Name": "SMG_centreCourt.png"
      },
      {
        "CN_Name": "运财酷儿",
        "HTML5_Game_ID": "SMG_coolBuck5Reel",
        "EN_Name": "Cool Buck - 5 Reel",
        "Button_Image_Name": "SMG_coolBuck5Reel.png"
      },
      {
        "CN_Name": "酷派狼人",
        "HTML5_Game_ID": "SMG_coolWolf",
        "EN_Name": "Cool Wolf",
        "Button_Image_Name": "SMG_coolWolf.png"
      },
      {
        "CN_Name": "板球明星",
        "HTML5_Game_ID": "SMG_cricketStar",
        "EN_Name": "Cricket Star",
        "Button_Image_Name": "SMG_cricketStar.png"
      },
      {
        "CN_Name": "板球明星 Scratch",
        "HTML5_Game_ID": "SMG_cricketStarScratch",
        "EN_Name": "Cricket Star Scratch",
        "Button_Image_Name": "SMG_cricketStarScratch.png"
      },
      {
        "CN_Name": "闪亮的圣诞节",
        "HTML5_Game_ID": "SMG_deckTheHalls",
        "EN_Name": "Deck the Halls",
        "Button_Image_Name": "SMG_deckTheHalls.png"
      },
      {
        "CN_Name": "钻石帝国",
        "HTML5_Game_ID": "SMG_diamondEmpire",
        "EN_Name": "Diamond Empire",
        "Button_Image_Name": "SMG_diamondEmpire.png"
      },
      {
        "CN_Name": "舞龙",
        "HTML5_Game_ID": "SMG_dragonDance",
        "EN_Name": "Dragon Dance",
        "Button_Image_Name": "SMG_dragonDance.png"
      },
      {
        "CN_Name": "神龙碎片",
        "HTML5_Game_ID": "SMG_dragonShard",
        "EN_Name": "Dragon Shard",
        "Button_Image_Name": "SMG_dragonShard.png"
      },
      {
        "CN_Name": "幸运龙宝贝",
        "HTML5_Game_ID": "SMG_dragonz",
        "EN_Name": "Dragonz",
        "Button_Image_Name": "SMG_dragonz.png"
      },
      {
        "CN_Name": "梦幻邂逅",
        "HTML5_Game_ID": "SMG_dreamDate",
        "EN_Name": "Dream Date",
        "Button_Image_Name": "SMG_dreamDate.png"
      },
      {
        "CN_Name": "雄鹰之翼",
        "HTML5_Game_ID": "SMG_eaglesWings",
        "EN_Name": "Eagle's Wings",
        "Button_Image_Name": "SMG_eaglesWings.png"
      },
      {
        "CN_Name": "埃及古墓",
        "HTML5_Game_ID": "SMG_egyptianTombs",
        "EN_Name": "Egyptian Tombs",
        "Button_Image_Name": "SMG_egyptianTombs.png"
      },
      {
        "CN_Name": "金翠绿",
        "HTML5_Game_ID": "SMG_emeraldGold",
        "EN_Name": "Emerald Gold",
        "Button_Image_Name": "SMG_emeraldGold.png"
      },
      {
        "CN_Name": "青龙出海",
        "HTML5_Game_ID": "SMG_emperorOfTheSea",
        "EN_Name": "Emperor of the Sea",
        "Button_Image_Name": "SMG_emperorOfTheSea.png"
      },
      {
        "CN_Name": "青龙出海豪华版",
        "HTML5_Game_ID": "SMG_emperorOfTheSeaDeluxe",
        "EN_Name": "Emperor of the Sea Deluxe",
        "Button_Image_Name": "SMG_emperorOfTheSeaDeluxe.png"
      },
      {
        "CN_Name": "异域狂兽",
        "HTML5_Game_ID": "SMG_exoticCats",
        "EN_Name": "Exotic Cats",
        "Button_Image_Name": "SMG_exoticCats.png"
      },
      {
        "CN_Name": "烈火锻金",
        "HTML5_Game_ID": "SMG_fireForge",
        "EN_Name": "Fire Forge",
        "Button_Image_Name": "SMG_fireForge.png"
      },
      {
        "CN_Name": "深海派对",
        "HTML5_Game_ID": "SMG_fishParty",
        "EN_Name": "Fish Party",
        "Button_Image_Name": "SMG_fishParty.png"
      },
      {
        "CN_Name": "足球明星",
        "HTML5_Game_ID": "SMG_footballStar",
        "EN_Name": "Football Star",
        "Button_Image_Name": "SMG_footballStar.png"
      },
      {
        "CN_Name": "足球明星豪华版",
        "HTML5_Game_ID": "SMG_footballStarDeluxe",
        "EN_Name": "Football Star Deluxe",
        "Button_Image_Name": "SMG_footballStarDeluxe.png"
      },
      {
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "SFG_ForestParty",
        "EN_Name": "Forest Party",
        "Button_Image_Name": "SFG_ForestParty.png"
      },
      {
        "CN_Name": "遗忘之岛Megaways",
        "HTML5_Game_ID": "SMG_forgottenIsland",
        "EN_Name": "Forgotten Island Megaways ",
        "Button_Image_Name": "SMG_forgottenIsland.png"
      },
      {
        "CN_Name": "金库甜心",
        "HTML5_Game_ID": "SMG_fortuneGirl",
        "EN_Name": "Fortune Girl",
        "Button_Image_Name": "SMG_fortuneGirl.png"
      },
      {
        "CN_Name": "财富之都",
        "HTML5_Game_ID": "SMG_fortunium",
        "EN_Name": "Fortunium",
        "Button_Image_Name": "SMG_fortunium.png"
      },
      {
        "CN_Name": "水果大爆发",
        "HTML5_Game_ID": "SMG_fruitBlast",
        "EN_Name": "Fruit Blast",
        "Button_Image_Name": "SMG_fruitBlast.png"
      },
      {
        "CN_Name": "水果VS糖果",
        "HTML5_Game_ID": "SMG_fruitVSCandy",
        "EN_Name": "Fruit vs Candy",
        "Button_Image_Name": "SMG_fruitVSCandy.png"
      },
      {
        "CN_Name": "魔龙宝珠",
        "HTML5_Game_ID": "SMG_gemsAndDragons",
        "EN_Name": "Gems & Dragons",
        "Button_Image_Name": "SMG_gemsAndDragons.png"
      },
      {
        "CN_Name": "淘金者",
        "HTML5_Game_ID": "SMG_goldCollector",
        "EN_Name": "Gold Collector ",
        "Button_Image_Name": "SMG_goldCollector.png"
      },
      {
        "CN_Name": "黄金时代",
        "HTML5_Game_ID": "SMG_goldenEra",
        "EN_Name": "Golden Era",
        "Button_Image_Name": "SMG_goldenEra.png"
      },
      {
        "CN_Name": "黄金公主",
        "HTML5_Game_ID": "SMG_goldenPrincess",
        "EN_Name": "Golden Princess",
        "Button_Image_Name": "SMG_goldenPrincess.png"
      },
      {
        "CN_Name": "金色骏马",
        "HTML5_Game_ID": "SMG_goldenStallion",
        "EN_Name": "Golden Stallion",
        "Button_Image_Name": "SMG_goldenStallion.png"
      },
      {
        "CN_Name": "黄金地鼠",
        "HTML5_Game_ID": "SMG_gopherGold",
        "EN_Name": "Gopher Gold",
        "Button_Image_Name": "SMG_gopherGold.png"
      },
      {
        "CN_Name": "万圣节派对",
        "HTML5_Game_ID": "SMG_halloweenies",
        "EN_Name": "Halloweenies",
        "Button_Image_Name": "SMG_halloweenies.png"
      },
      {
        "CN_Name": "快乐假日",
        "HTML5_Game_ID": "SMG_HappyHolidays",
        "EN_Name": "Happy Holidays",
        "Button_Image_Name": "SMG_HappyHolidays.png"
      },
      {
        "CN_Name": "开心娃娃机",
        "HTML5_Game_ID": "SMG_happyMonsterClaw",
        "EN_Name": "Happy Monster Claw",
        "Button_Image_Name": "SMG_happyMonsterClaw.png"
      },
      {
        "CN_Name": "上流社会",
        "HTML5_Game_ID": "SMG_highSociety",
        "EN_Name": "High Society",
        "Button_Image_Name": "SMG_highSociety.png"
      },
      {
        "CN_Name": "圣诞企鹅",
        "HTML5_Game_ID": "SMG_hollyJollyPenguins",
        "EN_Name": "Holly Jolly Penguins",
        "Button_Image_Name": "SMG_hollyJollyPenguins.png"
      },
      {
        "CN_Name": "轩辕帝传",
        "HTML5_Game_ID": "SMG_huangdiTheYellowEmperor",
        "EN_Name": "Huangdi - The Yellow Emperor",
        "Button_Image_Name": "SMG_huangdiTheYellowEmperor.png"
      },
      {
        "CN_Name": "超猎黄金",
        "HTML5_Game_ID": "SMG_hyperGold",
        "EN_Name": "Hyper Gold",
        "Button_Image_Name": "SMG_hyperGold.png"
      },
      {
        "CN_Name": "不朽情缘",
        "HTML5_Game_ID": "SMG_immortalRomance",
        "EN_Name": "Immortal Romance",
        "Button_Image_Name": "SMG_immortalRomance.png"
      },
      {
        "CN_Name": "挖到宝",
        "HTML5_Game_ID": "SMG_incanAdventure",
        "EN_Name": "Incan Adventure",
        "Button_Image_Name": "SMG_incanAdventure.png"
      },
      {
        "CN_Name": "不可思议的气球机",
        "HTML5_Game_ID": "SMG_theIncredibleBalloonMachine",
        "EN_Name": "The Incredible Balloon Machine",
        "Button_Image_Name": "SMG_theIncredibleBalloonMachine.png"
      },
      {
        "CN_Name": "财神 元宝",
        "HTML5_Game_ID": "SMG_ingotsOfCaiShen",
        "EN_Name": "Ingots of Cai Shen",
        "Button_Image_Name": "SMG_ingotsOfCaiShen.png"
      },
      {
        "CN_Name": "丛林吉姆-黄金国",
        "HTML5_Game_ID": "SMG_jungleJimElDorado",
        "EN_Name": "Jungle Jim - El Dorado",
        "Button_Image_Name": "SMG_jungleJimElDorado.png"
      },
      {
        "CN_Name": "丛林吉姆失落的狮身人面像",
        "HTML5_Game_ID": "SMG_jungleJimAndTheLostSphinx",
        "EN_Name": "Jungle Jim and the Lost Sphinx ",
        "Button_Image_Name": "SMG_jungleJimAndTheLostSphinx.png"
      },
      {
        "CN_Name": "加德满都",
        "HTML5_Game_ID": "SMG_kathmandu",
        "EN_Name": "Kathmandu",
        "Button_Image_Name": "SMG_kathmandu.png"
      },
      {
        "CN_Name": "大象之王",
        "HTML5_Game_ID": "SMG_kingTusk",
        "EN_Name": "King Tusk",
        "Button_Image_Name": "SMG_kingTusk.png"
      },
      {
        "CN_Name": "现金之王",
        "HTML5_Game_ID": "SMG_kingsOfCash",
        "EN_Name": "Kings of Cash",
        "Button_Image_Name": "SMG_kingsOfCash.png"
      },
      {
        "CN_Name": "淑女派对",
        "HTML5_Game_ID": "SMG_ladiesNite",
        "EN_Name": "Ladies Nite",
        "Button_Image_Name": "SMG_ladiesNite.png"
      },
      {
        "CN_Name": "萝拉卡芙特之神庙古墓",
        "HTML5_Game_ID": "SMG_laraCroftTemplesAndTombs",
        "EN_Name": "Lara Croft - Temples and Tombs",
        "Button_Image_Name": "SMG_laraCroftTemplesAndTombs.png"
      },
      {
        "CN_Name": "奥兹Oz传奇",
        "HTML5_Game_ID": "SMG_legacyOfOz",
        "EN_Name": "Legacy of Oz",
        "Button_Image_Name": "SMG_legacyOfOz.png"
      },
      {
        "CN_Name": "奔月传说",
        "HTML5_Game_ID": "SMG_LegendOftheMoonLovers",
        "EN_Name": "Legend of the Moon Lovers",
        "Button_Image_Name": "SMG_LegendOftheMoonLovers.png"
      },
      {
        "CN_Name": "富裕人生",
        "HTML5_Game_ID": "SMG_lifeOfRiches",
        "EN_Name": "Life of Riches",
        "Button_Image_Name": "SMG_lifeOfRiches.png"
      },
      {
        "CN_Name": "腰缠万贯",
        "HTML5_Game_ID": "SMG_loaded",
        "EN_Name": "Loaded",
        "Button_Image_Name": "SMG_loaded.png"
      },
      {
        "CN_Name": "龙母财富",
        "HTML5_Game_ID": "SMG_longMuFortunes",
        "EN_Name": "Long Mu Fortunes",
        "Button_Image_Name": "SMG_longMuFortunes.png"
      },
      {
        "CN_Name": "迷失拉斯维加斯",
        "HTML5_Game_ID": "SMG_lostVegas",
        "EN_Name": "Lost Vegas",
        "Button_Image_Name": "SMG_lostVegas.png"
      },
      {
        "CN_Name": "摔角传奇",
        "HTML5_Game_ID": "SMG_luchaLegends",
        "EN_Name": "Lucha Legends",
        "Button_Image_Name": "SMG_luchaLegends.png"
      },
      {
        "CN_Name": "幸运单身族",
        "HTML5_Game_ID": "SMG_luckyBachelors",
        "EN_Name": "Lucky Bachelors",
        "Button_Image_Name": "SMG_luckyBachelors.png"
      },
      {
        "CN_Name": "招财鞭炮",
        "HTML5_Game_ID": "SMG_luckyfirecracker",
        "EN_Name": "Lucky Firecracker",
        "Button_Image_Name": "SMG_luckyfirecracker.png"
      },
      {
        "CN_Name": "幸运锦鲤",
        "HTML5_Game_ID": "SMG_luckyKoi",
        "EN_Name": "Lucky Koi",
        "Button_Image_Name": "SMG_luckyKoi.png"
      },
      {
        "CN_Name": "幸运矮妖精",
        "HTML5_Game_ID": "SMG_luckyLeprechaun",
        "EN_Name": "Lucky Leprechaun",
        "Button_Image_Name": "SMG_luckyLeprechaun.png"
      },
      {
        "CN_Name": "宝贝财神",
        "HTML5_Game_ID": "SMG_luckyLittleGods",
        "EN_Name": "Lucky Little Gods",
        "Button_Image_Name": "SMG_luckyLittleGods.png"
      },
      {
        "CN_Name": "幸运富豪 Hyperspins",
        "HTML5_Game_ID": "SMG_luckyRichesHyperspins",
        "EN_Name": "Lucky Riches Hyperspins",
        "Button_Image_Name": "SMG_luckyRichesHyperspins.png"
      },
      {
        "CN_Name": "幸运双星",
        "HTML5_Game_ID": "SMG_luckyTwins",
        "EN_Name": "Lucky Twins",
        "Button_Image_Name": "SMG_luckyTwins.png"
      },
      {
        "CN_Name": "幸运双星接财神",
        "HTML5_Game_ID": "SMG_luckyTwinsCatcher",
        "EN_Name": "Lucky Twins Catcher",
        "Button_Image_Name": "SMG_luckyTwinsCatcher.png"
      },
      {
        "CN_Name": "幸运双星大奖",
        "HTML5_Game_ID": "SMG_luckyTwinsJackpot",
        "EN_Name": "Lucky Twins Jackpot",
        "Button_Image_Name": "SMG_luckyTwinsJackpot.png"
      },
      {
        "CN_Name": "幸运双星百搭",
        "HTML5_Game_ID": "SMG_luckyTwinsWilds",
        "EN_Name": "Lucky Twins Wilds",
        "Button_Image_Name": "SMG_luckyTwinsWilds.png"
      },
      {
        "CN_Name": "魔力撒哈拉",
        "HTML5_Game_ID": "SMG_magicOfSahara",
        "EN_Name": "Magic of Sahara",
        "Button_Image_Name": "SMG_magicOfSahara.png"
      },
      {
        "CN_Name": "毛伊大乱斗",
        "HTML5_Game_ID": "SMG_mauiMischief",
        "EN_Name": "Maui Mischief",
        "Button_Image_Name": "SMG_mauiMischief.png"
      },
      {
        "CN_Name": "终结侵略者",
        "HTML5_Game_ID": "SMG_maxDamageArcade",
        "EN_Name": "Max Damage and the Alien Attack",
        "Button_Image_Name": "SMG_maxDamageArcade.png"
      },
      {
        "CN_Name": "巨额现金乘数",
        "HTML5_Game_ID": "SMG_megaMoneyMultiplier",
        "EN_Name": "Mega Money Multiplier",
        "Button_Image_Name": "SMG_megaMoneyMultiplier.png"
      },
      {
        "CN_Name": "百万人鱼",
        "HTML5_Game_ID": "SMG_mermaidsMillions",
        "EN_Name": "Mermaids Millions",
        "Button_Image_Name": "SMG_mermaidsMillions.png"
      },
      {
        "CN_Name": "怪物爆炸",
        "HTML5_Game_ID": "SMG_monsterBlast",
        "EN_Name": "Monster Blast",
        "Button_Image_Name": "SMG_monsterBlast.png"
      },
      {
        "CN_Name": "海王星宝藏狂野之海",
        "HTML5_Game_ID": "SMG_neptunesRichesOceanOfWilds",
        "EN_Name": "Neptune's Riches: Ocean of Wilds",
        "Button_Image_Name": "SMG_neptunesRichesOceanOfWilds.png"
      },
      {
        "CN_Name": "奥丁的财宝",
        "HTML5_Game_ID": "SMG_odinsRiches",
        "EN_Name": "Odin’s Riches",
        "Button_Image_Name": "SMG_odinsRiches.png"
      },
      {
        "CN_Name": "鬼狩",
        "HTML5_Game_ID": "SMG_oniHunter",
        "EN_Name": "Oni Hunter",
        "Button_Image_Name": "SMG_oniHunter.png"
      },
      {
        "CN_Name": "鬼狩加強版",
        "HTML5_Game_ID": "SMG_oniHunterPlus",
        "EN_Name": "Oni Hunter Plus",
        "Button_Image_Name": "SMG_oniHunterPlus.png"
      },
      {
        "CN_Name": "有你的校园",
        "HTML5_Game_ID": "SMG_ourDaysA",
        "EN_Name": "Our Days",
        "Button_Image_Name": "SMG_ourDaysA.png"
      },
      {
        "CN_Name": "乒乓巨星",
        "HTML5_Game_ID": "SMG_pingPongStar",
        "EN_Name": "Ping Pong Star",
        "Button_Image_Name": "SMG_pingPongStar.png"
      },
      {
        "CN_Name": "花花公子",
        "HTML5_Game_ID": "SMG_playboy",
        "EN_Name": "Playboy",
        "Button_Image_Name": "SMG_playboy.png"
      },
      {
        "CN_Name": "PLAYBOY 财富",
        "HTML5_Game_ID": "SMG_playboyFortunes",
        "EN_Name": "Playboy Fortunes ",
        "Button_Image_Name": "SMG_playboyFortunes.png"
      },
      {
        "CN_Name": "Playboy黄金",
        "HTML5_Game_ID": "SMG_playboyGold",
        "EN_Name": "Playboy Gold",
        "Button_Image_Name": "SMG_playboyGold.png"
      },
      {
        "CN_Name": "Playboy黄金大奖",
        "HTML5_Game_ID": "SMG_playboyGoldJackpots",
        "EN_Name": "Playboy Gold Jackpots",
        "Button_Image_Name": "SMG_playboyGoldJackpots.png"
      },
      {
        "CN_Name": "完美白金",
        "HTML5_Game_ID": "SMG_purePlatinum",
        "EN_Name": "Pure Platinum",
        "Button_Image_Name": "SMG_purePlatinum.png"
      },
      {
        "CN_Name": "亚历山大女王",
        "HTML5_Game_ID": "SMG_queenofAlexandria",
        "EN_Name": "Queen of Alexandria",
        "Button_Image_Name": "SMG_queenofAlexandria.png"
      },
      {
        "CN_Name": "水晶射线女王",
        "HTML5_Game_ID": "SMG_queenOfTheCrystalRays",
        "EN_Name": "Queen of the Crystal Rays",
        "Button_Image_Name": "SMG_queenOfTheCrystalRays.png"
      },
      {
        "CN_Name": "宝石转轴",
        "HTML5_Game_ID": "SMG_reelGems",
        "EN_Name": "Reel Gems",
        "Button_Image_Name": "SMG_reelGems.png"
      },
      {
        "CN_Name": "宝石转轴豪华版",
        "HTML5_Game_ID": "SMG_reelGemsDeluxe",
        "EN_Name": "Reel Gems Deluxe",
        "Button_Image_Name": "SMG_reelGemsDeluxe.png"
      },
      {
        "CN_Name": "旋转大战",
        "HTML5_Game_ID": "SMG_reelSpinner",
        "EN_Name": "Reel Spinner",
        "Button_Image_Name": "SMG_reelSpinner.png"
      },
      {
        "CN_Name": "真正高手",
        "HTML5_Game_ID": "SMG_ReelTalent",
        "EN_Name": "Reel Talent",
        "Button_Image_Name": "SMG_ReelTalent.png"
      },
      {
        "CN_Name": "转轴雷电",
        "HTML5_Game_ID": "SMG_reelThunder",
        "EN_Name": "Reel Thunder",
        "Button_Image_Name": "SMG_reelThunder.png"
      },
      {
        "CN_Name": "探陵人",
        "HTML5_Game_ID": "SMG_relicSeekers",
        "EN_Name": "Relic Seekers",
        "Button_Image_Name": "SMG_relicSeekers.png"
      },
      {
        "CN_Name": "经典老虎机",
        "HTML5_Game_ID": "SMG_retroReels",
        "EN_Name": "Retro Reels",
        "Button_Image_Name": "SMG_retroReels.png"
      },
      {
        "CN_Name": "复古转轴之炫目钻石",
        "HTML5_Game_ID": "SMG_retroReelsDiamondGlitz",
        "EN_Name": "Retro Reels - Diamond Glitz",
        "Button_Image_Name": "SMG_retroReelsDiamondGlitz.png"
      },
      {
        "CN_Name": "酷热经典",
        "HTML5_Game_ID": "SMG_retroReelsExtremeHeat",
        "EN_Name": "Retro Reels - Extreme Heat",
        "Button_Image_Name": "SMG_retroReelsExtremeHeat.png"
      },
      {
        "CN_Name": "童话转轴 红心皇后",
        "HTML5_Game_ID": "SMG_rhymingReelsHeartsAndTarts",
        "EN_Name": "Rhyming Reels - Hearts & Tarts",
        "Button_Image_Name": "SMG_rhymingReelsHeartsAndTarts.png"
      },
      {
        "CN_Name": "橄榄球明星",
        "HTML5_Game_ID": "SMG_rugbyStar",
        "EN_Name": "Rugby Star",
        "Button_Image_Name": "SMG_rugbyStar.png"
      },
      {
        "CN_Name": "橄榄球明星豪华版",
        "HTML5_Game_ID": "SMG_rugbyStarDeluxe",
        "EN_Name": "Rugby Star Deluxe",
        "Button_Image_Name": "SMG_rugbyStarDeluxe.png"
      },
      {
        "CN_Name": "圣诞老人的百搭摩托车",
        "HTML5_Game_ID": "SMG_santasWildRide",
        "EN_Name": "Santa's Wild Ride",
        "Button_Image_Name": "SMG_santasWildRide.png"
      },
      {
        "CN_Name": "小气财神",
        "HTML5_Game_ID": "SMG_scrooge",
        "EN_Name": "Scrooge",
        "Button_Image_Name": "SMG_scrooge.png"
      },
      {
        "CN_Name": "暗恋",
        "HTML5_Game_ID": "SMG_secretAdmirer",
        "EN_Name": "Secret Admirer",
        "Button_Image_Name": "SMG_secretAdmirer.png"
      },
      {
        "CN_Name": "秘密爱慕者",
        "HTML5_Game_ID": "SMG_secretRomance",
        "EN_Name": "Secret Romance",
        "Button_Image_Name": "SMG_secretRomance.png"
      },
      {
        "CN_Name": "塞伦盖蒂黄金",
        "HTML5_Game_ID": "SMG_serengetiGold",
        "EN_Name": "Serengeti Gold",
        "Button_Image_Name": "SMG_serengetiGold.png"
      },
      {
        "CN_Name": "三叶草福尔摩斯 Megaways",
        "HTML5_Game_ID": "SMG_shamrockHolmes",
        "EN_Name": "Shamrock Holmes Megaways",
        "Button_Image_Name": "SMG_shamrockHolmes.png"
      },
      {
        "CN_Name": "时界门之将军",
        "HTML5_Game_ID": "SMG_shogunofTime",
        "EN_Name": "Shogun of Time",
        "Button_Image_Name": "SMG_shogunofTime.png"
      },
      {
        "CN_Name": "对决沙龙",
        "HTML5_Game_ID": "SMG_showdownSaloon",
        "EN_Name": "Showdown Saloon",
        "Button_Image_Name": "SMG_showdownSaloon.png"
      },
      {
        "CN_Name": "银狼",
        "HTML5_Game_ID": "SMG_silverFang",
        "EN_Name": "Silver Fang",
        "Button_Image_Name": "SMG_silverFang.png"
      },
      {
        "CN_Name": "银色之海",
        "HTML5_Game_ID": "SMG_silverSeas",
        "EN_Name": "Silver Seas",
        "Button_Image_Name": "SMG_silverSeas.png"
      },
      {
        "CN_Name": "银背乘数山",
        "HTML5_Game_ID": "SMG_silverbackMultiplierMountain",
        "EN_Name": "Silverback: Multiplier Mountain",
        "Button_Image_Name": "SMG_silverbackMultiplierMountain.png"
      },
      {
        "CN_Name": "足球前锋",
        "HTML5_Game_ID": "SMG_soccerStriker",
        "EN_Name": "Soccer Striker",
        "Button_Image_Name": "SMG_soccerStriker.png"
      },
      {
        "CN_Name": "太阳系百搭符号",
        "HTML5_Game_ID": "SMG_solarWilds",
        "EN_Name": "Solar Wilds",
        "Button_Image_Name": "SMG_solarWilds.png"
      },
      {
        "CN_Name": "放浪假日",
        "HTML5_Game_ID": "SMG_springBreak",
        "EN_Name": "Spring Break",
        "Button_Image_Name": "SMG_springBreak.png"
      },
      {
        "CN_Name": "星光之吻",
        "HTML5_Game_ID": "SMG_starlightKiss",
        "EN_Name": "Starlight Kiss",
        "Button_Image_Name": "SMG_starlightKiss.png"
      },
      {
        "CN_Name": "纯银",
        "HTML5_Game_ID": "SMG_sterlingSilver",
        "EN_Name": "Sterling Silver",
        "Button_Image_Name": "SMG_sterlingSilver.png"
      },
      {
        "CN_Name": "暑假时光",
        "HTML5_Game_ID": "SMG_summertime",
        "EN_Name": "Summertime",
        "Button_Image_Name": "SMG_summertime.png"
      },
      {
        "CN_Name": "太阳征程",
        "HTML5_Game_ID": "SMG_sunTide",
        "EN_Name": "SunTide",
        "Button_Image_Name": "SMG_sunTide.png"
      },
      {
        "CN_Name": "必胜",
        "HTML5_Game_ID": "SMG_sureWin",
        "EN_Name": "Sure Win",
        "Button_Image_Name": "SMG_sureWin.png"
      },
      {
        "CN_Name": "狩猎游戏",
        "HTML5_Game_ID": "SMG_tallyHo",
        "EN_Name": "Tally Ho",
        "Button_Image_Name": "SMG_tallyHo.png"
      },
      {
        "CN_Name": "奇妙马戏团",
        "HTML5_Game_ID": "SMG_theTwistedCircus",
        "EN_Name": "The Twisted Circus",
        "Button_Image_Name": "SMG_theTwistedCircus.png"
      },
      {
        "CN_Name": "雷霆万钧",
        "HTML5_Game_ID": "SMG_thunderstruck",
        "EN_Name": "Thunderstruck",
        "Button_Image_Name": "SMG_thunderstruck.png"
      },
      {
        "CN_Name": "雷霆万钧2",
        "HTML5_Game_ID": "SMG_thunderstruck2",
        "EN_Name": "Thunderstruck II",
        "Button_Image_Name": "SMG_thunderstruck2.png"
      },
      {
        "CN_Name": "雷霆万钧之百搭闪电",
        "HTML5_Game_ID": "SMG_thunderstruckWildLightning",
        "EN_Name": "Thunderstruck Wild Lightning",
        "Button_Image_Name": "SMG_thunderstruckWildLightning.png"
      },
      {
        "CN_Name": "虎眼",
        "HTML5_Game_ID": "SMG_tigersEye",
        "EN_Name": "Tiger's Eye",
        "Button_Image_Name": "SMG_tigersEye.png"
      },
      {
        "CN_Name": "提基奖励",
        "HTML5_Game_ID": "SMG_tikiReward",
        "EN_Name": "Tiki Reward",
        "Button_Image_Name": "SMG_tikiReward.png"
      },
      {
        "CN_Name": "太阳神忒伊亚",
        "HTML5_Game_ID": "SMG_titansOfTheSunTheia",
        "EN_Name": "Titans of the Sun - Theia",
        "Button_Image_Name": "SMG_titansOfTheSunTheia.png"
      },
      {
        "CN_Name": "逃宝街机游戏",
        "HTML5_Game_ID": "SMG_treasureDash",
        "EN_Name": "Treasure Dash",
        "Button_Image_Name": "SMG_treasureDash.png"
      },
      {
        "CN_Name": "財寶宮殿",
        "HTML5_Game_ID": "SMG_treasurePalace",
        "EN_Name": "Treasure Palace",
        "Button_Image_Name": "SMG_treasurePalace.png"
      },
      {
        "CN_Name": "海底宝城",
        "HTML5_Game_ID": "SMG_treasuresOfLionCity",
        "EN_Name": "Treasures of Lion City",
        "Button_Image_Name": "SMG_treasuresOfLionCity.png"
      },
      {
        "CN_Name": "野生大熊猫",
        "HTML5_Game_ID": "SMG_untamedGiantPanda",
        "EN_Name": "Untamed - Giant Panda",
        "Button_Image_Name": "SMG_untamedGiantPanda.png"
      },
      {
        "CN_Name": "囧囧熊猫",
        "HTML5_Game_ID": "SMG_wackyPanda",
        "EN_Name": "Wacky Panda",
        "Button_Image_Name": "SMG_wackyPanda.png"
      },
      {
        "CN_Name": "歹徒通缉令",
        "HTML5_Game_ID": "SMG_wantedOutlaws",
        "EN_Name": "Wanted Outlaws",
        "Button_Image_Name": "SMG_wantedOutlaws.png"
      },
      {
        "CN_Name": "万达福娃捕鱼",
        "HTML5_Game_ID": "SFG_WDFuWaFishing",
        "EN_Name": "WD FuWa Fishing",
        "Button_Image_Name": "SFG_WDFuWaFishing.png"
      },
      {
        "CN_Name": "万达爆金捕鱼",
        "HTML5_Game_ID": "SFG_WDGoldBlastFishing",
        "EN_Name": "WD Gold Blast Fishing",
        "Button_Image_Name": "SFG_WDGoldBlastFishing.png"
      },
      {
        "CN_Name": "西域黄金",
        "HTML5_Game_ID": "SMG_westernGold",
        "EN_Name": "Western Gold",
        "Button_Image_Name": "SMG_westernGold.png"
      },
      {
        "CN_Name": "滑稽猫头鹰",
        "HTML5_Game_ID": "SMG_whatAHoot",
        "EN_Name": "What A Hoot",
        "Button_Image_Name": "SMG_whatAHoot.png"
      },
      {
        "CN_Name": "黑暗故事神秘深红",
        "HTML5_Game_ID": "SMG_wickedTalesDarkRed",
        "EN_Name": "Wicked Tales: Dark Red",
        "Button_Image_Name": "SMG_wickedTalesDarkRed.png"
      },
      {
        "CN_Name": "荒野垂钓",
        "HTML5_Game_ID": "SMG_wildCatchNew",
        "EN_Name": "Wild Catch (New)",
        "Button_Image_Name": "SMG_wildCatchNew.png"
      },
      {
        "CN_Name": "东方珍兽",
        "HTML5_Game_ID": "SMG_wildOrient",
        "EN_Name": "Wild Orient",
        "Button_Image_Name": "SMG_wildOrient.png"
      },
      {
        "CN_Name": "百搭圣甲虫",
        "HTML5_Game_ID": "SMG_wildScarabs",
        "EN_Name": "Wild Scarabs",
        "Button_Image_Name": "SMG_wildScarabs.png"
      },
      {
        "CN_Name": "开心点心",
        "HTML5_Game_ID": "SMG_winSumDimSum",
        "EN_Name": "Win Sum Dim Sum",
        "Button_Image_Name": "SMG_winSumDimSum.png"
      },
      {
        "CN_Name": "王牌二八杠",
        "HTML5_Game_ID": "SFG_WP28Mahjong",
        "EN_Name": "WP 28 Mahjong",
        "Button_Image_Name": "SFG_WP28Mahjong.png"
      },
      {
        "CN_Name": "王牌五张",
        "HTML5_Game_ID": "SFG_WP5PK",
        "EN_Name": "WP 5PK",
        "Button_Image_Name": "SFG_WP5PK.png"
      },
      {
        "CN_Name": "王牌抢庄牛牛",
        "HTML5_Game_ID": "SFG_WPBankerNiuNiu",
        "EN_Name": "WP Banker Niu Niu",
        "Button_Image_Name": "SFG_WPBankerNiuNiu.png"
      },
      {
        "CN_Name": "王牌抢庄牛牛(看3张)",
        "HTML5_Game_ID": "SFG_WPBankerNiuNiu_3cards",
        "EN_Name": "WP Banker Niu Niu (3 Open cards)",
        "Button_Image_Name": "SFG_WPBankerNiuNiu_3cards.png"
      },
      {
        "CN_Name": "王牌抢庄牛牛(看4张)",
        "HTML5_Game_ID": "SFG_WPBankerNiuNiu_4cards",
        "EN_Name": "WP Banker Niu Niu (4 Open cards)",
        "Button_Image_Name": "SFG_WPBankerNiuNiu_4cards.png"
      },
      {
        "CN_Name": "王牌奖金德州",
        "HTML5_Game_ID": "SFG_WPBonusTexas",
        "EN_Name": "WP Bonus Texas",
        "Button_Image_Name": "SFG_WPBonusTexas.png"
      },
      {
        "CN_Name": "王牌财神水果机 (街机版)",
        "HTML5_Game_ID": "SFG_WPCaiShenFruitMario",
        "EN_Name": "WP CaiShen Fruit Mario (Arcade Edition)",
        "Button_Image_Name": "SFG_WPCaiShenFruitMario.png"
      },
      {
        "CN_Name": "王牌楚汉德州",
        "HTML5_Game_ID": "SFG_WPChuhanTexas",
        "EN_Name": "WP Chuhan Texas",
        "Button_Image_Name": "SFG_WPChuhanTexas.png"
      },
      {
        "CN_Name": "王牌斗地主",
        "HTML5_Game_ID": "SFG_Doudizhu",
        "EN_Name": "WP Doudizhu",
        "Button_Image_Name": "SFG_Doudizhu.png"
      },
      {
        "CN_Name": "王牌森林舞会(JP)",
        "HTML5_Game_ID": "SFG_WPForestPartyJP",
        "EN_Name": "WP Forest Party (JP)",
        "Button_Image_Name": "SFG_WPForestPartyJP.png"
      },
      {
        "CN_Name": "王牌炸金花",
        "HTML5_Game_ID": "SFG_GoldenFlower",
        "EN_Name": "WP Golden Flower",
        "Button_Image_Name": "SFG_GoldenFlower.png"
      },
      {
        "CN_Name": "王牌赛金猪",
        "HTML5_Game_ID": "SFG_WPGoldenPigRace",
        "EN_Name": "WP Golden Pig Race",
        "Button_Image_Name": "SFG_WPGoldenPigRace.png"
      },
      {
        "CN_Name": "王牌金银鲨",
        "HTML5_Game_ID": "SFG_WPGoldenShark",
        "EN_Name": "WP Golden Shark",
        "Button_Image_Name": "SFG_WPGoldenShark.png"
      },
      {
        "CN_Name": "王牌炸金花 (极速版)",
        "HTML5_Game_ID": "SFG_WPInstantGoldenFlower",
        "EN_Name": "WP Instant Golden Flower",
        "Button_Image_Name": "SFG_WPInstantGoldenFlower.png"
      },
      {
        "CN_Name": "王牌麻将(二人街机版)",
        "HTML5_Game_ID": "SFG_WPMahjong_2P",
        "EN_Name": "WP Mahjong (2P Arcade)",
        "Button_Image_Name": "SFG_WPMahjong_2P.png"
      },
      {
        "CN_Name": "王牌百人牛牛",
        "HTML5_Game_ID": "SFG_WP100NiuNiu",
        "EN_Name": "WP Niu Niu 100+ Players",
        "Button_Image_Name": "SFG_WP100NiuNiu.png"
      },
      {
        "CN_Name": "王牌客栈",
        "HTML5_Game_ID": "SFG_WPTavern",
        "EN_Name": "WP Tavern",
        "Button_Image_Name": "SFG_WPTavern.png"
      }
    ]
  },
    {
    "type": 4,
    "game_id": 405,
    "game_api": "MW",
    "name": "MW老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/mw.png",
    "icon": "mw",
    "game_list_json": "MW_game_list",
    "game_list_img_path": "/new/slot_res/mw/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：活动专区、千炮捕鱼、水浒传、蜜糖甜心、百家乐、五路财神...",
    "labels": "#老虎机#活动专区#千炮捕鱼#水浒传#蜜糖甜心",
    gamelist:[
      {
        "CN_Name": "活动专区",
        "HTML5_Game_ID": 10001,
        "EN_Name": "Events",
        "Button_Image_Name": "10001.png"
      },
      {
        "CN_Name": "捕鱼大满贯",
        "HTML5_Game_ID": 1050,
        "EN_Name": "Megawin Fishing",
        "Button_Image_Name": "1050.png"
      },
      {
        "CN_Name": "千炮捕鱼(H5)",
        "HTML5_Game_ID": 1051,
        "EN_Name": "Fishing Hunter(H5)",
        "Button_Image_Name": "1051.png"
      },
      {
        "CN_Name": "森林舞会(H5)",
        "HTML5_Game_ID": 1041,
        "EN_Name": "Forest Party(H5)",
        "Button_Image_Name": "1041.png"
      },
      {
        "CN_Name": "森林舞会2",
        "HTML5_Game_ID": 1040,
        "EN_Name": "Forest Party2",
        "Button_Image_Name": "1040.png"
      },
      {
        "CN_Name": "水浒传(H5)",
        "HTML5_Game_ID": 1110,
        "EN_Name": "Water Margin(H5)",
        "Button_Image_Name": "1110.png"
      },
      {
        "CN_Name": "水浒英雄(H5)",
        "HTML5_Game_ID": 1112,
        "EN_Name": "(H5)Outlawed Heroes",
        "Button_Image_Name": "1112.png"
      },
      {
        "CN_Name": "弹珠2",
        "HTML5_Game_ID": 6071,
        "EN_Name": "Plinko2",
        "Button_Image_Name": "6071.png"
      },
      {
        "CN_Name": "海神波塞顿",
        "HTML5_Game_ID": 6066,
        "EN_Name": "Poseidon",
        "Button_Image_Name": "6066.png"
      },
      {
        "CN_Name": "麻将碰碰2+",
        "HTML5_Game_ID": 6070,
        "EN_Name": "Mahjong Hit 2+",
        "Button_Image_Name": "6070.png"
      },
      {
        "CN_Name": "疯狂麻将",
        "HTML5_Game_ID": 6069,
        "EN_Name": "Crazy Mahjong",
        "Button_Image_Name": "6069.png"
      },
      {
        "CN_Name": "松果弹珠2",
        "HTML5_Game_ID": 6067,
        "EN_Name": "PINE OF PLINKO 2",
        "Button_Image_Name": "6067.png"
      },
      {
        "CN_Name": "多福多财",
        "HTML5_Game_ID": 6068,
        "EN_Name": "DuoFuDuoCai",
        "Button_Image_Name": "6068.png"
      },
      {
        "CN_Name": "疯狂小羊哥",
        "HTML5_Game_ID": 6065,
        "EN_Name": "Crazy Sheep",
        "Button_Image_Name": "6065.png"
      },
      {
        "CN_Name": "开心农场",
        "HTML5_Game_ID": 6063,
        "EN_Name": "Happy Farm",
        "Button_Image_Name": "6063.png"
      },
      {
        "CN_Name": "满贯王牌",
        "HTML5_Game_ID": 6064,
        "EN_Name": "MEGA ACE",
        "Button_Image_Name": "6064.png"
      },
      {
        "CN_Name": "麻将碰碰+",
        "HTML5_Game_ID": 6062,
        "EN_Name": "Mahjong Hit +",
        "Button_Image_Name": "6062.png"
      },
      {
        "CN_Name": "恐龙扛狼",
        "HTML5_Game_ID": 6061,
        "EN_Name": "Dragon Arena",
        "Button_Image_Name": "6061.png"
      },
      {
        "CN_Name": "弹珠",
        "HTML5_Game_ID": 6058,
        "EN_Name": "Plinko",
        "Button_Image_Name": "6058.png"
      },
      {
        "CN_Name": "火箭",
        "HTML5_Game_ID": 6805,
        "EN_Name": "SpaceX",
        "Button_Image_Name": "6805.png"
      },
      {
        "CN_Name": "麻将碰碰2",
        "HTML5_Game_ID": 6060,
        "EN_Name": "Mahjong Hit 2",
        "Button_Image_Name": "6060.png"
      },
      {
        "CN_Name": "麻将碰碰",
        "HTML5_Game_ID": 6059,
        "EN_Name": "Mahjong Hit",
        "Button_Image_Name": "6059.png"
      },
      {
        "CN_Name": "赏金洛基",
        "HTML5_Game_ID": 6057,
        "EN_Name": "Bounty Loki",
        "Button_Image_Name": "6057.png"
      },
      {
        "CN_Name": "开心消消乐",
        "HTML5_Game_ID": 6056,
        "EN_Name": "Happy Hit",
        "Button_Image_Name": "6056.png"
      },
      {
        "CN_Name": "西游记",
        "HTML5_Game_ID": 6003,
        "EN_Name": "JourneyToTheWest",
        "Button_Image_Name": "6003.png"
      },
      {
        "CN_Name": "福袋连连-龙",
        "HTML5_Game_ID": 6055,
        "EN_Name": "Fu Dai Lian Lian-Dragon",
        "Button_Image_Name": "6055.png"
      },
      {
        "CN_Name": "福袋连连-熊猫",
        "HTML5_Game_ID": 6054,
        "EN_Name": "Fu Dai Lian Lian-Panda",
        "Button_Image_Name": "6054.png"
      },
      {
        "CN_Name": "喜茶消消乐2",
        "HTML5_Game_ID": 6053,
        "EN_Name": "XiBoBa Hit2",
        "Button_Image_Name": "6053.png"
      },
      {
        "CN_Name": "喜茶消消乐",
        "HTML5_Game_ID": 6052,
        "EN_Name": "XiBoBa Hit",
        "Button_Image_Name": "6052.png"
      },
      {
        "CN_Name": "喜茶",
        "HTML5_Game_ID": 6051,
        "EN_Name": "XiBoBA",
        "Button_Image_Name": "6051.png"
      },
      {
        "CN_Name": "百人摸珠子",
        "HTML5_Game_ID": 6804,
        "EN_Name": "Multiplayer Pick Up",
        "Button_Image_Name": "6804.png"
      },
      {
        "CN_Name": "摸珠子",
        "HTML5_Game_ID": 6050,
        "EN_Name": "Pick up",
        "Button_Image_Name": "6050.png"
      },
      {
        "CN_Name": "逆天问道-斗佛",
        "HTML5_Game_ID": 6047,
        "EN_Name": "Destiny-Monk",
        "Button_Image_Name": "6047.png"
      },
      {
        "CN_Name": "逆天问道-天音",
        "HTML5_Game_ID": 6046,
        "EN_Name": "Destiny-Healer",
        "Button_Image_Name": "6046.png"
      },
      {
        "CN_Name": "逆天问道-渔湘",
        "HTML5_Game_ID": 6045,
        "EN_Name": "Destiny-Archer",
        "Button_Image_Name": "6045.png"
      },
      {
        "CN_Name": "逆天问道-法魂",
        "HTML5_Game_ID": 6044,
        "EN_Name": "Destiny-Mage",
        "Button_Image_Name": "6044.png"
      },
      {
        "CN_Name": "逆天问道-奕剑",
        "HTML5_Game_ID": 6042,
        "EN_Name": "Destiny-Warrior",
        "Button_Image_Name": "6042.png"
      },
      {
        "CN_Name": "比倍王-777",
        "HTML5_Game_ID": 6043,
        "EN_Name": "King-777",
        "Button_Image_Name": "6043.png"
      },
      {
        "CN_Name": "尊爵会馆",
        "HTML5_Game_ID": 6409,
        "EN_Name": "VIP Club",
        "Button_Image_Name": "6409.png"
      },
      {
        "CN_Name": "淘金热",
        "HTML5_Game_ID": 6041,
        "EN_Name": "Gold Rush",
        "Button_Image_Name": "6041.png"
      },
      {
        "CN_Name": "丛林秘宝",
        "HTML5_Game_ID": 6036,
        "EN_Name": "Jungle Gold",
        "Button_Image_Name": "6036.png"
      },
      {
        "CN_Name": "森林舞会3",
        "HTML5_Game_ID": 6803,
        "EN_Name": "Forest Party3",
        "Button_Image_Name": "6803.png"
      },
      {
        "CN_Name": "龙虎斗",
        "HTML5_Game_ID": 1163,
        "EN_Name": "Dragon&Tiger",
        "Button_Image_Name": "1163.png"
      },
      {
        "CN_Name": "金吉-牡丹齐放",
        "HTML5_Game_ID": 6030,
        "EN_Name": "G-BloomingRiches",
        "Button_Image_Name": "6030.png"
      },
      {
        "CN_Name": "金吉-锣鼓喧天",
        "HTML5_Game_ID": 6029,
        "EN_Name": "G-GoldenDrum",
        "Button_Image_Name": "6029.png"
      },
      {
        "CN_Name": "金吉-彩钻连连",
        "HTML5_Game_ID": 6028,
        "EN_Name": "G-DazzlingDiamond",
        "Button_Image_Name": "6028.png"
      },
      {
        "CN_Name": "金吉-无尽宝藏",
        "HTML5_Game_ID": 6027,
        "EN_Name": "G-InfiniteTreasure",
        "Button_Image_Name": "6027.png"
      },
      {
        "CN_Name": "金吉-财源滚滚",
        "HTML5_Game_ID": 6026,
        "EN_Name": "G-RollingRiches",
        "Button_Image_Name": "6026.png"
      },
      {
        "CN_Name": "闪电-金蟾蜍",
        "HTML5_Game_ID": 6035,
        "EN_Name": "L-GoldenToad",
        "Button_Image_Name": "6035.png"
      },
      {
        "CN_Name": "闪电-帝王宝藏",
        "HTML5_Game_ID": 6034,
        "EN_Name": "L-EmperorTreasure",
        "Button_Image_Name": "6034.png"
      },
      {
        "CN_Name": "闪电-财富之眼",
        "HTML5_Game_ID": 6033,
        "EN_Name": "L-EyesOfFortune",
        "Button_Image_Name": "6033.png"
      },
      {
        "CN_Name": "闪电-龙之宝藏",
        "HTML5_Game_ID": 6032,
        "EN_Name": "L-DragonRiches",
        "Button_Image_Name": "6032.png"
      },
      {
        "CN_Name": "鱼跃龙门",
        "HTML5_Game_ID": 6019,
        "EN_Name": "LuckyFish",
        "Button_Image_Name": "6019.png"
      },
      {
        "CN_Name": "财-富贵牡丹(H5)",
        "HTML5_Game_ID": 6017,
        "EN_Name": "C-88Flower(H5)",
        "Button_Image_Name": "6017.png"
      },
      {
        "CN_Name": "财-锣鼓迎富(H5)",
        "HTML5_Game_ID": 6016,
        "EN_Name": "C-88Drum(H5)",
        "Button_Image_Name": "6016.png"
      },
      {
        "CN_Name": "财-永恒彩钻(H5)",
        "HTML5_Game_ID": 6015,
        "EN_Name": "C-88Diamond(H5)",
        "Button_Image_Name": "6015.png"
      },
      {
        "CN_Name": "财-传奇选择(H5)",
        "HTML5_Game_ID": 6014,
        "EN_Name": "C-88Treasures(H5)",
        "Button_Image_Name": "6014.png"
      },
      {
        "CN_Name": "财-财源广进(H5)",
        "HTML5_Game_ID": 6013,
        "EN_Name": "C-88Riches(H5)",
        "Button_Image_Name": "6013.png"
      },
      {
        "CN_Name": "富-富贵牡丹(H5)",
        "HTML5_Game_ID": 6011,
        "EN_Name": "F-88Flower(H5)",
        "Button_Image_Name": "6011.png"
      },
      {
        "CN_Name": "富-锣鼓迎富(H5)",
        "HTML5_Game_ID": 6010,
        "EN_Name": "F-88Drum(H5)",
        "Button_Image_Name": "6010.png"
      },
      {
        "CN_Name": "富-永恒彩钻(H5)",
        "HTML5_Game_ID": 6009,
        "EN_Name": "F-88Diamond(H5)",
        "Button_Image_Name": "6009.png"
      },
      {
        "CN_Name": "富-传奇选择(H5)",
        "HTML5_Game_ID": 6008,
        "EN_Name": "F-88Treasures(H5)",
        "Button_Image_Name": "6008.png"
      },
      {
        "CN_Name": "富-财源广进(H5)",
        "HTML5_Game_ID": 6007,
        "EN_Name": "F-88Riches(H5)",
        "Button_Image_Name": "6007.png"
      },
      {
        "CN_Name": "吉祥如意(H5)",
        "HTML5_Game_ID": 6018,
        "EN_Name": "JiXiangRuYi(H5)",
        "Button_Image_Name": "6018.png"
      },
      {
        "CN_Name": "发大财(H5)",
        "HTML5_Game_ID": 6004,
        "EN_Name": "FA DA CAI(H5)",
        "Button_Image_Name": "6004.png"
      },
      {
        "CN_Name": "金运熊猫(H5)",
        "HTML5_Game_ID": 6012,
        "EN_Name": "Gold Panda(H5)",
        "Button_Image_Name": "6012.png"
      },
      {
        "CN_Name": "招财猫(H5)",
        "HTML5_Game_ID": 6005,
        "EN_Name": "MANEKI NEKO(H5)",
        "Button_Image_Name": "6005.png"
      },
      {
        "CN_Name": "鱼鱼鱼(H5)",
        "HTML5_Game_ID": 6002,
        "EN_Name": "Triple Fish(H5)",
        "Button_Image_Name": "6002.png"
      },
      {
        "CN_Name": "金鲨银鲨(H5)",
        "HTML5_Game_ID": 1145,
        "EN_Name": "Golden Shark(H5)",
        "Button_Image_Name": "1145.png"
      },
      {
        "CN_Name": "蜜糖甜心(H5)",
        "HTML5_Game_ID": 1149,
        "EN_Name": "Honey Bakery(H5)",
        "Button_Image_Name": "1149.png"
      },
      {
        "CN_Name": "五路财神(H5)",
        "HTML5_Game_ID": 1153,
        "EN_Name": "5 CaiShen(H5)",
        "Button_Image_Name": "1153.png"
      },
      {
        "CN_Name": "明星97(H5)",
        "HTML5_Game_ID": 1117,
        "EN_Name": "Idol 97(H5)",
        "Button_Image_Name": "1117.png"
      },
      {
        "CN_Name": "五龙争霸(H5)",
        "HTML5_Game_ID": 1111,
        "EN_Name": "5 Dragons(H5)",
        "Button_Image_Name": "1111.png"
      },
      {
        "CN_Name": "洪福齐天(H5)",
        "HTML5_Game_ID": 1120,
        "EN_Name": "Big Fortune(H5)",
        "Button_Image_Name": "1120.png"
      },
      {
        "CN_Name": "天龙虎地(H5)",
        "HTML5_Game_ID": 1114,
        "EN_Name": "Heaven & Earth(H5)",
        "Button_Image_Name": "1114.png"
      },
      {
        "CN_Name": "舞狮报喜(H5)",
        "HTML5_Game_ID": 1144,
        "EN_Name": "Lion Dance(H5)",
        "Button_Image_Name": "1144.png"
      },
      {
        "CN_Name": "百乐牛牛(H5)",
        "HTML5_Game_ID": 1139,
        "EN_Name": "Taurus(H5)",
        "Button_Image_Name": "1139.png"
      },
      {
        "CN_Name": "经典水果机(H5)",
        "HTML5_Game_ID": 1021,
        "EN_Name": "Fruit Trail(H5)",
        "Button_Image_Name": "1021.png"
      },
      {
        "CN_Name": "超级斗地主(H5)",
        "HTML5_Game_ID": 1023,
        "EN_Name": "Super Doudizhu(H5)",
        "Button_Image_Name": "1023.png"
      },
      {
        "CN_Name": "皇家轮盘(H5)",
        "HTML5_Game_ID": 1138,
        "EN_Name": "Royal Roulette(H5)",
        "Button_Image_Name": "1138.png"
      },
      {
        "CN_Name": "好运5扑克(H5)",
        "HTML5_Game_ID": 1022,
        "EN_Name": "Lucky 5(H5)",
        "Button_Image_Name": "1022.png"
      },
      {
        "CN_Name": "双龙抢珠(H5)",
        "HTML5_Game_ID": 1143,
        "EN_Name": "Double Dragons(H5)",
        "Button_Image_Name": "1143.png"
      },
      {
        "CN_Name": "黄金777(H5)",
        "HTML5_Game_ID": 1115,
        "EN_Name": "Golden 777(H5)",
        "Button_Image_Name": "1115.png"
      },
      {
        "CN_Name": "太极(H5)",
        "HTML5_Game_ID": 1140,
        "EN_Name": "TaiChi(H5)",
        "Button_Image_Name": "1140.png"
      },
      {
        "CN_Name": "百家乐(H5)",
        "HTML5_Game_ID": 1141,
        "EN_Name": "Baccarat-Super 6(H5)",
        "Button_Image_Name": "1141.png"
      }
    ]
  },
    {
      "type": 4,
      "game_id": 401,
      "game_api": "PT",
      "name": "APT老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/pt.png",
      "icon": "logo-pt",
      "game_list_json": "PT_game_list",
      "game_list_img_path": "/new/slot_res/pt/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：丛林巨兽、猿人传奇、龙之战士、烈焰砖石、亚洲幻想...",
      "labels": "#老虎机#丛林巨兽#猿人传奇#龙之战士#烈焰砖石",
      gamelist: [
        {
          "CN_Name": "来自外太空的鸽子",
          "EN_Name": "Pigeons from Space",
          "HTML5_Game_ID": "gpas_pigeonfs_pop",
          "Button_Image_Name": "gpas_pigeonfs_pop.jpg"
        },
        {
          "CN_Name": "绝对长毛象强力累积奖金",
          "EN_Name": "Absolutely Mammoth PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_amammothpp_pop",
          "Button_Image_Name": "gpas_amammothpp_pop.jpg"
        },
        {
          "CN_Name": "极限热辣宝石强力累计奖金",
          "EN_Name": "Hot Gems Xtreme PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_hgextremepp_pop",
          "Button_Image_Name": "gpas_hgextremepp_pop.jpg"
        },
        {
          "CN_Name": "樱花财富",
          "EN_Name": "Sakura Fortune",
          "HTML5_Game_ID": "gpas_sfortune_pop",
          "Button_Image_Name": "gpas_sfortune_pop.jpg"
        },
        {
          "CN_Name": "奖金火车土匪",
          "EN_Name": "Bonus Train Bandits",
          "HTML5_Game_ID": "gpas_btbandit_pop",
          "Button_Image_Name": "gpas_btbandit_pop.jpg"
        },
        {
          "CN_Name": "罗马之盾",
          "EN_Name": "Shields of Rome",
          "HTML5_Game_ID": "gpas_sorome_pop",
          "Button_Image_Name": "gpas_sorome_pop.jpg"
        },
        {
          "CN_Name": "伟大的表演",
          "EN_Name": "The Great Reveal",
          "HTML5_Game_ID": "gpas_gtreveal_pop",
          "Button_Image_Name": "gpas_gtreveal_pop.jpg"
        },
        {
          "CN_Name": "星星闪耀",
          "EN_Name": "Stars Ablaze",
          "HTML5_Game_ID": "gpas_starsablaze_pop",
          "Button_Image_Name": "gpas_starsablaze_pop.jpg"
        },
        {
          "CN_Name": "发财财神",
          "EN_Name": "Fat Choy Choy Sun",
          "HTML5_Game_ID": "gpas_fatchoy_pop",
          "Button_Image_Name": "gpas_fatchoy_pop.jpg"
        },
        {
          "CN_Name": "暗黑吸血鬼公主",
          "EN_Name": "Vampire Princess of Darkness",
          "HTML5_Game_ID": "gpas_vampirep_pop",
          "Button_Image_Name": "gpas_vampirep_pop.jpg"
        },
        {
          "CN_Name": "毁灭拳手",
          "EN_Name": "Eliminators",
          "HTML5_Game_ID": "gpas_eliminators_pop",
          "Button_Image_Name": "gpas_eliminators_pop.jpg"
        },
        {
          "CN_Name": "南瓜富矿",
          "EN_Name": "Pumpkin Bonanza",
          "HTML5_Game_ID": "gpas_pumpkinb_pop",
          "Button_Image_Name": "gpas_pumpkinb_pop.jpg"
        },
        {
          "CN_Name": "自拍狂人",
          "EN_Name": "Selfie Elfie",
          "HTML5_Game_ID": "gpas_selfelf_pop",
          "Button_Image_Name": "gpas_selfelf_pop.jpg"
        },
        {
          "CN_Name": "炸弹",
          "EN_Name": "Bombs",
          "HTML5_Game_ID": "gpas_bomaway_pop",
          "Button_Image_Name": "gpas_bomaway_pop.jpg"
        },
        {
          "CN_Name": "赌场之魅",
          "EN_Name": "Casino Charms",
          "HTML5_Game_ID": "gpas_cascharms_pop",
          "Button_Image_Name": "gpas_cascharms_pop.jpg"
        },
        {
          "CN_Name": "星际流亡者",
          "EN_Name": "Starmada Exiles",
          "HTML5_Game_ID": "gpas_sexiles_pop",
          "Button_Image_Name": "gpas_sexiles_pop.jpg"
        },
        {
          "CN_Name": "级联洞穴",
          "EN_Name": "Cascading Cave",
          "HTML5_Game_ID": "gpas_ccave_pop",
          "Button_Image_Name": "gpas_ccave_pop.jpg"
        },
        {
          "CN_Name": "顶级图腾",
          "EN_Name": "Tip Top Totems",
          "HTML5_Game_ID": "gpas_tttotems_pop",
          "Button_Image_Name": "gpas_tttotems_pop.jpg"
        },
        {
          "CN_Name": "爆裂海盗",
          "EN_Name": "Buccaneer Blast",
          "HTML5_Game_ID": "gpas_pblast_pop",
          "Button_Image_Name": "gpas_pblast_pop.jpg"
        },
        {
          "CN_Name": "海南冰",
          "EN_Name": "Hainan Ice",
          "HTML5_Game_ID": "gpas_hice_pop",
          "Button_Image_Name": "gpas_hice_pop.jpg"
        },
        {
          "CN_Name": "森林王子",
          "EN_Name": "Forest Prince",
          "HTML5_Game_ID": "gpas_fprince_pop",
          "Button_Image_Name": "gpas_fprince_pop.jpg"
        },
        {
          "CN_Name": "新年富园",
          "EN_Name": "New Year's Bonanza",
          "HTML5_Game_ID": "gpas_nyslot_pop",
          "Button_Image_Name": "gpas_nyslot_pop.jpg"
        },
        {
          "CN_Name": "复古潮流冲刺",
          "EN_Name": "Retro Rush",
          "HTML5_Game_ID": "gpas_rrider_pop",
          "Button_Image_Name": "gpas_rrider_pop.jpg"
        },
        {
          "CN_Name": "幸运绿宝石",
          "EN_Name": "Lucky Emeralds",
          "HTML5_Game_ID": "gpas_lemeralds_pop",
          "Button_Image_Name": "gpas_lemeralds_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：阿斯加德国王",
          "EN_Name": "Age of the Gods Norse: King of Asgard",
          "HTML5_Game_ID": "gpas_aogkasgard_pop",
          "Button_Image_Name": "gpas_aogkasgard_pop.jpg"
        },
        {
          "CN_Name": "午夜荒野",
          "EN_Name": "Midnight Wilds",
          "HTML5_Game_ID": "gpas_mwilds_pop",
          "Button_Image_Name": "gpas_mwilds_pop.jpg"
        },
        {
          "CN_Name": "埃及绿宝石",
          "EN_Name": "Egyptian Emeralds",
          "HTML5_Game_ID": "gpas_eemeralds_pop",
          "Button_Image_Name": "gpas_eemeralds_pop.jpg"
        },
        {
          "CN_Name": "国王之书",
          "EN_Name": "Book of Kings",
          "HTML5_Game_ID": "gpas_boking_pop",
          "Button_Image_Name": "gpas_boking_pop.jpg"
        },
        {
          "CN_Name": "狂野西部荒野",
          "EN_Name": "Wild West Wilds",
          "HTML5_Game_ID": "gpas_wwd_pop",
          "Button_Image_Name": "gpas_wwd_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：雷电之道",
          "EN_Name": "Age of the Gods Norse: Ways of Thunder",
          "HTML5_Game_ID": "gpas_aognwot_pop",
          "Button_Image_Name": "gpas_aognwot_pop.jpg"
        },
        {
          "CN_Name": "进击的闪电马",
          "EN_Name": "Stallion Strike",
          "HTML5_Game_ID": "gpas_sstrike_pop",
          "Button_Image_Name": "gpas_sstrike_pop.jpg"
        },
        {
          "CN_Name": "众神时代：辉煌狮鹫",
          "EN_Name": "Age of the Gods: Glorious Griffin",
          "HTML5_Game_ID": "gpas_aogggriffin_pop",
          "Button_Image_Name": "gpas_aogggriffin_pop.jpg"
        },
        {
          "CN_Name": "探险之途",
          "EN_Name": "Adventure Trail",
          "HTML5_Game_ID": "gpas_atrail_pop",
          "Button_Image_Name": "gpas_atrail_pop.jpg"
        },
        {
          "CN_Name": "闪耀铃铛",
          "EN_Name": "Blazing Bells",
          "HTML5_Game_ID": "gpas_bbells_pop",
          "Button_Image_Name": "gpas_bbells_pop.jpg"
        },
        {
          "CN_Name": "运财熊猫",
          "EN_Name": "Panda Luck",
          "HTML5_Game_ID": "gpas_pluck_pop",
          "Button_Image_Name": "gpas_pluck_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：神与巨人",
          "EN_Name": "Age of the Gods Norse: Gods and Giants",
          "HTML5_Game_ID": "gpas_aoggagiants_pop",
          "Button_Image_Name": "gpas_aoggagiants_pop.jpg"
        },
        {
          "CN_Name": "金恩之月",
          "EN_Name": "Jinns Moon",
          "HTML5_Game_ID": "gpas_jmoon_pop",
          "Button_Image_Name": "gpas_jmoon_pop.jpg"
        },
        {
          "CN_Name": "龙女之光",
          "EN_Name": "Dragon Spark",
          "HTML5_Game_ID": "gpas_dsparks_pop",
          "Button_Image_Name": "gpas_dsparks_pop.jpg"
        },
        {
          "CN_Name": "极佳荒野",
          "EN_Name": "Better Wilds",
          "HTML5_Game_ID": "gpas_betwilds_pop",
          "Button_Image_Name": "gpas_betwilds_pop.jpg"
        },
        {
          "CN_Name": "宝石横飞：彩虹礼物",
          "EN_Name": "Gem Splash: Rainbows Gift",
          "HTML5_Game_ID": "gpas_rgift_pop",
          "Button_Image_Name": "gpas_rgift_pop.jpg"
        },
        {
          "CN_Name": "小丑转转转",
          "EN_Name": "Joker Hot Reels",
          "HTML5_Game_ID": "gpas_jhreels_pop",
          "Button_Image_Name": "gpas_jhreels_pop.jpg"
        },
        {
          "CN_Name": "猛虎馈赠",
          "EN_Name": "Legacy of the Tiger",
          "HTML5_Game_ID": "gpas_lottiger_pop",
          "Button_Image_Name": "gpas_lottiger_pop.jpg"
        },
        {
          "CN_Name": "气龙",
          "EN_Name": "Dragon Chi",
          "HTML5_Game_ID": "gpas_dragonchi_pop",
          "Button_Image_Name": "gpas_dragonchi_pop.jpg"
        },
        {
          "CN_Name": "西游记",
          "EN_Name": "Quest West",
          "HTML5_Game_ID": "gpas_mquest_pop",
          "Button_Image_Name": "gpas_mquest_pop.jpg"
        },
        {
          "CN_Name": "公牛财富",
          "EN_Name": "Ox Riches",
          "HTML5_Game_ID": "gpas_strongox_pop",
          "Button_Image_Name": "gpas_strongox_pop.jpg"
        },
        {
          "CN_Name": "九头蛇传奇",
          "EN_Name": "Legend of Hydra",
          "HTML5_Game_ID": "gpas_hydra_pop",
          "Button_Image_Name": "gpas_hydra_pop.jpg"
        },
        {
          "CN_Name": "水牛闪电战2",
          "EN_Name": "Buffalo Blitz II",
          "HTML5_Game_ID": "gpas_bblitz2_pop",
          "Button_Image_Name": "gpas_bblitz2_pop.jpg"
        },
        {
          "CN_Name": "青蛙之礼",
          "EN_Name": "Frog's Gift",
          "HTML5_Game_ID": "gpas_fgift_pop",
          "Button_Image_Name": "gpas_fgift_pop.jpg"
        },
        {
          "CN_Name": "野外宝藏2",
          "EN_Name": "Legacy of the Wilds 2",
          "HTML5_Game_ID": "gpas_lotwild2_pop",
          "Button_Image_Name": "gpas_lotwild2_pop.jpg"
        },
        {
          "CN_Name": "财神组合",
          "EN_Name": "Caishen Ways",
          "HTML5_Game_ID": "gpas_cways_pop",
          "Button_Image_Name": "gpas_cways_pop.jpg"
        },
        {
          "CN_Name": "黄金组合",
          "EN_Name": "Golden Ways",
          "HTML5_Game_ID": "gpas_buddhaways_pop",
          "Button_Image_Name": "gpas_buddhaways_pop.jpg"
        },
        {
          "CN_Name": "幸运星宿",
          "EN_Name": "28 Mansions",
          "HTML5_Game_ID": "gpas_28dman_pop",
          "Button_Image_Name": "gpas_28dman_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：矮人之书",
          "EN_Name": "Age of the Gods Norse: Book of Dwarves",
          "HTML5_Game_ID": "gpas_gogold_pop",
          "Button_Image_Name": "gpas_gogold_pop.jpg"
        },
        {
          "CN_Name": "众神时代：冥府统治者",
          "EN_Name": "Age of the Gods: Ruler Of The Dead",
          "HTML5_Game_ID": "gpas_aogrotu_pop",
          "Button_Image_Name": "gpas_aogrotu_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：托尔特克之宝藏",
          "EN_Name": "Gold Pile: Toltec Treasure",
          "HTML5_Game_ID": "gpas_ttreasure_pop",
          "Button_Image_Name": "gpas_ttreasure_pop.jpg"
        },
        {
          "CN_Name": "野生熔岩",
          "EN_Name": "Wild Lava",
          "HTML5_Game_ID": "gpas_wlava_pop",
          "Button_Image_Name": "gpas_wlava_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：新年之金",
          "EN_Name": "Gold Pile: New Years Gold",
          "HTML5_Game_ID": "gpas_gpnyg_pop",
          "Button_Image_Name": "gpas_gpnyg_pop.jpg"
        },
        {
          "CN_Name": "招财进宝2",
          "EN_Name": "Zhao Cai Jin Bao 2",
          "HTML5_Game_ID": "gpas_zcjbao2_pop",
          "Button_Image_Name": "gpas_zcjbao2_pop.jpg"
        },
        {
          "CN_Name": "黄金烈焰大奖：富贵工厂",
          "EN_Name": "Fire Blaze Golden: Amazing Factory",
          "HTML5_Game_ID": "gpas_afactory_pop",
          "Button_Image_Name": "gpas_afactory_pop.jpg"
        },
        {
          "CN_Name": "疯狂蜜蜂",
          "EN_Name": "Bee Frenzy",
          "HTML5_Game_ID": "gpas_bfrenzy_pop",
          "Button_Image_Name": "gpas_bfrenzy_pop.jpg"
        },
        {
          "CN_Name": "金龙鱼",
          "EN_Name": "Arowanas Luck",
          "HTML5_Game_ID": "gpas_aluck_pop",
          "Button_Image_Name": "gpas_aluck_pop.jpg"
        },
        {
          "CN_Name": "狂野蟒蛇2",
          "EN_Name": "Anaconda Wild II",
          "HTML5_Game_ID": "gpas_awild2_pop",
          "Button_Image_Name": "gpas_awild2_pop.jpg"
        },
        {
          "CN_Name": "音乐之财",
          "EN_Name": "Record Riches",
          "HTML5_Game_ID": "gpas_rriches_pop",
          "Button_Image_Name": "gpas_rriches_pop.jpg"
        },
        {
          "CN_Name": "爱情傻瓜",
          "EN_Name": "Lovefool",
          "HTML5_Game_ID": "gpas_scupid_pop",
          "Button_Image_Name": "gpas_scupid_pop.jpg"
        },
        {
          "CN_Name": "金蟾蜍的礼物",
          "EN_Name": "Toad's Gift",
          "HTML5_Game_ID": "gpas_tgift_pop",
          "Button_Image_Name": "gpas_tgift_pop.jpg"
        },
        {
          "CN_Name": "珍珠，珍珠，珍珠",
          "EN_Name": "Pearls Pearls Pearls",
          "HTML5_Game_ID": "gpas_pppearls_pop",
          "Button_Image_Name": "gpas_pppearls_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：老虎天骄",
          "EN_Name": "Gold Pile: Tigers Pride",
          "HTML5_Game_ID": "gpas_tfortune_pop",
          "Button_Image_Name": "gpas_tfortune_pop.jpg"
        },
        {
          "CN_Name": "众神时代：神谕之书",
          "EN_Name": "Age of Gods: Book of Oracle",
          "HTML5_Game_ID": "gpas_aogbooracle_pop",
          "Button_Image_Name": "gpas_aogbooracle_pop.jpg"
        },
        {
          "CN_Name": "简琼斯国王之书2",
          "EN_Name": "Jane Jones In Book Of King 2",
          "HTML5_Game_ID": "gpas_nalight_pop",
          "Button_Image_Name": "gpas_nalight_pop.jpg"
        },
        {
          "CN_Name": "烈焰大奖：河皇后",
          "EN_Name": "Fire Blaze: River Empress",
          "HTML5_Game_ID": "gpas_rempress_pop",
          "Button_Image_Name": "gpas_rempress_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：姐妹之礼",
          "EN_Name": "Fire Blaze: Sisters Gift",
          "HTML5_Game_ID": "gpas_sgift_pop",
          "Button_Image_Name": "gpas_sgift_pop.jpg"
        },
        {
          "CN_Name": "越狱的鲍勃",
          "EN_Name": "Breakout Bob",
          "HTML5_Game_ID": "gpas_bbob_pop",
          "Button_Image_Name": "gpas_bbob_pop.jpg"
        },
        {
          "CN_Name": "黄金满屋：猩猩",
          "EN_Name": "Gold Pile: Orangutan",
          "HTML5_Game_ID": "gpas_gporang_pop",
          "Button_Image_Name": "gpas_gporang_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：红巫师",
          "EN_Name": "Fire Blaze: Red Wizard",
          "HTML5_Game_ID": "gpas_rwizard_pop",
          "Button_Image_Name": "gpas_rwizard_pop.jpg"
        },
        {
          "CN_Name": "烈焰黄金：海盗铃钟",
          "EN_Name": "Fire Blaze Golden: Buccaneer Bells",
          "HTML5_Game_ID": "gpas_fbgbb_pop",
          "Button_Image_Name": "gpas_fbgbb_pop.jpg"
        },
        {
          "CN_Name": "完美大盗",
          "EN_Name": "The Perfect Heist",
          "HTML5_Game_ID": "gpas_etscore_pop",
          "Button_Image_Name": "gpas_etscore_pop.jpg"
        },
        {
          "CN_Name": "生肖魅力",
          "EN_Name": "Zodiac Charms",
          "HTML5_Game_ID": "gpas_zcharms_pop",
          "Button_Image_Name": "gpas_zcharms_pop.jpg"
        },
        {
          "CN_Name": "百万烈焰大奖：孔苏月神",
          "EN_Name": "Mega Fire Blaze: Khonsu God of Moon",
          "HTML5_Game_ID": "gpas_kgomoon_pop",
          "Button_Image_Name": "gpas_kgomoon_pop.jpg"
        },
        {
          "CN_Name": "宝石横飞：国王庭院",
          "EN_Name": "Gem Splash: Kings Court",
          "HTML5_Game_ID": "gpas_gskc_pop",
          "Button_Image_Name": "gpas_gskc_pop.jpg"
        },
        {
          "CN_Name": "玛雅积木",
          "EN_Name": "Mayan Blocks",
          "HTML5_Game_ID": "gpas_mblocks_pop",
          "Button_Image_Name": "gpas_mblocks_pop.jpg"
        },
        {
          "CN_Name": "魔法师公会",
          "EN_Name": "Sorcerer's Guild of Magic",
          "HTML5_Game_ID": "gpas_sgomagic_pop",
          "Button_Image_Name": "gpas_sgomagic_pop.jpg"
        },
        {
          "CN_Name": "绝对长毛象",
          "EN_Name": "Absolutely Mammoth",
          "HTML5_Game_ID": "gpas_amammoth_pop",
          "Button_Image_Name": "gpas_amammoth_pop.jpg"
        },
        {
          "CN_Name": "伊丝塔",
          "EN_Name": "Ishtar",
          "HTML5_Game_ID": "gpas_ishtar_pop",
          "Button_Image_Name": "gpas_ishtar_pop.jpg"
        },
        {
          "CN_Name": "发财黑豹奖池版",
          "EN_Name": "Panther Pays Power Play Jackpot",
          "HTML5_Game_ID": "gpas_ppayspp_pop",
          "Button_Image_Name": "gpas_ppayspp_pop.jpg"
        },
        {
          "CN_Name": "火焰棒",
          "EN_Name": "Flaming Bars",
          "HTML5_Game_ID": "gpas_fbars_pop",
          "Button_Image_Name": "gpas_fbars_pop.jpg"
        },
        {
          "CN_Name": "黄金烈焰大奖：苔原狼",
          "EN_Name": "Fire Blaze Golden: Tundra Wolf",
          "HTML5_Game_ID": "gpas_twolf_pop",
          "Button_Image_Name": "gpas_twolf_pop.jpg"
        },
        {
          "CN_Name": "龙之堂：雷霆之弹",
          "EN_Name": "Dragons Hall: Thundershots",
          "HTML5_Game_ID": "gpas_dhall_pop",
          "Button_Image_Name": "gpas_dhall_pop.jpg"
        },
        {
          "CN_Name": "神秘连接",
          "EN_Name": "Wild Linx",
          "HTML5_Game_ID": "gpas_wlinx_pop",
          "Button_Image_Name": "gpas_wlinx_pop.jpg"
        },
        {
          "CN_Name": "猿人传奇2奖池闪电战",
          "EN_Name": "Epic Ape II Jackpot Blitz",
          "HTML5_Game_ID": "gpas_eape2_pop",
          "Button_Image_Name": "gpas_eape2_pop.jpg"
        },
        {
          "CN_Name": "满月：白熊猫",
          "EN_Name": "Full Moon: White Panda",
          "HTML5_Game_ID": "gpas_wpanda_pop",
          "Button_Image_Name": "gpas_wpanda_pop.jpg"
        },
        {
          "CN_Name": "满月：狂野赛道",
          "EN_Name": "Full Moon: Wild Track",
          "HTML5_Game_ID": "gpas_wtrack_pop",
          "Button_Image_Name": "gpas_wtrack_pop.jpg"
        },
        {
          "CN_Name": "阿努比斯之眼",
          "EN_Name": "Eye of Anubis",
          "HTML5_Game_ID": "gpas_sscarab_pop",
          "Button_Image_Name": "gpas_sscarab_pop.jpg"
        },
        {
          "CN_Name": "国王之书2奖池版",
          "EN_Name": "Book of Kings 2 PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_bokings2pp_pop",
          "Button_Image_Name": "gpas_bokings2pp_pop.jpg"
        },
        {
          "CN_Name": "福临五象",
          "EN_Name": "Glorious Guardians",
          "HTML5_Game_ID": "gpas_gguardians_pop",
          "Button_Image_Name": "gpas_gguardians_pop.jpg"
        },
        {
          "CN_Name": "金字塔谷：能量区域",
          "EN_Name": "Pyramid Valley: Power Zones",
          "HTML5_Game_ID": "gpas_pstrike_pop",
          "Button_Image_Name": "gpas_pstrike_pop.jpg"
        },
        {
          "CN_Name": "辣椒喷发：雷霆之弹",
          "EN_Name": "Chili Eruption Thundershots",
          "HTML5_Game_ID": "gpas_ceruption_pop",
          "Button_Image_Name": "gpas_ceruption_pop.jpg"
        },
        {
          "CN_Name": "撒哈拉财富：现金收集",
          "EN_Name": "Sahara Riches: Cash Collect",
          "HTML5_Game_ID": "gpas_ccsahara_pop",
          "Button_Image_Name": "gpas_ccsahara_pop.jpg"
        },
        {
          "CN_Name": "猴鼠",
          "EN_Name": "Monkey and Rat",
          "HTML5_Game_ID": "gpas_marat_pop",
          "Button_Image_Name": "gpas_marat_pop.jpg"
        },
        {
          "CN_Name": "亚特兰蒂斯：现金收集",
          "EN_Name": "Atlantis Cash Collect",
          "HTML5_Game_ID": "gpas_ccskingofa_pop",
          "Button_Image_Name": "gpas_ccskingofa_pop.jpg"
        },
        {
          "CN_Name": "阿努比斯的诅咒",
          "EN_Name": "Curse of Anubis",
          "HTML5_Game_ID": "gpas_soanubis_pop",
          "Button_Image_Name": "gpas_soanubis_pop.jpg"
        },
        {
          "CN_Name": "阿兹特克探险",
          "EN_Name": "Aztec Expedition Thundershots",
          "HTML5_Game_ID": "gpas_aexpedition_pop",
          "Button_Image_Name": "gpas_aexpedition_pop.jpg"
        },
        {
          "CN_Name": "金秋",
          "EN_Name": "Autumn Gold",
          "HTML5_Game_ID": "pop_autumngold_eye",
          "Button_Image_Name": "pop_autumngold_eye.jpg"
        },
        {
          "CN_Name": "鏖战英警",
          "EN_Name": "Beat The Bobbies",
          "HTML5_Game_ID": "pop_btthbbbs_eye",
          "Button_Image_Name": "pop_btthbbbs_eye.jpg"
        },
        {
          "CN_Name": "无敌小蜜蜂",
          "EN_Name": "Beez Kneez",
          "HTML5_Game_ID": "pop_beezkneez_eye",
          "Button_Image_Name": "pop_beezkneez_eye.jpg"
        },
        {
          "CN_Name": "弹跳球2",
          "EN_Name": "Bouncy Balls 2",
          "HTML5_Game_ID": "pop_bfad477a_eye",
          "Button_Image_Name": "pop_bfad477a_eye.jpg"
        },
        {
          "CN_Name": "前往开罗的大篷车",
          "EN_Name": "Caravan to Cairo",
          "HTML5_Game_ID": "pop_caravantcro_eye",
          "Button_Image_Name": "pop_caravantcro_eye.jpg"
        },
        {
          "CN_Name": "转运四叶草2",
          "EN_Name": "Clover Rollover 2",
          "HTML5_Game_ID": "pop_1fc2f43a_eye",
          "Button_Image_Name": "pop_1fc2f43a_eye.jpg"
        },
        {
          "CN_Name": "水晶莲花",
          "EN_Name": "Crystal Lotus",
          "HTML5_Game_ID": "pop_crystallotus_eye",
          "Button_Image_Name": "pop_crystallotus_eye.jpg"
        },
        {
          "CN_Name": "足球杯狂欢节",
          "EN_Name": "Cup Carnaval",
          "HTML5_Game_ID": "pop_cupcarnaval_eye",
          "Button_Image_Name": "pop_cupcarnaval_eye.jpg"
        },
        {
          "CN_Name": "被施咒的王子",
          "EN_Name": "Enchanted Prince",
          "HTML5_Game_ID": "pop_nchntdprnc_eye",
          "Button_Image_Name": "pop_nchntdprnc_eye.jpg"
        },
        {
          "CN_Name": "毛绒爱宠大体验",
          "EN_Name": "Fluffy Favourites Megaways",
          "HTML5_Game_ID": "pop_df48c20d_eye",
          "Button_Image_Name": "pop_df48c20d_eye.jpg"
        },
        {
          "CN_Name": "烟火撩人",
          "EN_Name": "Fireworks Frenzy",
          "HTML5_Game_ID": "pop_frwrksfrnz_eye",
          "Button_Image_Name": "pop_frwrksfrnz_eye.jpg"
        },
        {
          "CN_Name": "毛绒玩具收藏品",
          "EN_Name": "Fluffy Favourites",
          "HTML5_Game_ID": "pop_flfffvrts_eye",
          "Button_Image_Name": "pop_flfffvrts_eye.jpg"
        },
        {
          "CN_Name": "银河少女",
          "EN_Name": "Galactic Girls",
          "HTML5_Game_ID": "pop_e63aedf0_eye",
          "Button_Image_Name": "pop_e63aedf0_eye.jpg"
        },
        {
          "CN_Name": "热血沸腾",
          "EN_Name": "Heartburst",
          "HTML5_Game_ID": "pop_heartburst_eye",
          "Button_Image_Name": "pop_heartburst_eye.jpg"
        },
        {
          "CN_Name": "掌控保险箱",
          "EN_Name": "Hold The Safe",
          "HTML5_Game_ID": "pop_holdthesafe_eye",
          "Button_Image_Name": "pop_holdthesafe_eye.jpg"
        },
        {
          "CN_Name": "幸运爱尔兰",
          "EN_Name": "Irish Luck(Eyecon)",
          "HTML5_Game_ID": "pop_irishluck_eye",
          "Button_Image_Name": "pop_irishluck_eye.jpg"
        },
        {
          "CN_Name": "吻我吧，三叶草妖精",
          "EN_Name": "Kiss Me Clover",
          "HTML5_Game_ID": "pop_kissmeclover_eye",
          "Button_Image_Name": "pop_kissmeclover_eye.jpg"
        },
        {
          "CN_Name": "小猫派彩",
          "EN_Name": "Kitty Payout",
          "HTML5_Game_ID": "pop_kittypayout_eye",
          "Button_Image_Name": "pop_kittypayout_eye.jpg"
        },
        {
          "CN_Name": "失落的岛屿",
          "EN_Name": "Lost Island",
          "HTML5_Game_ID": "pop_lostisland_eye",
          "Button_Image_Name": "pop_lostisland_eye.jpg"
        },
        {
          "CN_Name": "摔跤大战",
          "EN_Name": "Lucha Rumble",
          "HTML5_Game_ID": "pop_lucharumble_eye",
          "Button_Image_Name": "pop_lucharumble_eye.jpg"
        },
        {
          "CN_Name": "超越对手",
          "EN_Name": "Past the Post",
          "HTML5_Game_ID": "pop_pastthepost_eye",
          "Button_Image_Name": "pop_pastthepost_eye.jpg"
        },
        {
          "CN_Name": "小猪派彩",
          "EN_Name": "Piggy Payout",
          "HTML5_Game_ID": "pop_piggypayout_eye",
          "Button_Image_Name": "pop_piggypayout_eye.jpg"
        },
        {
          "CN_Name": "小猪的大餐",
          "EN_Name": "Pigs Feast",
          "HTML5_Game_ID": "pop_pigsfeast_eye",
          "Button_Image_Name": "pop_pigsfeast_eye.jpg"
        },
        {
          "CN_Name": "小狗派彩",
          "EN_Name": "Puggy Payout",
          "HTML5_Game_ID": "pop_puggypayout_eye",
          "Button_Image_Name": "pop_puggypayout_eye.jpg"
        },
        {
          "CN_Name": "华丽重转",
          "EN_Name": "Rebets Splendour",
          "HTML5_Game_ID": "pop_rbtssplndr_eye",
          "Button_Image_Name": "pop_rbtssplndr_eye.jpg"
        },
        {
          "CN_Name": "秘密花园",
          "EN_Name": "Secret Garden",
          "HTML5_Game_ID": "pop_secretgarden_eye",
          "Button_Image_Name": "pop_secretgarden_eye.jpg"
        },
        {
          "CN_Name": "萨满之梦2",
          "EN_Name": "Shamans Dream 2",
          "HTML5_Game_ID": "pop_047c7908_eye",
          "Button_Image_Name": "pop_047c7908_eye.jpg"
        },
        {
          "CN_Name": "疯狂购物",
          "EN_Name": "Shopping Spree",
          "HTML5_Game_ID": "pop_shppngspr_eye",
          "Button_Image_Name": "pop_shppngspr_eye.jpg"
        },
        {
          "CN_Name": "狂潮",
          "EN_Name": "Stampede",
          "HTML5_Game_ID": "pop_stampede_eye",
          "Button_Image_Name": "pop_stampede_eye.jpg"
        },
        {
          "CN_Name": "重现 777",
          "EN_Name": "Stepback 7s",
          "HTML5_Game_ID": "pop_stepback7s_eye",
          "Button_Image_Name": "pop_stepback7s_eye.jpg"
        },
        {
          "CN_Name": "热血前锋",
          "EN_Name": "Striker Goes Wild",
          "HTML5_Game_ID": "pop_strkrgswld_eye",
          "Button_Image_Name": "pop_strkrgswld_eye.jpg"
        },
        {
          "CN_Name": "伊莉斯神庙2",
          "EN_Name": "Temple of Iris 2",
          "HTML5_Game_ID": "pop_tmplfrs_eye",
          "Button_Image_Name": "pop_tmplfrs_eye.jpg"
        },
        {
          "CN_Name": "圣诞很快乐",
          "EN_Name": "Very Merry Christmas",
          "HTML5_Game_ID": "pop_vrmrrchrstms_eye",
          "Button_Image_Name": "pop_vrmrrchrstms_eye.jpg"
        },
        {
          "CN_Name": "德古拉城堡",
          "EN_Name": "Vlads Castle",
          "HTML5_Game_ID": "pop_vladscastle_eye",
          "Button_Image_Name": "pop_vladscastle_eye.jpg"
        },
        {
          "CN_Name": "白色巫师",
          "EN_Name": "White Wizard",
          "HTML5_Game_ID": "pop_whitewizard_eye",
          "Button_Image_Name": "pop_whitewizard_eye.jpg"
        },
        {
          "CN_Name": "白色巫师豪华版",
          "EN_Name": "White Wizard Deluxe",
          "HTML5_Game_ID": "pop_whtwzrddlx_eye",
          "Button_Image_Name": "pop_whtwzrddlx_eye.jpg"
        },
        {
          "CN_Name": "幸运爆竹",
          "EN_Name": "Xingyun BaoZhu",
          "HTML5_Game_ID": "pop_xngnbzh_eye",
          "Button_Image_Name": "pop_xngnbzh_eye.jpg"
        },
        {
          "CN_Name": "圣诞现金",
          "EN_Name": "Xmas Cash",
          "HTML5_Game_ID": "pop_xmascash_eye",
          "Button_Image_Name": "pop_xmascash_eye.jpg"
        },
        {
          "CN_Name": "疯狂悠悠球",
          "EN_Name": "Yoyo's Wild",
          "HTML5_Game_ID": "pop_yoyoswild_eye",
          "Button_Image_Name": "pop_yoyoswild_eye.jpg"
        },
        {
          "CN_Name": "烈焰至尊：蓝巫师Megaways",
          "EN_Name": "Fire Blaze: Blue Wizard Megaways",
          "HTML5_Game_ID": "gpas_mgbwizard_pop",
          "Button_Image_Name": "gpas_mgbwizard_pop.jpg"
        },
        {
          "CN_Name": "英雄之箭",
          "EN_Name": "Heroes Arrow",
          "HTML5_Game_ID": "gpas_harrow_pop",
          "Button_Image_Name": "gpas_harrow_pop.jpg"
        },
        {
          "CN_Name": "侏罗纪岛2",
          "EN_Name": "Jurassic Island 2",
          "HTML5_Game_ID": "gpas_jisland2_pop",
          "Button_Image_Name": "gpas_jisland2_pop.jpg"
        },
        {
          "CN_Name": "巨型烈焰：大马戏！",
          "EN_Name": "Mega Fire Blaze: Big Circus!",
          "HTML5_Game_ID": "gpas_bcircus_pop",
          "Button_Image_Name": "gpas_bcircus_pop.jpg"
        },
        {
          "CN_Name": "能量区域：雷鸟",
          "EN_Name": "Power Zones: Thunder Birds",
          "HTML5_Game_ID": "gpas_tbirds_pop",
          "Button_Image_Name": "gpas_tbirds_pop.jpg"
        },
        {
          "CN_Name": "钓好运",
          "EN_Name": "Fishin' Bonanza",
          "HTML5_Game_ID": "gpas_ffever_pop",
          "Button_Image_Name": "gpas_ffever_pop.jpg"
        },
        {
          "CN_Name": "小妖精的好运：现金收集",
          "EN_Name": "Leprechaun’s Luck: Cash Collect",
          "HTML5_Game_ID": "gpas_ccluck_pop",
          "Button_Image_Name": "gpas_ccluck_pop.jpg"
        },
        {
          "CN_Name": "巨型烈焰：狂野手枪",
          "EN_Name": "Mega Fire Blaze: Wild Pistolero",
          "HTML5_Game_ID": "gpas_wpisto_pop",
          "Button_Image_Name": "gpas_wpisto_pop.jpg"
        },
        {
          "CN_Name": "极限热辣宝石",
          "EN_Name": "Hot Gems Xtreme",
          "HTML5_Game_ID": "gpas_hgextreme_pop",
          "Button_Image_Name": "gpas_hgextreme_pop.jpg"
        },
        {
          "CN_Name": "蟒蛇献宝",
          "EN_Name": "Anaconda Uncoiled",
          "HTML5_Game_ID": "gpas_auncoil_pop",
          "Button_Image_Name": "gpas_auncoil_pop.jpg"
        },
        {
          "CN_Name": "终极火辣",
          "EN_Name": "Chilli Xtreme",
          "HTML5_Game_ID": "gpas_cxtreme_pop",
          "Button_Image_Name": "gpas_cxtreme_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：消防勇士",
          "EN_Name": "Fire Blaze: Fire Fighter",
          "HTML5_Game_ID": "gpas_ffighter_pop",
          "Button_Image_Name": "gpas_ffighter_pop.jpg"
        },
        {
          "CN_Name": "武术拳",
          "EN_Name": "Wushu Punch",
          "HTML5_Game_ID": "gpas_wpunch_pop",
          "Button_Image_Name": "gpas_wpunch_pop.jpg"
        },
        {
          "CN_Name": "钻石金字塔",
          "EN_Name": "Diamond Rise",
          "HTML5_Game_ID": "gpas_drise_pop",
          "Button_Image_Name": "gpas_drise_pop.jpg"
        },
        {
          "CN_Name": "热辣财富",
          "EN_Name": "Hit Bar",
          "HTML5_Game_ID": "gpas_fmhitbar_pop",
          "Button_Image_Name": "gpas_fmhitbar_pop.jpg"
        },
        {
          "CN_Name": "萬能帽 : 金灯",
          "EN_Name": "Mighty Hat: Lamp of Gold",
          "HTML5_Game_ID": "gpas_gwish_pop",
          "Button_Image_Name": "gpas_gwish_pop.jpg"
        },
        {
          "CN_Name": "如意锦鲤",
          "EN_Name": "Koi Harmony",
          "HTML5_Game_ID": "gpas_koihar_pop",
          "Button_Image_Name": "gpas_koihar_pop.jpg"
        },
        {
          "CN_Name": "银弹匪徒：现金收集",
          "EN_Name": "Silver Bullet Bandit: Cash Collect",
          "HTML5_Game_ID": "gpas_sbullet_pop",
          "Button_Image_Name": "gpas_sbullet_pop.jpg"
        },
        {
          "CN_Name": "小丑狂奔",
          "EN_Name": "Joker Rush",
          "HTML5_Game_ID": "gpas_jrush_pop",
          "Button_Image_Name": "gpas_jrush_pop.jpg"
        },
        {
          "CN_Name": "大秦帝国：天界守护者",
          "EN_Name": "Qin's Empire: Celestial Guardians",
          "HTML5_Game_ID": "gpas_cguard_pop",
          "Button_Image_Name": "gpas_cguard_pop.jpg"
        },
        {
          "CN_Name": "乘数怪物",
          "EN_Name": "Monster Multipliers",
          "HTML5_Game_ID": "gpas_sking_pop",
          "Button_Image_Name": "gpas_sking_pop.jpg"
        },
        {
          "CN_Name": "大秦帝国：财神庙",
          "EN_Name": "Qin's Empire: Caishen's Temple",
          "HTML5_Game_ID": "gpas_ctemple_pop",
          "Button_Image_Name": "gpas_ctemple_pop.jpg"
        },
        {
          "CN_Name": "烈焰四驱：魅力凯尔特",
          "EN_Name": "Fire Blaze Quattro: Celtic Charm",
          "HTML5_Game_ID": "gpas_qccharm_pop",
          "Button_Image_Name": "gpas_qccharm_pop.jpg"
        },
        {
          "CN_Name": "萬能帽：我的宝矿",
          "EN_Name": "Mighty Hat: Mine O' Mine",
          "HTML5_Game_ID": "gpas_grush_pop",
          "Button_Image_Name": "gpas_grush_pop.jpg"
        },
        {
          "CN_Name": "黄金疯狂：奥莱利的财富",
          "EN_Name": "Gold Hit: O'Reilly's Riches",
          "HTML5_Game_ID": "gpas_bcash_pop",
          "Button_Image_Name": "gpas_bcash_pop.jpg"
        },
        {
          "CN_Name": "烈焰至尊：绿巫师",
          "EN_Name": "Fire Blaze: Green Wizard",
          "HTML5_Game_ID": "gpas_gwizard_pop",
          "Button_Image_Name": "gpas_gwizard_pop.jpg"
        },
        {
          "CN_Name": "女巫：现金收集",
          "EN_Name": "Witches: Cash Collect",
          "HTML5_Game_ID": "gpas_cchfortune_pop",
          "Button_Image_Name": "gpas_cchfortune_pop.jpg"
        },
        {
          "CN_Name": "萬能帽：神秘传说",
          "EN_Name": "Mighty Hat: Mystic Tales",
          "HTML5_Game_ID": "gpas_mforest_pop",
          "Button_Image_Name": "gpas_mforest_pop.jpg"
        },
        {
          "CN_Name": "足球！现金收集",
          "EN_Name": "Football! Cash Collect",
          "HTML5_Game_ID": "gpas_focashco_pop",
          "Button_Image_Name": "gpas_focashco_pop.jpg"
        },
        {
          "CN_Name": "撒哈拉财富MegaWays：现金收集",
          "EN_Name": "Sahara Riches MegaWays: Cash Collect",
          "HTML5_Game_ID": "gpas_mgccsahara_pop",
          "Button_Image_Name": "gpas_mgccsahara_pop.jpg"
        },
        {
          "CN_Name": "大枢纽：黄金枪",
          "EN_Name": "Grand Junction: Golden Guns",
          "HTML5_Game_ID": "gpas_ggun_pop",
          "Button_Image_Name": "gpas_ggun_pop.jpg"
        },
        {
          "CN_Name": "阿兹特克：奖励线",
          "EN_Name": "Azteca Bonus Lines",
          "HTML5_Game_ID": "gpas_azboli_pop",
          "Button_Image_Name": "gpas_azboli_pop.jpg"
        },
        {
          "CN_Name": "玉兔进财：金钱满屋",
          "EN_Name": "Yu Tu Jin Cai: Cash Collect",
          "HTML5_Game_ID": "gpas_rabbitcash_pop",
          "Button_Image_Name": "gpas_rabbitcash_pop.jpg"
        },
        {
          "CN_Name": "张飞劈鱼",
          "EN_Name": "Warrior Fishing",
          "HTML5_Game_ID": "pop_3d62bb7b_3kg",
          "Button_Image_Name": "pop_3d62bb7b_3kg.jpg"
        },
        {
          "CN_Name": "终极火辣奖池版",
          "EN_Name": "Chilli Xtreme PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_cxtremepp_pop",
          "Button_Image_Name": "gpas_cxtremepp_pop.jpg"
        },
        {
          "CN_Name": "间谍觉醒",
          "EN_Name": "Spy Rise",
          "HTML5_Game_ID": "gpas_secagent_pop",
          "Button_Image_Name": "gpas_secagent_pop.jpg"
        },
        {
          "CN_Name": "众神时代：海洋统治者",
          "EN_Name": "Age of the Gods: Ruler of the Seas",
          "HTML5_Game_ID": "gpas_aogrotsea_pop",
          "Button_Image_Name": "gpas_aogrotsea_pop.jpg"
        },
        {
          "CN_Name": "众神时代：史诗特洛伊",
          "EN_Name": "Age of the Gods: Epic Troy",
          "HTML5_Game_ID": "gpas_aogetroy_pop",
          "Button_Image_Name": "gpas_aogetroy_pop.jpg"
        },
        {
          "CN_Name": "众神时代：阿波罗之力",
          "EN_Name": "Age of the Gods: Apollo Power",
          "HTML5_Game_ID": "gpas_appower_pop",
          "Button_Image_Name": "gpas_appower_pop.jpg"
        },
        {
          "CN_Name": "闪耀铃铛奖池版",
          "EN_Name": "Blazing Bells PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_bbellspp_pop",
          "Button_Image_Name": "gpas_bbellspp_pop.jpg"
        },
        {
          "CN_Name": "国王之书奖池版",
          "EN_Name": "Book of Kings PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_bokingpp_pop",
          "Button_Image_Name": "gpas_bokingpp_pop.jpg"
        },
        {
          "CN_Name": "埃及绿宝石奖池版",
          "EN_Name": "Egyptian Emeralds PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_eemeraldspp_pop",
          "Button_Image_Name": "gpas_eemeraldspp_pop.jpg"
        },
        {
          "CN_Name": "顶级图腾奖池版",
          "EN_Name": "Tip Top Totems PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_tttotemspp_pop",
          "Button_Image_Name": "gpas_tttotemspp_pop.jpg"
        },
        {
          "CN_Name": "众神北欧时代：北欧传奇",
          "EN_Name": "Age of the Gods Norse: Norse Legends",
          "HTML5_Game_ID": "gpas_aognnl_pop",
          "Button_Image_Name": "gpas_aognnl_pop.jpg"
        },
        {
          "CN_Name": "风暴精灵奖池版",
          "EN_Name": "Djinn of Storms PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_dostormspp_pop",
          "Button_Image_Name": "gpas_dostormspp_pop.jpg"
        },
        {
          "CN_Name": "更好的百搭奖池版",
          "EN_Name": "Better Wilds PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_betwildspp_pop",
          "Button_Image_Name": "gpas_betwildspp_pop.jpg"
        },
        {
          "CN_Name": "冰暴：强力累积奖金",
          "EN_Name": "Storms of Ice PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_soicepp_pop",
          "Button_Image_Name": "gpas_soicepp_pop.jpg"
        },
        {
          "CN_Name": "犀牛闪电战",
          "EN_Name": "Rhino Blitz",
          "HTML5_Game_ID": "gpas_rslot_pop",
          "Button_Image_Name": "gpas_rslot_pop.jpg"
        },
        {
          "CN_Name": "女王的诅咒：帝国宝藏",
          "EN_Name": "The Queen's Curse: Empire Treasures",
          "HTML5_Game_ID": "gpas_tqcempt_pop",
          "Button_Image_Name": "gpas_tqcempt_pop.jpg"
        },
        {
          "CN_Name": "足球刮刮乐奖池版",
          "EN_Name": "Football Scratch PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_fballiwpp_pop",
          "Button_Image_Name": "gpas_fballiwpp_pop.jpg"
        },
        {
          "CN_Name": "野生熔岩刮刮乐",
          "EN_Name": "Wild Lava Scratch",
          "HTML5_Game_ID": "gpas_wlavaiw_pop",
          "Button_Image_Name": "gpas_wlavaiw_pop.jpg"
        },
        {
          "CN_Name": "热辣宝石刮刮乐",
          "EN_Name": "Hot Gems Xtreme Scratch",
          "HTML5_Game_ID": "gpas_hgextremeiw_pop",
          "Button_Image_Name": "gpas_hgextremeiw_pop.jpg"
        },
        {
          "CN_Name": "众神时代：奇迹战士",
          "EN_Name": "Age of the Gods: Wonder Warriors",
          "HTML5_Game_ID": "gpas_aogww_pop",
          "Button_Image_Name": "gpas_aogww_pop.jpg"
        },
        {
          "CN_Name": "众神时代：奥林匹斯转盘",
          "EN_Name": "Age of the Gods: Wheels of Olympus",
          "HTML5_Game_ID": "gpas_aogwfot_pop",
          "Button_Image_Name": "gpas_aogwfot_pop.jpg"
        },
        {
          "CN_Name": "众神时代：迷宫守护者",
          "EN_Name": "Age of the Gods: Maze Keeper",
          "HTML5_Game_ID": "gpas_mkeeper_pop",
          "Button_Image_Name": "gpas_mkeeper_pop.jpg"
        },
        {
          "CN_Name": "众神时代：风暴之神2",
          "EN_Name": "Age of the Gods: God of Storms 2",
          "HTML5_Game_ID": "gpas_gstorm2_pop",
          "Button_Image_Name": "gpas_gstorm2_pop.jpg"
        },
        {
          "CN_Name": "火热皇冠豪华版",
          "EN_Name": "Hot Crown Deluxe",
          "HTML5_Game_ID": "gpas_hcdelux_pop",
          "Button_Image_Name": "gpas_hcdelux_pop.jpg"
        },
        {
          "CN_Name": "王者闪电战",
          "EN_Name": "King Blitz",
          "HTML5_Game_ID": "gpas_bprog_pop",
          "Button_Image_Name": "gpas_bprog_pop.jpg"
        },
        {
          "CN_Name": "进击的闪电马奖池版",
          "EN_Name": "Stallion Strike PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_sstrikepp_pop",
          "Button_Image_Name": "gpas_sstrikepp_pop.jpg"
        },
        {
          "CN_Name": "波斯的骄傲：帝国宝藏",
          "EN_Name": "Pride of Persia: Empire Treasures",
          "HTML5_Game_ID": "gpas_etpop_pop",
          "Button_Image_Name": "gpas_etpop_pop.jpg"
        },
        {
          "CN_Name": "维京人：帝国宝藏",
          "EN_Name": "Vikings: Empire Treasures",
          "HTML5_Game_ID": "gpas_vemptr_pop",
          "Button_Image_Name": "gpas_vemptr_pop.jpg"
        },
        {
          "CN_Name": "狂野十字军：帝国宝藏",
          "EN_Name": "Wild Crusade: Empire Treasures",
          "HTML5_Game_ID": "gpas_wcrusade_pop",
          "Button_Image_Name": "gpas_wcrusade_pop.jpg"
        },
        {
          "CN_Name": "水牛闪电战Megaways",
          "EN_Name": "Buffalo Blitz: Megaways",
          "HTML5_Game_ID": "gpas_bbmways_pop",
          "Button_Image_Name": "gpas_bbmways_pop.jpg"
        },
        {
          "CN_Name": "鲨鱼闪电战",
          "EN_Name": "Shark Blitz",
          "HTML5_Game_ID": "gpas_sharks_pop",
          "Button_Image_Name": "gpas_sharks_pop.jpg"
        },
        {
          "CN_Name": "神秘连接 强力累积奖金",
          "EN_Name": "Wild LinX PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_wlinxpp_pop",
          "Button_Image_Name": "gpas_wlinxpp_pop.jpg"
        },
        {
          "CN_Name": "热辣财富奖池版",
          "EN_Name": "Hit Bar PowerPlay Jackpot",
          "HTML5_Game_ID": "gpas_fmhitbarpp_pop",
          "Button_Image_Name": "gpas_fmhitbarpp_pop.jpg"
        },
        {
          "CN_Name": "泰国神庙",
          "EN_Name": "Thai Temple",
          "HTML5_Game_ID": "tht",
          "Button_Image_Name": "tht.jpg"
        },
        {
          "CN_Name": "年年有余亚洲版",
          "EN_Name": "Nian Nian You Yu Asia",
          "HTML5_Game_ID": "nian_k",
          "Button_Image_Name": "nian_k.jpg"
        },
        {
          "CN_Name": "众神时代：奥林匹斯之王",
          "EN_Name": "Age of the Gods: King of Olympus",
          "HTML5_Game_ID": "zeus",
          "Button_Image_Name": "zeus.jpg"
        },
        {
          "CN_Name": "华尔街狂热",
          "EN_Name": "Wall Street Fever",
          "HTML5_Game_ID": "wsffr",
          "Button_Image_Name": "wsffr.jpg"
        },
        {
          "CN_Name": "拉美西斯金字塔",
          "EN_Name": "Pyramid of Ramesses",
          "HTML5_Game_ID": "pyrr",
          "Button_Image_Name": "pyrr.jpg"
        },
        {
          "CN_Name": "彩池铃铛",
          "EN_Name": "Jackpot Bells",
          "HTML5_Game_ID": "jbells",
          "Button_Image_Name": "jbells.jpg"
        },
        {
          "CN_Name": "圣诞彩池铃铛",
          "EN_Name": "Christmas Jackpot Bells",
          "HTML5_Game_ID": "cbells",
          "Button_Image_Name": "cbells.jpg"
        },
        {
          "CN_Name": "绿野仙踪",
          "EN_Name": "Winnings of Oz",
          "HTML5_Game_ID": "ashwnoz",
          "Button_Image_Name": "ashwnoz.jpg"
        },
        {
          "CN_Name": "妖精的幸运",
          "EN_Name": "Leprechaun's Luck",
          "HTML5_Game_ID": "ashlcl",
          "Button_Image_Name": "ashlcl.jpg"
        },
        {
          "CN_Name": "水果大赢家",
          "EN_Name": "Juicy Booty",
          "HTML5_Game_ID": "ashjcb",
          "Button_Image_Name": "ashjcb.jpg"
        },
        {
          "CN_Name": "仙境冒险",
          "EN_Name": "Adventures Beyond Wonderland",
          "HTML5_Game_ID": "ashabw",
          "Button_Image_Name": "ashabw.jpg"
        },
        {
          "CN_Name": "众神时代：奥林匹斯统治者",
          "EN_Name": "Age of the Gods: Rulers of Olympus",
          "HTML5_Game_ID": "aogroo",
          "Button_Image_Name": "aogroo.jpg"
        },
        {
          "CN_Name": "无尽之龙",
          "EN_Name": "Infinity Dragon",
          "HTML5_Game_ID": "infdrg",
          "Button_Image_Name": "infdrg.jpg"
        },
        {
          "CN_Name": "世界统治者：帝国宝藏",
          "EN_Name": "Rulers of the World: Empire Treasures",
          "HTML5_Game_ID": "gfal",
          "Button_Image_Name": "gfal.jpg"
        },
        {
          "CN_Name": "假日车站豪华版",
          "EN_Name": "Vacation Station Deluxe",
          "HTML5_Game_ID": "vcstd",
          "Button_Image_Name": "vcstd.jpg"
        },
        {
          "CN_Name": "假日车站",
          "EN_Name": "Vacation Station",
          "HTML5_Game_ID": "er",
          "Button_Image_Name": "er.jpg"
        },
        {
          "CN_Name": "丛林之心",
          "EN_Name": "Heart of the Jungle",
          "HTML5_Game_ID": "ashhotj",
          "Button_Image_Name": "ashhotj.jpg"
        },
        {
          "CN_Name": "亚特兰蒂斯女王",
          "EN_Name": "Atlantis Queen",
          "HTML5_Game_ID": "gtsatq",
          "Button_Image_Name": "gtsatq.jpg"
        },
        {
          "CN_Name": "英式足球嘉年华",
          "EN_Name": "Football Carnival",
          "HTML5_Game_ID": "gtsfc",
          "Button_Image_Name": "gtsfc.jpg"
        },
        {
          "CN_Name": "命运女神",
          "EN_Name": "Miss Fortune",
          "HTML5_Game_ID": "mfrt",
          "Button_Image_Name": "mfrt.jpg"
        },
        {
          "CN_Name": "狼狼狼",
          "EN_Name": "Wolves Wolves Wolves",
          "HTML5_Game_ID": "www",
          "Button_Image_Name": "www.jpg"
        },
        {
          "CN_Name": "神灯宝藏",
          "EN_Name": "Treasures of the Lamps",
          "HTML5_Game_ID": "totl",
          "Button_Image_Name": "totl.jpg"
        },
        {
          "CN_Name": "星球觉醒",
          "EN_Name": "Stars Awakening",
          "HTML5_Game_ID": "strsawk",
          "Button_Image_Name": "strsawk.jpg"
        },
        {
          "CN_Name": "神石",
          "EN_Name": "Sacred Stones",
          "HTML5_Game_ID": "scrdstns",
          "Button_Image_Name": "scrdstns.jpg"
        },
        {
          "CN_Name": "皇家重转豪华版",
          "EN_Name": "Royal Respin Deluxe",
          "HTML5_Game_ID": "rrd",
          "Button_Image_Name": "rrd.jpg"
        },
        {
          "CN_Name": "马基荒野",
          "EN_Name": "Maji Wilds",
          "HTML5_Game_ID": "mwilds",
          "Button_Image_Name": "mwilds.jpg"
        },
        {
          "CN_Name": "谋杀之谜",
          "EN_Name": "Murder Mystery",
          "HTML5_Game_ID": "murder",
          "Button_Image_Name": "murder.jpg"
        },
        {
          "CN_Name": "闪耀生命",
          "EN_Name": "Luminous Life",
          "HTML5_Game_ID": "lumli",
          "Button_Image_Name": "lumli.jpg"
        },
        {
          "CN_Name": "赛鹅图",
          "EN_Name": "Juego De La Oca",
          "HTML5_Game_ID": "jdlo",
          "Button_Image_Name": "jdlo.jpg"
        },
        {
          "CN_Name": "全息荒野",
          "EN_Name": "Hologram Wilds",
          "HTML5_Game_ID": "hwilds",
          "Button_Image_Name": "hwilds.jpg"
        },
        {
          "CN_Name": "宝石狂热",
          "EN_Name": "Gem Heat",
          "HTML5_Game_ID": "gemheat",
          "Button_Image_Name": "gemheat.jpg"
        },
        {
          "CN_Name": "银河斑纹",
          "EN_Name": "Galactic Streak",
          "HTML5_Game_ID": "galst",
          "Button_Image_Name": "galst.jpg"
        },
        {
          "CN_Name": "达芬奇之顶",
          "EN_Name": "Da Vinci's Vault",
          "HTML5_Game_ID": "dvncv",
          "Button_Image_Name": "dvncv.jpg"
        },
        {
          "CN_Name": "系上安全带",
          "EN_Name": "Buckle Up",
          "HTML5_Game_ID": "bup",
          "Button_Image_Name": "bup.jpg"
        },
        {
          "CN_Name": "大人物",
          "EN_Name": "Big Shots",
          "HTML5_Game_ID": "bigsh",
          "Button_Image_Name": "bigsh.jpg"
        },
        {
          "CN_Name": "浆果大富园",
          "EN_Name": "Berry Berry Bonanza",
          "HTML5_Game_ID": "bbbo",
          "Button_Image_Name": "bbbo.jpg"
        },
        {
          "CN_Name": "丛林危机",
          "EN_Name": "Jungle Trouble",
          "HTML5_Game_ID": "ashjut",
          "Button_Image_Name": "ashjut.jpg"
        },
        {
          "CN_Name": "侏罗纪岛",
          "EN_Name": "Jurassic Island",
          "HTML5_Game_ID": "ashjid",
          "Button_Image_Name": "ashjid.jpg"
        },
        {
          "CN_Name": "好运连连幽灵火车",
          "EN_Name": "Fairground Fortunes Ghost Train",
          "HTML5_Game_ID": "ashfgf",
          "Button_Image_Name": "ashfgf.jpg"
        },
        {
          "CN_Name": "埃及时代",
          "EN_Name": "Age of Egypt",
          "HTML5_Game_ID": "agoeg",
          "Button_Image_Name": "agoeg.jpg"
        },
        {
          "CN_Name": "王后的权杖",
          "EN_Name": "Queen of Wands",
          "HTML5_Game_ID": "qnw",
          "Button_Image_Name": "qnw.jpg"
        },


        {
          "CN_Name": "神的时代：天空之王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogrots",
          "Button_Image_Name": "aogrots.png"
        }, {
          "CN_Name": "神的时代：强大之迈达斯",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogmt",
          "Button_Image_Name": "aogmt.png"
        }, {
          "CN_Name": "神的时代：美杜莎与妖怪",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogmm",
          "Button_Image_Name": "aogmm.png"
        }, {
          "CN_Name": "金猴",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_gmacaque_pop",
          "Button_Image_Name": "gpas_gmacaque_pop.png"
        }, {
          "CN_Name": "你属什么",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_nsshen_pop",
          "Button_Image_Name": "nsshen.png"
        }, {
          "CN_Name": "财神兴盛",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_tsgift_pop",
          "Button_Image_Name": "tsgift.png"
        }, {
          "CN_Name": "老虎堆",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_tstacks_pop",
          "Button_Image_Name": "tstacks.png"
        }, {
          "CN_Name": "蓝巫师",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_bwizard_pop",
          "Button_Image_Name": "bwizard.png"
        }, {
          "CN_Name": "疯狂猴子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mnkmn",
          "Button_Image_Name": "mnkmn.png"
        }, {
          "CN_Name": "白色王者2",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "whk2",
          "Button_Image_Name": "whk2.png"
        }, {
          "CN_Name": "三张扑克牌",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "3cb",
          "Button_Image_Name": "3Cardbrag.png"
        }, {
          "CN_Name": "所有投注黑杰克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "abbj",
          "Button_Image_Name": "AllBetsBlackjack.png"
        }, {
          "CN_Name": "玻璃拖鞋",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashglss",
          "Button_Image_Name": "ashglss.png"
        }, {
          "CN_Name": "交易时刻",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashtmd",
          "Button_Image_Name": "ashtmd.png"
        }, {
          "CN_Name": "返现黑杰克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bjcb",
          "Button_Image_Name": "CashbackBlackjack.png"
        }, {
          "CN_Name": "投降21点",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bjsd2",
          "Button_Image_Name": "BlackjackSurrender.png"
        }, {
          "CN_Name": "高级黑杰克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bjto",
          "Button_Image_Name": "PremiumBlackjack.png"
        }, {
          "CN_Name": "扑克",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "car",
          "Button_Image_Name": "CaribbeanStudPoker.png"
        }, {
          "CN_Name": "免费筹码21点",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fcbj",
          "Button_Image_Name": "FreeChipBlackjack.png"
        },  {
          "CN_Name": "龙门传说",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_dbond_pop",
          "Button_Image_Name": "gpas_dbond_pop.png"
        }, {
          "CN_Name": "金福龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_jflong_pop",
          "Button_Image_Name": "gpas_jflong_pop.png"
        }, {
          "CN_Name": "法老王的女儿",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_scqueen_pop",
          "Button_Image_Name": "gpas_scqueen_pop.png"
        }, {
          "CN_Name": "野蛮丛林",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_sjungle_pop",
          "Button_Image_Name": "gpas_sjungle_pop.png"
        }, {
          "CN_Name": "足智多谋",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "huh",
          "Button_Image_Name": "huhl.png"
        }, {
          "CN_Name": "金土地",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "lndg",
          "Button_Image_Name": "lndg.png"
        }, {
          "CN_Name": "迷你轮盘",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mro",
          "Button_Image_Name": "MiniRoulette.png"
        }, {
          "CN_Name": "三倍猴子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "trpmnk",
          "Button_Image_Name": "trpmnk.png"
        }, {
          "CN_Name": "金钱蛙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "jqw",
          "Button_Image_Name": "jqw.png"
        }, {
          "CN_Name": "荣耀罗马",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "rng2",
          "Button_Image_Name": "rng2.png"
        }, {
          "CN_Name": "幸运狮子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "frtln",
          "Button_Image_Name": "Fortune_Lions.png"
        }, {
          "CN_Name": "猫女王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "catqc",
          "Button_Image_Name": "catqc.png"
        }, {
          "CN_Name": "沙漠财宝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mobdt",
          "Button_Image_Name": "dt.png"
        }, {
          "CN_Name": "招财童子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "zctz",
          "Button_Image_Name": "zctz.png"
        }, {
          "CN_Name": "巴西森宝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtssmbr",
          "Button_Image_Name": "gtssmbr.png"
        }, {
          "CN_Name": "杰克与魔豆",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashbob",
          "Button_Image_Name": "ashbob.png"
        }, {
          "CN_Name": "赌场Hold 'Em游戏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "cheaa",
          "Button_Image_Name": "CasinoHoldEm.png"
        }, {
          "CN_Name": "埃斯梅拉达",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "esm",
          "Button_Image_Name": "esm.png"
        },   {
          "CN_Name": "凤凰之道",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "wotp",
          "Button_Image_Name": "wotp.png"
        }, {
          "CN_Name": "币币币",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ccccny",
          "Button_Image_Name": "ccccny.png"
        }, {
          "CN_Name": "虎爪",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "tigc",
          "Button_Image_Name": "tigc.png"
        }, {
          "CN_Name": "狂野蟒蛇",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "anwild",
          "Button_Image_Name": "anwild.png"
        }, {
          "CN_Name": "发财黑豹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_panthpays_pop",
          "Button_Image_Name": "gpas_panthpays_pop.png"
        }, {
          "CN_Name": "金福幸运",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_xjinfu_pop",
          "Button_Image_Name": "gpas_xjinfu_pop.png"
        }, {
          "CN_Name": "嫦娥奔月",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_elady_pop",
          "Button_Image_Name": "gpas_elady_pop.png"
        }, {
          "CN_Name": "青龙,白虎,朱雀,玄武",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_tigertdp_pop",
          "Button_Image_Name": "gpas_tigertdp_pop.png"
        }, {
          "CN_Name": "天之女王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gpas_squeen_pop",
          "Button_Image_Name": "gpas_squeen_pop.png"
        }, {
          "CN_Name": "百家乐",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ba",
          "Button_Image_Name": "Baccarat.png"
        }, {
          "CN_Name": "龙之战士",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "drgch",
          "Button_Image_Name": "drgch.png"
        }, {
          "CN_Name": "猿人传奇",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "epa",
          "Button_Image_Name": "epa.png"
        }, {
          "CN_Name": "每个人头奖",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "evj",
          "Button_Image_Name": "EverybodysJackpot.png"
        }, {
          "CN_Name": "丛林巨兽",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "jnglg",
          "Button_Image_Name": "jnglg.png"
        }, {
          "CN_Name": "龙家祥运",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ljxy",
          "Button_Image_Name": "ljxy.png"
        }, {
          "CN_Name": "超级法老王宝藏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "phtd",
          "Button_Image_Name": "phtd.png"
        }, {
          "CN_Name": "武士元素",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pisa",
          "Button_Image_Name": "pisa.png"
        }, {
          "CN_Name": "幸运盖尔",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "popc",
          "Button_Image_Name": "popc.png"
        }, {
          "CN_Name": "云从龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "yclong",
          "Button_Image_Name": "YunCongLong.png"
        }, {
          "CN_Name": "玄圃联欢",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "xufe",
          "Button_Image_Name": "XuanPuLianHuan.png"
        }, {
          "CN_Name": "烈焰钻石",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ght_a",
          "Button_Image_Name": "LieYanZuanShi.png"
        }, {
          "CN_Name": "FunkyMonkeyJackpot",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fmjp",
          "Button_Image_Name": "FunkyMonkeyJackpot.png"
        }, {
          "CN_Name": "Great Blue Jackpot",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "grbjp",
          "Button_Image_Name": "GreatBlueJackpot.png"
        }, {
          "CN_Name": "幸运日",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fday",
          "Button_Image_Name": "FortuneDay.png"
        }, {
          "CN_Name": "God of Storms",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aeolus",
          "Button_Image_Name": "AgeoftheGodsGodofStorms.png"
        }, {
          "CN_Name": "LegacyOfWild",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "legwld",
          "Button_Image_Name": "LegacyoftheWild.png"
        }, {
          "CN_Name": "Ice Cave",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashicv",
          "Button_Image_Name": "IceCave.png"
        }, {
          "CN_Name": "HeartOfFrontier",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashhof",
          "Button_Image_Name": "HeartoftheFrontier.png"
        }, {
          "CN_Name": "热紫",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "phot",
          "Button_Image_Name": "PurpleHotKiosk.png"
        }, {
          "CN_Name": "Amazon Wild",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashamw",
          "Button_Image_Name": "amazon_wild.jpg"
        }, {
          "CN_Name": "亚洲幻想",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "asfa",
          "Button_Image_Name": "Asian_Fantasy.jpg"
        }, {
          "CN_Name": "武则天",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "heavru",
          "Button_Image_Name": "heavenly_ruler.png"
        }, {
          "CN_Name": "超级狮子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "slion",
          "Button_Image_Name": "super_lion.png"
        }, {
          "CN_Name": "孙悟空",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsswk",
          "Button_Image_Name": "new_10.png"
        }, {
          "CN_Name": "玉皇大帝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsje",
          "Button_Image_Name": "Jade_Emperor_138x125.png"
        }, {
          "CN_Name": "神的时代：雷霆4神",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "furf",
          "Button_Image_Name": "Furious_4_source_file.png"
        }, {
          "CN_Name": "印加帝国头奖",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aztec",
          "Button_Image_Name": "Inca_JackPot_138x125.png"
        }, {
          "CN_Name": "火热KTV",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hotktv",
          "Button_Image_Name": "Hot_KTV.png"
        }, {
          "CN_Name": "超级888",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "chao",
          "Button_Image_Name": "Chaoji_888_138x125.png"
        }, {
          "CN_Name": "角斗士彩池游戏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "glrj",
          "Button_Image_Name": "tiger_41.jpg"
        }, {
          "CN_Name": "日日进财",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ririjc",
          "Button_Image_Name": "RiRiJinCai_138x125.png"
        }, {
          "CN_Name": "疯狂乐透",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "lm",
          "Button_Image_Name": "tiger_37.jpg"
        }, {
          "CN_Name": "黄金之旅",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gos",
          "Button_Image_Name": "golden_tour.jpg"
        }, {
          "CN_Name": "吉祥8",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsjxb",
          "Button_Image_Name": "JiXiang.png"
        }, {
          "CN_Name": "水牛闪电",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bfb",
          "Button_Image_Name": "buffalo_blitz_138x125.png"
        }, {
          "CN_Name": "疯狂麻将",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fkmj",
          "Button_Image_Name": "Feng_Kuang_Ma_Jiang_138x125.png"
        }, {
          "CN_Name": "Halloween Fortune II",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hlf2",
          "Button_Image_Name": "Halloween_Fortune_II_138x125.png"
        }, {
          "CN_Name": "幸运爱尔兰",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "irl",
          "Button_Image_Name": "irish_luck.jpg"
        }, {
          "CN_Name": "神灵时代：命运姐妹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ftsis",
          "Button_Image_Name": "Age_of_the_Gods_Fate_Sisters_138x125.png"
        },  {
          "CN_Name": "小猪与狼",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "paw",
          "Button_Image_Name": "piggle_wolf.jpg"
        },  {
          "CN_Name": "水果狂",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fmn",
          "Button_Image_Name": "fruit_mania.jpg"
        }, {
          "CN_Name": "舞龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "wlg",
          "Button_Image_Name": "dragon_dance.jpg"
        }, {
          "CN_Name": "神的时代：智慧女神",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "athn",
          "Button_Image_Name": "Age_of_the_Gods_Goddess_of_Wisdom_Athena_138x125.png"
        }, {
          "CN_Name": "五路财神",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "wlcsh",
          "Button_Image_Name": "WuLuCaiShen_SourceFile.png"
        }, {
          "CN_Name": "五虎将",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ftg",
          "Button_Image_Name": "5TigerGenerals_SourceFile.png"
        }, {
          "CN_Name": "六福兽",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "kfp",
          "Button_Image_Name": "Liu_Fu_Shou_PSD_Source.png"
        }, {
          "CN_Name": "众神时代",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "aogs",
          "Button_Image_Name": "Age_of_the_Gods_King_of_Olympus_138x125.png"
        }, {
          "CN_Name": "黑豹之月",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pmn",
          "Button_Image_Name": "panther_moon.png"
        }, {
          "CN_Name": "辛巴达的金色航程",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashsbd",
          "Button_Image_Name": "sinbad_golden_voyage.png"
        }, {
          "CN_Name": "宝物箱中寻",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashcpl",
          "Button_Image_Name": "chests_of_plenty.png"
        }, {
          "CN_Name": "拉斯维加斯的猫",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ctiv",
          "Button_Image_Name": "cat_in_vegas.png"
        }, {
          "CN_Name": "大明帝国",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsgme",
          "Button_Image_Name": "great_ming_empire.png"
        }, {
          "CN_Name": "海滨嘉年华",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bl",
          "Button_Image_Name": "Beach-Life.jpg"
        }, {
          "CN_Name": "金色召集",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "grel",
          "Button_Image_Name": "Gold-Rally.jpg"
        }, {
          "CN_Name": "Fairest of them All",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashfta",
          "Button_Image_Name": "fairest_of_them_all.png"
        }, {
          "CN_Name": "年年有余",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "nian",
          "Button_Image_Name": "nian_nian_you_yu.png"
        }, {
          "CN_Name": "堂吉诃德的财富",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "donq",
          "Button_Image_Name": "riches_of_don_quixote.png"
        }, {
          "CN_Name": "招财进宝彩池游戏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "zcjbjp",
          "Button_Image_Name": "zhao_cai_jin_bao.png"
        }, {
          "CN_Name": "非洲炙热",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sfh",
          "Button_Image_Name": "safari_heat.png"
        }, {
          "CN_Name": "翡翠公主",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fcgz",
          "Button_Image_Name": "fei_cui_gong_zhu.png"
        }, {
          "CN_Name": "鬼屋",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hh",
          "Button_Image_Name": "Haunted House_191X181.png"
        }, {
          "CN_Name": "沉默的武士",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sis",
          "Button_Image_Name": "Silent_Samurai_Jackpot_138x125.png"
        }, {
          "CN_Name": "泰国天堂",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "tpd2",
          "Button_Image_Name": "Thai Paradise_191x181.png"
        }, {
          "CN_Name": "熊之舞",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bob",
          "Button_Image_Name": "bonus_bears.png"
        }, {
          "CN_Name": "艺伎故事",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ges",
          "Button_Image_Name": "geisha_story.png"
        }, {
          "CN_Name": "热力宝石",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gts50",
          "Button_Image_Name": "HotGems_191X181.png"
        }, {
          "CN_Name": "飞龙在天",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtsflzt",
          "Button_Image_Name": "Fei Long Zai Tian_191x181.png"
        }, {
          "CN_Name": "一夜奇遇",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hb",
          "Button_Image_Name": "A_Night_Out_138x125.png"
        }, {
          "CN_Name": "万圣节财富",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hlf",
          "Button_Image_Name": "Halloween Fortune_138x125.png"
        }, {
          "CN_Name": "中国厨房",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "cm",
          "Button_Image_Name": "hot_6.jpg"
        }, {
          "CN_Name": "趣味水果",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fnfrj",
          "Button_Image_Name": "new_5.jpg"
        }, {
          "CN_Name": "四象",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sx",
          "Button_Image_Name": "new_13.png"
        }, {
          "CN_Name": "魔法堆叠",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mgstk",
          "Button_Image_Name": "new_11.png"
        }, {
          "CN_Name": "白狮王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "whk",
          "Button_Image_Name": "hot_15.jpg"
        }, {
          "CN_Name": "白狮",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bs",
          "Button_Image_Name": "new_7.png"
        }, {
          "CN_Name": "赌徒",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtswg",
          "Button_Image_Name": "tiger_24.jpg"
        }, {
          "CN_Name": "百慕大三角",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bt",
          "Button_Image_Name": "tiger_35.jpg"
        }, {
          "CN_Name": "诙谐财富",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fxf",
          "Button_Image_Name": "hot_16.jpg"
        }, {
          "CN_Name": "法老王的秘密",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pst",
          "Button_Image_Name": "tiger_23.jpg"
        }, {
          "CN_Name": "返利先生",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "mcb",
          "Button_Image_Name": "cash_back.jpg"
        }, {
          "CN_Name": "亚马逊的秘密",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "samz",
          "Button_Image_Name": "tiger_28.jpg"
        }, {
          "CN_Name": "招财进宝",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "zcjb",
          "Button_Image_Name": "new_6.png"
        }, {
          "CN_Name": "弓兵",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "arc",
          "Button_Image_Name": "tiger_40.jpg"
        },

        {
          "CN_Name": "非常幸运",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gtspor",
          "Button_Image_Name": "new_16.jpg"
        }, {
          "CN_Name": "夏洛克的秘密",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "shmst",
          "Button_Image_Name": "tiger_63.jpg"
        }, {
          "CN_Name": "海王星王国",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "nk",
          "Button_Image_Name": "neptunes_kingdom.jpg"
        },  {
          "CN_Name": "终极足球",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fbr",
          "Button_Image_Name": "football_rules.jpg"
        }, {
          "CN_Name": "真爱",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "trl",
          "Button_Image_Name": "tiger_94.jpg"
        }, {
          "CN_Name": "丛林巫师",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ub",
          "Button_Image_Name": "Ugga-Bugga.jpg"
        }, {
          "CN_Name": "奖金巨人",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "jpgt",
          "Button_Image_Name": "jackpot_giant.jpg"
        }, {
          "CN_Name": "好运连胜",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sol",
          "Button_Image_Name": "streak_of_luck.jpg"
        }, {
          "CN_Name": "企鹅假期",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "pgv",
          "Button_Image_Name": "penguin_vacation.png"
        }, {
          "CN_Name": "古怪猴子",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "fm",
          "Button_Image_Name": "hot_3.jpg"
        }, {
          "CN_Name": "湛蓝深海",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "bib",
          "Button_Image_Name": "great_blue.jpg"
        }, {
          "CN_Name": "高速公路之王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hk",
          "Button_Image_Name": "hot_4.jpg"
        }, {
          "CN_Name": "船长的宝藏",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ct",
          "Button_Image_Name": "hot_1.jpg"
        }, {
          "CN_Name": "疯狂之七",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "c7",
          "Button_Image_Name": "hot_10.jpg"
        }, {
          "CN_Name": "圣诞奇迹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ssp",
          "Button_Image_Name": "santa_surprise.jpg"
        }, {
          "CN_Name": "惊喜复活节",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "eas",
          "Button_Image_Name": "easter_surprise.jpg"
        }, {
          "CN_Name": "甜蜜派对",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "cnpr",
          "Button_Image_Name": "hot_20.jpg"
        }, {
          "CN_Name": "海豚礁",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "dnr",
          "Button_Image_Name": "dolphin_reef.png"
        }, {
          "CN_Name": "宝石女王",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "gemq",
          "Button_Image_Name": "Gem_Queen_138x125.png"
        }, {
          "CN_Name": "龙龙龙",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "longlong",
          "Button_Image_Name": "long_long_long.png"
        }, {
          "CN_Name": "神的时代：奥林匹斯",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "hrcls",
          "Button_Image_Name": "Age_of_the_Gods_Prince_of_Olympus_138x125.png"
        }, {
          "CN_Name": "银子弹",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "sib",
          "Button_Image_Name": "silver_bullet.jpg"
        }, {
          "CN_Name": "仙境冒险豪华版",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ashadv",
          "Button_Image_Name": "Adventures_in_Wonderland_Deluxe.png"
        }, {
          "CN_Name": "日日生财",
          "EN_Name": "Yun Cong Long",
          "HTML5_Game_ID": "ririshc",
          "Button_Image_Name": "Ri_Ri_Sheng_Cai_138x125.png"
        }
      ]
    },

    {
      "type": 4,
      "game_id": 402,
      "game_api": "PG",
      "name": "PG老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/pg.png",
      "icon": "logo-pg",
      "game_list_json": "PG_game_list",
      "game_list_img_path": "/new/slot_res/pg/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：爱尔兰精灵、麻将胡了、麻將胡了2、寻宝黄金城、招财喵...",
      "labels": "#老虎机#爱尔兰精灵#麻将胡了#冰雪大冲关#赏金船长",
      gameList: [
        {
          "CN_Name": "麻将胡了",
          "HTML5_Game_ID": "65",
          "EN_Name": "Mahjong Ways",
          "Button_Image_Name": "65.png"
        },
        {
          "CN_Name": "麻将胡了2",
          "HTML5_Game_ID": "74",
          "EN_Name": "Mahjong Ways 2",
          "Button_Image_Name": "74.png"
        },
        {
          "CN_Name": "寻宝黄金城",
          "HTML5_Game_ID": "87",
          "EN_Name": "Treasures of Aztec",
          "Button_Image_Name": "87.png"
        },
        {
          "CN_Name": "爱尔兰精灵",
          "HTML5_Game_ID": "60",
          "EN_Name": "Leprechaun Riches",
          "Button_Image_Name": "60.png"
        },
        {
          "CN_Name": "招财喵",
          "HTML5_Game_ID": "89",
          "EN_Name": "Lucky Neko",
          "Button_Image_Name": "89.png"
        },
        {
          "CN_Name": "赏金船长",
          "HTML5_Game_ID": "54",
          "EN_Name": "Captain's Bounty",
          "Button_Image_Name": "54.png"
        },
        {
          "CN_Name": "赏金女王",
          "HTML5_Game_ID": "84",
          "EN_Name": "Queen of Bounty ",
          "Button_Image_Name": "84.png"
        },
        {
          "CN_Name": "亡灵大盗",
          "HTML5_Game_ID": "104",
          "EN_Name": "Wild Bandito",
          "Button_Image_Name": "104.png"
        },
        {
          "CN_Name": "麒麟送宝",
          "HTML5_Game_ID": "106",
          "EN_Name": "Ways of the Qilin",
          "Button_Image_Name": "106.png"
        },
        {
          "CN_Name": "寻龙探宝",
          "HTML5_Game_ID": "57",
          "EN_Name": "Dragon Hatch",
          "Button_Image_Name": "57.png"
        },
        {
          "CN_Name": "双囍临门",
          "HTML5_Game_ID": "48",
          "EN_Name": "Double Fortune",
          "Button_Image_Name": "48.png"
        },
        {
          "CN_Name": "冰雪大冲关",
          "HTML5_Game_ID": "53",
          "EN_Name": "The Great Icescape",
          "Button_Image_Name": "53.png"
        },
        {
          "CN_Name": "赢财神",
          "HTML5_Game_ID": "71",
          "EN_Name": "Caishen Wins",
          "Button_Image_Name": "71.png"
        },
        {
          "CN_Name": "福运象财神",
          "HTML5_Game_ID": "75",
          "EN_Name": "Ganesha Fortune",
          "Button_Image_Name": "75.png"
        },
        {
          "CN_Name": "澳门壕梦",
          "HTML5_Game_ID": "79",
          "EN_Name": "Dreams of Macau",
          "Button_Image_Name": "79.png"
        },
        {
          "CN_Name": "十倍金牛",
          "HTML5_Game_ID": "98",
          "EN_Name": "Fortune Ox",
          "Button_Image_Name": "98.png"
        },
        {
          "CN_Name": "赏金大对决",
          "HTML5_Game_ID": "135",
          "EN_Name": "Wild Bounty Showdown",
          "Button_Image_Name": "135.png"
        },
        {
          "CN_Name": "黄金摇钱树",
          "HTML5_Game_ID": "1312883",
          "EN_Name": "Prosperity Fortune Tree",
          "Button_Image_Name": "1312883.png"
        },
        {
          "CN_Name": "美食美刻",
          "HTML5_Game_ID": "1372643",
          "EN_Name": "Diner Delights",
          "Button_Image_Name": "1372643.png"
        },

        {
          "CN_Name": "凤凰传奇",
          "HTML5_Game_ID": "82",
          "EN_Name": "Phoenix Rises",
          "Button_Image_Name": "82.png"
        },
        {
          "CN_Name": "火树赢花",
          "HTML5_Game_ID": "83",
          "EN_Name": "Wild Fireworks",
          "Button_Image_Name": "83.png"
        },
        {
          "CN_Name": "水上泰神奇",
          "HTML5_Game_ID": "92",
          "EN_Name": "Thai River Wonders",
          "Button_Image_Name": "92.png"
        },
        {
          "CN_Name": "巴厘之旅",
          "HTML5_Game_ID": "94",
          "EN_Name": "Bali Vacation",
          "Button_Image_Name": "94.png"
        },
        {
          "CN_Name": "比特淘金",
          "HTML5_Game_ID": "103",
          "EN_Name": "Crypto Gold",
          "Button_Image_Name": "103.png"
        },
        {
          "CN_Name": "恐龙帝国",
          "HTML5_Game_ID": "110",
          "EN_Name": "Jurassic Kingdom",
          "Button_Image_Name": "110.png"
        },
        {
          "CN_Name": "夜醉佳人",
          "HTML5_Game_ID": "117",
          "EN_Name": "Cocktail Nights",
          "Button_Image_Name": "117.png"
        },
        {
          "CN_Name": "虎虎生财",
          "HTML5_Game_ID": "126",
          "EN_Name": "Fortune Tiger",
          "Button_Image_Name": "126.png"
        },
        {
          "CN_Name": "极速赢家",
          "HTML5_Game_ID": "127",
          "EN_Name": "Speed Winner",
          "Button_Image_Name": "127.png"
        },
        {
          "CN_Name": "珀尔修斯传奇",
          "HTML5_Game_ID": "128",
          "EN_Name": "Legend of Perseus",
          "Button_Image_Name": "128.png"
        },
        {
          "CN_Name": "夜戏貂蝉",
          "HTML5_Game_ID": "1",
          "EN_Name": "Honey Trap of Diao Chan",
          "Button_Image_Name": "1.png"
        },
        {
          "CN_Name": "横财来啦",
          "HTML5_Game_ID": "3",
          "EN_Name": "Fortune Gods",
          "Button_Image_Name": "3.png"
        },
        {
          "CN_Name": "旺旺旺",
          "HTML5_Game_ID": "24",
          "EN_Name": "Win Win Won",
          "Button_Image_Name": "24.png"
        },
        {
          "CN_Name": "美杜莎 II",
          "HTML5_Game_ID": "6",
          "EN_Name": "Medusa II",
          "Button_Image_Name": "6.png"
        },
        {
          "CN_Name": "摇钱树",
          "HTML5_Game_ID": "26",
          "EN_Name": "Tree of Fortune",
          "Button_Image_Name": "26.png"
        },
        {
          "CN_Name": "美杜莎 ",
          "HTML5_Game_ID": "7",
          "EN_Name": "Medusa ",
          "Button_Image_Name": "7.png"
        },
        {
          "CN_Name": "抓抓乐",
          "HTML5_Game_ID": "25",
          "EN_Name": "Plushie Frenzy",
          "Button_Image_Name": "25.png"
        },
        {
          "CN_Name": "宝石侠",
          "HTML5_Game_ID": "2",
          "EN_Name": "Gem Saviour",
          "Button_Image_Name": "2.png"
        },
        {
          "CN_Name": "逆袭的小红帽",
          "HTML5_Game_ID": "18",
          "EN_Name": "Hood vs Wolf",
          "Button_Image_Name": "18.png"
        },
        {
          "CN_Name": "麻辣火锅",
          "HTML5_Game_ID": "28",
          "EN_Name": "Hotpot",
          "Button_Image_Name": "28.png"
        },
        {
          "CN_Name": "鱼跃龙门",
          "HTML5_Game_ID": "29",
          "EN_Name": "Dragon Legend",
          "Button_Image_Name": "29.png"
        },
        {
          "CN_Name": "万胜狂欢夜",
          "HTML5_Game_ID": "35",
          "EN_Name": "Mr. Hallow-Win",
          "Button_Image_Name": "35.png"
        },
        {
          "CN_Name": "后羿射日",
          "HTML5_Game_ID": "34",
          "EN_Name": "Legend of Hou Yi",
          "Button_Image_Name": "34.png"
        },
        {
          "CN_Name": "舞狮进宝",
          "HTML5_Game_ID": "36",
          "EN_Name": "Prosperity Lion",
          "Button_Image_Name": "36.png"
        },
        {
          "CN_Name": "嘻哈熊猫",
          "HTML5_Game_ID": "33",
          "EN_Name": "Hip Hop Panda",
          "Button_Image_Name": "33.png"
        },
        {
          "CN_Name": "圣诞欢乐送",
          "HTML5_Game_ID": "37",
          "EN_Name": "Santa's Gift Rush",
          "Button_Image_Name": "37.png"
        },
        {
          "CN_Name": "至尊百家乐",
          "HTML5_Game_ID": "31",
          "EN_Name": "Baccarat Deluxe",
          "Button_Image_Name": "31.png"
        },
        {
          "CN_Name": "宝石侠-大宝剑",
          "HTML5_Game_ID": "38",
          "EN_Name": "Gem Saviour Sword",
          "Button_Image_Name": "38.png"
        },
        {
          "CN_Name": "金猪报财",
          "HTML5_Game_ID": "39",
          "EN_Name": "Piggy Gold",
          "Button_Image_Name": "39.png"
        },
        {
          "CN_Name": "埃及寻宝",
          "HTML5_Game_ID": "41",
          "EN_Name": "Symbols of Egypt",
          "Button_Image_Name": "41.png"
        },
        {
          "CN_Name": "皇上吉祥",
          "HTML5_Game_ID": "44",
          "EN_Name": "Emperor's Favour",
          "Button_Image_Name": "44.png"
        },
        {
          "CN_Name": "象财神",
          "HTML5_Game_ID": "42",
          "EN_Name": "Ganesha Gold",
          "Button_Image_Name": "42.png"
        },
        {
          "CN_Name": "水果丛林",
          "HTML5_Game_ID": "40",
          "EN_Name": "Jungle Delight",
          "Button_Image_Name": "40.png"
        },
        {
          "CN_Name": "嘻游记",
          "HTML5_Game_ID": "50",
          "EN_Name": "Journey to the Wealth",
          "Button_Image_Name": "50.png"
        },
        {
          "CN_Name": "唐伯虎点秋香",
          "HTML5_Game_ID": "61",
          "EN_Name": "Flirting Scholar",
          "Button_Image_Name": "61.png"
        },
        {
          "CN_Name": "忍者vs武侍",
          "HTML5_Game_ID": "59",
          "EN_Name": "Ninja vs Samurai",
          "Button_Image_Name": "59.png"
        },
        {
          "CN_Name": "拳霸",
          "HTML5_Game_ID": "64",
          "EN_Name": "Muay Thai Champion",
          "Button_Image_Name": "64.png"
        },
        {
          "CN_Name": "龙虎争霸",
          "HTML5_Game_ID": "63",
          "EN_Name": "Dragon Tiger Luck",
          "Button_Image_Name": "63.png"
        },
        {
          "CN_Name": "鼠鼠福福",
          "HTML5_Game_ID": "68",
          "EN_Name": "Fortune Mouse",
          "Button_Image_Name": "68.png"
        },
        {
          "CN_Name": "亲爱的",
          "HTML5_Game_ID": "20",
          "EN_Name": "Reel Love",
          "Button_Image_Name": "20.png"
        },
        {
          "CN_Name": "少林足球",
          "HTML5_Game_ID": "67",
          "EN_Name": "Shaolin Soccer",
          "Button_Image_Name": "67.png"
        },
        {
          "CN_Name": "比基尼天堂",
          "HTML5_Game_ID": "69",
          "EN_Name": "Bikini Paradise ",
          "Button_Image_Name": "69.png"
        },
        {
          "CN_Name": "阿拉丁神灯",
          "HTML5_Game_ID": "85",
          "EN_Name": "Genie's 3 Wishes",
          "Button_Image_Name": "85.png"
        },
        {
          "CN_Name": "欢乐嘉年华",
          "HTML5_Game_ID": "80",
          "EN_Name": "Circus Delight",
          "Button_Image_Name": "80.png"
        },
        {
          "CN_Name": " 艳后之谜",
          "HTML5_Game_ID": "90",
          "EN_Name": "Secrets of Cleopatra",
          "Button_Image_Name": "90.png"
        },
        {
          "CN_Name": " 德古拉女爵",
          "HTML5_Game_ID": "58",
          "EN_Name": "Vampire's Charm",
          "Button_Image_Name": "58.png"
        },
        {
          "CN_Name": "金玉满堂",
          "HTML5_Game_ID": "88",
          "EN_Name": "Jewels of Prosperity",
          "Button_Image_Name": "88.png"
        },
        {
          "CN_Name": "冰锋奇侠",
          "HTML5_Game_ID": "97",
          "EN_Name": "Jack Frost's Winter",
          "Button_Image_Name": "97.png"
        },
        {
          "CN_Name": "星旅淘金",
          "HTML5_Game_ID": "86",
          "EN_Name": "Galactic Gems",
          "Button_Image_Name": "86.png"
        },
        {
          "CN_Name": "冰火双娇",
          "HTML5_Game_ID": "91",
          "EN_Name": "Guardians of Ice and Fire",
          "Button_Image_Name": "91.png"
        },
        {
          "CN_Name": "新国粹",
          "HTML5_Game_ID": "93",
          "EN_Name": "Opera Dynasty",
          "Button_Image_Name": "93.png"
        },
        {
          "CN_Name": "宝石传奇",
          "HTML5_Game_ID": "95",
          "EN_Name": "Majestic Treasures",
          "Button_Image_Name": "95.png"
        },
        {
          "CN_Name": "糖心风暴",
          "HTML5_Game_ID": "100",
          "EN_Name": "Candy Bonanza",
          "Button_Image_Name": "100.png"
        },
        {
          "CN_Name": "霹雳神偷",
          "HTML5_Game_ID": "105",
          "EN_Name": "Heist  Stakes",
          "Button_Image_Name": "105.png"
        },
        {
          "CN_Name": "太阳神传说",
          "HTML5_Game_ID": "101",
          "EN_Name": "Rise of Apollo",
          "Button_Image_Name": "101.png"
        },
        {
          "CN_Name": "人鱼公主",
          "HTML5_Game_ID": "102",
          "EN_Name": "Mermaid Riches",
          "Button_Image_Name": "102.png"
        },
        {
          "CN_Name": "丽影奇兵之探秘埃及",
          "HTML5_Game_ID": "113",
          "EN_Name": "Raider Jane's Crypt of Fortune",
          "Button_Image_Name": "113.png"
        },
        {
          "CN_Name": "超市大血拼",
          "HTML5_Game_ID": "115",
          "EN_Name": "Supermarket Spree",
          "Button_Image_Name": "115.png"
        },
        {
          "CN_Name": "美洲野牛",
          "HTML5_Game_ID": "108",
          "EN_Name": "Buffalo Win",
          "Button_Image_Name": "108.png"
        },
        {
          "CN_Name": "美猴王传奇",
          "HTML5_Game_ID": "107",
          "EN_Name": "Legendary Monkey King",
          "Button_Image_Name": "107.png"
        },
        {
          "CN_Name": "百鬼夜行",
          "HTML5_Game_ID": "119",
          "EN_Name": "Spirited Wonders",
          "Button_Image_Name": "119.png"
        },
        {
          "CN_Name": "钞级表情包",
          "HTML5_Game_ID": "114",
          "EN_Name": "Emoji Riches",
          "Button_Image_Name": "114.png"
        },
        {
          "CN_Name": "假面嘉年华",
          "HTML5_Game_ID": "118",
          "EN_Name": "Mask Carnival",
          "Button_Image_Name": "118.png"
        },
        {
          "CN_Name": "江山美景图",
          "HTML5_Game_ID": "112",
          "EN_Name": "Oriental Prosperity",
          "Button_Image_Name": "112.png"
        },
        {
          "CN_Name": "神鹰宝石",
          "HTML5_Game_ID": "122",
          "EN_Name": "Garuda Gems",
          "Button_Image_Name": "122.png"
        },
        {
          "CN_Name": "日月星辰",
          "HTML5_Game_ID": "121",
          "EN_Name": "Destiny of Sun & Moon",
          "Button_Image_Name": "121.png"
        },
        {
          "CN_Name": "蝶恋花",
          "HTML5_Game_ID": "125",
          "EN_Name": "Butterfly Blossom",
          "Button_Image_Name": "125.png"
        },
        {
          "CN_Name": "斗鸡",
          "HTML5_Game_ID": "123",
          "EN_Name": "Rooster Rumble",
          "Button_Image_Name": "123.png"
        },
        {
          "CN_Name": "韩宫御宴",
          "HTML5_Game_ID": "120",
          "EN_Name": "The Queen's Banquet",
          "Button_Image_Name": "120.png"
        },
        {
          "CN_Name": "绝地大逃杀",
          "HTML5_Game_ID": "124",
          "EN_Name": "Battleground Royale",
          "Button_Image_Name": "124.png"
        },
        {
          "CN_Name": "发财鱼虾蟹",
          "HTML5_Game_ID": "129",
          "EN_Name": "Win Win Fish Prawn Crab",
          "Button_Image_Name": "129.png"
        },
        {
          "CN_Name": "电玩金猪",
          "HTML5_Game_ID": "130",
          "EN_Name": "Lucky Piggy",
          "Button_Image_Name": "130.png"
        },
        {
          "CN_Name": "疯赚过山车",
          "HTML5_Game_ID": "132",
          "EN_Name": "Wild Coaster",
          "Button_Image_Name": "132.png"
        },
        {
          "CN_Name": "图腾奇迹",
          "HTML5_Game_ID": "1338274",
          "EN_Name": "Totem Wonders",
          "Button_Image_Name": "1338274.png"
        },
        {
          "CN_Name": "炼金工坊",
          "HTML5_Game_ID": "1368367",
          "EN_Name": "Alchemy Gold",
          "Button_Image_Name": "1368367.png"
        },
        {
          "CN_Name": "维京纪元",
          "HTML5_Game_ID": "1340277",
          "EN_Name": "Asgardian Rising",
          "Button_Image_Name": "1340277.png"
        },
        {
          "CN_Name": "点石成金",
          "HTML5_Game_ID": "1402846",
          "EN_Name": "Midas Fortune",
          "Button_Image_Name": "1402846.png"
        },
        {
          "CN_Name": "金钱兔",
          "HTML5_Game_ID": "1543462",
          "EN_Name": "Fortune Rabbit",
          "Button_Image_Name": "1543462.png"
        },
        {
          "CN_Name": "电音派对",
          "HTML5_Game_ID": "1420892",
          "EN_Name": "Rave Party Fever",
          "Button_Image_Name": "1420892.png"
        },
        {
          "CN_Name": "夏威夷探奇",
          "HTML5_Game_ID": "1381200",
          "EN_Name": "Hawaiian Tiki",
          "Button_Image_Name": "1381200.png"
        },
        {
          "CN_Name": "烘焙总动员",
          "HTML5_Game_ID": "1418544",
          "EN_Name": "Bakery Bonanza",
          "Button_Image_Name": "1418544.png"
        },
        {
          "CN_Name": "泰嗨泼水节",
          "HTML5_Game_ID": "1448762",
          "EN_Name": "Songkran Splash",
          "Button_Image_Name": "1448762.png"
        },
        {
          "CN_Name": "元素精灵",
          "HTML5_Game_ID": "1432733",
          "EN_Name": "Mystical Spirits",
          "Button_Image_Name": "1432733.png"
        },
        {
          "CN_Name": "超级高尔夫",
          "HTML5_Game_ID": "1513328",
          "EN_Name": "Super Golf Drive",
          "Button_Image_Name": "1513328.png"
        },
        {
          "CN_Name": "幸运草之恋",
          "HTML5_Game_ID": "1601012",
          "EN_Name": "Lucky Clover Lady",
          "Button_Image_Name": "1601012.png"
        },
        {
          "CN_Name": "水果甜心",
          "HTML5_Game_ID": "1397455",
          "EN_Name": "Fruity Candy",
          "Button_Image_Name": "1397455.png"
        },
        {
          "CN_Name": "皇家游轮",
          "HTML5_Game_ID": "1473388",
          "EN_Name": "Cruise Royale",
          "Button_Image_Name": "1473388.png"
        },
        {
          "CN_Name": "非洲大冒险",
          "HTML5_Game_ID": "1594259",
          "EN_Name": "Safari Wilds",
          "Button_Image_Name": "1594259.png"
        },
        {
          "CN_Name": "角斗士荣耀",
          "HTML5_Game_ID": "1572362",
          "EN_Name": "Gladiator's Glory",
          "Button_Image_Name": "1572362.png"
        },
        {
          "CN_Name": "忍者小浣熊",
          "HTML5_Game_ID": "1529867",
          "EN_Name": "Ninja Racoon Frenzy",
          "Button_Image_Name": "1529867.png"
        },
        {
          "CN_Name": "金球射手",
          "HTML5_Game_ID": "1489936",
          "EN_Name": "Ultimate Striker",
          "Button_Image_Name": "1489936.png"
        },
        {
          "CN_Name": "怪盗神偷",
          "HTML5_Game_ID": "1568554",
          "EN_Name": "Wild Heist Cashout",
          "Button_Image_Name": "1568554.png"
        },
        {
          "CN_Name": "铸造大师",
          "HTML5_Game_ID": "1555350",
          "EN_Name": "Forge of Wealth",
          "Button_Image_Name": "1555350.png"
        },
        {
          "CN_Name": "黑帮风云",
          "HTML5_Game_ID": "1580541",
          "EN_Name": "Mafia Mayhem",
          "Button_Image_Name": "1580541.png"
        },
        {
          "CN_Name": "沙皇宝藏",
          "HTML5_Game_ID": "1655268",
          "EN_Name": "Tsar Treasures",
          "Button_Image_Name": "1655268.png"
        },
        {
          "CN_Name": "狼人传奇",
          "HTML5_Game_ID": "1615454",
          "EN_Name": "Werewolf's Hunt",
          "Button_Image_Name": "1615454.png"
        },
        {
          "CN_Name": "寻龙探宝 2",
          "HTML5_Game_ID": "1451122",
          "EN_Name": "Dragon Hatch 2",
          "Button_Image_Name": "1451122.png"
        },
        {
          "CN_Name": "金龙送宝",
          "HTML5_Game_ID": "1695365",
          "EN_Name": "Fortune Dragon",
          "Button_Image_Name": "1695365.png"
        },
        {
          "CN_Name": "宝石矿工",
          "HTML5_Game_ID": "1671262",
          "EN_Name": "Gemstones Gold",
          "Button_Image_Name": "1671262.png"
        },
        {
          "CN_Name": "快钱来了",
          "HTML5_Game_ID": "1682240",
          "EN_Name": "Cash Mania",
          "Button_Image_Name": "1682240.png"
        },
        {
          "CN_Name": "猿宇宙#3258",
          "HTML5_Game_ID": "1508783",
          "EN_Name": "Wild Ape #3258",
          "Button_Image_Name": "1508783.png"
        },
        {
          "CN_Name": "墨西哥狂欢",
          "HTML5_Game_ID": "1492288",
          "EN_Name": "Pinata Wins",
          "Button_Image_Name": "1492288.png"
        },
        {
          "CN_Name": "魔法药水",
          "HTML5_Game_ID": "1717688",
          "EN_Name": "Mystic Potion",
          "Button_Image_Name": "1717688.png"
        },
        {
          "CN_Name": "阿努比斯",
          "HTML5_Game_ID": "1623475",
          "EN_Name": "Anubis Wrath",
          "Button_Image_Name": "1623475.png"
        },
        {
          "CN_Name": "丧尸危机",
          "HTML5_Game_ID": "1635221",
          "EN_Name": "Zombie Outbreak",
          "Button_Image_Name": "1635221.png"
        },
        {
          "CN_Name": "小鸡跑酷",
          "HTML5_Game_ID": "1738001",
          "EN_Name": "Chicky Run",
          "Button_Image_Name": "1738001.png"
        },
        {
          "CN_Name": "热血足球",
          "HTML5_Game_ID": "1778752",
          "EN_Name": "Futebol Fever",
          "Button_Image_Name": "1778752.png"
        },
        {
          "CN_Name": "鲨鱼赏金",
          "HTML5_Game_ID": "1648578",
          "EN_Name": "Shark Bounty",
          "Button_Image_Name": "1648578.png"
        },
        {
          "CN_Name": "极道荣耀",
          "HTML5_Game_ID": "1760238",
          "EN_Name": "Yakuza Honor",
          "Button_Image_Name": "1760238.png"
        },
        {
          "CN_Name": "南美之翼",
          "HTML5_Game_ID": "1747549",
          "EN_Name": "Wings of Iguazu",
          "Button_Image_Name": "1747549.png"
        },
        {
          "CN_Name": "三只疯狂小猪",
          "HTML5_Game_ID": "1727711",
          "EN_Name": "Three Crazy Piggies",
          "Button_Image_Name": "1727711.png"
        },
        {
          "CN_Name": "美食夏日祭",
          "HTML5_Game_ID": "1815268",
          "EN_Name": "Oishi Delights",
          "Button_Image_Name": "1815268.png"
        },
        {
          "CN_Name": "世界珍宝馆 ",
          "HTML5_Game_ID": "1755623",
          "EN_Name": "Museum Mystery",
          "Button_Image_Name": "1755623.png"
        },
        {
          "CN_Name": "里约嘉年华",
          "HTML5_Game_ID": "1786529",
          "EN_Name": "Rio Fantasia",
          "Button_Image_Name": "1786529.png"
        },

        {
          "CN_Name": "艺伎之刃",
          "HTML5_Game_ID": "1702123",
          "EN_Name": "Geisha's Revenge",
          "Button_Image_Name": "1702123.png"
        }
      ]
    },



    {
      "type": 4,
      "game_id": 415,
      "game_api": "PP",
      "name": "PP老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/pp.png",
      "icon": "logo-pp",
      "game_list_json": "PP_game_list",
      "game_list_img_path": "/new/slot_res/pp/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：奥林匹斯之门、甜入心扉、魔幻钱迷宫、极速糖果、星光公主...",
      "labels": "#老虎机#奥林匹斯之门#甜入心扉#极速糖果#星光公主",
      gameList: [
        {
          "CN_Name": "龙王火锅",
          "HTML5_Game_ID": "vs10dkinghp",
          "EN_Name": "Dragon King Hot Pots",
          "Button_Image_Name": "vs10dkinghp.png"
        },

        {
          "CN_Name": "小小蟾蜍",
          "HTML5_Game_ID": "vs50fatfrogs",
          "EN_Name": "Tiny Toads",
          "Button_Image_Name": "vs50fatfrogs.png"
        },
        {
          "CN_Name": "极速圣诞快乐",
          "HTML5_Game_ID": "vs20rainbowrsh",
          "EN_Name": "Santa’s Xmas Rush",
          "Button_Image_Name": "vs20rainbowrsh.png"
        },
        {
          "CN_Name": "奥林匹斯之门圣诞 1000",
          "HTML5_Game_ID": "vs20olympxmas",
          "EN_Name": "Gates of Olympus Xmas 1000",
          "Button_Image_Name": "vs20olympxmas.png"
        },
        {
          "CN_Name": "企鹅圣诞派对",
          "HTML5_Game_ID": "vs25xmasparty",
          "EN_Name": "Penguins Christmas Party Time",
          "Button_Image_Name": "vs25xmasparty.png"
        },
        {
          "CN_Name": "金银闪电骰子",
          "HTML5_Game_ID": "vs20msdice",
          "EN_Name": "Money Stacks Dice",
          "Button_Image_Name": "vs20msdice.png"
        },
        {
          "CN_Name": "女神弗蕾雅Megaways",
          "HTML5_Game_ID": "vswaysmfreya",
          "EN_Name": "Might of Freya Megaways",
          "Button_Image_Name": "vswaysmfreya.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼圣诞极限",
          "HTML5_Game_ID": "vs10bbxext",
          "EN_Name": "Big Bass Xmas Xtreme",
          "Button_Image_Name": "vs10bbxext.png"
        },
        {
          "CN_Name": "永恒女皇 – 急冻时刻",
          "HTML5_Game_ID": "vswaysfreezet",
          "EN_Name": "Eternal Empress – Freeze Time",
          "Button_Image_Name": "vswaysfreezet.png"
        },
        {
          "CN_Name": "喜马拉雅疯狂",
          "HTML5_Game_ID": "vs5himalaw",
          "EN_Name": "Himalayan Wild",
          "Button_Image_Name": "vs5himalaw.png"
        },
        {
          "CN_Name": "野狼黄金终极",
          "HTML5_Game_ID": "vs25ultwolgol",
          "EN_Name": "Wolf Gold Ultimate",
          "Button_Image_Name": "vs25ultwolgol.png"
        },
        {
          "CN_Name": "百万富鼠",
          "HTML5_Game_ID": "vs20clreacts",
          "EN_Name": "Moleionaire",
          "Button_Image_Name": "vs20clreacts.png"
        },
        {
          "CN_Name": "糖果堆",
          "HTML5_Game_ID": "vs20fourmc",
          "EN_Name": "Candy Corner",
          "Button_Image_Name": "vs20fourmc.png"
        },
        {
          "CN_Name": "麻将大胜3 – 黑金龙耀",
          "HTML5_Game_ID": "vswaysmahwblck",
          "EN_Name": "Mahjong Wins 3 – Black Scatter",
          "Button_Image_Name": "vswaysmahwblck.png"
        },
        {
          "CN_Name": "7幸运草",
          "HTML5_Game_ID": "vswayssevenc",
          "EN_Name": "7 Clovers of Fortune",
          "Button_Image_Name": "vswayssevenc.png"
        },
        {
          "CN_Name": "超劲爆免费旋转",
          "HTML5_Game_ID": "vs10fangfree",
          "EN_Name": "Fangtastic Freespins",
          "Button_Image_Name": "vs10fangfree.png"
        },
        {
          "CN_Name": "吸血鬼派对",
          "HTML5_Game_ID": "vswayswbounty",
          "EN_Name": "Vampy Party",
          "Button_Image_Name": "vswayswbounty.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼万圣节2",
          "HTML5_Game_ID": "vs10bhallbnza2",
          "EN_Name": "Big Bass Halloween 2",
          "Button_Image_Name": "vs10bhallbnza2.png"
        },
        {
          "CN_Name": "解放大海怪 Megaways",
          "HTML5_Game_ID": "vswayskrakenmw",
          "EN_Name": "Release the Kraken Megaways",
          "Button_Image_Name": "vswayskrakenmw.png"
        },
        {
          "CN_Name": "财神聚宝盒",
          "HTML5_Game_ID": "vs25checaishen",
          "EN_Name": "Chests of Cai Shen",
          "Button_Image_Name": "vs25checaishen.png"
        },
        {
          "CN_Name": "财富打带跑",
          "HTML5_Game_ID": "vs40wildrun",
          "EN_Name": "Fortune Hit’n Roll",
          "Button_Image_Name": "vs40wildrun.png"
        },
        {
          "CN_Name": "福虎生财",
          "HTML5_Game_ID": "vs5luckytig",
          "EN_Name": "Tigre Sortudo",
          "Button_Image_Name": "vs5luckytig.png"
        },
        {
          "CN_Name": "汪汪之家 – 穆特利伙伴",
          "HTML5_Game_ID": "vs20dhcluster2",
          "EN_Name": "The Dog House – Muttley Crew",
          "Button_Image_Name": "vs20dhcluster2.png"
        },
        {
          "CN_Name": "雅典娜的智慧 1000",
          "HTML5_Game_ID": "vs20procountx",
          "EN_Name": "Wisdom of Athena 1000",
          "Button_Image_Name": "vs20procountx.png"
        },
        {
          "CN_Name": "机甲战队 Megaways",
          "HTML5_Game_ID": "vswaysanime",
          "EN_Name": "Anime Mecha Megaways",
          "Button_Image_Name": "vswaysanime.png"
        },
        {
          "CN_Name": "888富贵",
          "HTML5_Game_ID": "vs5triple8gold",
          "EN_Name": "888 Gold",
          "Button_Image_Name": "vs5triple8gold.png"
        },
        {
          "CN_Name": "徽章闪电",
          "HTML5_Game_ID": "vs25badge",
          "EN_Name": "Badge Blitz",
          "Button_Image_Name": "vs25badge.png"
        },

        {
          "CN_Name": "神鼠",
          "HTML5_Game_ID": "vs20powerwild",
          "EN_Name": "Mystery Mice",
          "Button_Image_Name": "vs20powerwild.png"
        },
        {
          "CN_Name": "面面相趣",
          "HTML5_Game_ID": "vs10noodles",
          "EN_Name": "Oodles of Noodles",
          "Button_Image_Name": "vs10noodles.png"
        },
        {
          "CN_Name": "刚果财富 XL",
          "HTML5_Game_ID": "vswayscongcash",
          "EN_Name": "Congo Cash XL",
          "Button_Image_Name": "vswayscongcash.png"
        },
        {
          "CN_Name": "至尊双赢疯狂大鲈鱼",
          "HTML5_Game_ID": "vs10bbdoubled",
          "EN_Name": "Big Bass Vegas Double Down Deluxe",
          "Button_Image_Name": "vs10bbdoubled.png"
        },
        {
          "CN_Name": "黄金野马 Megaways",
          "HTML5_Game_ID": "vswaysgoldcol",
          "EN_Name": "Mustang Gold Megaways",
          "Button_Image_Name": "vswaysgoldcol.png"
        },
        {
          "CN_Name": "天使与罪人",
          "HTML5_Game_ID": "vs15fghtmultlv",
          "EN_Name": "Angel vs Sinner",
          "Button_Image_Name": "vs15fghtmultlv.png"
        },
        {
          "CN_Name": "宝石天梯",
          "HTML5_Game_ID": "vs20elevclust",
          "EN_Name": "Gem Elevator",
          "Button_Image_Name": "vs20elevclust.png"
        },
        {
          "CN_Name": "金龙88",
          "HTML5_Game_ID": "vs10dgold88",
          "EN_Name": "Dragon Gold 88",
          "Button_Image_Name": "vs10dgold88.png"
        },
        {
          "CN_Name": "阿兹特克秘宝狩猎",
          "HTML5_Game_ID": "vs20trswild3",
          "EN_Name": "Aztec Treasure Hunt",
          "Button_Image_Name": "vs20trswild3.png"
        },
        {
          "CN_Name": "热火燃烧  - 大力七旋转",
          "HTML5_Game_ID": "vs10hottb7fs",
          "EN_Name": "Hot to Burn – 7 Deadly Free Spins",
          "Button_Image_Name": "vs10hottb7fs.png"
        },
        {
          "CN_Name": "狂野锻造",
          "HTML5_Game_ID": "vs20forgewilds",
          "EN_Name": "Forging Wilds",
          "Button_Image_Name": "vs20forgewilds.png"
        },
        {
          "CN_Name": "奖金猎人",
          "HTML5_Game_ID": "vs20jhunter",
          "EN_Name": "Jackpot Hunter",
          "Button_Image_Name": "vs20jhunter.png"
        },
        {
          "CN_Name": "雪人探索",
          "HTML5_Game_ID": "vs20mesmult",
          "EN_Name": "Yeti Quest",
          "Button_Image_Name": "vs20mesmult.png"
        },
        {
          "CN_Name": "奔跑寿司",
          "HTML5_Game_ID": "vswayscashconv",
          "EN_Name": "Running Sushi",
          "Button_Image_Name": "vswayscashconv.png"
        },
        {
          "CN_Name": "野狼黄金骰子",
          "HTML5_Game_ID": "vs25wgdice",
          "EN_Name": "Wolf Gold Dice",
          "Button_Image_Name": "vs25wgdice.png"
        },
        {
          "CN_Name": "金银闪电",
          "HTML5_Game_ID": "vs20bblitz",
          "EN_Name": "Money Stacks",
          "Button_Image_Name": "vs20bblitz.png"
        },
        {
          "CN_Name": "狩猎女神之弓",
          "HTML5_Game_ID": "vs20gembondx",
          "EN_Name": "Bow of Artemis",
          "Button_Image_Name": "vs20gembondx.png"
        },
        {
          "CN_Name": "相扑力士 Megaways",
          "HTML5_Game_ID": "vswaysmegwghts",
          "EN_Name": "Sumo Supreme Megaways",
          "Button_Image_Name": "vswaysmegwghts.png"
        },

        {
          "CN_Name": "劲爆矿工德哥",
          "HTML5_Game_ID": "vs10dyndigd",
          "EN_Name": "Dynamite Diggin Doug",
          "Button_Image_Name": "vs10dyndigd.png"
        },
        {
          "CN_Name": "6小丑",
          "HTML5_Game_ID": "vs5magicdoor",
          "EN_Name": "6 Jokers",
          "Button_Image_Name": "vs5magicdoor.png"
        },
        {
          "CN_Name": "热火燃烧暴增",
          "HTML5_Game_ID": "vs5hotbmult",
          "EN_Name": "Hot to Burn Multiplier",
          "Button_Image_Name": "vs5hotbmult.png"
        },
        {
          "CN_Name": "武士密码",
          "HTML5_Game_ID": "vs12scode",
          "EN_Name": "Samurai Code",
          "Button_Image_Name": "vs12scode.png"
        },
        {
          "CN_Name": "全面启动",
          "HTML5_Game_ID": "vs20crankit",
          "EN_Name": "Crank It Up",
          "Button_Image_Name": "vs20crankit.png"
        },
        {
          "CN_Name": "甜心王国",
          "HTML5_Game_ID": "vs20clustcol",
          "EN_Name": "Sweet Kingdom",
          "Button_Image_Name": "vs20clustcol.png"
        },
        {
          "CN_Name": "迈达斯之手 2",
          "HTML5_Game_ID": "vs20midas2",
          "EN_Name": "Hand of Midas 2",
          "Button_Image_Name": "vs20midas2.png"
        },
        {
          "CN_Name": "麻将大胜 2",
          "HTML5_Game_ID": "vswaysmahwin2",
          "EN_Name": "Mahjong Wins 2",
          "Button_Image_Name": "vswaysmahwin2.png"
        },
        {
          "CN_Name": "王者野牛奔放Megaways",
          "HTML5_Game_ID": "vswaysbkingasc",
          "EN_Name": "Buffalo King Untamed Megaways",
          "Button_Image_Name": "vswaysbkingasc.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼垂钓任务",
          "HTML5_Game_ID": "vs10bbfmission",
          "EN_Name": "Big Bass Mission Fishin’",
          "Button_Image_Name": "vs10bbfmission.png"
        },
        {
          "CN_Name": "美杜莎之石",
          "HTML5_Game_ID": "vs20medusast",
          "EN_Name": "Medusa’s Stone",
          "Button_Image_Name": "vs20medusast.png"
        },
        {
          "CN_Name": "超级恶魔",
          "HTML5_Game_ID": "vs20devilic",
          "EN_Name": "Devilicious",
          "Button_Image_Name": "vs20devilic.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼 – 即刻出动",
          "HTML5_Game_ID": "vs10bbbrlact",
          "EN_Name": "Big Bass Bonanza - Reel Action",
          "Button_Image_Name": "vs10bbbrlact.png"
        },
        {
          "CN_Name": "甜蜜派对",
          "HTML5_Game_ID": "vs25wildies",
          "EN_Name": "Wildies",
          "Button_Image_Name": "vs25wildies.png"
        },
        {
          "CN_Name": "甜入心扉 1000",
          "HTML5_Game_ID": "vs20fruitswx",
          "EN_Name": "Sweet Bonanza 1000",
          "Button_Image_Name": "vs20fruitswx.png"
        },
        {
          "CN_Name": "洛基复仇Megaways",
          "HTML5_Game_ID": "vswaysloki",
          "EN_Name": "Revenge of Loki Megaways",
          "Button_Image_Name": "vswaysloki.png"
        },
        {
          "CN_Name": "星光公主帕奇",
          "HTML5_Game_ID": "vswaysjapan",
          "EN_Name": "Starlight Princess Pachi",
          "Button_Image_Name": "vswaysjapan.png"
        },
        {
          "CN_Name": "水果盛宴",
          "HTML5_Game_ID": "vs20fortbon",
          "EN_Name": "Fruity Treats",
          "Button_Image_Name": "vs20fortbon.png"
        },
        {
          "CN_Name": "金字塔崛起",
          "HTML5_Game_ID": "vswayshexhaus",
          "EN_Name": "Rise of Pyramids",
          "Button_Image_Name": "vswayshexhaus.png"
        },
        {
          "CN_Name": "矮人和龙",
          "HTML5_Game_ID": "vswaysspltsym",
          "EN_Name": "Dwarf & Dragon",
          "Button_Image_Name": "vswaysspltsym.png"
        },

        {
          "CN_Name": "埃及艳后之心",
          "HTML5_Game_ID": "vs20heartcleo",
          "EN_Name": "Heart of Cleopatra",
          "Button_Image_Name": "vs20heartcleo.png"
        },
        {
          "CN_Name": "英雄旋转",
          "HTML5_Game_ID": "vs20shootstars",
          "EN_Name": "Heroic Spin",
          "Button_Image_Name": "vs20shootstars.png"
        },
        {
          "CN_Name": "鲍勃龙虾海鲜大餐",
          "HTML5_Game_ID": "vs20lobseafd",
          "EN_Name": "Lobster Bob’s Sea Food and Win It",
          "Button_Image_Name": "vs20lobseafd.png"
        },
        {
          "CN_Name": "火热小丑珠宝",
          "HTML5_Game_ID": "vs5jjwild",
          "EN_Name": "Joker's Jewels Fire",
          "Button_Image_Name": "vs5jjwild.png"
        },
        {
          "CN_Name": "极速悟空",
          "HTML5_Game_ID": "vs20mkrush",
          "EN_Name": "Wukong Rush",
          "Button_Image_Name": "vs20mkrush.png"
        },

        {
          "CN_Name": "疯狂谷仓 Megaways",
          "HTML5_Game_ID": "vswaysmegahays",
          "EN_Name": "Barnyard Megahays Megaways",
          "Button_Image_Name": "vswaysmegahays.png"
        },
        {
          "CN_Name": "急冻龙虾",
          "HTML5_Game_ID": "vs20stickypos",
          "EN_Name": "Ice Lobster",
          "Button_Image_Name": "vs20stickypos.png"
        },

        {
          "CN_Name": "甜蜜奖赏",
          "HTML5_Game_ID": "vs40stckwldlvl",
          "EN_Name": "Ripe Rewards",
          "Button_Image_Name": "vs40stckwldlvl.png"
        },
        {
          "CN_Name": "释放野牛",
          "HTML5_Game_ID": "vs20bison",
          "EN_Name": "Release the Bison",
          "Button_Image_Name": "vs20bison.png"
        },
        {
          "CN_Name": "阿兹特克 Powernudge",
          "HTML5_Game_ID": "vs20sbpnudge",
          "EN_Name": "Aztec Powernudge",
          "Button_Image_Name": "vs20sbpnudge.png"
        },
        {
          "CN_Name": "汉堡巨无霸",
          "HTML5_Game_ID": "vs10bburger",
          "EN_Name": "Big Burger Load it up with Xtra Cheese",
          "Button_Image_Name": "vs10bburger.png"
        },
        {
          "CN_Name": "汪汪之家 狗狗神气",
          "HTML5_Game_ID": "vs20doghouse2",
          "EN_Name": "The Dog House Dog or Alive",
          "Button_Image_Name": "vs20doghouse2.png"
        },
        {
          "CN_Name": "网络大劫案",
          "HTML5_Game_ID": "vs20cbrhst",
          "EN_Name": "Cyber Heist",
          "Button_Image_Name": "vs20cbrhst.png"
        },
        {
          "CN_Name": "极速糖果1000",
          "HTML5_Game_ID": "vs20sugarrushx",
          "EN_Name": "Sugar Rush 1000",
          "Button_Image_Name": "vs20sugarrushx.png"
        },
        {
          "CN_Name": "火焰之界",
          "HTML5_Game_ID": "vs20portals",
          "EN_Name": "Fire Portals",
          "Button_Image_Name": "vs20portals.png"
        },
        {
          "CN_Name": "崛起的武士4",
          "HTML5_Game_ID": "vs15samurai4",
          "EN_Name": "Rise of Samurai 4",
          "Button_Image_Name": "vs15samurai4.png"
        },
        {
          "CN_Name": "幸运之壶",
          "HTML5_Game_ID": "vs20stckwldsc",
          "EN_Name": "Pot of Fortune",
          "Button_Image_Name": "vs20stckwldsc.png"
        },
        {
          "CN_Name": "小心深海Megaways",
          "HTML5_Game_ID": "vswaysbewaretd",
          "EN_Name": "Beware The Deep Megaways",
          "Button_Image_Name": "vswaysbewaretd.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼竞赛",
          "HTML5_Game_ID": "vs10bbbnz",
          "EN_Name": "Big Bass Day at the Races",
          "Button_Image_Name": "vs10bbbnz.png"
        },
        {
          "CN_Name": "印尼传奇迦多铎卡 1000",
          "HTML5_Game_ID": "vs20gatotx",
          "EN_Name": "Gates of Gatot Kaca 1000",
          "Button_Image_Name": "vs20gatotx.png"
        },
        {
          "CN_Name": "荷鲁斯的齿轮",
          "HTML5_Game_ID": "vs20clustext",
          "EN_Name": "Gears of Horus",
          "Button_Image_Name": "vs20clustext.png"
        },

        {
          "CN_Name": "武力忍者",
          "HTML5_Game_ID": "vs20ninjapower",
          "EN_Name": "Power of Ninja",
          "Button_Image_Name": "vs20ninjapower.png"
        },
        {
          "CN_Name": "奥林匹斯之门骰子",
          "HTML5_Game_ID": "vs20olympdice",
          "EN_Name": "Gates of Olympus Dice",
          "Button_Image_Name": "vs20olympdice.png"
        },
        {
          "CN_Name": "丧尸列车",
          "HTML5_Game_ID": "vs15seoultrain",
          "EN_Name": "Zombie Train",
          "Button_Image_Name": "vs15seoultrain.png"
        },
        {
          "CN_Name": "大啖甜瓜",
          "HTML5_Game_ID": "vs20mmmelon",
          "EN_Name": "Mighty Munching Melons",
          "Button_Image_Name": "vs20mmmelon.png"
        },
        {
          "CN_Name": "浆果鸡尾酒",
          "HTML5_Game_ID": "vs10strawberry",
          "EN_Name": "Strawberry Cocktail",
          "Button_Image_Name": "vs10strawberry.png"
        },
        {
          "CN_Name": "大泻湖",
          "HTML5_Game_ID": "vs25lagoon",
          "EN_Name": "Great Lagoon",
          "Button_Image_Name": "vs25lagoon.png"
        },
        {
          "CN_Name": "黄金之轮",
          "HTML5_Game_ID": "vs20multiup",
          "EN_Name": "Wheel O'Gold",
          "Button_Image_Name": "vs20multiup.png"
        },
        {
          "CN_Name": "庞贝古城 Megaways",
          "HTML5_Game_ID": "vswaysmegareel",
          "EN_Name": "Pompeii Megareels Megaways",
          "Button_Image_Name": "vswaysmegareel.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼摇摇船",
          "HTML5_Game_ID": "vs10bbfloats",
          "EN_Name": "Big Bass Floats My Boat",
          "Button_Image_Name": "vs10bbfloats.png"
        },
        {
          "CN_Name": "巴厘之龙",
          "HTML5_Game_ID": "vs5balidragon",
          "EN_Name": "Bali Dragon",
          "Button_Image_Name": "vs5balidragon.png"
        },
        {
          "CN_Name": "双面特务",
          "HTML5_Game_ID": "vswaysalterego",
          "EN_Name": "The Alter Ego",
          "Button_Image_Name": "vswaysalterego.png"
        },
        {
          "CN_Name": "招财树",
          "HTML5_Game_ID": "vs20treesot",
          "EN_Name": "Trees of Treasure",
          "Button_Image_Name": "vs20treesot.png"
        },
        {
          "CN_Name": "财富高手",
          "HTML5_Game_ID": "vs1024fortune",
          "EN_Name": "Fortune Ace",
          "Button_Image_Name": "vs1024fortune.png"
        },
        {
          "CN_Name": "炽热狂野 Megaways",
          "HTML5_Game_ID": "vswaysfirewmw",
          "EN_Name": "Blazing Wilds Megaways",
          "Button_Image_Name": "vswaysfirewmw.png"
        },
        {
          "CN_Name": "刀光 & 獠牙",
          "HTML5_Game_ID": "vs20mergedwndw",
          "EN_Name": "Blade & Fangs",
          "Button_Image_Name": "vs20mergedwndw.png"
        },
        {
          "CN_Name": "麻将大胜 Bonus",
          "HTML5_Game_ID": "vs1024mjwinbns",
          "EN_Name": "Mahjong Wins Bonus",
          "Button_Image_Name": "vs1024mjwinbns.png"
        },
        {
          "CN_Name": "洛基财富",
          "HTML5_Game_ID": "vs20loksriches",
          "EN_Name": "Loki's Riches",
          "Button_Image_Name": "vs20loksriches.png"
        },
        {
          "CN_Name": "神龙运财",
          "HTML5_Game_ID": "vs243fdragon",
          "EN_Name": "Fortune Dragon",
          "Button_Image_Name": "vs243fdragon.png"
        },
        {
          "CN_Name": "大人物",
          "HTML5_Game_ID": "vs20bigdawgs",
          "EN_Name": "The Big Dawgs",
          "Button_Image_Name": "vs20bigdawgs.png"
        },
        {
          "CN_Name": "鸿运当头",
          "HTML5_Game_ID": "vs10luckfort",
          "EN_Name": "Good Luck & Good Fortune",
          "Button_Image_Name": "vs10luckfort.png"
        },
        {
          "CN_Name": "烈火城堡",
          "HTML5_Game_ID": "vswaysexpandng",
          "EN_Name": "Castle of Fire",
          "Button_Image_Name": "vswaysexpandng.png"
        },
        {
          "CN_Name": "笑口迎财",
          "HTML5_Game_ID": "vs20laughluck",
          "EN_Name": "Happy Fortune",
          "Button_Image_Name": "vs20laughluck.png"
        },
        {
          "CN_Name": "龙王之年",
          "HTML5_Game_ID": "vs20yotdk",
          "EN_Name": "Year of the Dragon King",
          "Button_Image_Name": "vs20yotdk.png"
        },
        {
          "CN_Name": "鱼跃龙门贺新年Ultra Megaways持控旋转",
          "HTML5_Game_ID": "vswaysfltdrgny",
          "EN_Name": "Floating Dragon New Year Festival Ultra Megaways",
          "Button_Image_Name": "vswaysfltdrgny.png"
        },
        {
          "CN_Name": "多汁水果多方持控",
          "HTML5_Game_ID": "vs50jfmulthold",
          "EN_Name": "Juicy Fruits Multihold",
          "Button_Image_Name": "vs50jfmulthold.png"
        },
        {
          "CN_Name": "奥林匹斯之门 1000",
          "HTML5_Game_ID": "vs20olympx",
          "EN_Name": "Gates of Olympus 1000",
          "Button_Image_Name": "vs20olympx.png"
        },
        {
          "CN_Name": "野蛮帮派",
          "HTML5_Game_ID": "vswayswildgang",
          "EN_Name": "The Wild Gang",
          "Button_Image_Name": "vswayswildgang.png"
        },
        {
          "CN_Name": "海洋幻想",
          "HTML5_Game_ID": "vswaysseastory",
          "EN_Name": "Sea Fantasy",
          "Button_Image_Name": "vswaysseastory.png"
        },
        {
          "CN_Name": "热火暴走",
          "HTML5_Game_ID": "vswaysstampede",
          "EN_Name": "Fire Stampede",
          "Button_Image_Name": "vswaysstampede.png"
        },
        {
          "CN_Name": "糖果罐派对",
          "HTML5_Game_ID": "vs20cjcluster",
          "EN_Name": "Candy Jar Cluster",
          "Button_Image_Name": "vs20cjcluster.png"
        },

        {
          "CN_Name": "圣诞响叮当",
          "HTML5_Game_ID": "vs10ddcbells",
          "EN_Name": "Ding Dong Christmas Bells",
          "Button_Image_Name": "vs10ddcbells.png"
        },
        {
          "CN_Name": "5冰冻魅力Megaways",
          "HTML5_Game_ID": "vswayscharms",
          "EN_Name": "5 Frozen Charms Megaways",
          "Button_Image_Name": "vswayscharms.png"
        },
        {
          "CN_Name": "极速糖果圣诞",
          "HTML5_Game_ID": "vs20sugrux",
          "EN_Name": "Sugar Rush Xmas",
          "Button_Image_Name": "vs20sugrux.png"
        },
        {
          "CN_Name": "麻将X",
          "HTML5_Game_ID": "vs20mahjxbnz",
          "EN_Name": "Mahjong X",
          "Button_Image_Name": "vs20mahjxbnz.png"
        },

        {
          "CN_Name": "尼罗河财富",
          "HTML5_Game_ID": "vs20nilefort",
          "EN_Name": "Nile Fortunes",
          "Button_Image_Name": "vs20nilefort.png"
        },
        {
          "CN_Name": "小丑珠宝骰子",
          "HTML5_Game_ID": "vs5jokerdice",
          "EN_Name": "Joker Jewels Dice",
          "Button_Image_Name": "vs5jokerdice.png"
        },
        {
          "CN_Name": "追逐荣光",
          "HTML5_Game_ID": "vswayscfglory",
          "EN_Name": "Chase for Glory",
          "Button_Image_Name": "vswayscfglory.png"
        },
        {
          "CN_Name": "森林大冒险",
          "HTML5_Game_ID": "vswaystimber",
          "EN_Name": "Timber Stacks",
          "Button_Image_Name": "vswaystimber.png"
        },
        {
          "CN_Name": "顶级糖果Powernudge",
          "HTML5_Game_ID": "vs20sugarnudge",
          "EN_Name": "Sugar Supreme Powernudge",
          "Button_Image_Name": "vs20sugarnudge.png"
        },
        {
          "CN_Name": "维京锻造",
          "HTML5_Game_ID": "vs20sugarcoins",
          "EN_Name": "Viking Forge",
          "Button_Image_Name": "vs20sugarcoins.png"
        },
        {
          "CN_Name": "水果沙拉盛宴",
          "HTML5_Game_ID": "vs20rujakbnz",
          "EN_Name": "Rujak Bonanza",
          "Button_Image_Name": "vs20rujakbnz.png"
        },
        {
          "CN_Name": "七福海神",
          "HTML5_Game_ID": "vs20olympgrace",
          "EN_Name": "Grace of Ebisu",
          "Button_Image_Name": "vs20olympgrace.png"
        },
        {
          "CN_Name": "暮光公主",
          "HTML5_Game_ID": "vs20dhcluster",
          "EN_Name": "Twilight Princess",
          "Button_Image_Name": "vs20dhcluster.png"
        },
        {
          "CN_Name": "888富矿",
          "HTML5_Game_ID": "vs243goldfor",
          "EN_Name": "888 Bonanza",
          "Button_Image_Name": "vs243goldfor.png"
        },
        {
          "CN_Name": "大富豪Megaways",
          "HTML5_Game_ID": "vswaysmoneyman",
          "EN_Name": "The Money Men Megaways",
          "Button_Image_Name": "vswaysmoneyman.png"
        },
        {
          "CN_Name": "魔法之壶",
          "HTML5_Game_ID": "vs40demonpots",
          "EN_Name": "Demon Pots",
          "Button_Image_Name": "vs40demonpots.png"
        },
        {
          "CN_Name": "甜入心扉骰子",
          "HTML5_Game_ID": "vs20bnnzdice",
          "EN_Name": "Sweet Bonanza Dice",
          "Button_Image_Name": "vs20bnnzdice.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼万圣节",
          "HTML5_Game_ID": "vs10bhallbnza",
          "EN_Name": "Big Bass Halloween",
          "Button_Image_Name": "vs10bhallbnza.png"
        },
        {
          "CN_Name": "彩虹转轴",
          "HTML5_Game_ID": "vs40rainbowr",
          "EN_Name": "Rainbow Reels",
          "Button_Image_Name": "vs40rainbowr.png"
        },
        {
          "CN_Name": "宝石",
          "HTML5_Game_ID": "vs5gemstone",
          "EN_Name": "Gemstone",
          "Button_Image_Name": "vs5gemstone.png"
        },
        {
          "CN_Name": "极地财富",
          "HTML5_Game_ID": "vswaysraghex",
          "EN_Name": "Tundra’s Fortune",
          "Button_Image_Name": "vswaysraghex.png"
        },
        {
          "CN_Name": "现金筹码",
          "HTML5_Game_ID": "vs20maskgame",
          "EN_Name": "Cash Chips",
          "Button_Image_Name": "vs20maskgame.png"
        },
        {
          "CN_Name": "狂野感染",
          "HTML5_Game_ID": "vs40infwild",
          "EN_Name": "Infective Wild",
          "Button_Image_Name": "vs40infwild.png"
        },
        {
          "CN_Name": "重力鸿运",
          "HTML5_Game_ID": "vs20gravity",
          "EN_Name": "Gravity Bonanza",
          "Button_Image_Name": "vs20gravity.png"
        },

        {
          "CN_Name": "糖果闪电",
          "HTML5_Game_ID": "vs20candyblitz",
          "EN_Name": "Candy Blitz",
          "Button_Image_Name": "vs20candyblitz.png"
        },
        {
          "CN_Name": "阿兹特克财宝",
          "HTML5_Game_ID": "vswaysstrlght",
          "EN_Name": "Fortunes of Aztec",
          "Button_Image_Name": "vswaysstrlght.png"
        },
        {
          "CN_Name": "8金龙挑战",
          "HTML5_Game_ID": "vs10gdchalleng",
          "EN_Name": "8 Golden Dragon Challenge",
          "Button_Image_Name": "vs10gdchalleng.png"
        },

        {
          "CN_Name": "冰冻热带",
          "HTML5_Game_ID": "vswaysftropics",
          "EN_Name": "Frozen Tropics",
          "Button_Image_Name": "vswaysftropics.png"
        },
        {
          "CN_Name": "黄金绿洲",
          "HTML5_Game_ID": "vswaysincwnd",
          "EN_Name": "Gold Oasis",
          "Button_Image_Name": "vswaysincwnd.png"
        },
        {
          "CN_Name": "赛亚狂热",
          "HTML5_Game_ID": "vs20saiman",
          "EN_Name": "Saiyan Mania",
          "Button_Image_Name": "vs20saiman.png"
        },
        {
          "CN_Name": "独眼巨人猛击",
          "HTML5_Game_ID": "vs20earthquake",
          "EN_Name": "Cyclops Smash",
          "Button_Image_Name": "vs20earthquake.png"
        },

        {
          "CN_Name": "麻将大胜",
          "HTML5_Game_ID": "vs1024mahjwins",
          "EN_Name": "Mahjong Wins",
          "Button_Image_Name": "vs1024mahjwins.png"
        },
        {
          "CN_Name": "奥林匹斯锻造",
          "HTML5_Game_ID": "vs20forge",
          "EN_Name": "Forge of Olympus",
          "Button_Image_Name": "vs20forge.png"
        },
        {
          "CN_Name": "小猪银行家",
          "HTML5_Game_ID": "vs20piggybank",
          "EN_Name": "Piggy Bankers",
          "Button_Image_Name": "vs20piggybank.png"
        },
        {
          "CN_Name": "酒吧之王",
          "HTML5_Game_ID": "vs20lvlup",
          "EN_Name": "Pub Kings",
          "Button_Image_Name": "vs20lvlup.png"
        },
        {
          "CN_Name": "野马踪迹",
          "HTML5_Game_ID": "vs10trail",
          "EN_Name": "Mustang Trail",
          "Button_Image_Name": "vs10trail.png"
        },
        {
          "CN_Name": "火箭弹爆破 Megaways",
          "HTML5_Game_ID": "vswaysrockblst",
          "EN_Name": "Rocket Blast Megaways",
          "Button_Image_Name": "vswaysrockblst.png"
        },
        {
          "CN_Name": "超人狂热",
          "HTML5_Game_ID": "vs20supermania",
          "EN_Name": "Supermania",
          "Button_Image_Name": "vs20supermania.png"
        },
        {
          "CN_Name": "鲍勃龙虾的疯狂螃蟹屋",
          "HTML5_Game_ID": "vs20lobcrab",
          "EN_Name": "Lobster Bob's Crazy Crab Shack",
          "Button_Image_Name": "vs20lobcrab.png"
        },
        {
          "CN_Name": "钻石瀑布",
          "HTML5_Game_ID": "vs50dmdcascade",
          "EN_Name": "Diamond Cascade",
          "Button_Image_Name": "vs50dmdcascade.png"
        },
        {
          "CN_Name": "星光公主 1000",
          "HTML5_Game_ID": "vs20starlightx",
          "EN_Name": "Starlight Princess 1000",
          "Button_Image_Name": "vs20starlightx.png"
        },
        {
          "CN_Name": "喧闹虫虫",
          "HTML5_Game_ID": "vs20wildparty",
          "EN_Name": "3 Buzzing Wilds",
          "Button_Image_Name": "vs20wildparty.png"
        },
        {
          "CN_Name": "法力梅林Megaways",
          "HTML5_Game_ID": "vswayspowzeus",
          "EN_Name": "Power of Merlin Megaways",
          "Button_Image_Name": "vswayspowzeus.png"
        },
        {
          "CN_Name": "魔法的奥秘",
          "HTML5_Game_ID": "vs20splmystery",
          "EN_Name": "Spellbinding Mystery",
          "Button_Image_Name": "vs20splmystery.png"
        },
        {
          "CN_Name": "金库宝藏",
          "HTML5_Game_ID": "vs20cashmachine",
          "EN_Name": "Cash Box",
          "Button_Image_Name": "vs20cashmachine.png"
        },
        {
          "CN_Name": "海怪的高额赏金",
          "HTML5_Game_ID": "vs50jucier",
          "EN_Name": "Kraken's Sky Bounty",
          "Button_Image_Name": "vs50jucier.png"
        },
        {
          "CN_Name": "小丑赛跑",
          "HTML5_Game_ID": "vs25jokrace",
          "EN_Name": "Joker Race",
          "Button_Image_Name": "vs25jokrace.png"
        },
        {
          "CN_Name": "野蛮恶魔",
          "HTML5_Game_ID": "vs243nudge4gold",
          "EN_Name": "Hellvis Wild",
          "Button_Image_Name": "vs243nudge4gold.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼 亚马逊极限",
          "HTML5_Game_ID": "vs10bbextreme",
          "EN_Name": "Big Bass Amazon Xtreme",
          "Button_Image_Name": "vs10bbextreme.png"
        },
        {
          "CN_Name": "金块大劫案",
          "HTML5_Game_ID": "vs20hstgldngt",
          "EN_Name": "Heist for the Golden Nuggets",
          "Button_Image_Name": "vs20hstgldngt.png"
        },
        {
          "CN_Name": "雅典娜的智慧",
          "HTML5_Game_ID": "vs20procount",
          "EN_Name": "Wisdom of Athena",
          "Button_Image_Name": "vs20procount.png"
        },
        {
          "CN_Name": "肥宝熊猫",
          "HTML5_Game_ID": "vs20beefed",
          "EN_Name": "Fat Panda",
          "Button_Image_Name": "vs20beefed.png"
        },
        {
          "CN_Name": "海盗酒吧",
          "HTML5_Game_ID": "vs9outlaw",
          "EN_Name": "Pirates Pub",
          "Button_Image_Name": "vs9outlaw.png"
        },
        {
          "CN_Name": "珍宝粉碎",
          "HTML5_Game_ID": "vs20jewelparty",
          "EN_Name": "Jewel Rush",
          "Button_Image_Name": "vs20jewelparty.png"
        },
        {
          "CN_Name": "大盗反击",
          "HTML5_Game_ID": "vs4096robber",
          "EN_Name": "Robber Strike",
          "Button_Image_Name": "vs4096robber.png"
        },
        {
          "CN_Name": "蛙群家族",
          "HTML5_Game_ID": "vswaysfrbugs",
          "EN_Name": "Frogs & Bugs",
          "Button_Image_Name": "vswaysfrbugs.png"
        },
        {
          "CN_Name": "无限神灯",
          "HTML5_Game_ID": "vs20lampinf",
          "EN_Name": "Lamp Of Infinity",
          "Button_Image_Name": "vs20lampinf.png"
        },
        {
          "CN_Name": "鱼跃龙门-天空之船",
          "HTML5_Game_ID": "vs10fdrasbf",
          "EN_Name": "Floating Dragon - Dragon Boat Festival",
          "Button_Image_Name": "vs10fdrasbf.png"
        },
        {
          "CN_Name": "粘性蜜蜂",
          "HTML5_Game_ID": "vs20clustwild",
          "EN_Name": "Sticky Bees",
          "Button_Image_Name": "vs20clustwild.png"
        },
        {
          "CN_Name": "骑士热点",
          "HTML5_Game_ID": "vs25spotz",
          "EN_Name": "Knight Hot Spotz",
          "Button_Image_Name": "vs25spotz.png"
        },
        {
          "CN_Name": "宙斯vs哈迪斯-众神之战",
          "HTML5_Game_ID": "vs15godsofwar",
          "EN_Name": "Zeus vs Hades - Gods of War",
          "Button_Image_Name": "vs15godsofwar.png"
        },
        {
          "CN_Name": "野牛冲锋",
          "HTML5_Game_ID": "vs20stickywild",
          "EN_Name": "Wild Bison Charge",
          "Button_Image_Name": "vs20stickywild.png"
        },
        {
          "CN_Name": "圣剑出鞘",
          "HTML5_Game_ID": "vs20excalibur",
          "EN_Name": "Excalibur Unleashed",
          "Button_Image_Name": "vs20excalibur.png"
        },
        {
          "CN_Name": "兜风假期",
          "HTML5_Game_ID": "vs25holiday",
          "EN_Name": "Holiday Ride",
          "Button_Image_Name": "vs25holiday.png"
        },
        {
          "CN_Name": "埃及钻石",
          "HTML5_Game_ID": "vswayseternity",
          "EN_Name": "Diamonds of Egypt",
          "Button_Image_Name": "vswayseternity.png"
        },
        {
          "CN_Name": "亡者王国",
          "HTML5_Game_ID": "vs10kingofdth",
          "EN_Name": "Kingdom of The Dead",
          "Button_Image_Name": "vs10kingofdth.png"
        },
        {
          "CN_Name": "阿拉伯之梦",
          "HTML5_Game_ID": "vs20mvwild",
          "EN_Name": "Jasmine Dreams",
          "Button_Image_Name": "vs20mvwild.png"
        },
        {
          "CN_Name": "珍亨特与蒙特苏马的面具",
          "HTML5_Game_ID": "vs10jnmntzma",
          "EN_Name": "Jane Hunter and the Mask of Montezuma",
          "Button_Image_Name": "vs10jnmntzma.png"
        },
        {
          "CN_Name": "牛仔金币",
          "HTML5_Game_ID": "vswaysultrcoin",
          "EN_Name": "Cowboy Coins",
          "Button_Image_Name": "vswaysultrcoin.png"
        },
        {
          "CN_Name": "吉萨之神",
          "HTML5_Game_ID": "vs10gizagods",
          "EN_Name": "Gods of Giza",
          "Button_Image_Name": "vs10gizagods.png"
        },
        {
          "CN_Name": "狂野名人大巴 Megaways",
          "HTML5_Game_ID": "vswaysrsm",
          "EN_Name": "Wild Celebrity Bus Megaways",
          "Button_Image_Name": "vswaysrsm.png"
        },
        {
          "CN_Name": "3舞蹈猴子",
          "HTML5_Game_ID": "vswaysmonkey",
          "EN_Name": "3 Dancing Monkeys",
          "Button_Image_Name": "vswaysmonkey.png"
        },
        {
          "CN_Name": "非洲大象",
          "HTML5_Game_ID": "vs20hotzone",
          "EN_Name": "African Elephant",
          "Button_Image_Name": "vs20hotzone.png"
        },
        {
          "CN_Name": "登陆月球",
          "HTML5_Game_ID": "vs1024moonsh",
          "EN_Name": "Moonshot",
          "Button_Image_Name": "vs1024moonsh.png"
        },
        {
          "CN_Name": "红桃王后",
          "HTML5_Game_ID": "vswaysredqueen",
          "EN_Name": "The Red Queen",
          "Button_Image_Name": "vswaysredqueen.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼持控旋转",
          "HTML5_Game_ID": "vs10bbhas",
          "EN_Name": "Big Bass Bonanza - Hold & Spinner",
          "Button_Image_Name": "vs10bbhas.png"
        },
        {
          "CN_Name": "亚马逊果实",
          "HTML5_Game_ID": "vs20framazon",
          "EN_Name": "Fruits of the Amazon",
          "Button_Image_Name": "vs20framazon.png"
        },
        {
          "CN_Name": "骑士之王",
          "HTML5_Game_ID": "vs20sknights",
          "EN_Name": "The Knight King",
          "Button_Image_Name": "vs20sknights.png"
        },
        {
          "CN_Name": "汪汪之家多方位持控",
          "HTML5_Game_ID": "vs20doghousemh",
          "EN_Name": "The Dog House Multihold",
          "Button_Image_Name": "vs20doghousemh.png"
        },
        {
          "CN_Name": "兔子乐园",
          "HTML5_Game_ID": "vs20goldclust",
          "EN_Name": "Rabbit Garden",
          "Button_Image_Name": "vs20goldclust.png"
        },
        {
          "CN_Name": "西部牛仔对决",
          "HTML5_Game_ID": "vs20pistols",
          "EN_Name": "Wild West Duels",
          "Button_Image_Name": "vs20pistols.png"
        },
        {
          "CN_Name": "东方的奥秘",
          "HTML5_Game_ID": "vswaysmorient",
          "EN_Name": "Mystery Of The Orient",
          "Button_Image_Name": "vswaysmorient.png"
        },
        {
          "CN_Name": "爱尔兰人宝藏 Megaways",
          "HTML5_Game_ID": "vswayswwriches",
          "EN_Name": "Wild Wild Riches Megaways",
          "Button_Image_Name": "vswayswwriches.png"
        },
        {
          "CN_Name": "极限能量",
          "HTML5_Game_ID": "vs10powerlines",
          "EN_Name": "Peak Power",
          "Button_Image_Name": "vs10powerlines.png"
        },
        {
          "CN_Name": "热带俱乐部",
          "HTML5_Game_ID": "vs12tropicana",
          "EN_Name": "Club Tropicana",
          "Button_Image_Name": "vs12tropicana.png"
        },
        {
          "CN_Name": "热火弓箭手",
          "HTML5_Game_ID": "vs25archer",
          "EN_Name": "Fire Archer",
          "Button_Image_Name": "vs25archer.png"
        },
        {
          "CN_Name": "迦多铎卡之怒",
          "HTML5_Game_ID": "vs20gatotfury",
          "EN_Name": "Gatot Kaca's Fury",
          "Button_Image_Name": "vs20gatotfury.png"
        },
        {
          "CN_Name": "麻糬宝贝",
          "HTML5_Game_ID": "vs20mochimon",
          "EN_Name": "Mochimon",
          "Button_Image_Name": "vs20mochimon.png"
        },
        {
          "CN_Name": "怪物超人",
          "HTML5_Game_ID": "vs20superlanche",
          "EN_Name": "Monster Superlanche",
          "Button_Image_Name": "vs20superlanche.png"
        },
        {
          "CN_Name": "埃及鱼之眼",
          "HTML5_Game_ID": "vs10fisheye",
          "EN_Name": "Fish Eye",
          "Button_Image_Name": "vs10fisheye.png"
        },
        {
          "CN_Name": "神秘黄金之城",
          "HTML5_Game_ID": "vs25spgldways",
          "EN_Name": "Secret City Gold",
          "Button_Image_Name": "vs25spgldways.png"
        },
        {
          "CN_Name": "海报女郎",
          "HTML5_Game_ID": "vs20ltng",
          "EN_Name": "Pinup Girls",
          "Button_Image_Name": "vs20ltng.png"
        },
        {
          "CN_Name": "猛犸黄金 Megaways",
          "HTML5_Game_ID": "vs20mammoth",
          "EN_Name": "Mammoth Gold Megaways",
          "Button_Image_Name": "vs20mammoth.png"
        },
        {
          "CN_Name": "狂野美味香蕉 ",
          "HTML5_Game_ID": "vswayswwhex",
          "EN_Name": "Wild Wild Bananas",
          "Button_Image_Name": "vswayswwhex.png"
        },
        {
          "CN_Name": "阿茲特克之门",
          "HTML5_Game_ID": "vs20aztecgates",
          "EN_Name": "Gates of Aztec",
          "Button_Image_Name": "vs20aztecgates.png"
        },
        {
          "CN_Name": "奥丁之怒 Megaways",
          "HTML5_Game_ID": "vswaysfuryodin",
          "EN_Name": "Fury of Odin Megaways",
          "Button_Image_Name": "vswaysfuryodin.png"
        },
        {
          "CN_Name": "甜品派对 Powernudge",
          "HTML5_Game_ID": "vs20clspwrndg",
          "EN_Name": "Sweet Powernudge",
          "Button_Image_Name": "vs20clspwrndg.png"
        },
        {
          "CN_Name": "辣椒狂热",
          "HTML5_Game_ID": "vs20dugems",
          "EN_Name": "Hot Pepper",
          "Button_Image_Name": "vs20dugems.png"
        },


        {
          "CN_Name": "5金兔 Megaways",
          "HTML5_Game_ID": "vswaysrabbits",
          "EN_Name": "5 Rabbits Megaways",
          "Button_Image_Name": "vswaysrabbits.png"
        },
        {
          "CN_Name": "神龙大侠",
          "HTML5_Game_ID": "vs20drgbless",
          "EN_Name": "Dragon Hero",
          "Button_Image_Name": "vs20drgbless.png"
        },
        {
          "CN_Name": "幸运捕鱼 Megaways",
          "HTML5_Game_ID": "vswaysluckyfish",
          "EN_Name": "Lucky Fishing Megaways",
          "Button_Image_Name": "vswaysluckyfish.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼持续旋转",
          "HTML5_Game_ID": "vs10bbkir",
          "EN_Name": "Big Bass Bonanza - Keeping it Reel",
          "Button_Image_Name": "vs10bbkir.png"
        },
        {
          "CN_Name": "斯巴达之盾",
          "HTML5_Game_ID": "vs20sparta",
          "EN_Name": "Shield of Sparta",
          "Button_Image_Name": "vs20sparta.png"
        },

        {
          "CN_Name": "海盗黄金时代",
          "HTML5_Game_ID": "vs20mtreasure",
          "EN_Name": "Pirate Golden Age",
          "Button_Image_Name": "vs20mtreasure.png"
        },
        {
          "CN_Name": "塞伦盖蒂之宝石",
          "HTML5_Game_ID": "vs20lcount",
          "EN_Name": "Gems of Serengeti",
          "Button_Image_Name": "vs20lcount.png"
        },

        {
          "CN_Name": "摩天大楼",
          "HTML5_Game_ID": "vs20theights",
          "EN_Name": "Towering Fortunes",
          "Button_Image_Name": "vs20theights.png"
        },
        {
          "CN_Name": "星光圣诞系列",
          "HTML5_Game_ID": "vs20schristmas",
          "EN_Name": "Starlight Christmas",
          "Button_Image_Name": "vs20schristmas.png"
        },
        {
          "CN_Name": "解放大海怪2",
          "HTML5_Game_ID": "vs20kraken2",
          "EN_Name": "Release the Kraken 2",
          "Button_Image_Name": "vs20kraken2.png"
        },
        {
          "CN_Name": "圣诞惊喜礼物",
          "HTML5_Game_ID": "vs20porbs",
          "EN_Name": "Santa's Great Gifts",
          "Button_Image_Name": "vs20porbs.png"
        },
        {
          "CN_Name": "阿茲特克火焰",
          "HTML5_Game_ID": "vs25kfruit",
          "EN_Name": "Aztec Blaze",
          "Button_Image_Name": "vs25kfruit.png"
        },
        {
          "CN_Name": "阿斯加德之王",
          "HTML5_Game_ID": "vs20asgard",
          "EN_Name": "Kingdom of Asgard",
          "Button_Image_Name": "vs20asgard.png"
        },
        {
          "CN_Name": "超级大鲈鱼暴风雨 - 圣诞鱼获",
          "HTML5_Game_ID": "vs12bbbxmas",
          "EN_Name": "Bigger Bass Blizzard - Christmas Catch",
          "Button_Image_Name": "vs12bbbxmas.png"
        },
        {
          "CN_Name": "旋转及射龙门Megaways",
          "HTML5_Game_ID": "vswaysfrywld",
          "EN_Name": "Spin & Score Megaways",
          "Button_Image_Name": "vswaysfrywld.png"
        },
        {
          "CN_Name": "约翰猎人与埃及之书重转",
          "HTML5_Game_ID": "vs10tut",
          "EN_Name": "John Hunter & the Book of Tut Respin",
          "Button_Image_Name": "vs10tut.png"
        },
        {
          "CN_Name": "黄金老矿工Megaways",
          "HTML5_Game_ID": "vswaysoldminer",
          "EN_Name": "Old Gold Miner Megaways",
          "Button_Image_Name": "vswaysoldminer.png"
        },
        {
          "CN_Name": "希腊战神之剑",
          "HTML5_Game_ID": "vs20swordofares",
          "EN_Name": "Sword of Ares",
          "Button_Image_Name": "vs20swordofares.png"
        },
        {
          "CN_Name": "狂野蹦跳青蛙",
          "HTML5_Game_ID": "vs20mparty",
          "EN_Name": "Wild Hop & Drop",
          "Button_Image_Name": "vs20mparty.png"
        },
        {
          "CN_Name": "埃及金沙之书",
          "HTML5_Game_ID": "vswaysbook",
          "EN_Name": "Book of Golden Sands",
          "Button_Image_Name": "vswaysbook.png"
        },
        {
          "CN_Name": "英雄联盟 Megaways",
          "HTML5_Game_ID": "vswayslofhero",
          "EN_Name": "Legend of Heroes Megaways",
          "Button_Image_Name": "vswayslofhero.png"
        },
        {
          "CN_Name": "火热闪烁5",
          "HTML5_Game_ID": "vs5strh",
          "EN_Name": "Striking Hot 5",
          "Button_Image_Name": "vs5strh.png"
        },
        {
          "CN_Name": "蛇和梯子：蛇眼",
          "HTML5_Game_ID": "vs10snakeeyes",
          "EN_Name": "Snakes & Ladders - Snake Eyes",
          "Button_Image_Name": "vs10snakeeyes.png"
        },
        {
          "CN_Name": "墨西哥亡灵节 Megaways",
          "HTML5_Game_ID": "vs20muertos",
          "EN_Name": "Muertos Multiplier Megaways",
          "Button_Image_Name": "vs20muertos.png"
        },
        {
          "CN_Name": "王冠之火",
          "HTML5_Game_ID": "vs10crownfire",
          "EN_Name": "Crown of Fire",
          "Button_Image_Name": "vs10crownfire.png"
        },
        {
          "CN_Name": "糖果星星",
          "HTML5_Game_ID": "vswaysstrwild",
          "EN_Name": "Candy Stars",
          "Button_Image_Name": "vswaysstrwild.png"
        },
        {
          "CN_Name": " 黑公牛",
          "HTML5_Game_ID": "vs20trswild2",
          "EN_Name": "Black Bull",
          "Button_Image_Name": "vs20trswild2.png"
        },
        {
          "CN_Name": "百搭超人星际战士",
          "HTML5_Game_ID": "vs20wildman",
          "EN_Name": "Wildman Super Bonanza",
          "Button_Image_Name": "vs20wildman.png"
        },
        {
          "CN_Name": "鱼跃龙门 Megaways",
          "HTML5_Game_ID": "vswaysfltdrg",
          "EN_Name": "Floating Dragon Megaways",
          "Button_Image_Name": "vswaysfltdrg.png"
        },
        {
          "CN_Name": "魔幻钱迷宫",
          "HTML5_Game_ID": "vs10mmm",
          "EN_Name": "Magic Money Maze",
          "Button_Image_Name": "vs10mmm.png"
        },
        {
          "CN_Name": "伦敦地铁",
          "HTML5_Game_ID": "vs20underground",
          "EN_Name": "Down the Rails",
          "Button_Image_Name": "vs20underground.png"
        },
        {
          "CN_Name": "野狼与小猪",
          "HTML5_Game_ID": "vs20wolfie",
          "EN_Name": "Greedy Wolf",
          "Button_Image_Name": "vs20wolfie.png"
        },
        {
          "CN_Name": "热火 100",
          "HTML5_Game_ID": "vs100firehot",
          "EN_Name": "Fire Hot 100",
          "Button_Image_Name": "vs100firehot.png"
        },
        {
          "CN_Name": "热火 40",
          "HTML5_Game_ID": "vs40firehot",
          "EN_Name": "Fire Hot 40",
          "Button_Image_Name": "vs40firehot.png"
        },
        {
          "CN_Name": "热火 20",
          "HTML5_Game_ID": "vs20fh",
          "EN_Name": "Fire Hot 20",
          "Button_Image_Name": "vs20fh.png"
        },
        {
          "CN_Name": "热火 5",
          "HTML5_Game_ID": "vs5firehot",
          "EN_Name": "Fire Hot 5",
          "Button_Image_Name": "vs5firehot.png"
        },
        {
          "CN_Name": "暴怒猩猩",
          "HTML5_Game_ID": "vs1024gmayhem",
          "EN_Name": "Gorilla Mayhem",
          "Button_Image_Name": "vs1024gmayhem.png"
        },
        {
          "CN_Name": "十月啤酒嘉年华",
          "HTML5_Game_ID": "vs20octobeer",
          "EN_Name": "Octobeer Fortunes",
          "Button_Image_Name": "vs20octobeer.png"
        },
        {
          "CN_Name": "极度热火燃烧",
          "HTML5_Game_ID": "vs40hotburnx",
          "EN_Name": "Hot to Burn Extreme",
          "Button_Image_Name": "vs40hotburnx.png"
        },
        {
          "CN_Name": "调皮的皇帝",
          "HTML5_Game_ID": "vs243ckemp",
          "EN_Name": "Cheeky Emperor",
          "Button_Image_Name": "vs243ckemp.png"
        },
        {
          "CN_Name": "极速糖果",
          "HTML5_Game_ID": "vs20sugarrush",
          "EN_Name": "Sugar Rush",
          "Button_Image_Name": "vs20sugarrush.png"
        },
        {
          "CN_Name": "热带提基",
          "HTML5_Game_ID": "vswaysjkrdrop",
          "EN_Name": "Tropical Tiki",
          "Button_Image_Name": "vswaysjkrdrop.png"
        },
        {
          "CN_Name": "拉摩卡 百搭",
          "HTML5_Game_ID": "vs10coffee",
          "EN_Name": "Coffee Wild",
          "Button_Image_Name": "vs10coffee.png"
        },


        {
          "CN_Name": "閃亮 热火 20",
          "HTML5_Game_ID": "vs20sh",
          "EN_Name": "Shining Hot 20",
          "Button_Image_Name": "vs20sh.png"
        },

        {
          "CN_Name": "大鲈鱼溅水",
          "HTML5_Game_ID": "vs10txbigbass",
          "EN_Name": "Big Bass Splash",
          "Button_Image_Name": "vs10txbigbass.png"
        },
        {
          "CN_Name": "轰炸富矿",
          "HTML5_Game_ID": "vs25bomb",
          "EN_Name": "Bomb Bonanza",
          "Button_Image_Name": "vs25bomb.png"
        },
        {
          "CN_Name": "熊猫麻将",
          "HTML5_Game_ID": "vs1024mahjpanda",
          "EN_Name": "Mahjong Panda",
          "Button_Image_Name": "vs1024mahjpanda.png"
        },
        {
          "CN_Name": "宇宙现金",
          "HTML5_Game_ID": "vs40cosmiccash",
          "EN_Name": "Cosmic Cash",
          "Button_Image_Name": "vs40cosmiccash.png"
        },
        {
          "CN_Name": "优质宝石 持控旋转",
          "HTML5_Game_ID": "vs5littlegem",
          "EN_Name": "Little Gem",
          "Button_Image_Name": "vs5littlegem.png"
        },
        {
          "CN_Name": "艳后之神",
          "HTML5_Game_ID": "vs10egrich",
          "EN_Name": "Queen of Gods",
          "Button_Image_Name": "vs10egrich.png"
        },
        {
          "CN_Name": "埃及神猫",
          "HTML5_Game_ID": "vs20cleocatra",
          "EN_Name": "Cleocatra",
          "Button_Image_Name": "vs20cleocatra.png"
        },
        {
          "CN_Name": "招财锦鲤",
          "HTML5_Game_ID": "vs243koipond",
          "EN_Name": "Koi Pond",
          "Button_Image_Name": "vs243koipond.png"
        },
        {
          "CN_Name": "崛起的武士3",
          "HTML5_Game_ID": "vs40samurai3",
          "EN_Name": "Rise Of Samurai III",
          "Button_Image_Name": "vs40samurai3.png"
        },
        {
          "CN_Name": "北境守护者",
          "HTML5_Game_ID": "vs50northgard",
          "EN_Name": "North Guardians",
          "Button_Image_Name": "vs50northgard.png"
        },
        {
          "CN_Name": "城市大盗",
          "HTML5_Game_ID": "vs20stickysymbol",
          "EN_Name": "The Great Stick-Up",
          "Button_Image_Name": "vs20stickysymbol.png"
        },
        {
          "CN_Name": "丧尸嘉年华",
          "HTML5_Game_ID": "vswayszombcarn",
          "EN_Name": "Zombie Carnival",
          "Button_Image_Name": "vswayszombcarn.png"
        },
        {
          "CN_Name": "地精时代",
          "HTML5_Game_ID": "vs20gobnudge",
          "EN_Name": "Goblin Heist Powernudge",
          "Button_Image_Name": "vs20gobnudge.png"
        },
        {
          "CN_Name": "埃及艳后之眼",
          "HTML5_Game_ID": "vs40cleoeye",
          "EN_Name": "Eye of Cleopatra",
          "Button_Image_Name": "vs40cleoeye.png"
        },
        {
          "CN_Name": "红火暴击2",
          "HTML5_Game_ID": "vs10firestrike2",
          "EN_Name": "Fire Strike 2",
          "Button_Image_Name": "vs10firestrike2.png"
        },
        {
          "CN_Name": "寻宝冒险精神",
          "HTML5_Game_ID": "vs10spiritadv",
          "EN_Name": "Spirit of Adventure",
          "Button_Image_Name": "vs10spiritadv.png"
        },
        {
          "CN_Name": "钻取金子",
          "HTML5_Game_ID": "vs20drtgold",
          "EN_Name": "Drill that Gold",
          "Button_Image_Name": "vs20drtgold.png"
        },
        {
          "CN_Name": "黄金三叶草",
          "HTML5_Game_ID": "vs20mustanggld2",
          "EN_Name": "Clover Gold",
          "Button_Image_Name": "vs20mustanggld2.png"
        },
        {
          "CN_Name": "西部牛仔黄金地段 Megaways",
          "HTML5_Game_ID": "vswayswildwest",
          "EN_Name": "Wild West Gold Megaways",
          "Button_Image_Name": "vswayswildwest.png"
        },
        {
          "CN_Name": "农场追逐",
          "HTML5_Game_ID": "vs10chkchase",
          "EN_Name": "Chicken Chase",
          "Button_Image_Name": "vs10chkchase.png"
        },
        {
          "CN_Name": "农场嘉年华",
          "HTML5_Game_ID": "vs20farmfest",
          "EN_Name": "Barn Festival",
          "Button_Image_Name": "vs20farmfest.png"
        },
        {
          "CN_Name": "女王",
          "HTML5_Game_ID": "vs243queenie",
          "EN_Name": "Queenie",
          "Button_Image_Name": "vs243queenie.png"
        },
        {
          "CN_Name": "迪斯科女士",
          "HTML5_Game_ID": "vs243discolady",
          "EN_Name": "Disco Lady",
          "Button_Image_Name": "vs243discolady.png"
        },
        {
          "CN_Name": "井字游戏",
          "HTML5_Game_ID": "vs10tictac",
          "EN_Name": "Tic Tac Take",
          "Button_Image_Name": "vs10tictac.png"
        },
        {
          "CN_Name": "黄金彩虹",
          "HTML5_Game_ID": "vs20rainbowg",
          "EN_Name": "Rainbow Gold",
          "Button_Image_Name": "vs20rainbowg.png"
        },
        {
          "CN_Name": "蛇梯棋超级骰子",
          "HTML5_Game_ID": "vs10snakeladd",
          "EN_Name": "Snakes and Ladders Megadice",
          "Button_Image_Name": "vs10snakeladd.png"
        },
        {
          "CN_Name": "斗牛嘉年华",
          "HTML5_Game_ID": "vs25bullfiesta",
          "EN_Name": "Bull Fiesta",
          "Button_Image_Name": "vs25bullfiesta.png"
        },
        {
          "CN_Name": "埃及神祇",
          "HTML5_Game_ID": "vs50mightra",
          "EN_Name": "Might of Ra",
          "Button_Image_Name": "vs50mightra.png"
        },
        {
          "CN_Name": "阿兹特克国王之书",
          "HTML5_Game_ID": "vs10bookazteck",
          "EN_Name": "Book of Aztec King",
          "Button_Image_Name": "vs10bookazteck.png"
        },
        {
          "CN_Name": "疯狂海滩派对",
          "HTML5_Game_ID": "vs20bchprty",
          "EN_Name": "Wild Beach Party",
          "Button_Image_Name": "vs20bchprty.png"
        },

        {
          "CN_Name": "终极之5",
          "HTML5_Game_ID": "vs20ultim5",
          "EN_Name": "The Ultimate 5",
          "Button_Image_Name": "vs20ultim5.png"
        },
        {
          "CN_Name": "巨大的现金区域",
          "HTML5_Game_ID": "vs20colcashzone",
          "EN_Name": "Colossal Cash Zone",
          "Button_Image_Name": "vs20colcashzone.png"
        },
        {
          "CN_Name": "巡逻现警",
          "HTML5_Game_ID": "vs25copsrobbers",
          "EN_Name": "Cash Patrol",
          "Button_Image_Name": "vs25copsrobbers.png"
        },
        {
          "CN_Name": "石头族赌城",
          "HTML5_Game_ID": "vs20rockvegas",
          "EN_Name": "Rock Vegas",
          "Button_Image_Name": "vs20rockvegas.png"
        },
        {
          "CN_Name": "五行宝石 Megaways",
          "HTML5_Game_ID": "vswayselements",
          "EN_Name": "Elemental Gems Megaways",
          "Button_Image_Name": "vswayselements.png"
        },
        {
          "CN_Name": "瓦尔哈拉之门",
          "HTML5_Game_ID": "vs10runes",
          "EN_Name": "Gates of Valhalla",
          "Button_Image_Name": "vs10runes.png"
        },
        {
          "CN_Name": "黄金派对",
          "HTML5_Game_ID": "vs25goldparty",
          "EN_Name": "Gold Party",
          "Button_Image_Name": "vs25goldparty.png"
        },
        {
          "CN_Name": "额外多汁Megaways",
          "HTML5_Game_ID": "vswaysxjuicy",
          "EN_Name": "Extra Juicy Megaways",
          "Button_Image_Name": "vswaysxjuicy.png"
        },
        {
          "CN_Name": "深海动物",
          "HTML5_Game_ID": "vs40wanderw",
          "EN_Name": "Wild Depths",
          "Button_Image_Name": "vs40wanderw.png"
        },
        {
          "CN_Name": "神秘的巫师",
          "HTML5_Game_ID": "vs4096magician",
          "EN_Name": "Magician's Secrets",
          "Button_Image_Name": "vs4096magician.png"
        },
        {
          "CN_Name": "水晶洞穴Megaways",
          "HTML5_Game_ID": "vswayscryscav",
          "EN_Name": "Crystal Caverns Megaways",
          "Button_Image_Name": "vswayscryscav.png"
        },
        {
          "CN_Name": "走私者海湾",
          "HTML5_Game_ID": "vs20smugcove",
          "EN_Name": "Smugglers Cove",
          "Button_Image_Name": "vs20smugcove.png"
        },
        {
          "CN_Name": "财神驾到",
          "HTML5_Game_ID": "vs243empcaishen",
          "EN_Name": "Emperor Caishen",
          "Button_Image_Name": "vs243empcaishen.png"
        },
        {
          "CN_Name": "虎虎生财",
          "HTML5_Game_ID": "vs25tigeryear",
          "EN_Name": "Lucky New Year - Tiger Treasures",
          "Button_Image_Name": "vs25tigeryear.png"
        },
        {
          "CN_Name": "埃及财富",
          "HTML5_Game_ID": "vs20amuleteg",
          "EN_Name": "Fortune of Giza",
          "Button_Image_Name": "vs20amuleteg.png"
        },
        {
          "CN_Name": "超级X",
          "HTML5_Game_ID": "vs20superx",
          "EN_Name": "Super X",
          "Button_Image_Name": "vs20superx.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼 Megaways",
          "HTML5_Game_ID": "vswaysbbb",
          "EN_Name": "Big Bass Bonanza Megaways",
          "Button_Image_Name": "vswaysbbb.png"
        },
        {
          "CN_Name": "冰球竞赛",
          "HTML5_Game_ID": "vs88hockattack",
          "EN_Name": "Hockey Attack",
          "Button_Image_Name": "vs88hockattack.png"
        },
        {
          "CN_Name": "赏金猎人",
          "HTML5_Game_ID": "vs25btygold",
          "EN_Name": "Bounty Gold",
          "Button_Image_Name": "vs25btygold.png"
        },
        {
          "CN_Name": "泡泡糖",
          "HTML5_Game_ID": "vs10bblpop",
          "EN_Name": "Bubble Pop",
          "Button_Image_Name": "vs10bblpop.png"
        },
        {
          "CN_Name": "坠落之书",
          "HTML5_Game_ID": "vs10bookfallen",
          "EN_Name": "Book of Fallen",
          "Button_Image_Name": "vs10bookfallen.png"
        },
        {
          "CN_Name": " 圣诞大鲈鱼",
          "HTML5_Game_ID": "vs10bxmasbnza",
          "EN_Name": "Christmas Big Bass Bonanza",
          "Button_Image_Name": "vs10bxmasbnza.png"
        },
        {
          "CN_Name": "圣诞仙境",
          "HTML5_Game_ID": "vs20santawonder",
          "EN_Name": "Santa's Wonderland",
          "Button_Image_Name": "vs20santawonder.png"
        },
        {
          "CN_Name": "墨西哥餐厅",
          "HTML5_Game_ID": "vs40bigjuan",
          "EN_Name": "Big Juan",
          "Button_Image_Name": "vs40bigjuan.png"
        },
        {
          "CN_Name": "约翰猎人与百慕大财富",
          "HTML5_Game_ID": "vs20bermuda",
          "EN_Name": "John Hunter and the Quest for Bermuda Riches",
          "Button_Image_Name": "vs20bermuda.png"
        },
        {
          "CN_Name": "星际海盗代码",
          "HTML5_Game_ID": "vs10starpirate",
          "EN_Name": "Star Pirates Code",
          "Button_Image_Name": "vs10starpirate.png"
        },
        {
          "CN_Name": "禽鸟之屋",
          "HTML5_Game_ID": "vs20tweethouse",
          "EN_Name": "The Tweety House",
          "Button_Image_Name": "vs20tweethouse.png"
        },
        {
          "CN_Name": "神秘的酋长",
          "HTML5_Game_ID": "vswayswest",
          "EN_Name": "Mystic Chief",
          "Button_Image_Name": "vswayswest.png"
        },
        {
          "CN_Name": "亡灵节",
          "HTML5_Game_ID": "vs20daydead",
          "EN_Name": "Day of Dead",
          "Button_Image_Name": "vs20daydead.png"
        },
        {
          "CN_Name": "糖果部落",
          "HTML5_Game_ID": "vs20candvil",
          "EN_Name": "Candy Village",
          "Button_Image_Name": "vs20candvil.png"
        },
        {
          "CN_Name": "百万$小猪",
          "HTML5_Game_ID": "vs9piggybank",
          "EN_Name": "Piggy Bank Bills",
          "Button_Image_Name": "vs9piggybank.png"
        },
        {
          "CN_Name": "水果银行",
          "HTML5_Game_ID": "vswaysbankbonz",
          "EN_Name": "Cash Bonanza",
          "Button_Image_Name": "vswaysbankbonz.png"
        },
        {
          "CN_Name": "疯狂宝藏",
          "HTML5_Game_ID": "vs20trsbox",
          "EN_Name": "Treasure Wild",
          "Button_Image_Name": "vs20trsbox.png"
        },
        {
          "CN_Name": "超疯狂大鲈鱼",
          "HTML5_Game_ID": "vs12bbb",
          "EN_Name": "Bigger Bass Bonanza",
          "Button_Image_Name": "vs12bbb.png"
        },
        {
          "CN_Name": "星光公主",
          "HTML5_Game_ID": "vs20starlight",
          "EN_Name": "Starlight Princess",
          "Button_Image_Name": "vs20starlight.png"
        },
        {
          "CN_Name": "牛转钱坤",
          "HTML5_Game_ID": "vs243chargebull",
          "EN_Name": "Raging Bull",
          "Button_Image_Name": "vs243chargebull.png"
        },
        {
          "CN_Name": "疯狂金字塔",
          "HTML5_Game_ID": "vs20pbonanza",
          "EN_Name": "Pyramid Bonanza",
          "Button_Image_Name": "vs20pbonanza.png"
        },
        {
          "CN_Name": "吉萨金字塔 PowerNudge",
          "HTML5_Game_ID": "vs10nudgeit",
          "EN_Name": "Rise of Giza PowerNudge",
          "Button_Image_Name": "vs10nudgeit.png"
        },
        {
          "CN_Name": "可口好吃 Powerways",
          "HTML5_Game_ID": "vswaysyumyum",
          "EN_Name": "Yum Yum Powerways",
          "Button_Image_Name": "vswaysyumyum.png"
        },
        {
          "CN_Name": "超级辣椒 Megaways",
          "HTML5_Game_ID": "vswayschilheat",
          "EN_Name": "Chilli Heat Megaways",
          "Button_Image_Name": "vswayschilheat.png"
        },
        {
          "CN_Name": "幸运，恩典和魅力",
          "HTML5_Game_ID": "vs10luckcharm",
          "EN_Name": "Lucky, Grace And Charm",
          "Button_Image_Name": "vs10luckcharm.png"
        },
        {
          "CN_Name": "阿兹特克国王Megaways",
          "HTML5_Game_ID": "vswaysaztecking",
          "EN_Name": "Aztec King Megaways",
          "Button_Image_Name": "vswaysaztecking.png"
        },
        {
          "CN_Name": "金鸡下蛋",
          "HTML5_Game_ID": "vs20chickdrop",
          "EN_Name": "Chicken Drop",
          "Button_Image_Name": "vs20chickdrop.png"
        },
        {
          "CN_Name": "霹雳凤凰",
          "HTML5_Game_ID": "vs20phoenixf",
          "EN_Name": "Phoenix Forge",
          "Button_Image_Name": "vs20phoenixf.png"
        },
        {
          "CN_Name": "水果派对 2",
          "HTML5_Game_ID": "vs20fparty2",
          "EN_Name": "Fruit Party 2",
          "Button_Image_Name": "vs20fparty2.png"
        },
        {
          "CN_Name": "盗空银行",
          "HTML5_Game_ID": "vs20emptybank",
          "EN_Name": "Empty the Bank",
          "Button_Image_Name": "vs20emptybank.png"
        },
        {
          "CN_Name": "幸运雷神",
          "HTML5_Game_ID": "vswayslight",
          "EN_Name": "Lucky Lightning",
          "Button_Image_Name": "vswayslight.png"
        },
        {
          "CN_Name": "裏約之心",
          "HTML5_Game_ID": "vs25rio",
          "EN_Name": "Heart of Rio",
          "Button_Image_Name": "vs25rio.png"
        },
        {
          "CN_Name": "舞龙 热控旋转",
          "HTML5_Game_ID": "vs5drhs",
          "EN_Name": "Dragon Hot Hold and Spin",
          "Button_Image_Name": "vs5drhs.png"
        },
        {
          "CN_Name": "5金狮 Megaways",
          "HTML5_Game_ID": "vswayslions",
          "EN_Name": "5 Lions Megaways",
          "Button_Image_Name": "vswayslions.png"
        },
        {
          "CN_Name": "北海道之狼",
          "HTML5_Game_ID": "vs576hokkwolf",
          "EN_Name": "Hokkaido Wolf",
          "Button_Image_Name": "vs576hokkwolf.png"
        },
        {
          "CN_Name": "现金升降机",
          "HTML5_Game_ID": "vs20terrorv",
          "EN_Name": "Cash Elevator",
          "Button_Image_Name": "vs20terrorv.png"
        },
        {
          "CN_Name": "崛起的武士 Megaways",
          "HTML5_Game_ID": "vswayssamurai",
          "EN_Name": "Rise of Samurai Megaways",
          "Button_Image_Name": "vswayssamurai.png"
        },
        {
          "CN_Name": "魔法大釜绝佳药剂",
          "HTML5_Game_ID": "vs20magicpot",
          "EN_Name": "The Magic Cauldron",
          "Button_Image_Name": "vs20magicpot.png"
        },
        {
          "CN_Name": "阿茲特克国王",
          "HTML5_Game_ID": "vs25aztecking",
          "EN_Name": "Aztec King",
          "Button_Image_Name": "vs25aztecking.png"
        },
        {
          "CN_Name": "奇幻无比金钱取款机",
          "HTML5_Game_ID": "vs10amm",
          "EN_Name": "Amazing Money Machine",
          "Button_Image_Name": "vs10amm.png"
        },
        {
          "CN_Name": "熊猫财富2",
          "HTML5_Game_ID": "vs25pandatemple",
          "EN_Name": "Panda Fortune 2",
          "Button_Image_Name": "vs25pandatemple.png"
        },
        {
          "CN_Name": "鱼跃龙门 持控旋转",
          "HTML5_Game_ID": "vs10floatdrg",
          "EN_Name": "Floating Dragon",
          "Button_Image_Name": "vs10floatdrg.png"
        },
        {
          "CN_Name": "王者野牛 Megaways",
          "HTML5_Game_ID": "vswaysbufking",
          "EN_Name": "Buffalo King Megaways",
          "Button_Image_Name": "vswaysbufking.png"
        },
        {
          "CN_Name": "狂热嘉年华",
          "HTML5_Game_ID": "vs25hotfiesta",
          "EN_Name": "Hot Fiesta",
          "Button_Image_Name": "vs25hotfiesta.png"
        },
        {
          "CN_Name": "雷神之锤 Megaways",
          "HTML5_Game_ID": "vswayshammthor",
          "EN_Name": "Power of Thor Megaways",
          "Button_Image_Name": "vswayshammthor.png"
        },
        {
          "CN_Name": "百搭加强器",
          "HTML5_Game_ID": "vs20wildboost",
          "EN_Name": "Wild Booster",
          "Button_Image_Name": "vs20wildboost.png"
        },
        {
          "CN_Name": "多汁水果",
          "HTML5_Game_ID": "vs50juicyfr",
          "EN_Name": "Juicy Fruits",
          "Button_Image_Name": "vs50juicyfr.png"
        },
        {
          "CN_Name": "铁木真宝物",
          "HTML5_Game_ID": "vs1024temuj",
          "EN_Name": "Temujin Treasures",
          "Button_Image_Name": "vs1024temuj.png"
        },
        {
          "CN_Name": "大鱼奇缘",
          "HTML5_Game_ID": "vs10goldfish",
          "EN_Name": "Fishin Reels",
          "Button_Image_Name": "vs10goldfish.png"
        },
        {
          "CN_Name": "奥林匹斯之门",
          "HTML5_Game_ID": "vs20olympgate",
          "EN_Name": "Gates of Olympus",
          "Button_Image_Name": "vs20olympgate.png"
        },
        {
          "CN_Name": "热火燃烧持控旋转",
          "HTML5_Game_ID": "vs20hburnhs",
          "EN_Name": "Hot to Burn Hold and Spin",
          "Button_Image_Name": "vs20hburnhs.png"
        },
        {
          "CN_Name": "小丑王国",
          "HTML5_Game_ID": "vs25jokerking",
          "EN_Name": "Joker King",
          "Button_Image_Name": "vs25jokerking.png"
        },
        {
          "CN_Name": "迈达斯之手",
          "HTML5_Game_ID": "vs20midas",
          "EN_Name": "The Hand of Midas",
          "Button_Image_Name": "vs20midas.png"
        },
        {
          "CN_Name": "风暴之眼",
          "HTML5_Game_ID": "vs10eyestorm",
          "EN_Name": "Eye of the Storm",
          "Button_Image_Name": "vs10eyestorm.png"
        },
        {
          "CN_Name": "神龙宝藏雄火之眼",
          "HTML5_Game_ID": "vs5drmystery",
          "EN_Name": "Dragon Kingdom - Eyes of Fire",
          "Button_Image_Name": "vs5drmystery.png"
        },
        {
          "CN_Name": "命运女巫Megaways",
          "HTML5_Game_ID": "vswaysmadame",
          "EN_Name": "Madame Destiny Megaways",
          "Button_Image_Name": "vswaysmadame.png"
        },
        {
          "CN_Name": "刚果财富",
          "HTML5_Game_ID": "vs432congocash",
          "EN_Name": "Congo Cash",
          "Button_Image_Name": "vs432congocash.png"
        },
        {
          "CN_Name": "翡翠之王彩虹道路",
          "HTML5_Game_ID": "vs20ekingrr",
          "EN_Name": "Emerald King Rainbow Road",
          "Button_Image_Name": "vs20ekingrr.png"
        },
        {
          "CN_Name": "古埃及神话",
          "HTML5_Game_ID": "vs10wildtut",
          "EN_Name": "Mysterious Egypt",
          "Button_Image_Name": "vs10wildtut.png"
        },
        {
          "CN_Name": "鸿运你最牛",
          "HTML5_Game_ID": "vs25gldox",
          "EN_Name": "Golden Ox",
          "Button_Image_Name": "vs25gldox.png"
        },
        {
          "CN_Name": "恶毒魔法",
          "HTML5_Game_ID": "vs40voodoo",
          "EN_Name": "Voodoo Magic",
          "Button_Image_Name": "vs40voodoo.png"
        },
        {
          "CN_Name": "疯狂大鲈鱼",
          "HTML5_Game_ID": "vs10bbbonanza",
          "EN_Name": "Big Bass Bonanza",
          "Button_Image_Name": "vs10bbbonanza.png"
        },
        {
          "CN_Name": "黄金富矿",
          "HTML5_Game_ID": "vs20bonzgold",
          "EN_Name": "Bonanza Gold",
          "Button_Image_Name": "vs20bonzgold.png"
        },
        {
          "CN_Name": "约翰猎人与玛雅神迹",
          "HTML5_Game_ID": "vs10mayangods",
          "EN_Name": "John Hunter and the Mayan Gods",
          "Button_Image_Name": "vs10mayangods.png"
        },
        {
          "CN_Name": "斯巴达之王",
          "HTML5_Game_ID": "vs40spartaking",
          "EN_Name": "Spartan King",
          "Button_Image_Name": "vs40spartaking.png"
        },
        {
          "CN_Name": "圣诞颂歌 Megaways",
          "HTML5_Game_ID": "vs20xmascarol",
          "EN_Name": "Christmas Carol Megaways",
          "Button_Image_Name": "vs20xmascarol.png"
        },
        {
          "CN_Name": "黄金叛变",
          "HTML5_Game_ID": "vs10cowgold",
          "EN_Name": "Cowboys Gold",
          "Button_Image_Name": "vs10cowgold.png"
        },
        {
          "CN_Name": "黄金海盗加强版",
          "HTML5_Game_ID": "vs40pirgold",
          "EN_Name": "Pirate Gold Deluxe",
          "Button_Image_Name": "vs40pirgold.png"
        },
        {
          "CN_Name": "翡翠之王",
          "HTML5_Game_ID": "vs20eking",
          "EN_Name": "Emerald King",
          "Button_Image_Name": "vs20eking.png"
        },
        {
          "CN_Name": "龙虎榜",
          "HTML5_Game_ID": "vs1024dtiger",
          "EN_Name": "The Dragon Tiger",
          "Button_Image_Name": "vs1024dtiger.png"
        },
        {
          "CN_Name": "圣书帝国",
          "HTML5_Game_ID": "vs25bkofkngdm",
          "EN_Name": "Book of Kingdoms",
          "Button_Image_Name": "vs25bkofkngdm.png"
        },
        {
          "CN_Name": "活死人归来",
          "HTML5_Game_ID": "vs10returndead",
          "EN_Name": "Return of the Dead",
          "Button_Image_Name": "vs10returndead.png"
        },
        {
          "CN_Name": "富矿宝石",
          "HTML5_Game_ID": "vs20goldfever",
          "EN_Name": "Gems Bonanza",
          "Button_Image_Name": "vs20goldfever.png"
        },
        {
          "CN_Name": "行尸走肉",
          "HTML5_Game_ID": "vs25walker",
          "EN_Name": "Wild Walker",
          "Button_Image_Name": "vs25walker.png"
        },
        {
          "CN_Name": "崛起的武士",
          "HTML5_Game_ID": "vs25samurai",
          "EN_Name": "Rise of Samurai",
          "Button_Image_Name": "vs25samurai.png"
        },
        {
          "CN_Name": "五狮团拜",
          "HTML5_Game_ID": "vs1024lionsd",
          "EN_Name": "5 Lions Dance",
          "Button_Image_Name": "vs1024lionsd.png"
        },
        {
          "CN_Name": "星际赏金",
          "HTML5_Game_ID": "vswayshive",
          "EN_Name": "Star Bounty",
          "Button_Image_Name": "vswayshive.png"
        },
        {
          "CN_Name": "巨大犀牛 加强版",
          "HTML5_Game_ID": "vs20rhinoluxe",
          "EN_Name": "Great Rhino Deluxe",
          "Button_Image_Name": "vs20rhinoluxe.png"
        },
        {
          "CN_Name": "爱尔兰人宝藏",
          "HTML5_Game_ID": "vs576treasures",
          "EN_Name": "Wild Wild Riches",
          "Button_Image_Name": "vs576treasures.png"
        },
        {
          "CN_Name": "超级持控旋转",
          "HTML5_Game_ID": "vs5ultra",
          "EN_Name": "Ultra Hold and Spin",
          "Button_Image_Name": "vs5ultra.png"
        },
        {
          "CN_Name": "狼人的诅咒Megaways",
          "HTML5_Game_ID": "vswayswerewolf",
          "EN_Name": "Curse of the Werewolf Megaways",
          "Button_Image_Name": "vswayswerewolf.png"
        },
        {
          "CN_Name": "深林之猩",
          "HTML5_Game_ID": "vs20gorilla",
          "EN_Name": "Jungle Gorilla",
          "Button_Image_Name": "vs20gorilla.png"
        },
        {
          "CN_Name": "古时代宝石 加强版",
          "HTML5_Game_ID": "vs9aztecgemsdx",
          "EN_Name": "Aztec Gems Deluxe",
          "Button_Image_Name": "vs9aztecgemsdx.png"
        },
        {
          "CN_Name": "汪汪之家Megaways",
          "HTML5_Game_ID": "vswaysdogs",
          "EN_Name": "The Dog House Megaways",
          "Button_Image_Name": "vswaysdogs.png"
        },
        {
          "CN_Name": "武松打虎",
          "HTML5_Game_ID": "vs25tigerwar",
          "EN_Name": "The Tiger Warrior",
          "Button_Image_Name": "vs25tigerwar.png"
        },
        {
          "CN_Name": "街头赛车",
          "HTML5_Game_ID": "vs40streetracer",
          "EN_Name": "Street Racer",
          "Button_Image_Name": "vs40streetracer.png"
        },
        {
          "CN_Name": "福福福",
          "HTML5_Game_ID": "vs1fufufu",
          "EN_Name": "Fu Fu Fu",
          "Button_Image_Name": "vs1fufufu.png"
        },
        {
          "CN_Name": "群龙保珠-宝石致富",
          "HTML5_Game_ID": "vs1600drago",
          "EN_Name": "Drago - Jewels of Fortune",
          "Button_Image_Name": "vs1600drago.png"
        },
        {
          "CN_Name": "王者金字塔",
          "HTML5_Game_ID": "vs25pyramid",
          "EN_Name": "Pyramid King",
          "Button_Image_Name": "vs25pyramid.png"
        },
        {
          "CN_Name": "超级燃烧",
          "HTML5_Game_ID": "vs5ultrab",
          "EN_Name": "Ultra Burn",
          "Button_Image_Name": "vs5ultrab.png"
        },
        {
          "CN_Name": "钱生钱",
          "HTML5_Game_ID": "vs1money",
          "EN_Name": "Money Money Money",
          "Button_Image_Name": "vs1money.png"
        },
        {
          "CN_Name": "行星宝石 Megaways",
          "HTML5_Game_ID": "vs117649starz ",
          "EN_Name": "Starz Megaways",
          "Button_Image_Name": "vs117649starz .png"
        },
        {
          "CN_Name": "水果派对",
          "HTML5_Game_ID": "vs20fruitparty ",
          "EN_Name": "Fruit Party",
          "Button_Image_Name": "vs20fruitparty .png"
        },
        {
          "CN_Name": "约翰·亨特之埃及图特书",
          "HTML5_Game_ID": "vs10bookoftut",
          "EN_Name": "John Hunter and the Book of Tut",
          "Button_Image_Name": "vs10bookoftut.png"
        },
        {
          "CN_Name": "三星报喜",
          "HTML5_Game_ID": "vs10threestar",
          "EN_Name": "Three Star Fortune",
          "Button_Image_Name": "vs10threestar.png"
        },
        {
          "CN_Name": "巨大犀牛 Megaways",
          "HTML5_Game_ID": "vswaysrhino",
          "EN_Name": "Great Rhino Megaways",
          "Button_Image_Name": "vswaysrhino.png"
        },
        {
          "CN_Name": "热火燃烧",
          "HTML5_Game_ID": "vs5hotburn",
          "EN_Name": "Hot to Burn",
          "Button_Image_Name": "vs5hotburn.png"
        },
        {
          "CN_Name": "跳舞趴",
          "HTML5_Game_ID": "vs243dancingpar",
          "EN_Name": "Dance Party",
          "Button_Image_Name": "vs243dancingpar.png"
        },
        {
          "CN_Name": "西部牛仔黄金地段",
          "HTML5_Game_ID": "vs40wildwest",
          "EN_Name": "Wild West Gold",
          "Button_Image_Name": "vs40wildwest.png"
        },
        {
          "CN_Name": "金龙吐珠",
          "HTML5_Game_ID": "vs1ball",
          "EN_Name": "Lucky Dragon Ball",
          "Button_Image_Name": "vs1ball.png"
        },
        {
          "CN_Name": "彩虹水果",
          "HTML5_Game_ID": "vs40frrainbow",
          "EN_Name": "Fruit Rainbow",
          "Button_Image_Name": "vs40frrainbow.png"
        },
        {
          "CN_Name": "疯狂机器",
          "HTML5_Game_ID": "vs40madwheel",
          "EN_Name": "The Wild Machine",
          "Button_Image_Name": "vs40madwheel.png"
        },
        {
          "CN_Name": "阿兹特克获利之道",
          "HTML5_Game_ID": "vs7776aztec",
          "EN_Name": "Aztec Bonanza",
          "Button_Image_Name": "vs7776aztec.png"
        },
        {
          "CN_Name": "神秘之城",
          "HTML5_Game_ID": "vs4096mystery",
          "EN_Name": "Mysterious",
          "Button_Image_Name": "vs4096mystery.png"
        },
        {
          "CN_Name": "杰克大师",
          "HTML5_Game_ID": "vs1masterjoker",
          "EN_Name": "Master Joker",
          "Button_Image_Name": "vs1masterjoker.png"
        },
        {
          "CN_Name": "超级7s",
          "HTML5_Game_ID": "vs5super7",
          "EN_Name": "Super 7s ",
          "Button_Image_Name": "vs5super7.png"
        },
        {
          "CN_Name": "解放大海怪",
          "HTML5_Game_ID": "vs20kraken",
          "EN_Name": "Release the Kraken",
          "Button_Image_Name": "vs20kraken.png"
        },
        {
          "CN_Name": "王者野牛",
          "HTML5_Game_ID": "vs4096bufking",
          "EN_Name": "Buffalo King",
          "Button_Image_Name": "vs4096bufking.png"
        },
        {
          "CN_Name": "西游争霸",
          "HTML5_Game_ID": "vs8magicjourn",
          "EN_Name": "Magic Journey",
          "Button_Image_Name": "vs8magicjourn.png"
        },
        {
          "CN_Name": "招财鼠",
          "HTML5_Game_ID": "vs25mmouse",
          "EN_Name": "Money Mouse",
          "Button_Image_Name": "vs25mmouse.png"
        },
        {
          "CN_Name": "阿拉丁和巫师",
          "HTML5_Game_ID": "vs20aladdinsorc",
          "EN_Name": "Aladdin and the Sorcerer",
          "Button_Image_Name": "vs20aladdinsorc.png"
        },
        {
          "CN_Name": "希腊众神",
          "HTML5_Game_ID": "vs243fortseren",
          "EN_Name": "Greek Gods",
          "Button_Image_Name": "vs243fortseren.png"
        },
        {
          "CN_Name": "甜心盛宴圣诞",
          "HTML5_Game_ID": "vs20sbxmas",
          "EN_Name": "Sweet Bonanza Xmas",
          "Button_Image_Name": "vs20sbxmas.png"
        },
        {
          "CN_Name": "大力神和飞马",
          "HTML5_Game_ID": "vs20hercpeg",
          "EN_Name": "Hercules and Pegasus",
          "Button_Image_Name": "vs20hercpeg.png"
        },
        {
          "CN_Name": "红火暴击",
          "HTML5_Game_ID": "vs10firestrike",
          "EN_Name": "Fire Strike",
          "Button_Image_Name": "vs10firestrike.png"
        },
        {
          "CN_Name": "甜蜜蜜",
          "HTML5_Game_ID": "vs20honey",
          "EN_Name": "Honey Honey Honey",
          "Button_Image_Name": "vs20honey.png"
        },
        {
          "CN_Name": "超炫小丑",
          "HTML5_Game_ID": "vs5spjoker",
          "EN_Name": "Super Joker",
          "Button_Image_Name": "vs5spjoker.png"
        },
        {
          "CN_Name": "金龟子女王",
          "HTML5_Game_ID": "vs25scarabqueen",
          "EN_Name": "John Hunter and the Tomb of the Scarab Queen",
          "Button_Image_Name": "vs25scarabqueen.png"
        },
        {
          "CN_Name": "发发树",
          "HTML5_Game_ID": "vs1fortunetree",
          "EN_Name": "Tree of Riches",
          "Button_Image_Name": "vs1fortunetree.png"
        },
        {
          "CN_Name": "小鸡大逃亡",
          "HTML5_Game_ID": "vs20chicken",
          "EN_Name": "The Great Chicken Escape",
          "Button_Image_Name": "vs20chicken.png"
        },
        {
          "CN_Name": "吸血鬼vs狼",
          "HTML5_Game_ID": "vs10vampwolf",
          "EN_Name": "Vampires VS Wolves",
          "Button_Image_Name": "vs10vampwolf.png"
        },
        {
          "CN_Name": "超级辣",
          "HTML5_Game_ID": "vs9hotroll",
          "EN_Name": "Hot Chilli",
          "Button_Image_Name": "vs9hotroll.png"
        },
        {
          "CN_Name": "阿兹特克秘宝",
          "HTML5_Game_ID": "vs7776secrets",
          "EN_Name": "John Hunter and the Aztec Treasure",
          "Button_Image_Name": "vs7776secrets.png"
        },
        {
          "CN_Name": "酷猴战士",
          "HTML5_Game_ID": "vs243mwarrior",
          "EN_Name": "Monkey Warrior",
          "Button_Image_Name": "vs243mwarrior.png"
        },

        {
          "CN_Name": "5金狮",
          "HTML5_Game_ID": "vs243lionsgold",
          "EN_Name": "5 Lions Gold",
          "Button_Image_Name": "vs243lionsgold.png"
        },
        {
          "CN_Name": "野精灵",
          "HTML5_Game_ID": "vs20wildpix",
          "EN_Name": "Wild Pixies",
          "Button_Image_Name": "vs20wildpix.png"
        },
        {
          "CN_Name": "甜入心扉",
          "HTML5_Game_ID": "vs20fruitsw",
          "EN_Name": "Sweet Bonanza",
          "Button_Image_Name": "vs20fruitsw.png"
        },
        {
          "CN_Name": "财神运财",
          "HTML5_Game_ID": "vs243caishien",
          "EN_Name": "Caishen's Cash",
          "Button_Image_Name": "vs243caishien.png"
        },
        {
          "CN_Name": "夺金海贼",
          "HTML5_Game_ID": "vs40pirate",
          "EN_Name": "Pirate Gold",
          "Button_Image_Name": "vs40pirate.png"
        },
        {
          "CN_Name": "汪汪之家",
          "HTML5_Game_ID": "vs20doghouse",
          "EN_Name": "The Dog House",
          "Button_Image_Name": "vs20doghouse.png"
        },
        {
          "CN_Name": "埃及宿命",
          "HTML5_Game_ID": "vs20egypttrs",
          "EN_Name": "Egyptian Fortunes",
          "Button_Image_Name": "vs20egypttrs.png"
        },
        {
          "CN_Name": "额外多汁",
          "HTML5_Game_ID": "vs10fruity2",
          "EN_Name": "Extra Juicy",
          "Button_Image_Name": "vs10fruity2.png"
        },

        {
          "CN_Name": "狂野角斗士",
          "HTML5_Game_ID": "vs25gladiator",
          "EN_Name": "Wild Gladiator",
          "Button_Image_Name": "vs25gladiator.png"
        },
        {
          "CN_Name": "招财福猪",
          "HTML5_Game_ID": "vs25goldpig",
          "EN_Name": "Golden Pig",
          "Button_Image_Name": "vs25goldpig.png"
        },
        {
          "CN_Name": "马上有钱",
          "HTML5_Game_ID": "vs18mashang",
          "EN_Name": "Treasure Horse",
          "Button_Image_Name": "vs18mashang.png"
        },
        {
          "CN_Name": "狩猎之王",
          "HTML5_Game_ID": "vs50safariking",
          "EN_Name": "Safari King",
          "Button_Image_Name": "vs50safariking.png"
        },
        {
          "CN_Name": "小妖之歌圣诞版",
          "HTML5_Game_ID": "vs20leprexmas",
          "EN_Name": "Leprechaun Carol",
          "Button_Image_Name": "vs20leprexmas.png"
        },
        {
          "CN_Name": "黄金野马",
          "HTML5_Game_ID": "vs25mustang",
          "EN_Name": "Mustang Gold",
          "Button_Image_Name": "vs25mustang.png"
        },
        {
          "CN_Name": "龙龙龙",
          "HTML5_Game_ID": "vs5trdragons",
          "EN_Name": "Triple Dragons",
          "Button_Image_Name": "vs5trdragons.png"
        },

        {
          "CN_Name": "古代埃及经典版",
          "HTML5_Game_ID": "vs10egyptcls",
          "EN_Name": "Ancient Egypt Classic",
          "Button_Image_Name": "vs10egyptcls.png"
        },
        {
          "CN_Name": "魔力维加斯",
          "HTML5_Game_ID": "vs20vegasmagic",
          "EN_Name": "Vegas Magic",
          "Button_Image_Name": "vs20vegasmagic.png"
        },
        {
          "CN_Name": "陈师傅的财富",
          "HTML5_Game_ID": "vs9chen",
          "EN_Name": "Master Chen's Fortune",
          "Button_Image_Name": "vs9chen.png"
        },
        {
          "CN_Name": "达芬奇宝藏",
          "HTML5_Game_ID": "vs25davinci",
          "EN_Name": "Da Vinci's Treasure",
          "Button_Image_Name": "vs25davinci.png"
        },
        {
          "CN_Name": "好运北京",
          "HTML5_Game_ID": "vs25peking",
          "EN_Name": "Peking Luck",
          "Button_Image_Name": "vs25peking.png"
        },
        {
          "CN_Name": "小妖之歌",
          "HTML5_Game_ID": "vs20leprechaun",
          "EN_Name": "Leprechaun Song",
          "Button_Image_Name": "vs20leprechaun.png"
        },
        {
          "CN_Name": "玉蝴蝶",
          "HTML5_Game_ID": "vs1024butterfly",
          "EN_Name": "Jade Butterfly",
          "Button_Image_Name": "vs1024butterfly.png"
        },
        {
          "CN_Name": "命运女士",
          "HTML5_Game_ID": "vs10madame",
          "EN_Name": "Madame Destiny",
          "Button_Image_Name": "vs10madame.png"
        },
        {
          "CN_Name": "仙宫",
          "HTML5_Game_ID": "vs25asgard",
          "EN_Name": "Asgard",
          "Button_Image_Name": "vs25asgard.png"
        },
        {
          "CN_Name": "5雄狮",
          "HTML5_Game_ID": "vs243lions",
          "EN_Name": "5 Lions",
          "Button_Image_Name": "vs243lions.png"
        },
        {
          "CN_Name": "巨大犀牛",
          "HTML5_Game_ID": "vs20rhino",
          "EN_Name": "Great Rhino",
          "Button_Image_Name": "vs20rhino.png"
        },

        {
          "CN_Name": "小丑珠宝",
          "HTML5_Game_ID": "vs5joker",
          "EN_Name": "Joker's Jewels",
          "Button_Image_Name": "vs5joker.png"
        },
        {
          "CN_Name": "童话财富",
          "HTML5_Game_ID": "vs15fairytale",
          "EN_Name": "Fairytale Fortune",
          "Button_Image_Name": "vs15fairytale.png"
        },

        {
          "CN_Name": "火辣辣",
          "HTML5_Game_ID": "vs25chilli",
          "EN_Name": "Chilli Heat",
          "Button_Image_Name": "vs25chilli.png"
        },
        {
          "CN_Name": "古时代宝石",
          "HTML5_Game_ID": "vs5aztecgems",
          "EN_Name": "Aztec Gems",
          "Button_Image_Name": "vs5aztecgems.png"
        },
        {
          "CN_Name": "淘金热",
          "HTML5_Game_ID": "vs25goldrush",
          "EN_Name": "Gold Rush",
          "Button_Image_Name": "vs25goldrush.png"
        },
        {
          "CN_Name": "幸运新年",
          "HTML5_Game_ID": "vs25newyear",
          "EN_Name": "Lucky New Year",
          "Button_Image_Name": "vs25newyear.png"
        },
        {
          "CN_Name": "古代埃及",
          "HTML5_Game_ID": "vs10egypt",
          "EN_Name": "Ancient Egypt",
          "Button_Image_Name": "vs10egypt.png"
        },
        {
          "CN_Name": "三只老虎",
          "HTML5_Game_ID": "vs1tigers",
          "EN_Name": "Triple Tigers",
          "Button_Image_Name": "vs1tigers.png"
        },
        {
          "CN_Name": "永恒钻石3线",
          "HTML5_Game_ID": "cs3w",
          "EN_Name": "Diamonds are Forever 3 Lines",
          "Button_Image_Name": "cs3w.png"
        },
        {
          "CN_Name": "猴子疯狂",
          "HTML5_Game_ID": "vs9madmonkey",
          "EN_Name": "Monkey Madness",
          "Button_Image_Name": "vs9madmonkey.png"
        },
        {
          "CN_Name": "圣诞老人",
          "HTML5_Game_ID": "vs20santa",
          "EN_Name": "Santa",
          "Button_Image_Name": "vs20santa.png"
        },
        {
          "CN_Name": "7只小猪",
          "HTML5_Game_ID": "vs7pigs",
          "EN_Name": "7 Piggies",
          "Button_Image_Name": "vs7pigs.png"
        },
        {
          "CN_Name": "发发发龙",
          "HTML5_Game_ID": "vs1dragon8",
          "EN_Name": "888 Dragons",
          "Button_Image_Name": "vs1dragon8.png"
        },
        {
          "CN_Name": "维加斯之夜",
          "HTML5_Game_ID": "vs25vegas",
          "EN_Name": "Vegas Nights",
          "Button_Image_Name": "vs25vegas.png"
        },
        {
          "CN_Name": "法力无边",
          "HTML5_Game_ID": "vs25wildspells",
          "EN_Name": "Wild Spells",
          "Button_Image_Name": "vs25wildspells.png"
        },
        {
          "CN_Name": "钻石罢工",
          "HTML5_Game_ID": "vs15diamond",
          "EN_Name": "Diamond Strike",
          "Button_Image_Name": "vs15diamond.png"
        },
        {
          "CN_Name": "熊猫财富",
          "HTML5_Game_ID": "vs25pandagold",
          "EN_Name": "Panda's Fortune",
          "Button_Image_Name": "vs25pandagold.png"
        },
        {
          "CN_Name": "财源滚滚",
          "HTML5_Game_ID": "cs5moneyroll",
          "EN_Name": "Money Roll",
          "Button_Image_Name": "cs5moneyroll.png"
        },
        {
          "CN_Name": "8条龙",
          "HTML5_Game_ID": "vs20eightdragons",
          "EN_Name": "8 Dragons",
          "Button_Image_Name": "vs20eightdragons.png"
        },
        {
          "CN_Name": "野狼黄金",
          "HTML5_Game_ID": "vs25wolfgold",
          "EN_Name": "Wolf Gold",
          "Button_Image_Name": "vs25wolfgold.png"
        },
        {
          "CN_Name": "龙之国度",
          "HTML5_Game_ID": "vs25dragonkingdom",
          "EN_Name": "Dragon Kingdom",
          "Button_Image_Name": "vs25dragonkingdom.png"
        },
        {
          "CN_Name": "三国",
          "HTML5_Game_ID": "vs25kingdoms",
          "EN_Name": "3 Kingdoms - Battle of Red Cliffs",
          "Button_Image_Name": "vs25kingdoms.png"
        },
        {
          "CN_Name": "黄金列车",
          "HTML5_Game_ID": "vs3train",
          "EN_Name": "Gold Train",
          "Button_Image_Name": "vs3train.png"
        },
        {
          "CN_Name": "宙斯之子赫拉克勒斯",
          "HTML5_Game_ID": "vs50hercules",
          "EN_Name": "Hercules Son Of Zeus",
          "Button_Image_Name": "vs50hercules.png"
        },
        {
          "CN_Name": "西游记",
          "HTML5_Game_ID": "vs25journey",
          "EN_Name": "Journey to the West",
          "Button_Image_Name": "vs25journey.png"
        },
        {
          "CN_Name": "恶魔13",
          "HTML5_Game_ID": "vs13g",
          "EN_Name": "Devil's 13",
          "Button_Image_Name": "vs13g.png"
        },
        {
          "CN_Name": "飞龙在天",
          "HTML5_Game_ID": "vs50chinesecharms",
          "EN_Name": "Lucky Dragons",
          "Button_Image_Name": "vs50chinesecharms.png"
        },
        {
          "CN_Name": "财神黄金",
          "HTML5_Game_ID": "vs243fortune",
          "EN_Name": "Caishen's Gold",
          "Button_Image_Name": "vs243fortune.png"
        },
        {
          "CN_Name": "精灵翅膀",
          "HTML5_Game_ID": "vs50pixie",
          "EN_Name": "Pixie Wings",
          "Button_Image_Name": "vs50pixie.png"
        },
        {
          "CN_Name": "大堡礁",
          "HTML5_Game_ID": "vs25sea",
          "EN_Name": "Great Reef",
          "Button_Image_Name": "vs25sea.png"
        },
        {
          "CN_Name": "3个精灵愿望",
          "HTML5_Game_ID": "vs50aladdin",
          "EN_Name": "3 Genie Wishes",
          "Button_Image_Name": "vs50aladdin.png"
        },
        {
          "CN_Name": "爱尔兰的魅力",
          "HTML5_Game_ID": "cs3irishcharms",
          "EN_Name": "Irish Charms",
          "Button_Image_Name": "cs3irishcharms.png"
        },

        {
          "CN_Name": "矮人黄金豪华版",
          "HTML5_Game_ID": "vs25dwarves_new",
          "EN_Name": "Dwarven Gold Deluxe",
          "Button_Image_Name": "vs25dwarves_new.png"
        },
        {
          "CN_Name": "无敌金刚",
          "HTML5_Game_ID": "vs50kingkong",
          "EN_Name": "Mighty Kong",
          "Button_Image_Name": "vs50kingkong.png"
        },

        {
          "CN_Name": "勤劳蜜蜂",
          "HTML5_Game_ID": "vs20bl",
          "EN_Name": "Busy Bees",
          "Button_Image_Name": "vs20bl.png"
        },
        {
          "CN_Name": "野生动物园",
          "HTML5_Game_ID": "vs25safari",
          "EN_Name": "Hot Safari",
          "Button_Image_Name": "vs25safari.png"
        },
        {
          "CN_Name": "女神戈帝梵",
          "HTML5_Game_ID": "vs20godiva",
          "EN_Name": "Lady Godiva",
          "Button_Image_Name": "vs20godiva.png"
        },
        {
          "CN_Name": "7只猴子",
          "HTML5_Game_ID": "vs7monkeys",
          "EN_Name": "7 Monkeys",
          "Button_Image_Name": "vs7monkeys.png"
        }
      ]
    },


    {
      "type": 4,
      "game_id": 404,
      "game_api": "SG",
      "name": "SG老虎机",
      "imgPath": "https://newpic.hdyb33.com/games/sg.png",
      "icon": "sg",
      "game_list_json": "SG_game_list",
      "game_list_img_path": "/new/slot_res/sg/",
      "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
      "memo2": "包含游戏：捕鱼大王、朱大富、四大财神、糖果泡泡、金武门、斧头帮、初恋、海龙王...",
      "labels": "#老虎机#捕鱼大王#朱大富#四大财神#糖果泡泡",
      gamelist: [
        {
          "HTML5_Game_ID": "F-SF01",
          "EN_Name": "Fishing_God",
          "CN_Name": "捕鱼天王",
          "Button_Image_Name": "Fishing_God_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CH01",
          "EN_Name": "Mr_Chu_Tycoon",
          "CN_Name": "朱大富",
          "Button_Image_Name": "Mr_Chu_Tycoon_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GK01",
          "EN_Name": "Brothers_kingdom",
          "CN_Name": "桃园三兄弟",
          "Button_Image_Name": "Brothers_kingdom_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PG01",
          "EN_Name": "Prosperity_Gods",
          "CN_Name": "四大财神",
          "Button_Image_Name": "Prosperity_Gods_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CP01",
          "EN_Name": "Candy_Pop",
          "CN_Name": "糖果泡泡",
          "Button_Image_Name": "Candy_Pop_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GF01",
          "EN_Name": "Golden_Fist",
          "CN_Name": "金武门",
          "Button_Image_Name": "Golden_Fist_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LY02",
          "EN_Name": "FaFaFa2",
          "CN_Name": "发发发2",
          "Button_Image_Name": "FaFaFa2_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GA01",
          "EN_Name": "Gangster_Axe",
          "CN_Name": "斧头帮",
          "Button_Image_Name": "Gangster_Axe_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PW02",
          "EN_Name": "Princess_Wang",
          "CN_Name": "旺旺公主",
          "Button_Image_Name": "Princess_Wang_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WP02",
          "EN_Name": "Wow_Prosperity",
          "CN_Name": "汪旺财",
          "Button_Image_Name": "Wow_Prosperity_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DM01",
          "EN_Name": "Ming_Dynasty",
          "CN_Name": "大明帝国",
          "Button_Image_Name": "Ming_Dynasty_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FL02",
          "EN_Name": "First_Love",
          "CN_Name": "初恋",
          "Button_Image_Name": "First_Love_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FM02",
          "EN_Name": "Golden_Monkey",
          "CN_Name": "齐天大胜",
          "Button_Image_Name": "Golden_Monkey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PP01",
          "EN_Name": "Pan_Fairy",
          "CN_Name": "潘精灵",
          "Button_Image_Name": "Pan_Fairy_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SH01",
          "EN_Name": "ShangHai_008",
          "CN_Name": "上海_00发",
          "Button_Image_Name": "ShangHai_008_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TZ01",
          "EN_Name": "Jungle_King",
          "CN_Name": "森林之王",
          "Button_Image_Name": "Jungle_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FG01",
          "EN_Name": "Fist_of_Gold",
          "CN_Name": "新霸天下",
          "Button_Image_Name": "Fist_of_Gold_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TW01",
          "EN_Name": "Tiger_Warrior",
          "CN_Name": "虎大天王_",
          "Button_Image_Name": "Tiger_Warrior_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-NT01",
          "EN_Name": "Sea_Emperor",
          "CN_Name": "海龙王",
          "Button_Image_Name": "Sea_Emperor_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-ZE01",
          "EN_Name": "ZEUS",
          "CN_Name": "天通雷神",
          "Button_Image_Name": "ZEUS_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HY01",
          "EN_Name": "Ho_Yeah_Monkey",
          "CN_Name": "猴爷到",
          "Button_Image_Name": "Ho_Yeah_Monkey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GC03",
          "EN_Name": "Golden_Chicken",
          "CN_Name": "金鸡",
          "Button_Image_Name": "Golden_Chicken_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DG04",
          "EN_Name": "Dragon_Gold_SA",
          "CN_Name": "金龙赐福_SA",
          "Button_Image_Name": "Dragon_Gold_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-EG03",
          "EN_Name": "Emperor_Gate_SA",
          "CN_Name": "斩五门SA",
          "Button_Image_Name": "Emperor_Gate_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FC03",
          "EN_Name": "Big_Prosperity_SA",
          "CN_Name": "发大财_SA",
          "Button_Image_Name": "Big_Prosperity_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GS04",
          "EN_Name": "Great_Stars_SA",
          "CN_Name": "吉星_SA",
          "Button_Image_Name": "Great_Stars_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IL03",
          "EN_Name": "Iceland_SA",
          "CN_Name": "雪冰世界SA",
          "Button_Image_Name": "Iceland_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IM03",
          "EN_Name": "Indian_Myth_SA",
          "CN_Name": "印度神话SA",
          "Button_Image_Name": "Indian_Myth_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LE03",
          "EN_Name": "Lion_Emperor_SA",
          "CN_Name": "狮子王SA",
          "Button_Image_Name": "Lion_Emperor_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LH03",
          "EN_Name": "King_The_Lion_Heart_SA",
          "CN_Name": "狮心王SA",
          "Button_Image_Name": "King_The_Lion_Heart_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SG04",
          "EN_Name": "Shougen_War_SA",
          "CN_Name": "将军令SA",
          "Button_Image_Name": "Shougen_War_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SP03",
          "EN_Name": "Spartan_SA",
          "CN_Name": "斯巴达SA",
          "Button_Image_Name": "Spartan_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WC03",
          "EN_Name": "Wong_Choy_SA",
          "CN_Name": "旺财_SA",
          "Button_Image_Name": "Wong_Choy_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WM03",
          "EN_Name": "5_Fortune_SA",
          "CN_Name": "五福门_SA",
          "Button_Image_Name": "5_Fortune_SA_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-BC01",
          "EN_Name": "Baby_Cai_Shen",
          "CN_Name": "财神宝宝",
          "Button_Image_Name": "Baby_Cai_Shen_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FO01",
          "EN_Name": "168_Fortunes",
          "CN_Name": "一路发发",
          "Button_Image_Name": "168_Fortunes_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GL02",
          "EN_Name": "Golden_Lotus_SE",
          "CN_Name": "潘金莲_特别版",
          "Button_Image_Name": "Golden_Lotus_SE_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-MR01",
          "EN_Name": "Mermaid",
          "CN_Name": "美人鱼",
          "Button_Image_Name": "Mermaid_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PH02",
          "EN_Name": "King_Pharaoh",
          "CN_Name": "法老宝藏",
          "Button_Image_Name": "King_Pharaoh_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PO01",
          "EN_Name": "Pocket_Mon_Go",
          "CN_Name": "宝袋精灵",
          "Button_Image_Name": "Pocket_Mon_Go_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WP01",
          "EN_Name": "Wong_Po",
          "CN_Name": "旺宝",
          "Button_Image_Name": "Wong_Po_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-AL01",
          "EN_Name": "Alibaba",
          "CN_Name": "阿里巴巴",
          "Button_Image_Name": "Alibaba_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CS01",
          "EN_Name": "Cai_Shen_888",
          "CN_Name": "财神888",
          "Button_Image_Name": "Cai_Shen_888_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DF01",
          "EN_Name": "Double_Fortunes",
          "CN_Name": "青龙白虎",
          "Button_Image_Name": "Double_Fortunes_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FD01",
          "EN_Name": "5_Fortune_Dragons",
          "CN_Name": "五龙吐珠",
          "Button_Image_Name": "5_Fortune_Dragons_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GO01",
          "EN_Name": "Gods_Kitchen",
          "CN_Name": "厨神",
          "Button_Image_Name": "Gods_Kitchen_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IL02",
          "EN_Name": "Adventure_Iceland",
          "CN_Name": "雪冰世界",
          "Button_Image_Name": "Adventure_Iceland_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-IM02",
          "EN_Name": "Adventure_Indian_Myth",
          "CN_Name": "印度神话",
          "Button_Image_Name": "Adventure_Indian_Myth_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LE02",
          "EN_Name": "Adventure_Lion_Emperor",
          "CN_Name": "狮子王",
          "Button_Image_Name": "Adventure_Lion_Emperor_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LK01",
          "EN_Name": "Lucky_Koi",
          "CN_Name": "运财鲤鱼",
          "Button_Image_Name": "Lucky_Koi_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SM01",
          "EN_Name": "Soccer_Mania",
          "CN_Name": "疯狂足球",
          "Button_Image_Name": "Soccer_Mania_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SM02",
          "EN_Name": "Space_Monkey",
          "CN_Name": "太空神猴",
          "Button_Image_Name": "Space_Monkey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-AT02",
          "EN_Name": "Amazing_Thailand",
          "CN_Name": "泰国神游",
          "Button_Image_Name": "Amazing_Thailand_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CM01",
          "EN_Name": "Drunken_Jungle",
          "CN_Name": "森林狂欢季",
          "Button_Image_Name": "Drunken_Jungle_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DG03",
          "EN_Name": "Dragon_Gold",
          "CN_Name": "金龙赐福",
          "Button_Image_Name": "Dragon_Gold_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-EG02",
          "EN_Name": "Emperor_Gate",
          "CN_Name": "斩五门",
          "Button_Image_Name": "Emperor_Gate_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FC02",
          "EN_Name": "Big_Prosperity",
          "CN_Name": "发大财",
          "Button_Image_Name": "Big_Prosperity_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GC02",
          "EN_Name": "Great_China",
          "CN_Name": "中华之最",
          "Button_Image_Name": "Great_China_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GG01",
          "EN_Name": "Aqua_Cash_",
          "CN_Name": "如鱼得水",
          "Button_Image_Name": "Aqua_Cash__128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GS03",
          "EN_Name": "Great_Stars",
          "CN_Name": "吉星",
          "Button_Image_Name": "Great_Stars_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HH01",
          "EN_Name": "Honey_Hunter",
          "CN_Name": "熊之蜜",
          "Button_Image_Name": "Honey_Hunter_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HL01",
          "EN_Name": "Lucky_Tank",
          "CN_Name": "幸运坦克",
          "Button_Image_Name": "Lucky_Tank_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-JF02",
          "EN_Name": "Japan_Fortune",
          "CN_Name": "日本福气",
          "Button_Image_Name": "Japan_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LH02",
          "EN_Name": "King_The_Lion_Heart",
          "CN_Name": "狮心王",
          "Button_Image_Name": "King_The_Lion_Heart_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LI02",
          "EN_Name": "Lava_Island",
          "CN_Name": "冒险火岛",
          "Button_Image_Name": "Lava_Island_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LY01",
          "EN_Name": "Fafafa",
          "CN_Name": "发发发",
          "Button_Image_Name": "Fafafa_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PT01",
          "EN_Name": "Hua_Mulan",
          "CN_Name": "花木兰",
          "Button_Image_Name": "Hua_Mulan_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SG03",
          "EN_Name": "Shougen_War",
          "CN_Name": "将军令",
          "Button_Image_Name": "Shougen_War_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SP02",
          "EN_Name": "Spartan",
          "CN_Name": "斯巴达",
          "Button_Image_Name": "Spartan_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WC02",
          "EN_Name": "Wong_Choy",
          "CN_Name": "旺财",
          "Button_Image_Name": "Wong_Choy_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-WM02",
          "EN_Name": "5_Fortune",
          "CN_Name": "五福门",
          "Button_Image_Name": "5_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LF01",
          "EN_Name": "Lucky_Feng_Shui",
          "CN_Name": "风生水起",
          "Button_Image_Name": "Lucky_Feng_Shui_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-PK01",
          "EN_Name": "Pirate_King",
          "CN_Name": "海盗霸王",
          "Button_Image_Name": "Pirate_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GP02",
          "EN_Name": "Golf_Champions",
          "CN_Name": "冠军高尔夫",
          "Button_Image_Name": "Golf_Champions_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-HF01",
          "EN_Name": "Highway_Fortune",
          "CN_Name": "极速王者",
          "Button_Image_Name": "Highway_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-BB01",
          "EN_Name": "Festive_Lion",
          "CN_Name": "金狮拜年",
          "Button_Image_Name": "Festive_Lion_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-BF02",
          "EN_Name": "Mr_Big_Foot",
          "CN_Name": "大脚先生",
          "Button_Image_Name": "Mr_Big_Foot_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CC01",
          "EN_Name": "Master_Chef",
          "CN_Name": "至尊厨师",
          "Button_Image_Name": "Master_Chef_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-CY01",
          "EN_Name": "Cai_Yuan_Guang_Jin",
          "CN_Name": "财源广进",
          "Button_Image_Name": "Cai_Yuan_Guang_Jin_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DV01",
          "EN_Name": "Daddys_Vacation",
          "CN_Name": "爸爸飞那儿",
          "Button_Image_Name": "Daddys_Vacation_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-DX01",
          "EN_Name": "Da_Fu_Xiao_Fu",
          "CN_Name": "大福小福",
          "Button_Image_Name": "Da_Fu_Xiao_Fu_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FB02",
          "EN_Name": "World_Cup_Golden_Boot",
          "CN_Name": "金靴世界杯",
          "Button_Image_Name": "World_Cup_Golden_Boot_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-FZ02",
          "EN_Name": "Father_vs_Zombies",
          "CN_Name": "父女战殭屍",
          "Button_Image_Name": "Father_vs_Zombies_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-GW01",
          "EN_Name": "Golden_Whale",
          "CN_Name": "黄金黥鱼",
          "Button_Image_Name": "Golden_Whale_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-JT01",
          "EN_Name": "Jack_The_Pirate",
          "CN_Name": "杰克海盗",
          "Button_Image_Name": "Jack_The_Pirate_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LC01",
          "EN_Name": "Lucky_Cai_Shen",
          "CN_Name": "天降财神",
          "Button_Image_Name": "Lucky_Cai_Shen_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LM01",
          "EN_Name": "Lucky_Meow",
          "CN_Name": "超吉猫",
          "Button_Image_Name": "Lucky_Meow_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-LS01",
          "EN_Name": "Lucky_Strike",
          "CN_Name": "马上赢",
          "Button_Image_Name": "Lucky_Strike_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-MH02",
          "EN_Name": "The_Curse_Magic_Hammer",
          "CN_Name": "神錘魔咒",
          "Button_Image_Name": "The_Curse_Magic_Hammer_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-MP02",
          "EN_Name": "Monster_Tunnel",
          "CN_Name": "下水道小魔兽",
          "Button_Image_Name": "Monster_Tunnel_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-RG02",
          "EN_Name": "Rising_Gems",
          "CN_Name": "魔幻宝石",
          "Button_Image_Name": "Rising_Gems_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-RK01",
          "EN_Name": "Railway_King",
          "CN_Name": "铁路王",
          "Button_Image_Name": "Railway_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SA02",
          "EN_Name": "Spin_Stone",
          "CN_Name": "石器时代",
          "Button_Image_Name": "Spin_Stone_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SG02",
          "EN_Name": "Santa_Gifts",
          "CN_Name": "圣诞大礼",
          "Button_Image_Name": "Santa_Gifts_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-SK01",
          "EN_Name": "Safari_King",
          "CN_Name": "野生动物园",
          "Button_Image_Name": "Safari_King_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TP01",
          "EN_Name": "Fun_Paradise",
          "CN_Name": "乐天堂",
          "Button_Image_Name": "Fun_Paradise_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "S-TS02",
          "EN_Name": "The_Songs_of_China",
          "CN_Name": "中国好歌声",
          "Button_Image_Name": "The_Songs_of_China_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-RB01",
          "EN_Name": "Rob_Stars",
          "CN_Name": "小偷星星",
          "Button_Image_Name": "Rob_Stars_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DN02",
          "EN_Name": "Derby_Night",
          "CN_Name": "德比夜赛_",
          "Button_Image_Name": "Derby_Night_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-GJ01",
          "EN_Name": "Golden_Journey",
          "CN_Name": "西游金",
          "Button_Image_Name": "Golden_Journey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-GL02",
          "EN_Name": "Goblin_Treasure",
          "CN_Name": "积宝小精灵_",
          "Button_Image_Name": "Goblin_Treasure_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-RW02",
          "EN_Name": "Richie_Wheel",
          "CN_Name": "至尊轮盘",
          "Button_Image_Name": "Richie_Wheel_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-PF02",
          "EN_Name": "PinBall_Fortune",
          "CN_Name": "单球致富_",
          "Button_Image_Name": "PinBall_Fortune_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-SC02",
          "EN_Name": "Dragon_Clan",
          "CN_Name": "龙会_",
          "Button_Image_Name": "Dragon_Clan_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-SH02",
          "EN_Name": "Shinobi_Hamster",
          "CN_Name": "仓鼠忍者_",
          "Button_Image_Name": "Shinobi_Hamster_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DB06",
          "EN_Name": "3_Kingdom_The_Dragon_Boat",
          "CN_Name": "三国龙舟战_",
          "Button_Image_Name": "3_Kingdom_The_Dragon_Boat_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-AP02",
          "EN_Name": "Animal_Paradise",
          "CN_Name": "动物奇园",
          "Button_Image_Name": "Animal_Paradise_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DB04",
          "EN_Name": "Bicycle_Race",
          "CN_Name": "职业赛单车",
          "Button_Image_Name": "Bicycle_Race_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DB05",
          "EN_Name": "Dirt_Bike",
          "CN_Name": "越野赛车",
          "Button_Image_Name": "Dirt_Bike_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DD02",
          "EN_Name": "Dog_Racing",
          "CN_Name": "极速赛狗",
          "Button_Image_Name": "Dog_Racing_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-DE02",
          "EN_Name": "Derby_Express",
          "CN_Name": "德比直通",
          "Button_Image_Name": "Derby_Express_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-JW02",
          "EN_Name": "Magic_Journey",
          "CN_Name": "仙桃演义",
          "Button_Image_Name": "Magic_Journey_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-LB02",
          "EN_Name": "Lucky_Baby",
          "CN_Name": "幸运宝贝",
          "Button_Image_Name": "Lucky_Baby_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "A-MT02",
          "EN_Name": "Monkey_Thunder_Bolt",
          "CN_Name": "霹雳神猴",
          "Button_Image_Name": "Monkey_Thunder_Bolt_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-HC02",
          "EN_Name": "Hulu_Cock",
          "CN_Name": "葫芦鸡_",
          "Button_Image_Name": "Hulu_Cock_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-CH02",
          "EN_Name": "Lucky_Cup",
          "CN_Name": "福杯",
          "Button_Image_Name": "Lucky_Cup_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-BK02",
          "EN_Name": "Belangkai",
          "CN_Name": "鱼虾蟹_",
          "Button_Image_Name": "Belangkai_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-DT03",
          "EN_Name": "Dragon_Tiger",
          "CN_Name": "龙虎_",
          "Button_Image_Name": "Dragon_Tiger_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-SB02",
          "EN_Name": "Sic_Bo",
          "CN_Name": "骰宝_",
          "Button_Image_Name": "Sic_Bo_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-SD03",
          "EN_Name": "Sedie",
          "CN_Name": "骰碟_",
          "Button_Image_Name": "Sedie_128x128_cn.png"
        },
        {
          "HTML5_Game_ID": "T-RT02",
          "EN_Name": "Roulette_Euro",
          "CN_Name": "欧式轮盘_",
          "Button_Image_Name": "Roulette_Euro_128x128_cn.png"
        },

      ]
    }],
  "icon": "dianjing"
},
  {
  "name": "棋牌",
  "type_id": 5,
  "gamelist": [
      {
    "type": 4,
    "game_id": 411,
    "game_api": "FG",
    "name": "FG棋牌",
    "imgPath": "https://newpic.hdyb33.com/games/pg.png",
    "icon": "fg",
    "game_list_json": "FG_Chess_game_list",
    "game_list_img_path": "/new/slot_res/fg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：变形金刚、淘金热、66路、狂欢、埃及、接头霸王、东方国度、西部牛仔..",
    "labels": "#老虎机#变形金刚#淘金热#66路#狂欢#埃及#接头霸王#东方国度#西部牛仔",
    gamelist: [
      {"CN_Name": "百人牛牛", "HTML5_Game_ID": "HB", "EN_Name": "Hundreds Of Bull", "Button_Image_Name": "HB.png"},
      // {"CN_Name": "皇家德州", "HTML5_Game_ID": "rt", "EN_Name": "Royal Texas", "Button_Image_Name": "rt.png"},
      {"CN_Name": "骰宝", "HTML5_Game_ID": "tz", "EN_Name": "Sic Bo", "Button_Image_Name": "tz.png"},
      {"CN_Name": "百家乐", "HTML5_Game_ID": "bjl", "EN_Name": "Baccarat", "Button_Image_Name": "bjl.png"},
      // {"CN_Name": "皇家宫殿", "HTML5_Game_ID": "RC", "EN_Name": "Royal Palace", "Button_Image_Name": "RC.png"},
      {
        "CN_Name": "经典炸金花",
        "HTML5_Game_ID": "zjhdr",
        "EN_Name": "Classical Win Three Cards",
        "Button_Image_Name": "zjhdr.png"
      },
      {
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "TexasCb",
        "EN_Name": "Texas Cowboy",
        "Button_Image_Name": "TexasCb.png"
      },
      {"CN_Name": "二人麻将", "HTML5_Game_ID": "mj", "EN_Name": "Two People Mahjong", "Button_Image_Name": "mj.png"},
      {
        "CN_Name": "抢庄牛牛",
        "HTML5_Game_ID": "cqznn",
        "EN_Name": "Rob Banker Of Bull",
        "Button_Image_Name": "cqznn.png"
      },
      {"CN_Name": "三公", "HTML5_Game_ID": "csg", "EN_Name": "San_Gong", "Button_Image_Name": "csg.png"},
      // {
      //     "CN_Name": "极速炸金花",
      //     "HTML5_Game_ID": "jszjh",
      //     "EN_Name": "Topspeed Win Three Cards",
      //     "Button_Image_Name": "jszjh.png"
      // },
      {"CN_Name": "通比牛牛", "HTML5_Game_ID": "tbnn", "EN_Name": "Tongbi Cattle", "Button_Image_Name": "tbnn.png"},
      // {
      //     "CN_Name": "十三水",
      //     "HTML5_Game_ID": "sss",
      //     "EN_Name": "Open-face Chinese Poker",
      //     "Button_Image_Name": "sss.png"
      // },
      {"CN_Name": "二八杠", "HTML5_Game_ID": "ebg", "EN_Name": "Mahjong", "Button_Image_Name": "ebg.png"},
      {"CN_Name": "抢庄牌九", "HTML5_Game_ID": "qzpj", "EN_Name": "Pai Gow", "Button_Image_Name": "qzpj.png"},
      {"CN_Name": "斗地主", "HTML5_Game_ID": "ddz", "EN_Name": "Landlord", "Button_Image_Name": "ddz.png"},
      {
        "CN_Name": "梭哈",
        "HTML5_Game_ID": "ShowHand",
        "EN_Name": "Five-Card Poker",
        "Button_Image_Name": "ShowHand.png"
      },
      {"CN_Name": "欢乐德州", "HTML5_Game_ID": "iPoker", "EN_Name": "Fun Poker", "Button_Image_Name": "iPoker.png"},
    ],
  }, {
    "type": 5,
    "game_id": 502,
    "game_api": "KY",
    "name": "开元棋牌",
    "imgPath": "https://newpic.hdyb33.com/chess/ky.png",
    "icon": "ky",
    "memo1": "平台特色：游戏玩法丰富多样，竞赛活动天天开启，24小时不停歇",
    "memo2": "包含游戏：炸金花、二八杠、德州扑克、血流成河、压庄龙虎、百家乐、二十一点...",
    "labels": "#棋牌#牛牛#抢庄牛牛#二十一点#炸金花#二八杠#龙虎斗#德州扑克",
    "hot_type": "hot",
    "showTitle": 1,
  }, {
    "type": 5,
    "game_id": 501,
    "game_api": "LY",
    "name": "乐游棋牌",
    "imgPath": "https://newpic.hdyb33.com/chess/ly.png",
    "icon": "ly",
    "memo1": "平台特色：万千玩家同时在线，自动匹配玩家，人满即开局",
    "memo2": "包含游戏：二人麻将、抢庄牛牛、二十一点、炸金花、二八杠、龙虎斗、德州扑克...",
    "labels": "#棋牌#牛牛#二人麻将#抢庄牛牛#二十一点#炸金花#二八杠#龙虎斗#德州扑克",
    "hot_type": "recommend",
    "showTitle": 1,
  },
    {
    "type": 4,
    "game_id": 406,
    "game_api": "CQ9",
    "name": "CQ9棋牌",
    "imgPath": "https://newpic.hdyb33.com/games/cq9.png",
    "icon": "cq9",
    "game_list_json": "CQ9_Chess_game_list",
    "game_list_img_path": "/new/slot_res/cq9/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：盗法老墓、妙笔生财、喵财进宝、鱼跃龙门、丛林宝藏、喵喵、老司机...",
    "labels": "#老虎机#盗法老墓#妙笔生财#喵财进宝#鱼跃龙门",
    gamelist: [
      {"Button_Image_Name": "dzpk.png", "HTML5_Game_ID": "BK04", "CN_Name": "德州扑克"},
      {"Button_Image_Name": "ermj.png", "HTML5_Game_ID": "AD11", "CN_Name": "二人麻将"},
      {"Button_Image_Name": "ddzsjb.png", "HTML5_Game_ID": "AR41", "CN_Name": "斗地主升级版"},
      {"Button_Image_Name": "jbddz.png", "HTML5_Game_ID": "AR04", "CN_Name": "斗地主经典版"},
      {"Button_Image_Name": "qznn.png", "HTML5_Game_ID": "BK01", "CN_Name": "抢庄牛牛"}
    ]
  }],
  "icon": "poker-cards",
  "FG_Chess_game_list": [{
    "type": 4,
    "game_id": 411,
    "game_api": "FG",
    "name": "FG老虎机",
    "imgPath": "https://newpic.hdyb33.com/games/pg.png",
    "icon": "fg",
    "game_list_json": "FG_game_list",
    "game_list_img_path": "/new/slot_res/fg/",
    "memo1": "平台特色：游戏齐全赚翻天 精彩好玩别错过",
    "memo2": "包含游戏：变形金刚、淘金热、66路、狂欢、埃及、接头霸王、东方国度、西部牛仔..",
    "labels": "#老虎机#变形金刚#淘金热#66路#狂欢#埃及#接头霸王#东方国度#西部牛仔",
    gamelist:[
      {
        "CN_Name": "变形金刚",
        "HTML5_Game_ID": "bxjg",
        "EN_Name": "Transformers",
        "Button_Image_Name": "bxjg.png"
      },
      {
        "CN_Name": "摸金校尉",
        "HTML5_Game_ID": "mjxw",
        "EN_Name": "Grave Robbers",
        "Button_Image_Name": "mjxw.png"
      },
      {
        "CN_Name": "金刚",
        "HTML5_Game_ID": "jg",
        "EN_Name": "King Kong",
        "Button_Image_Name": "jg.png"
      },
      {
        "CN_Name": "淘金热",
        "HTML5_Game_ID": "tjr",
        "EN_Name": "Gold Rush",
        "Button_Image_Name": "tjr.png"
      },
      {
        "CN_Name": "66路",
        "HTML5_Game_ID": "Route66",
        "EN_Name": "Route 66",
        "Button_Image_Name": "Route66.png"
      },
      {
        "CN_Name": "阿兹特克",
        "HTML5_Game_ID": "azteke",
        "EN_Name": "Azteke",
        "Button_Image_Name": "azteke.png"
      },
      {
        "CN_Name": "埃及",
        "HTML5_Game_ID": "Egypt",
        "EN_Name": "Egypt",
        "Button_Image_Name": "Egypt.png"
      },
      {
        "CN_Name": "狂欢",
        "HTML5_Game_ID": "kh",
        "EN_Name": "Carnival",
        "Button_Image_Name": "kh.png"
      },
      {
        "CN_Name": "中世纪特权",
        "HTML5_Game_ID": "zsjtq",
        "EN_Name": "Medieval Thrones",
        "Button_Image_Name": "zsjtq.png"
      },
      {
        "CN_Name": "大秦帝国",
        "HTML5_Game_ID": "dqdg",
        "EN_Name": "The Qin Empire",
        "Button_Image_Name": "dqdg.png"
      },
      {
        "CN_Name": "火影忍者",
        "HTML5_Game_ID": "hyrz",
        "EN_Name": "Naruto",
        "Button_Image_Name": "hyrz.png"
      },
      {
        "CN_Name": "欢乐水果",
        "HTML5_Game_ID": "sg",
        "EN_Name": "Joyful Fruit",
        "Button_Image_Name": "sg.png"
      },
      {
        "CN_Name": "街头霸王",
        "HTML5_Game_ID": "jtbw",
        "EN_Name": "Street Fighter",
        "Button_Image_Name": "jtbw.png"
      },
      {
        "CN_Name": "战舰少女",
        "HTML5_Game_ID": "zjsn",
        "EN_Name": "Warship Maiden",
        "Button_Image_Name": "zjsn.png"
      },
      {
        "CN_Name": "东方国度",
        "HTML5_Game_ID": "zgf",
        "EN_Name": "Eastern Countries",
        "Button_Image_Name": "zgf.png"
      },
      {
        "CN_Name": "西部牛仔",
        "HTML5_Game_ID": "nz",
        "EN_Name": "Western Cowboy",
        "Button_Image_Name": "nz.png"
      },
      {
        "CN_Name": "幻想大陆",
        "HTML5_Game_ID": "hxdl",
        "EN_Name": "Fantasy Earth",
        "Button_Image_Name": "hxdl.png"
      },
      {
        "CN_Name": "拉斯维加斯",
        "HTML5_Game_ID": "wjs",
        "EN_Name": "Las Vegas",
        "Button_Image_Name": "wjs.png"
      },
      {
        "CN_Name": "荒野大镖客",
        "HTML5_Game_ID": "nz2",
        "EN_Name": "Wilderness Escort",
        "Button_Image_Name": "nz2.png"
      },
      {
        "CN_Name": "埃及艳后",
        "HTML5_Game_ID": "mny",
        "EN_Name": "Cleopatra",
        "Button_Image_Name": "mny.png"
      },
      {
        "CN_Name": "仙剑",
        "HTML5_Game_ID": "xj",
        "EN_Name": "Xian Jian",
        "Button_Image_Name": "xj.png"
      },
      {
        "CN_Name": "梦游仙境",
        "HTML5_Game_ID": "myxj",
        "EN_Name": "Alice",
        "Button_Image_Name": "myxj.png"
      },
      {
        "CN_Name": "80天旅行",
        "HTML5_Game_ID": "80tlx",
        "EN_Name": "80 Days Travel",
        "Button_Image_Name": "80tlx.png"
      },
      {
        "CN_Name": "海盗财富",
        "HTML5_Game_ID": "hdcf",
        "EN_Name": "Pirates Fortune",
        "Button_Image_Name": "hdcf.png"
      },
      {
        "CN_Name": "西游",
        "HTML5_Game_ID": "xy",
        "EN_Name": "Xi You",
        "Button_Image_Name": "xy.png"
      },
      {
        "CN_Name": "白蛇传",
        "HTML5_Game_ID": "bsz",
        "EN_Name": "Bai She Zhuan",
        "Button_Image_Name": "bsz.png"
      },
      {
        "CN_Name": "指环王",
        "HTML5_Game_ID": "zhw",
        "EN_Name": "Lord Of The Ring",
        "Button_Image_Name": "zhw.png"
      },
      {
        "CN_Name": "封神榜",
        "HTML5_Game_ID": "fsb",
        "EN_Name": "Feng Shen Bang",
        "Button_Image_Name": "fsb.png"
      },
      {
        "CN_Name": "荣耀王者",
        "HTML5_Game_ID": "rywz",
        "EN_Name": "King Of Glory",
        "Button_Image_Name": "rywz.png"
      },
      {
        "CN_Name": "怪物命运",
        "HTML5_Game_ID": "gwmy",
        "EN_Name": "Freaks Fortune",
        "Button_Image_Name": "gwmy.png"
      },
      {
        "CN_Name": "埃菲尔",
        "HTML5_Game_ID": "Eiffel",
        "EN_Name": "Eiffel",
        "Button_Image_Name": "Eiffel.png"
      },
      {
        "CN_Name": "新年符号",
        "HTML5_Game_ID": "Nysymbols",
        "EN_Name": "Ny Symbols",
        "Button_Image_Name": "Nysymbols.png"
      },
      {
        "CN_Name": "性感女神",
        "HTML5_Game_ID": "Pinup",
        "EN_Name": "Marilyn Monroe",
        "Button_Image_Name": "Pinup.png"
      },
      {
        "CN_Name": "世界末日",
        "HTML5_Game_ID": "Doomsday",
        "EN_Name": "Doomsday",
        "Button_Image_Name": "Doomsday.png"
      },
      {
        "CN_Name": "现代战争",
        "HTML5_Game_ID": "Mowf",
        "EN_Name": "Modern War",
        "Button_Image_Name": "Mowf.png"
      },
      {
        "CN_Name": "甜水绿洲",
        "HTML5_Game_ID": "Lushwater",
        "EN_Name": "Sweet Water Oasis",
        "Button_Image_Name": "Lushwater.png"
      },
      {
        "CN_Name": "加勒比海盗",
        "HTML5_Game_ID": "Pirates",
        "EN_Name": "Caribbean Pirates",
        "Button_Image_Name": "Pirates.png"
      },
      {
        "CN_Name": "功夫熊猫",
        "HTML5_Game_ID": "Panda",
        "EN_Name": "Kung Fu Panda",
        "Button_Image_Name": "Panda.png"
      },
      {
        "CN_Name": "侏罗纪",
        "HTML5_Game_ID": "Jurassic",
        "EN_Name": "Jurassic",
        "Button_Image_Name": "Jurassic.png"
      },
      {
        "CN_Name": "植物大战僵尸",
        "HTML5_Game_ID": "PVZ",
        "EN_Name": "PVZ",
        "Button_Image_Name": "PVZ.png"
      },
      {
        "CN_Name": "守望英雄",
        "HTML5_Game_ID": "Watch",
        "EN_Name": "Overwatch",
        "Button_Image_Name": "Watch.png"
      },
      {
        "CN_Name": "阿凡达",
        "HTML5_Game_ID": "Avatar",
        "EN_Name": "Avatar",
        "Button_Image_Name": "Avatar.png"
      },
      {
        "CN_Name": "希腊传说",
        "HTML5_Game_ID": "xlcs",
        "EN_Name": "Greek Legend",
        "Button_Image_Name": "xlcs.png"
      },
      {
        "CN_Name": "吸血鬼PK狼人",
        "HTML5_Game_ID": "xxgpklr",
        "EN_Name": "Bloodz VS Wolvez",
        "Button_Image_Name": "xxgpklr.png"
      },
      {
        "CN_Name": "梦幻西游",
        "HTML5_Game_ID": "mhxy",
        "EN_Name": "Dream Journey",
        "Button_Image_Name": "mhxy.png"
      },
      {
        "CN_Name": "女校足球队",
        "HTML5_Game_ID": "nxzqd",
        "EN_Name": "Girls Football",
        "Button_Image_Name": "nxzqd.png"
      },
      {
        "CN_Name": "女校橄榄球",
        "HTML5_Game_ID": "nxglq",
        "EN_Name": "Girls Rugby",
        "Button_Image_Name": "nxglq.png"
      },
      {
        "CN_Name": "女校剑道部",
        "HTML5_Game_ID": "nxjdb",
        "EN_Name": "Girls Kendo Club",
        "Button_Image_Name": "nxjdb.png"
      },
      {
        "CN_Name": "武圣关云长",
        "HTML5_Game_ID": "wsgyc",
        "EN_Name": "Guan Yun Chang",
        "Button_Image_Name": "wsgyc.png"
      },
      {
        "CN_Name": "刺客信条",
        "HTML5_Game_ID": "ckxt",
        "EN_Name": "Assassins Creed",
        "Button_Image_Name": "ckxt.png"
      },
      {
        "CN_Name": "古墓丽影",
        "HTML5_Game_ID": "gmly",
        "EN_Name": "Tomb Raider",
        "Button_Image_Name": "gmly.png"
      },
      {
        "CN_Name": "绿野仙踪",
        "HTML5_Game_ID": "lyxz",
        "EN_Name": "The Wizard Of Oz",
        "Button_Image_Name": "lyxz.png"
      },
      {
        "CN_Name": "剑侠情缘",
        "HTML5_Game_ID": "jxqy",
        "EN_Name": "Sword Heroes",
        "Button_Image_Name": "jxqy.png"
      },
      {
        "CN_Name": "格斗之王",
        "HTML5_Game_ID": "gdzw",
        "EN_Name": "King Of Fighters",
        "Button_Image_Name": "gdzw.png"
      },
      {
        "CN_Name": "黑珍珠号",
        "HTML5_Game_ID": "hzzh",
        "EN_Name": "Black Pearl",
        "Button_Image_Name": "hzzh.png"
      },
      {
        "CN_Name": "富饶农场",
        "HTML5_Game_ID": "frnc",
        "EN_Name": "Rich Farm",
        "Button_Image_Name": "frnc.png"
      },
      {
        "CN_Name": "玛雅",
        "HTML5_Game_ID": "my",
        "EN_Name": "Maya",
        "Button_Image_Name": "my.png"
      },
      {
        "CN_Name": "印第安",
        "HTML5_Game_ID": "yda",
        "EN_Name": "India Dreams",
        "Button_Image_Name": "yda.png"
      },
      {
        "CN_Name": "神秘东方",
        "HTML5_Game_ID": "smdf",
        "EN_Name": "Mysterious East",
        "Button_Image_Name": "smdf.png"
      },
      {
        "CN_Name": "战争",
        "HTML5_Game_ID": "zz",
        "EN_Name": "Tanks",
        "Button_Image_Name": "zz.png"
      },
      {
        "CN_Name": "哪吒闹海",
        "HTML5_Game_ID": "nznh",
        "EN_Name": "Ne Zha Nao Hai",
        "Button_Image_Name": "nznh.png"
      },
      {
        "CN_Name": "七夕情缘",
        "HTML5_Game_ID": "qxqy",
        "EN_Name": "Tanabata Love",
        "Button_Image_Name": "qxqy.png"
      },
      {
        "CN_Name": "四大美女",
        "HTML5_Game_ID": "sdmn",
        "EN_Name": "Four Beauties",
        "Button_Image_Name": "sdmn.png"
      },
      {
        "CN_Name": "金瓶梅",
        "HTML5_Game_ID": "jpm",
        "EN_Name": "Jin Ping Mei",
        "Button_Image_Name": "jpm.png"
      },
      {
        "CN_Name": "红楼梦",
        "HTML5_Game_ID": "hlm",
        "EN_Name": "Hong Lou Meng",
        "Button_Image_Name": "hlm.png"
      },
      {
        "CN_Name": "武松打虎",
        "HTML5_Game_ID": "wsdf",
        "EN_Name": "Wu Song Da Hu",
        "Button_Image_Name": "wsdf.png"
      },
      {
        "CN_Name": "女校游泳队",
        "HTML5_Game_ID": "sst",
        "EN_Name": "Girls Swim Team",
        "Button_Image_Name": "sst.png"
      },
      {
        "CN_Name": "女校啦啦队",
        "HTML5_Game_ID": "scs",
        "EN_Name": "Cheerleaders",
        "Button_Image_Name": "scs.png"
      },
      {
        "CN_Name": "女校体操队",
        "HTML5_Game_ID": "sgt",
        "EN_Name": "Girls Gymnastics",
        "Button_Image_Name": "sgt.png"
      },
      {
        "CN_Name": "天龙八部",
        "HTML5_Game_ID": "wtlbb",
        "EN_Name": "Tian Long Ba Bu",
        "Button_Image_Name": "wtlbb.png"
      },
      {
        "CN_Name": "鹿鼎记",
        "HTML5_Game_ID": "wldj",
        "EN_Name": "Lu Ding Ji",
        "Button_Image_Name": "wldj.png"
      },
      {
        "CN_Name": "笑傲江湖",
        "HTML5_Game_ID": "wxajh",
        "EN_Name": "Swordsman",
        "Button_Image_Name": "wxajh.png"
      },
      {
        "CN_Name": "神雕侠侣",
        "HTML5_Game_ID": "wsdxl",
        "EN_Name": "Shen Diao Xia Lv",
        "Button_Image_Name": "wsdxl.png"
      },
      {
        "CN_Name": "幸运水果机",
        "HTML5_Game_ID": "mlfm",
        "EN_Name": "Lucky Fruit",
        "Button_Image_Name": "mlfm.png"
      },
      {
        "CN_Name": "钻石之恋",
        "HTML5_Game_ID": "mdl",
        "EN_Name": "Diamond Love",
        "Button_Image_Name": "mdl.png"
      },
      {
        "CN_Name": "众神之王",
        "HTML5_Game_ID": "mmm",
        "EN_Name": "Zeus",
        "Button_Image_Name": "mmm.png"
      },
      {
        "CN_Name": "粉红女郎",
        "HTML5_Game_ID": "mpg",
        "EN_Name": "Pink Lady",
        "Button_Image_Name": "mpg.png"
      },
      {
        "CN_Name": "鸟叔",
        "HTML5_Game_ID": "mpjs",
        "EN_Name": "PSY",
        "Button_Image_Name": "mpjs.png"
      },
      {
        "CN_Name": "十二星座",
        "HTML5_Game_ID": "Constellation",
        "EN_Name": "Constellation",
        "Button_Image_Name": "Constellation.png"
      },
      {
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "Zodiac",
        "EN_Name": "Chinese Zodiac",
        "Button_Image_Name": "Zodiac.png"
      },
      {
        "CN_Name": "愤怒的小鸟",
        "HTML5_Game_ID": "Angrybirds",
        "EN_Name": "Angry Birds",
        "Button_Image_Name": "Angrybirds.png"
      },
      {
        "CN_Name": "捕鱼达人",
        "HTML5_Game_ID": "Fishing",
        "EN_Name": "Fishing Joy",
        "Button_Image_Name": "Fishing.png"
      },
      {
        "CN_Name": "瑞狗迎春",
        "HTML5_Game_ID": "rgyc",
        "EN_Name": "Lucky Dog",
        "Button_Image_Name": "rgyc.png"
      },
      {
        "CN_Name": "哥谭魅影猫女",
        "HTML5_Game_ID": "gtmymn",
        "EN_Name": "Catwoman",
        "Button_Image_Name": "gtmymn.png"
      },
      {
        "CN_Name": "招财进宝",
        "HTML5_Game_ID": "zcjb",
        "EN_Name": "Zhao Cai Jin Bao",
        "Button_Image_Name": "zcjb.png"
      },
      {
        "CN_Name": "湛蓝深海",
        "HTML5_Game_ID": "xsjr",
        "EN_Name": "Great Blue",
        "Button_Image_Name": "xsjr.png"
      },
      {
        "CN_Name": "金狗旺财",
        "HTML5_Game_ID": "jgwc",
        "EN_Name": "Jin Gou Wang Cai",
        "Button_Image_Name": "jgwc.png"
      },
      {
        "CN_Name": "恭贺新春",
        "HTML5_Game_ID": "ghxc",
        "EN_Name": "Gong He Xin Chun",
        "Button_Image_Name": "ghxc.png"
      },
      {
        "CN_Name": "角斗士",
        "HTML5_Game_ID": "jiaods",
        "EN_Name": "Gladiatus",
        "Button_Image_Name": "jiaods.png"
      },
      {
        "CN_Name": "埃及女王",
        "HTML5_Game_ID": "aijinw",
        "EN_Name": "Egypt Queen",
        "Button_Image_Name": "aijinw.png"
      },
      {
        "CN_Name": "灌篮高手",
        "HTML5_Game_ID": "msd",
        "EN_Name": "Slamdunk",
        "Button_Image_Name": "msd.png"
      },
      {
        "CN_Name": "抢红包",
        "HTML5_Game_ID": "mqhb",
        "EN_Name": "Qiang Hong Bao",
        "Button_Image_Name": "mqhb.png"
      },
      {
        "CN_Name": "闹元宵",
        "HTML5_Game_ID": "mnys",
        "EN_Name": "Lantern Festival",
        "Button_Image_Name": "mnys.png"
      },
      {
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "mslwh",
        "EN_Name": "Forest Dance",
        "Button_Image_Name": "mslwh.png"
      },
      {
        "CN_Name": "金球争霸",
        "HTML5_Game_ID": "jqzb",
        "EN_Name": "Golden Globe",
        "Button_Image_Name": "jqzb.png"
      },
      {
        "CN_Name": "黄金右脚",
        "HTML5_Game_ID": "hjyj",
        "EN_Name": "Golden Foot",
        "Button_Image_Name": "hjyj.png"
      },
      {
        "CN_Name": "世界杯吉祥物",
        "HTML5_Game_ID": "sjbjxw",
        "EN_Name": "Fifa World",
        "Button_Image_Name": "sjbjxw.png"
      },
      {
        "CN_Name": "潘帕斯雄鹰",
        "HTML5_Game_ID": "panpasixiongying",
        "EN_Name": "Argentina",
        "Button_Image_Name": "panpasixiongying.png"
      },
      {
        "CN_Name": "群星闪耀",
        "HTML5_Game_ID": "qunxshanyao",
        "EN_Name": "Stars Shine",
        "Button_Image_Name": "qunxshanyao.png"
      },
      {
        "CN_Name": "金靴争霸",
        "HTML5_Game_ID": "mjxzb",
        "EN_Name": "The Golden Boot",
        "Button_Image_Name": "mjxzb.png"
      },
      {
        "CN_Name": "激情球迷",
        "HTML5_Game_ID": "mjqqm",
        "EN_Name": "Passion Fans",
        "Button_Image_Name": "mjqqm.png"
      },
      {
        "CN_Name": "激情世界杯",
        "HTML5_Game_ID": "mjqsjb",
        "EN_Name": "World Cup",
        "Button_Image_Name": "mjqsjb.png"
      },
      {
        "CN_Name": "人猿泰山",
        "HTML5_Game_ID": "ryts",
        "EN_Name": "Tarzan",
        "Button_Image_Name": "ryts.png"
      },
      {
        "CN_Name": "船长宝藏",
        "HTML5_Game_ID": "mczbz",
        "EN_Name": "Captain's Treasure",
        "Button_Image_Name": "mczbz.png"
      },
      {
        "CN_Name": "疯狂七",
        "HTML5_Game_ID": "mcrazy7",
        "EN_Name": "Crazy7",
        "Button_Image_Name": "mcrazy7.png"
      },
      {
        "CN_Name": "鹊桥会",
        "HTML5_Game_ID": "qqh",
        "EN_Name": "Magpie Bridge",
        "Button_Image_Name": "qqh.png"
      },
      {
        "CN_Name": "泰坦尼克号",
        "HTML5_Game_ID": "mtitanic",
        "EN_Name": "Titanic",
        "Button_Image_Name": "mtitanic.png"
      },
      {
        "CN_Name": "蜘蛛侠",
        "HTML5_Game_ID": "zzx",
        "EN_Name": "Spider Man",
        "Button_Image_Name": "zzx.png"
      },
      {
        "CN_Name": "速度与激情",
        "HTML5_Game_ID": "fastf",
        "EN_Name": "Fast & Furious",
        "Button_Image_Name": "fastf.png"
      },
      {
        "CN_Name": "古怪猴子",
        "HTML5_Game_ID": "gghz",
        "EN_Name": "Funky Monkey",
        "Button_Image_Name": "gghz.png"
      },
      {
        "CN_Name": "海岛奇兵",
        "HTML5_Game_ID": "mboombeach",
        "EN_Name": "BoomBeach",
        "Button_Image_Name": "mboombeach.png"
      },
      {
        "CN_Name": "水果派对",
        "HTML5_Game_ID": "party",
        "EN_Name": "Fruit_Party",
        "Button_Image_Name": "party.png"
      },
      {
        "CN_Name": "百变猴子",
        "HTML5_Game_ID": "mcm",
        "EN_Name": "WILDMonkey",
        "Button_Image_Name": "mcm.png"
      },
      {
        "CN_Name": "新年",
        "HTML5_Game_ID": "ny",
        "EN_Name": "New Year",
        "Button_Image_Name": "ny.png"
      },
      {
        "CN_Name": "多福多财",
        "HTML5_Game_ID": "dfdc",
        "EN_Name": "Bless&Wealth",
        "Button_Image_Name": "dfdc.png"
      },
      {
        "CN_Name": "街机水浒传",
        "HTML5_Game_ID": "mawm",
        "EN_Name": "Arcade Water Margin",
        "Button_Image_Name": "mawm.png"
      },
      {
        "CN_Name": "发发发",
        "HTML5_Game_ID": "fff",
        "EN_Name": "Fafafa",
        "Button_Image_Name": "fff.png"
      },

      {
        "CN_Name": "金瓶梅2",
        "HTML5_Game_ID": "xjpm",
        "EN_Name": "Jin Ping Mei 2",
        "Button_Image_Name": "xjpm.png"
      },
      {
        "CN_Name": "抢红包2",
        "HTML5_Game_ID": "qhb",
        "EN_Name": "Red Envelope2",
        "Button_Image_Name": "qhb.png"
      },
      {
        "CN_Name": "美人捕鱼",
        "HTML5_Game_ID": "fish_mm",
        "EN_Name": "Beauty Fishing",
        "Button_Image_Name": "fish_mm.png"
      },
      {
        "CN_Name": "雷霆战警",
        "HTML5_Game_ID": "fish_zj",
        "EN_Name": "X-Men",
        "Button_Image_Name": "fish_zj.png"
      },
      {
        "CN_Name": "捕鸟达人",
        "HTML5_Game_ID": "fish_bn",
        "EN_Name": "Birds Hunter",
        "Button_Image_Name": "fish_bn.png"
      },
      {
        "CN_Name": "欢乐捕鱼",
        "HTML5_Game_ID": "fish_hl",
        "EN_Name": "Fish Reef",
        "Button_Image_Name": "fish_hl.png"
      },
      {
        "CN_Name": "天天捕鱼",
        "HTML5_Game_ID": "fish_tt",
        "EN_Name": "Daily Fishing",
        "Button_Image_Name": "fish_tt.png"
      },
      {
        "CN_Name": "捕鱼来了3D",
        "HTML5_Game_ID": "fish_3D",
        "EN_Name": "Power Fishing 3D",
        "Button_Image_Name": "fish_3D.png"
      },


      {
        "CN_Name": "百人牛牛",
        "HTML5_Game_ID": "HB",
        "EN_Name": "Hundreds of Bull",
        "Button_Image_Name": "HB.png"
      },

      {
        "CN_Name": "骰宝",
        "HTML5_Game_ID": "tz",
        "EN_Name": "Sic Bo",
        "Button_Image_Name": "tz.png"
      },
      {
        "CN_Name": "百家乐",
        "HTML5_Game_ID": "bjl",
        "EN_Name": "Baccarat",
        "Button_Image_Name": "bjl.png"
      },


      {
        "CN_Name": "德州牛仔",
        "HTML5_Game_ID": "TexasCb",
        "EN_Name": "Texas Cowboy",
        "Button_Image_Name": "TexasCb.png"
      },
      {
        "CN_Name": "二人麻将",
        "HTML5_Game_ID": "mj",
        "EN_Name": "Two People Mahjong",
        "Button_Image_Name": "mj.png"
      },
      {
        "CN_Name": "抢庄牛牛",
        "HTML5_Game_ID": "cqznn",
        "EN_Name": "Rob Banker Of Bull",
        "Button_Image_Name": "cqznn.png"
      },
      {
        "CN_Name": "三公",
        "HTML5_Game_ID": "csg",
        "EN_Name": "San_Gong",
        "Button_Image_Name": "csg.png"
      },
      {
        "CN_Name": "斗地主",
        "HTML5_Game_ID": "ddz",
        "EN_Name": "Landlord",
        "Button_Image_Name": "ddz.png"
      },
      {
        "CN_Name": "经典炸金花",
        "HTML5_Game_ID": "zjhdr",
        "EN_Name": "Classical Win Three Cards",
        "Button_Image_Name": "zjhdr.png"
      },
      {
        "CN_Name": "梭哈",
        "HTML5_Game_ID": "ShowHand",
        "EN_Name": "ShowHand",
        "Button_Image_Name": "ShowHand.png"
      },
      {
        "CN_Name": "通比牛牛",
        "HTML5_Game_ID": "tbnn",
        "EN_Name": "Tongbi Cattle",
        "Button_Image_Name": "tbnn.png"
      },
      {
        "CN_Name": "十三水",
        "HTML5_Game_ID": "sss",
        "EN_Name": "Open-face Chinese Poker",
        "Button_Image_Name": "sss.png"
      },
      {
        "CN_Name": "二八杠",
        "HTML5_Game_ID": "ebg",
        "EN_Name": "Mahjong",
        "Button_Image_Name": "ebg.png"
      },
      {
        "CN_Name": "抢庄牌九",
        "HTML5_Game_ID": "qzpj",
        "EN_Name": "Pai Gow",
        "Button_Image_Name": "qzpj.png"
      },
      {
        "CN_Name": "极速炸金花",
        "HTML5_Game_ID": "jszjh",
        "EN_Name": "Topspeed Win Three Cards",
        "Button_Image_Name": "jszjh.png"
      },
      {
        "CN_Name": "欢乐德州",
        "HTML5_Game_ID": "iPoker",
        "EN_Name": "Fun Poker",
        "Button_Image_Name": "iPoker.png"
      },
      {
        "CN_Name": "欢乐红包",
        "HTML5_Game_ID": "hlhb",
        "EN_Name": "Happy Red Packet",
        "Button_Image_Name": "hlhb.png"
      },
      {
        "CN_Name": "欢乐麻将",
        "HTML5_Game_ID": "xzdd",
        "EN_Name": "Happy mahjong",
        "Button_Image_Name": "xzdd.png"
      },





      {
        "CN_Name": "水果机",
        "HTML5_Game_ID": "frt",
        "EN_Name": "Fruit",
        "Button_Image_Name": "frt.png"
      },
      {
        "CN_Name": "飞禽走兽",
        "HTML5_Game_ID": "bbs",
        "EN_Name": "Birds And Beasts",
        "Button_Image_Name": "bbs.png"
      },
      {
        "CN_Name": "奔驰宝马",
        "HTML5_Game_ID": "bzb",
        "EN_Name": "Benz And BMW",
        "Button_Image_Name": "bzb.png"
      },
      {
        "CN_Name": "森林舞会",
        "HTML5_Game_ID": "fod",
        "EN_Name": "Forest Dance",
        "Button_Image_Name": "fod.png"
      },

      {
        "CN_Name": "十二生肖",
        "HTML5_Game_ID": "chz",
        "EN_Name": "Chinese Zodiac",
        "Button_Image_Name": "chz.png"
      },
      {
        "CN_Name": "燃烧吧，足球",
        "HTML5_Game_ID": "bif",
        "EN_Name": "Burn It Football",
        "Button_Image_Name": "bif.png"
      },

      {
        "CN_Name": "黄金大转轮",
        "HTML5_Game_ID": "gw",
        "EN_Name": "Golden Whell",
        "Button_Image_Name": "gw.png"
      },
      {
        "CN_Name": "水果转盘",
        "HTML5_Game_ID": "fw",
        "EN_Name": "Fruit Turntable",
        "Button_Image_Name": "fw.png"
      },
      {
        "CN_Name": "三国赛马",
        "HTML5_Game_ID": "hr",
        "EN_Name": "Horse Race",
        "Button_Image_Name": "hr.png"
      },
      {
        "CN_Name": "连环夺宝",
        "HTML5_Game_ID": "si",
        "EN_Name": "Serial Indiana",
        "Button_Image_Name": "si.png"
      },
      {
        "CN_Name": "幸运5",
        "HTML5_Game_ID": "lucky",
        "EN_Name": "Lucky 5",
        "Button_Image_Name": "lucky.png"
      },
      {
        "CN_Name": "好运射击",
        "HTML5_Game_ID": "gls",
        "EN_Name": "Luck Shooting",
        "Button_Image_Name": "gls.png"
      },
      {
        "CN_Name": "猴子爬树",
        "HTML5_Game_ID": "mr",
        "EN_Name": "Monkey Race",
        "Button_Image_Name": "mr.png"
      },
      {
        "CN_Name": "浮岛历险记",
        "HTML5_Game_ID": "fia",
        "EN_Name": "Adventures",
        "Button_Image_Name": "fia.png"
      },
      {
        "CN_Name": "水果传奇",
        "HTML5_Game_ID": "fl",
        "EN_Name": "Fruit Legend",
        "Button_Image_Name": "fl.png"
      },
      {
        "CN_Name": "萌宠夺宝",
        "HTML5_Game_ID": "api",
        "EN_Name": "Adorable Pet Indiana",
        "Button_Image_Name": "api.png"
      },
      {
        "CN_Name": "王者足球",
        "HTML5_Game_ID": "tkof",
        "EN_Name": "The King Of Football",
        "Button_Image_Name": "tkof.png"
      }
    ]
  }]
}]

// 弹出不需要的游戏
if(global.ID== 'ca'){


  let vipgame = gameJSON[3].gamelist[0].gamelist;


  let tempArr =[];

  for(let i=0;i<vipgame.length;i++){
    if(vipgame[i].CN_Name == '灵猴献瑞'){
      continue;
    }
    tempArr.push(vipgame[i]);
  }

  gameJSON[3].gamelist[0].gamelist = tempArr;

}


export default gameJSON
